(function() {
  'use strict';

  angular.module('app.auth', ['ngMessages'])
    .controller('AuthController', AuthController);

  AuthController.$inject = [
    '$rootScope',
    'authService',
    '$state',
    '$timeout',
    '$scope',
    '$stateParams',
    '$uibModal',
    '$location',
    'logger',
    'coreUtilsService'
  ];

  function AuthController($rootScope,
                          authService,
                          $state,
                          $timeout,
                          $scope,
                          $stateParams,
                          $uibModal,
                          $location,
                          logger,
                          coreUtilsService) {
    var vm = this;
    $rootScope.showSplash = false;
    vm.email = authService.getUserName() || null;
    vm.company = null;
    vm.name = null;
    vm.password = null;
    vm.newPassword = null;
    vm.passwordRepeat = null;
    vm.phone = null;
    vm.isFetch = false;
    vm.rememberMe = false;
    vm.debounce = {debounce: 500};
    vm.ph_numbr = /^(\+?(\d{0}|\d{1}|\d{2}|\d{3})[- ]?)?\d{3}[- ]?\d{3}[- ]?\d{4}$/;
    vm.passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+=\[{\]};:<>|./?,-]).{8,}$/;
    vm.forcePasswordChange = false;
    vm.techAccount = coreUtilsService.techAccount;
    $scope.message = '';

    // FORCE_PASSWORD_CHANGE state
    if ($stateParams.oldPass) {
      vm.password = $stateParams.oldPass;
      vm.forcePasswordChange = true;
    }

    vm.showChangePasswordPage = function() {
      $timeout(function() {
        $state.go('changePassword', {oldPass: vm.password});
      });
    };

    vm.showLoginPage = function() {
      $timeout(function() {
        $state.go('login');
      });
    };

    vm.showPlayerPage = function() {
      $timeout(function() {
        $state.go('player');
      });
    };

    vm.showProfilePage = function() {
      $timeout(function() {
        $state.go('profile');
      });
    };

    vm.showStartPage = function() {
      var toState = vm.techAccount ? 'player' : 'start';
      $timeout(function() {
        if ($stateParams.back) {
          $state.go($stateParams.back).then(function() {
            $location.search($stateParams.queryParams);
          });
        } else {
          $state.go(toState);
        }
      });
    };

    vm.showRestorePage = function() {
      $timeout(function() {
        $state.go('restore-password');
      });
    };

    vm.signInHandler = function(event, hasLogin) {
      if (!hasLogin) {
        vm.showToS();
      } else {
        vm.showStartPage();
        vm.rememberUserHandler();
      }
    };

    vm.rememberUserHandler = function() {
      // TODO: set interval to refresh user token every 55min
      // $interval(authService.refreshUserToken, CONF.refreshUserTokenInterval);
    };

    vm.loginError = function(event, info) {
      vm.isFetch = false;
      if (info) {
        $scope.message = info.error;
      } else {
        $scope.message = 'Unknown error';
      }
    };

    vm.signIn = function() {
      vm.isFetch = true;
      $scope.message = '';
      vm.tempPassword = vm.password;
      authService.authUser(vm.email, vm.password, null, vm.rememberMe);
    };

    vm.changePassword = function(isValid) {
      if (isValid) {
        vm.isFetch = true;
        $scope.message = '';
        if (vm.forcePasswordChange) {
          authService.authUser(vm.email, vm.password, vm.newPassword, vm.rememberMe);
        } else {
          authService.changePassword(vm.email, vm.password, vm.newPassword).then(function(res) {
            if (res.status === 200) {
              logger.success('Password changed successfully');
              vm.showProfilePage();
            } else {
              return Promise.reject(res);
            }
          }).catch(function(error) {
            vm.isFetch = false;
            $scope.message = error.data ? error.data.error : error.message;
          });
        }
      }
    };

    vm.passwordRestore = function() {
      if (vm.email) {
        $rootScope.email = vm.email;
      }
      authService.passwordRestore($rootScope.email).then(function() {
        $state.go('confirm-restore-password');
      });
    };

    vm.confirmPasswordRestore = function(isValid) {
      if (isValid) {
        authService.confirmForgotPassword(vm.confirmCode, vm.newPassword, $rootScope.email).then(function() {
          vm.showLoginPage();
        });
      }
    };

    vm.resendConfirmation = function() {
      var data = {username: $rootScope.email};
      authService.resendConfirmation(data);
    };

    vm.register = function() {
      if (!(vm.phone.indexOf('+') > -1)) {
        vm.phone = '+' + vm.phone;
      }
      var userData = {
        email: vm.email,
        company: vm.company,
        name: vm.name,
        password: vm.password,
        passwordRepeat: vm.passwordRepeat,
        phone: vm.phone
      };
      authService.registerUser(userData).then(function(res) {
        $rootScope.email = res.data.email;
        $state.go('confirm-registration');
      });
    };

    vm.confirmRegistration = function() {
      var data = {code: vm.confirmCode, username: $rootScope.email};
      authService.confirmRegistration(data).then(function() {
        $state.go('login');
      });
    };

    vm.showToS = function() {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/auth/tos.html',
        backdrop: 'static',
        controller: 'tosController',
        controllerAs: 'tosCtrl',
        size: 'lg'
      });
      modalInstance.result.then(function() {
        authService.addUserToDB(vm.email).then(function() {
          vm.showStartPage();
          vm.rememberUserHandler();
        });
      }, function() {
        vm.isFetch = false;
      });
    };

    $scope.getUserName = function() {
      return authService.getUserName();
    };

    $scope.$on('authService:changePassword', vm.showChangePasswordPage);
    $scope.$on('authService:changePasswordSuccess', vm.showLoginPage);
    $scope.$on('authService:signin', vm.signInHandler);
    $scope.$on('authService:error', vm.loginError);
    $scope.$on('authService:showToS', vm.showToS);
  }
})();
