(function() {
  'use strict';

  angular
    .module('app.player')
    .component('queueComponent', {
      templateUrl: 'app/player/queue/queue.html',
      controller: queueController,
      bindings: {
        close: '&',
        resolve: '<'
      }
    });

  queueController.$inject = ['$window', 'fullVideosService', 'fullVideosUtilsService'];

  function queueController($window, fullVideosService, fullVideosUtilsService) {
    var vm = this;
    vm.active = [];
    vm.stored = [];

    this.$onInit = function() {
      this.active = this.resolve.activeGames;
      var runningAnalyzers = this.resolve.runningAnalyzers;
      fullVideosService.getStoredFullVideos().then(function(storedVideos) {
        vm.videoData = fullVideosUtilsService.fullVideosFormatter(storedVideos.data, runningAnalyzers);
        vm.stored = storedVideos.data;
      });
      this.activeElement = null;
    };

    this.formatDate = function(rawDate) {
      var date = new Date(rawDate);
      var year = date.getUTCFullYear();
      var month = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      return year + '-' + month;
    };

    this.gamesToFilter = function(games) {
      var datesByMonths = [],
        displayDate = null;
      games = games || [];
      games.forEach(function(game) {
        displayDate = vm.formatDate(game.gameDate);
        if (datesByMonths.indexOf(displayDate) === -1) {
          datesByMonths.push(displayDate);
        }
      });
      return datesByMonths;
    };

    this.moveToStored = function() {
      if (!this.activeElement) {
        return;
      }
      this.active.forEach(function(game, index) {
        if (vm.activeElement === game.ID) {
          vm.activeElement = null;
          var model = getModel(game, true);
          fullVideosService.updateGameData(model).then(function() {
            vm.active.splice(index, 1);
            vm.stored.push(game);
          });
        }
      });
    };

    this.moveToActive = function() {
      if (!this.activeElement) {
        return;
      }

      this.stored.forEach(function(game, index) {
        if (vm.activeElement === game.ID) {
          vm.activeElement = null;
          var model = getModel(game, false);
          fullVideosService.updateGameData(model).then(function() {
            vm.stored.splice(index, 1);
            vm.active.push(game);
          });
        }
      });
    };

    /**
     *
     * @param game
     * @param queue - 0 if game is in active queue, 1 if game is in stored queue
     */
    this.deleteGame = function(game, queue) {
      if ($window.confirm('Are you sure you want to delete game data, video file and all corresponding clips?')) {
        game.loading = true;
        fullVideosService.deleteVideo(game.ID).then(function() {
          var games = queue ? vm.stored : vm.active;
          for (var i = 0; i < games.length; i++) {
            if (game.ID === games[i].ID) {
              game.loading = false;
              games.splice(i, 1);
              break;
            }
          }
        });
      }
    };

    this.select = function(id) {
      this.activeElement = id;
    };

    function getModel(game, isStored) {
      var model = {};
      model.ID = game.ID;
      model.isStored = isStored;
      return model;
    }
  }
})();
