(function() {
  'use strict';

  angular
    .module('app.dashboard')
    .controller('DashboardController', DashboardController);

  DashboardController.$inject = ['authService', 'authenticatedUser'];

  function DashboardController(authService, authenticatedUser) {
    var $ctrl = this;
    authService.getUserFromDB(authenticatedUser.email).then(function(result) {
      $ctrl.user = result.data;
    });
  }
})();
