(function() {
  'use strict';

  angular
    .module('app.profile')
    .controller('InstagramSettingsController', InstagramSettingsController);

  InstagramSettingsController.$inject = ['authService'];

  function InstagramSettingsController(authService) {
    var vm = this;
    vm.userEmail = authService.getUserData().email;
    authService.getUserFromDB(vm.userEmail)
      .then(function(result) {
        vm.user = result.data;
        vm.instagramShareEmail = vm.user.userSettings.instagramShareEmail || vm.userEmail;

        if (!vm.user.userSettings.instagramShareEmail) {
          vm.user.userSettings.instagramShareEmail = vm.instagramShareEmail;
          vm.saveUser();
        }
      });

    vm.saveUser = function() {
      vm.user.userSettings.instagramShareEmail = vm.instagramShareEmail;
      authService.updateUser(vm.user);
    };
  }
})();
