(function() {

  angular.module('app.core')
    .directive('outputs', outputsDirective);

  outputsDirective.$inject = ['$timeout', 'fullVideosService', 'gameFieldsService', 'coreUtilsService', 'logger', 's3UploadService'];

  function outputsDirective($timeout, fullVideosService, gameFieldsService, coreUtilsService, logger, s3UploadService) {
    return {
      restrict: 'E',
      templateUrl: 'app/directives/outputs/outputs.html',
      scope: {
        controller: '='
      },
      link: function(scope) {
        scope.outputsLoading = false;
        scope.zippingInProgress = false;

        scope.$on('downloadClipsProcessingDone', function($event, eventData) {
          if (scope.controller.selectedGame.ID === eventData.videoID) {
            scope.zippingInProgress = false;
            coreUtilsService.saveToDisk(eventData.url);
          }
        });

        scope.refreshOutputs = function() {
          scope.outputsLoading = true;
          fullVideosService.getFullVideos().then(function(result) {
            if (result.data && result.data.length) {
              for (var i = 0; i < result.data.length; i++) {
                if (scope.controller.selectedGame.ID === result.data[i].ID) {
                  scope.controller.selectedGame.awsURL = result.data[i].awsURL;
                  checkURLs();
                  break;
                }
              }
            }
          }).finally(function() {
            scope.outputsLoading = false;
          });
        };

        scope.generateEventsList = function(gameObj) {
          return s3UploadService.getS3OutputJSON(gameObj)
            .then((res) => {
              var json = JSON.stringify(res);
              var blob = new Blob([json], {
                type: 'application/json'
              });
              var url = URL.createObjectURL(blob);
              var filename = gameObj.ID + '_' + gameObj.visitingTeam + '-at-' + gameObj.homeTeam + '_event-list.json';
              coreUtilsService.saveToDisk(url, filename);
            })
            .catch((err) => {
              if (err.code === 404) {
                return fullVideosService.generateEventsList(gameObj.ID)
                  .then((res) => res.data)
                  .then((output) => {
                    if (output) {
                      var json = JSON.stringify(output);
                      var blob = new Blob([json], {
                        type: 'application/json'
                      });
                      var url = URL.createObjectURL(blob);
                      var filename = gameObj.ID + '_' + gameObj.visitingTeam + '-at-' + gameObj.homeTeam + '_event-list.json';
                      coreUtilsService.saveToDisk(url, filename);
                      return;
                    }
                    return logger.error('Could not fetch the output JSON file');
                  })
              }
              return logger.error(err);
            })
        };

        scope.downloadAllClips = function(gameObj) {
          return fullVideosService.downloadAllClips(gameObj.ID)
            .then(function(response) {
              var data = response.data;
              if (data.status === 'processing') {
                scope.zippingInProgress = true;
              } else if (data.url) {
                coreUtilsService.saveToDisk(data.url);
              }
            })
            .catch(() => {});
        };

        checkURLs();

        function checkURLs() {
          if (scope.controller.selectedGame) {
            var highlightS3URL = scope.controller.selectedGame.highlightS3URL;
            var reelS3URL = scope.controller.selectedGame.reelS3URL;
            if (highlightS3URL) {
              gameFieldsService.checkURL(highlightS3URL, false, 'video/mp4').then(function() {
                scope.controller.selectedGame.hasHighlightReel = true;
              }).catch(function() {
                scope.controller.selectedGame.hasHighlightReel = false;
              });
            }
            if (reelS3URL) {
              gameFieldsService.checkURL(reelS3URL, false, 'video/mp4').then(function() {
                scope.controller.selectedGame.hasReelURL = true;
              }).catch(function() {
                scope.controller.selectedGame.hasReelURL = false;
              });
            }
          }
        }
      }
    };
  }

})();
