(function() {

  angular.module('app.core')
    .directive('analyzerRectangles', analyzerRectangles);

  analyzerRectangles.$inject = ['$timeout'];

  function analyzerRectangles($timeout) {
    var cachedRectangles = [];
    return {
      restrict: 'A',
      link: function(scope, element) {
        // fixed html5 video player height control block height
        var vm = this;
        vm.playerControlsOffset = 34;
        vm.showRectangles = false;
        vm.removeAllFramesTimeout = null;

        scope.$on('toggleFrames', function(event, showRectangles) {
          vm.showRectangles = showRectangles;
          if (showRectangles) {
            // immediately show cached rectangles
            cachedRectangles.forEach(function(rectangle) {
              element.prepend(rectangle);
            });
          } else {
            removeFrames();
          }
        });

        scope.$on('setAnalyzerFrames', function(event, params) {
          vm.removeAllFramesTimeout && $timeout.cancel(vm.removeAllFramesTimeout);
          vm.removeAllFramesTimeout = $timeout(transparentizeFrames, 3000);

          if (params && params.rectangles) {
            removeFrames();
            if (vm.showRectangles) {
              cachedRectangles = [];
              params.rectangles.forEach(function(rectangle, i) {
                var rectangleElement = getRectangleElement(rectangle, params['video-dimensions'], i);
                cachedRectangles.push(rectangleElement);
                element.prepend(rectangleElement);
              });
            }
          }
        });

        function getRectangleElement(rectangle, dimensions, index) {
          var element, styles, captionStyles;

          if (rectangle['caption-location'] !== 'inside' || rectangle['caption-location'] === 'inside' && !rectangle['caption']) {
            styles = rectangleStyles(rectangle, dimensions, [
              'width:' + (rectangle.width / dimensions.width * 100).toFixed(2) + '%',
              'height:' + (rectangle.height / dimensions.height * 100).toFixed(2) + '%'
            ]);
            captionStyles = rectangle['caption'] ?
              [
                'position: absolute',
                'font-weight: bold',
                'font-size: 12px',
                'background-color: white',
                'color: black',
                'padding: 1px',
                'white-space: nowrap'
              ].concat(getCaptionPosition(rectangle)).join('; ') :
              '';
            element = '<div id="analyzerRectangles' + index + '" style="' + styles + '"><span style="' + captionStyles + '">' + rectangle.caption + '</span></div>';
          } else {
            styles = rectangleStyles(rectangle, dimensions, [
              'color: white',
              'padding: 1px',
              'white-space: nowrap'
            ]);
            element = '<div id="analyzerRectangles' + index + '" style="' + styles + '">' + rectangle.caption + '</div>';
          }
          return element;
        }

        function rectangleStyles(rectangle, dimensions, additionalStyles) {
          var fill = (rectangle.fill === 'true' || rectangle.fill === true) ? rectangle.color : 'transparent';
          return [
            'transition: all 0.3s ease-in-out',
            'position: absolute',
            'z-index: 10000',
            'border: 1px solid ' + rectangle.color,
            'background-color: ' + fill,
            'bottom:' + ((rectangle.y) / dimensions.height * 100).toFixed(2) + '%',
            'left:' + (rectangle.x / dimensions.width * 100).toFixed(2) + '%',
          ].concat(additionalStyles).join('; ');
        }

        function getCaptionPosition(rectangle) {
          if (rectangle['caption-location'] === 'right') {
            return [
              'left: 105%',
              'top: -10px',
            ];
          }
          return [
            'left: -2px',
            'top: -22px',
          ];
        }

        function removeFrames() {
          return getVisibleFrames().remove();
        }

        function transparentizeFrames() {
          return getVisibleFrames().css({opacity: 0.5});
        }

        function getVisibleFrames() {
          return angular.element(document).find('[id^="analyzerRectangles"]');
        }
      }
    };
  }

})();
