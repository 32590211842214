(function() {
  'use strict';

  angular
    .module('app.layout')
    .component('appHeader', {
      templateUrl: 'app/navigation/app-header.html',
      controller: HeaderController,
    });

  HeaderController.$inject = ['$timeout', 'authService', '$state', 'logger', '$window', '$scope', 'CUSTOM_CONF', 'coreUtilsService'];

  /* @ngInject */
  function HeaderController($timeout, authService, $state, logger, $window, $scope, CUSTOM_CONF, coreUtilsService) {
    var vm = this;

    vm.techAccount = coreUtilsService.techAccount;
    vm.showCreatePage = CUSTOM_CONF.showCreatePage;

    vm.authorized = $window.localStorage['currentUserEmail'];
    vm.superuser = $window.localStorage['currentUserAdmin'] === 'true';
    vm.companyadmin = $window.localStorage['currentUserCompanyAdmin'] === 'true';

    $scope.$on('$locationChangeStart', function() {
      vm.authorized = $window.localStorage['currentUserEmail'];
      vm.superuser = $window.localStorage['currentUserAdmin'] === 'true';
      vm.companyadmin = $window.localStorage['currentUserCompanyAdmin'] === 'true';
    });

    vm.logout = function() {
      authService.logout()
        .catch(function(error) {
          logger.warning('Unable to logout:  ', error);
        })
        .finally(function() {
          $timeout(function(){
            window.location.reload(true);
          });
        });
    };
  }
})();
