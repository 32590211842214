(function() {
  'use strict';

  angular
    .module('app.core', [
      'ngAnimate', 'ngSanitize', 'ngMessages', 'ngclipboard',
      'blocks.exception', 'blocks.logger', 'blocks.router',

      'ui.router', 'ui.select', 'toaster',

      'app.auth'
    ]);
})();
