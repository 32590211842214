(function() {
  'use strict';

  angular
      .module('app.core')
      .factory('remoteAnalyzerService', remoteAnalyzerService);

  remoteAnalyzerService.$inject = ['$http', 'logger', '$window'];

  function remoteAnalyzerService($http, $window, logger) {
    var disableValue=false;
    return {
      analyzeURL: analyzeURL,
      getRunningAnalyzers: getRunningAnalyzers,
      getRTMPCredentials : getRTMPCredentials,
      checkStreamAvailability : checkStreamAvailability,
      removeRTMPCredentials : removeRTMPCredentials,
      showGameFields: true,
      showStreamPlayer: true,
      setDisable: function(data){
        disableValue= data;
      },
      getDisable: function(){
        return disableValue;
      }
    };

    function analyzeURL(videoID) {
      return $http({
        method: 'POST',
        url: '/api/detection/startAnalyzerStream',
        data: {
          videoID: videoID
        }
      }).catch(function(error) {
        // logger.info('cannot start analyzer: ', error);
        return Promise.reject(error);
      });
    }

    function getRunningAnalyzers(getAll) {
      // TODO: POST METHOD IS A HACK. INVESTIGATE NODE PROXY
      return $http({
        method: 'POST',
        url: '/api/detection/analyzerInstances' + (getAll ? '?all=true' : ''),
      });
    }

    function getRTMPCredentials(currentUser) {
      return $http({
        method: 'GET',
        url: '/api/rtmp/getRTMPKey',
        headers: {
          user: currentUser
        }
      });
    }

    function removeRTMPCredentials(rtmpId) {
      return $http({
        method: 'DELETE',
        url: '/api/rtmp/removeRTMPKey',
        headers: {
          rtmpId: rtmpId
        }
      });
    }

    function checkStreamAvailability(currentUser, streamURL) {
      return $http({
        method: 'GET',
        url: '/api/rtmp/checkStreamAvailability',
        headers: {
          user: currentUser,
          streamUrl: streamURL
        }
      });
    }
  }
})();
