(function() {
  'use strict';

  angular
    .module('app.profile')
    .controller('PrepostRollsController', PrepostRollsController);

  PrepostRollsController.$inject = ['authService'];

  function PrepostRollsController(authService) {
    let $ctrl = this;
    $ctrl.uploadInProgress = false;

    let email = authService.getUserData().email;
    authService.getUserFromDB(email).then(function(result) {
      $ctrl.user = result.data;
    });
  }
})();
