(function() {
  'use strict';

  angular.module('app', [
    'app.core',
    'app.profile',
    'app.auth',
    'app.layout',
    'app.navigation',
    'app.player',
    'app.remote-analyzer',
    'app.analyzerTask',
    'app.facebookShare',
    'app.streams-scheduler',
    'app.support',
    'app.limitToMax',
    'app.admin',
    'app.dashboard',
    'app.videoEditor',
    'ui.mask',
    'ui.bootstrap',
    'slickCarousel',
    'rzModule',
    'ngSanitize',
    'timer',
    'angular.chips',
    'dndLists',
    angularDragula(angular) // eslint-disable-line no-undef
  ]);
})();
