(function() {
  'use strict';

  angular
    .module('app.core')
    .component('reelyHighlightPackages', {
      templateUrl: 'app/directives/reely-highlight-packages/reely-highlight-packages.html',
      controller: reelyHighlightPackagesController,
      bindings: {
        fullVideoIds: '<',
        refresh: '<',
        user: '<?'
      }
    });

  reelyHighlightPackagesController.$inject = ['$timeout', '$window', 'clipsService', 'logger', 'fullVideosService', 'coreUtilsService'];

  function reelyHighlightPackagesController($timeout, $window, clipsService, logger, fullVideosService, coreUtilsService) {
    var vm = this;
    vm.isBusy = false;

    vm.$onInit = function() {};

    vm.$onChanges = function() {
      if (!vm.refresh || vm.cachedRefresh !== vm.refresh) {
        getList();
      }
    };

    vm.manualRefresh = function() {
      getList();
    };

    vm.downloadJSON = function(item) {
      return fullVideosService.generateEventsList(item.fullVideoIDs[0]).then(function(res) {
        if (res.data) {
          var json = JSON.stringify(res.data);
          var blob = new Blob([json], {
            type: 'application/json'
          });
          var url = URL.createObjectURL(blob);
          var filename = item.fullVideoIDs[0] + '_event-list.json';
          coreUtilsService.saveToDisk(url, filename).then(function () {
          }).catch(function () {
          }).finally(function () {
            URL.revokeObjectURL(url);
          });
        }
      });
    };

    vm.downloadAllClips = function(item) {
      return fullVideosService.downloadAllClips(item.fullVideoIDs[0])
        .then(function(response) {
          var data = response.data;
          if (data.status === 'processing') {
            vm.zippingInProgress = true;
          } else if (data.url) {
            coreUtilsService.saveToDisk(data.url);
          }
        })
        .catch(() => {});
    };

    vm.deleteHighlightPackage = function(highlightPackage) {
      return Promise.resolve()
        .then(() => {
          if ($window.confirm('This action is irreversible.\nAre you sure you want to delete this highlight package?')) {
            highlightPackage.deleting = true;
            return clipsService.deleteHighlightPackage(highlightPackage.ID)
              .finally(() => {
                vm.manualRefresh();
              });
          }
          return;
        })
        .catch((err) => logger.error('Could not remove highlight package at this moment'))
    }

    function getList() {
      if (vm.isBusy) {
        return;
      }
      vm.isBusy = true;
      return clipsService.getPackagesList(vm.fullVideoIds)
        .then(function(res) {
          vm.list = res.data;
        })
        .catch(logger.error)
        .finally(function() {
          vm.refresh = vm.refresh || Date.now();
          vm.cachedRefresh = vm.refresh;
          $timeout(function() {
            vm.isBusy = false;
          }, 1000);
          $timeout(function() {
            getList();
          }, 30 * 1000);
        });
    }
  }
})();
