(function() {
  'use strict';

  var config = {
    appErrorPrefix: '',
    appTitle: 'RocketReel'
  };

  angular
    .module('app.core')
    .value('config', config)
    .config(configure);

  configure.$inject = ['$logProvider', 'routerHelperProvider', 'exceptionHandlerProvider', 'SocketProvider', '$qProvider'];

  /* @ngInject */
  function configure($logProvider, routerHelperProvider, exceptionHandlerProvider, SocketProvider, $qProvider) {
    $qProvider.errorOnUnhandledRejections(false);

    if ($logProvider.debugEnabled) {
      $logProvider.debugEnabled(true);
    }
    exceptionHandlerProvider.configure(config.appErrorPrefix);
    routerHelperProvider.configure({docTitle: config.appTitle + ': '});
  }

})();

/**
 * filter for ordering clips objects.
 */
(function() {
  angular.module('app.core').filter('orderObjectBy', function() {
    return function(items, field, reverse) {
      if (!field) {
        return items;
      }
      var filtered = [];
      angular.forEach(items, function(item) {
        filtered.push(item);
      });
      filtered.sort(function(a, b) {
        return (a[field] > b[field] ? 1 : -1);
      });
      if (reverse) filtered.reverse();
      return filtered;
    };
  });
})();

(function() {

  angular.module('app.core')
    .directive('stoppropagation', function() {
      return {
        restrict: 'A',
        link: function(scope, element) {
          element.bind('click', function(event) {
            event.stopPropagation();
          });
        }
      };
    });

})();
