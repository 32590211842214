(function() {
  angular.module('app.core')
    .constant('CONF', {
      importanceColors: {
        level0: 'importance-level--low',
        level1: 'importance-level--low',
        level2: 'importance-level--low',
        level3: 'importance-level--low',
        level4: 'importance-level--low',
        level5: 'importance-level--mid',
        level6: 'importance-level--mid',
        level7: 'importance-level--mid',
        level8: 'importance-level--high',
        level9: 'importance-level--high',
        level10: 'importance-level--high'
      },

      // Hide elements that are not needed on current project stage
      hideFeatures: [
        'highlight_clip_field',
        'clipImageSlider',
        'mergeClip',
        'teamLogo'
      ],

      s3AlbumName: 'logos',

      datePickerFormat: 'M!/d!/yyyy',
      datePickerAltFormats: ['M!/d!/yyyy'],
      dateOptions: {
        dateDisabled: false,
        maxDate: new Date(),
        minDate: new Date(2010, 1, 1),
        startingDay: 1
      },

      clipsRefreshInterval: 5000,
      refreshUserTokenInterval: 3300000,

      allowGrayBg: [
        'player',
        'remote-analyzer',
        'profile',
        'profile.instagramSettings',
        'profile.twitterAccount',
        'profile.twitchWatchList',
        'profile.logos',
        'profile.apiAccess',
        'profile.teams',
        'profile.pushNotifications',
        'profile.prepostRolls',
        'admin',
        'admin.system',
        'admin.users',
        'admin.lists',
        'admin.resources',
        'admin.tasks',
        'admin.games',
        'admin-create-user',
        'create',
        'streams-scheduler',
        'support',
        'analyzer-task',
        'manage',
        'dashboard',
        '404',
        'videoEditor',
      ],

      controllerTypes: {
        stream: 'stream',
        manual: 'clip'
      },

      // colors class names for EC2 state display
      instanceStateColors: {
        running: 'active',
        processing: 'active',
        warning: 'pending',
        stopping: 'pending',
        stopped: 'inactive',
        pending: 'pending',
      },
    });
})();
