(function() {
  'use strict';

  angular
    .module('app.profile')
    .controller('LogosController', LogosController);

    LogosController.$inject = ['$state', '$window', '$uibModal', 'coreUtilsService', 's3UploadService', 'profileService', 'fullVideosService', 'authService'];

  function LogosController($state, $window, $uibModal, coreUtilsService, s3UploadService, profileService, fullVideosService, authService) {
    var vm = this;

    vm.userEmail = $window.localStorage['currentUserEmail'];
    vm.currentStateName = $state.current.name;
    vm.userFullVideos = [];
    vm.logoList = [];
    vm.logoSettings = [];
    vm.user = null;

    vm.getUser = function() {
      return authService.getUserFromDB(window.localStorage.currentUserEmail).then((res) => {
        vm.user = res.data;
      })
    }

    vm.toggleNotifications = function() {
      vm.isBusy = true;
      return authService.updateUser(vm.user)
        .catch((err) => {
          vm.user.userSettings.resourceNotifications = !vm.user.userSettings.resourceNotifications;
          vm.error = err.message || err;
          logger.warn(vm.error);
        })
        .finally(() => {
          vm.isBusy = false;
        });
    }

    vm.getLogos = function() {
      return profileService.getUserLogos()
        .then(function(result) {
          vm.logoList = result.data;
        });
    };

    vm.delete = function(inData) {
      var message = '';
      var matchedGames = vm.userFullVideos.length ? 
        vm.userFullVideos.filter((video) => video.logos ? video.logos.find(logo => logo.ID === inData.ID) : false) : [];
      
      if (matchedGames.length) {
        message = `You have ${matchedGames.length} games that are associated with this resource:\n\n`
        message += matchedGames.map((curr) => {
          return `${curr.gameDate} - ${curr.visitingTeam} vs. ${curr.homeTeam}\n`
        }).toString();
        message += '\n\nIf you delete it, you will NOT be able to rerun these games.\nAre you sure you want to delete this resource?';
      } else {
        message = 'Are you sure you want to delete this resource?';
      }
      
      if (confirm(message)) {
        vm.isBusy = true;
        // can distinguish between different resource types in the future if needed
        delete vm.user.logos[inData.ID];
        return authService.updateUser(vm.user)
          .catch((err) => {
            vm.error = err.message || err;
            logger.warn(vm.error);
          })
          .finally(() => {
            vm.refreshLogos();
            vm.isBusy = false;
          });
      }
      return;         
    };

    vm.getLogoName = function(logo) {
      return vm.user.logos ? vm.user.logos[logo.ID] ? vm.user.logos[logo.ID].alias || logo.name : '' : logo.name;
    }

    vm.getAllTags = function() {
      return vm.user.logos ? Object.keys(vm.user.logos).map((logoID) => vm.user.logos[logoID].tag).filter((tag, idx, self) => {
        return tag && self.indexOf(tag) === idx;
      }) : [];
    }

    vm.updateLogo = function(logo) {
      vm.isBusy = true;
      vm.user.logos[logo.ID].alias = logo.newName;
      vm.user.logos[logo.ID].tag = logo.newTag;
      return authService.updateUser(vm.user)
        .catch((err) => {
          vm.error = err.message || err;
          logger.warn(vm.error);
        })
        .finally(() => {
          vm.refreshLogos();
          vm.isBusy = false;
        });
    }

    vm.downloadLogo = function(inLogo) {
      const files = [`${inLogo.name}_thumbnail.png`, ...inLogo.files];
      const bucket = inLogo.S3directory.split('/')[0];
      const key = inLogo.S3directory.split('/').slice(1).join('/');
      return files.map(filename => {
        return coreUtilsService.saveToDisk(s3UploadService.getS3UrlForKey(`${key}${filename}`, bucket), filename);
      })
    }

    vm.$onInit = function() {
      return vm.refreshLogos()
        .then(vm.getUser())
        .then(() => {
          return fullVideosService.getFullVideos(`userID=${vm.userEmail}`);
        })
        .then((res) => {
          vm.userFullVideos = res.data || [];
        })
        .catch((err) => {
          vm.error = err.message || err;
          logger.warn(vm.error);
        })
    };

    vm.refreshLogos = function() {
      vm.loading = true;
      vm.logoList = [];
      return vm.getLogos()
        .finally(() => {
          vm.loading = false;
        })
    }
  }
})();
