(function() {

  angular.module('app.core')
    .directive('scoreboardDetection', scoreboardDetection);

  scoreboardDetection.$inject = ['$window', '$state', '$interval', 'logger', 'scoreboardDetectionService', 'analyzerUtilsService'];

  function scoreboardDetection($window, $state, $interval, logger, scoreboardDetectionService, analyzerUtilsService) {
    return {
      restrict: 'E',
      templateUrl: 'app/directives/scoreboard-detection/scoreboard-detection.html',
      scope: {
        videoId: '='
      },
      link: function(scope) {
        var vm = this;
        vm.playerControlsOffset = 34;
        vm.showRectangles = false;
        vm.removeAllFramesPromise = null;
        scope.userEmail = $window.localStorage['currentUserEmail'];
        scope.rectangles = [];
        scope.rectanglesIndexes = [];
        scope.data = {
          radioModel: null
        };
        scope.candidatesExist = false;

        var candidatesCache = [];
        var rectanglesTestIntervalDelay = 10000;
        var rectanglesTestInterval = null;
        var getCandidatesErrorCounter = 0;

        scope.$on('detectionStarted', function() {
          rectanglesTestInterval = $interval(setGetSBInterval, rectanglesTestIntervalDelay);
        });

        scope.$on('gameChanged', function(event, gameChangedMessage) {
          clearLayout();
          stopSBInterval();
          if (analyzerUtilsService.getCurrentGameId() === scope.videoId) {
            rectanglesTestInterval = $interval(setGetSBInterval, rectanglesTestIntervalDelay);
          }
        });

        scope.$on('$destroy', function() {
          stopSBInterval();
        });

        scope.getScoreboardRectangles = function() {
          scoreboardDetectionService.getScoreboardRectangles(scope.userEmail, scope.videoId).then(function(params) {
            if (!params.data) {
              return;
            }
            var result = params.data;
            var rootElem = angular.element(document.querySelector('#sbc'));
            if ($state.current.name === 'remote-analyzer') {
              rootElem = angular.element(document.querySelector('#hlsVideoWrapper'));
            }
            var rootElemDimensions = { x: rootElem[0].offsetWidth, y: rootElem[0].offsetHeight };
            if (result.rectangles) {
              scope.rectanglesIndexes = Object.keys(result.rectangles);
              scope.rectangles = result.rectangles;
              result.rectangles.forEach(function(rectangle, i) {
                angular.element('#scoreBoardCandidates' + i).remove();
              });
              result.rectangles.forEach(function(rectangle, i) {
                var rectangleElement = getRectangleElement(rectangle, result['video-dimensions'], rootElemDimensions, i);
                rootElem.prepend(rectangleElement);
              });
            }
          }, function(error) {
            logger.info('scoreboardDetectionService::getScoreboardRectangles - cannot get candidates: ', error);
          });
        };

        scope.setScoreBoard = function() {
          var rectangles = [];
          if (!scope.rectangles.length) {
            logger.info('No scoreboard candidates detected');
            return;
          }
          if (!scope.data.radioModel) {
            logger.info('Please select candidate');
            return;
          }
          if (candidatesCache.length < 2 || candidatesCache[0] === candidatesCache[1]) {
            logger.info('You should select two different elements');
            return;
          }
          scope.rectangles.forEach(function(item, index) {
            if (candidatesCache.indexOf(index.toString()) !== -1) {
              rectangles.push(item);
            }
          });
          var model = {
            userID: scope.userEmail,
            videoID: scope.videoId,
            requestRectangles: 0,
            resetProcessing: 0,
            scores: rectangles
          };
          scoreboardDetectionService.setUserRectangles(model).then(function() {
            logger.info('Scoreboard candidates are saved');
            clearLayout();
          });
        };

        scope.highlightSelectedCandidate = function(candidate) {
          var deleteCandidateIndex = null;
          if (candidatesCache.length < 2) {
            candidatesCache.push(candidate);
          } else {
            deleteCandidateIndex = candidatesCache.splice(0,1)[0];
            candidatesCache.push(candidate);
          }
          if (deleteCandidateIndex !== null) {
            scope.data.radioModel[deleteCandidateIndex] = false;
          }
          scope.rectanglesIndexes.forEach(function(item) {
            angular.element('#scoreBoardCandidates'+item).css('border-color', 'yellow');
            if (candidatesCache.indexOf(item.toString()) !== -1) {
              angular.element('#scoreBoardCandidates'+item).css('border-color', 'red');
            }
          });
        };

        function getRectangleElement(rectangle, dimensions, rootElemDimensions, index) {
          dimensions = dimensions || { width: 1280, height: 720 };
          var element, styles;
          styles = [
            'position: absolute',
            'z-index: 10000',
            'border: 1px solid yellow',
            'bottom:' + (rectangle.y * rootElemDimensions.y / dimensions.height).toFixed(2) + 'px',
            'left:' + (rectangle.x * rootElemDimensions.x / dimensions.width).toFixed(2) + 'px',
            'color: white',
            'padding: 1px',
            'white-space: nowrap',
            'width: ' + (rectangle.width * rootElemDimensions.x / dimensions.width).toFixed(2) + 'px',
            'height: ' + (rectangle.height * rootElemDimensions.y / dimensions.height).toFixed(2) + 'px'
          ].join('; ');
          element = '<div id="scoreBoardCandidates' + index + '" style="' + styles + '">' + index + '</div>';
          return element;
        }

        function clearLayout() {
          removeAllFrames();
          scope.rectanglesIndexes = [];
          scope.data = {
            radioModel: null
          };
        }

        function removeAllFrames() {
          angular.element(document).find('[id^="scoreBoardCandidates"]').remove();
        }

        function setGetSBInterval() {
          scoreboardDetectionService.getScoreboardRectangles(scope.userEmail, scope.videoId).then(function(params) {
            if (params.data && params.data.rectangles) {
              scope.candidatesExist = true;
              $interval.cancel(rectanglesTestInterval);
            }
          }).catch(function(error) {
            getCandidatesErrorCounter++;
            if (getCandidatesErrorCounter > 3) {
              stopSBInterval();
            }
            // allow user manually try to get candidates
            scope.candidatesExist = true;
          });
        }

        function stopSBInterval() {
          $interval.cancel(rectanglesTestInterval);
          getCandidatesErrorCounter = 0;
        }
      }
    };
  }

})();
