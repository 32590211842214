(function () {
  'use strict';

  angular
    .module('app.admin')
    .controller('AdminListsController', AdminListsController);

  AdminListsController.$inject = [
    '$timeout',
    'gameFieldsService'
  ];

  function AdminListsController($timeout, gameFieldsService) {
    var vm = this;
    vm.sportTypes = [];

    vm.filterEventTypeBySportType = function (sportType, showGeneralOnly) {
      return function (eventType) {
        if(!showGeneralOnly){
          return eventType.category === sportType.category &&
          (eventType.sportType === sportType.name);
        } else {
          return eventType.category === sportType.category &&
          (eventType.sportType ===  'General');
        }
      };
    };

    $timeout(function () {
      getSportTypes()
        .then(getEventTypes);
    }, 300);

    function getSportTypes() {
      return gameFieldsService.getSportTypes().then(function (res) {
        vm.sportTypes = res.data.sort(function (a, b) {
          return a.category > b.category ? 1 : -1;
        });
      });
    }

    function getEventTypes() {
      return gameFieldsService.getClipEventTypes().then(function (res) {
        vm.eventTypes = res.data;
      });
    }
  }
})();
