(function() {
  'use strict';

  angular
    .module('app.analyzerTask')
    .component('runningTask', {
      templateUrl: 'app/analyzerTask/running-task.html',
      controller: RunningTaskController,
      bindings: {
        isAdmin: '<'
      }
    });

  RunningTaskController.$inject = [
    '$window',
    '$state',
    '$timeout',
    '$uibModal',
    'CONF',
    'remoteAnalyzerService',
    'gameFieldsService',
    'fullVideosService',
  ];

  function RunningTaskController(
    $window,
    $state,
    $timeout,
    $uibModal,
    CONF,
    remoteAnalyzerService,
    gameFieldsService,
    fullVideosService
  ) {
    var vm = this;
    vm.runningTasks = [];

    vm.sorting = {
      key: 'createdAt',
      reverse: true
    };

    this.$onChanges = function() {
      // init
      vm.loadTasks();
    };

    vm.loadTasks = function() {
      if (vm.isLoading) {
        return Promise.resolve();
      }
      vm.isLoading = true;
      vm.runningTasks = [];

      return remoteAnalyzerService.getRunningAnalyzers(vm.isAdmin)
        .then(function(res) {
          var tasks = res.data.processList || res.data;
          tasks.forEach(function(task) {
            task.isStopping = (['Stopping', 'Analyzed'].includes(task.analyzerStatus)) ? true : false;

            task.statusClass = (task.isStopping) ? CONF.instanceStateColors['pending'] : CONF.instanceStateColors[task.status];
            task.startTime = new Date(task.createdAt).getTime();

            if (task.video) {
              task.sportType = task.video.sportType;
              task.gameDate = task.video.gameDate;

              task.streamStatusClass = CONF.instanceStateColors['pending'];
              task.streamStatusTooltip = 'Checking the stream feed.';

              return Promise.resolve()
                .then(function() {
                  if (!task.video.streamTwitchChannelName) {
                    return gameFieldsService.checkHLSURL(task.video.streamURL);
                  } else
                  if (task.video.streamTwitchChannelName) {
                    return gameFieldsService.checkTwitchChannel(task.video.streamTwitchChannelName);
                  }
                })
                .then(function() {
                  task.streamStatusClass = CONF.instanceStateColors['running'];
                  task.streamStatusTooltip = 'The stream feed looks like still alive';
                }).catch(function() {
                  task.streamStatusClass = CONF.instanceStateColors['stopped'];
                  task.streamStatusTooltip = 'The stream feed may be dead.';
                });
            }

            // parallel check hls url and update with CONF.instanceStateColors['warning']
          });

          vm.runningTasks = tasks;
          vm.changSorting();
        })
        .catch(() => {})
        .finally(() => {
          $timeout(function() {
            vm.isLoading = false;
          }, 2000);
        });
    };

    vm.reload = function() {
      return vm.loadTasks();
    };

    vm.goToDetails = function(task) {
      if (vm.isAdmin) {
        $uibModal.open({
          component: 'showVideoFilesComponent',
          windowClass: 'admin-show-video-files',
          backdrop: 'static',
          keyboard: false,
          resolve: {
            inUser: function() {
              return {
                email: task.userID,
              };
            },
            inUsers: function() {
              return null;
            },
            inTargetFullVideoID: function() {
              return task.fullVideoID;
            }
          }
        });
      } else {
        switch (task.detectionType) {
          case 'stream':
            $state.go('remote-analyzer', {
              fullVideoID: task.videoID
            });
            break;
          case 'clip':
          default:
            $state.go('player', {
              gameID: task.videoID
            });
        }
      }

    };

    vm.changSorting = function() {
      var reverseNum = vm.sorting.reverse ? -1 : 1;
      vm.runningTasks = vm.runningTasks.sort(function(a, b) {
        return (a[vm.sorting.key] > b[vm.sorting.key]) ? -reverseNum : reverseNum;
      });
    };

    vm.stop = function(task) {
      if (task.isStopping) {
        return;
      }
      if (!$window.confirm('Do you want to stop this task?')) {
        return;
      }
      task.isStopping = true;
      return fullVideosService.stopAnalyzer(task.ID).then(() => {
        vm.loadTasks();
      }).finally(() => {
        task.isStopping = false;
      });
    };


  }
})();
