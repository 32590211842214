(function() {
  'use strict';

  angular
    .module('app.core')
    .component('reelyHighlightPackagesSlider', {
      templateUrl: 'app/directives/reely-highlight-packages-slider/reely-highlight-packages-slider.html',
      controller: Controller,
      bindings: {
        refreshIntervalInSeconds: '<?',
        user: '<?'
      }
    });

  Controller.$inject = [
    '$window',
    '$timeout',
    '$uibModal',
    '$interval',
    'clipsService',
    'coreUtilsService'
  ];

  function Controller(
    $window,
    $timeout,
    $uibModal,
    $interval,
    clipsService,
    coreUtilsService
  ) {
    var vm = this;
    vm.pageSize = 6;
    vm.currentPage = 0;
    vm.totalPages = 0;
    vm.list = [];

    vm.$onInit = function() {
      vm.refreshInterval = (vm.refreshIntervalInSeconds || 30) * 1000;

      vm.refreshIntervalWorker = $interval(function() {
        vm.setList();
      }, vm.refreshInterval);
    };

    vm.$onChanges = function() {
      vm.setList();
    };

    vm.$onDestroy = function() {
      $interval.cancel(vm.refreshIntervalWorker);
    };

    vm.setList = function() {
      if (vm.isBusy) {
        return;
      }
      vm.isBusy = true;
      return vm.getLatestHighlightPackages()
        .then(function() {
          vm.totalPages = Math.ceil(vm.list.length / vm.pageSize);
        })
        .finally(function() {
          vm.isBusy = false;
        });
    };

    vm.getLatestHighlightPackages = function() {
      return clipsService.getPackagesList(null, 3 * 86400)
        .then(function(res) {
          vm.list = res.data;
        })
        .catch(function() {
          vm.list = [];
        });
    };

    vm.lastPage = function() {
      if (vm.currentPage !== 0) {
        vm.currentPage = vm.currentPage - 1;
      }
    };

    vm.nextPage = function() {
      if (vm.currentPage + 1 < vm.totalPages) {
        vm.currentPage = vm.currentPage + 1;
      }
    };

    vm.download = function(inItem) {
      if (inItem.isDownloading) {
        return;
      }
      inItem.isDownloading = true;
      coreUtilsService.saveToDisk(inItem.awsURL, inItem.packageName + '.mp4').finally(function() {
        $timeout(function() {
          inItem.isDownloading = false;
        });
      });
    };

    vm.open = function(inClip) {
      $window.open(inClip.awsURL, '_blank');
    };
  }
})();
