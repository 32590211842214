(function() {
  'use strict';

  angular
    .module('app.core')
    .factory('fullVideosService', fullVideosService);

  fullVideosService.$inject = ['$http', 'logger'];

  function fullVideosService($http, logger) {

    return {
      getFullVideos: getFullVideos,
      getFullVideosByDates: getFullVideosByDates,
      getFullVideoData: getFullVideoData,
      getStoredFullVideos: getStoredFullVideos,
      getStoredFullVideoByID: getStoredFullVideoByID,
      updateFullVideo: updateFullVideo,
      updateGameData: updateGameData,
      createGameVideo: createGameVideo,
      addVideoToAnalyzer: addVideoToAnalyzer,
      stopAnalyzer: stopAnalyzer,
      restartAnalyzer: restartAnalyzer,
      stopAnalyzerByFullVideoID: stopAnalyzerByFullVideoID,
      deleteVideo: deleteVideo,
      generateEventsList: generateEventsList,
      downloadAllClips: downloadAllClips,
      getRelatedAnalyzerTasks: getRelatedAnalyzerTasks,
      getRelatedTranscoderTasks: getRelatedTranscoderTasks,
      transferOwnership: transferOwnership
    };

    function getFullVideos(queryString) {
      return $http({
        method: 'GET',
        url: '/api/videos' + ((queryString) ? '?' + queryString : '')
      }).catch(function(error) {
        logger.info('error on get full videos list: ', error);
      });
    }

    function getFullVideosByDates(query) {
      return $http({
        method: 'GET',
        url: '/api/videosByDates/',
        data: query
      }).catch(function(error) {
        logger.info('error on get full videos by dates: ', error);
      });
    }

    function getFullVideoData(fullVideoID){
      return $http({
        method: 'GET',
        url: '/api/videos/' + fullVideoID
      });
    }

    function getStoredFullVideoByID(gameID) {
      return $http({
        method: 'GET',
        url: '/api/videos/' + gameID
      }).catch(function(error) {
        logger.info('error on get full videos list: ', error);
      });
    }

    function getStoredFullVideos() {
      return $http({
        method: 'GET',
        url: '/api/videosInStored'
      }).catch(function(error) {
        logger.info('error on get stored videos list: ', error);
      });
    }

    function updateFullVideo(gameID, game) {
      return $http({
        method: 'PUT',
        url: '/api/videos/' + gameID,
        data: game
      }).catch(function(error) {
        logger.info('error on game update: ', error);
      });
    }

    function updateGameData(model) {
      if (!model) {
        return;
      }

      if (model.countries) {
        model.countries = model.countries.map(function(item) {
          return item.name;
        }).join(';');
      }

      return $http({
        method: 'PUT',
        url: '/api/videos/',
        data: model
      }).catch(function(error) {
        logger.info('error on games update: ', error);
      });
    }

    function createGameVideo(game) {
      return $http({
        method: 'POST',
        url: '/api/videos/',
        data: game
      });
    }

    function addVideoToAnalyzer(videoId, optionalParams) {
      return $http({
        method: 'POST',
        url: '/api/detection/startAnalyzer',
        data: { 
          videoID: videoId,
          optionalParams: optionalParams
        }
      }).catch(function(error) {
        if(error.data && error.data.code === 'DETECTION_SERVER_BUSY'){
          logger.info(error.data.error);
        } else if (error.message) {
          logger.info(error.message);
        } else {
          logger.info('Video added to Scheduler, Detection Busy: ', error);
        }
        return Promise.reject(error);
      });
    }

    function stopAnalyzer(inID) {
      return $http({
        method: 'GET',
        url: '/api/detection/stopAnalyzer/' + inID,
      });
    }

    function stopAnalyzerByFullVideoID(videoId) {
      return $http({
        method: 'POST',
        url: '/api/detection/stopAnalyzer',
        data: {videoID: videoId}
      });
    }

    function restartAnalyzer(userID, videoId, restartValue) {
      return $http({
        method: 'POST',
        url: '/api/detection/uiServerRestartAnalyzer',
        data: {
          userID: userID,
          videoID: videoId,
          resetProcessing: restartValue
        }
      });
    }

    function deleteVideo(videoId) {
      return $http({
        method: 'DELETE',
        url: '/api/videos/' + videoId
      }).catch(function(error) {
        logger.info('error on deleting video: ', error);
      });
    }

    function generateEventsList(videoId) {
      return $http({
        method: 'GET',
        url: '/api/videos/' + videoId + '/eventList'
      }).catch(function(error) {
        logger.info('error on generate events list: ', error);
      });
    }

    function downloadAllClips(videoId) {
      return $http({
        method: 'GET',
        url: '/api/videos/' + videoId + '/downloadClips'
      }).catch(function(response) {
        logger.warning('Error on download clips: ' + response.data.error, response);
        return Promise.reject(response);
      });
    }

    function getRelatedAnalyzerTasks(fullVideoID) {
      return $http({
        method: 'GET',
        url: '/api/videos/' + fullVideoID + '/tasks'
      });
    }

    function getRelatedTranscoderTasks(fullVideoID) {
      return $http({
        method: 'GET',
        url: '/api/videos/' + fullVideoID + '/transcoderTasks'
      });
    }

    function transferOwnership(inRecords){
      return $http({
        method: 'POST',
        url: '/api/transferOwnership',
        data: inRecords
      });
    }
  }
})();
