(function() {
  'use strict';

  angular
    .module('app.core')
    .component('reelyVideo', {
      templateUrl: 'app/directives/reely-video/reely-video.html',
      controller: videoController,
      bindings: {
        domId: '@',
        url: '<',
        mp4: '<',
        twitch: '<',
        twitchIframeUrl: '<',
        trovo: '<',
        trovoIframeUrl: '<',
        preview: '<',
        autoPlay: '<',
        showDuration: '<',
        videoDuration: '<',
        video: '<?'
      }
    });

  videoController.$inject = ['$timeout', '$sce', 'coreUtilsService', 'clipsUtilsService'];

  function videoController($timeout, $sce, coreUtilsService, clipsUtilsService) {
    var vm = this;

    vm.mode = 'preview';

    vm.$onInit = function() {
      vm.domID = vm.domId || (Date.now() * Math.random()).toFixed(0);
      vm.domIDHls = vm.domID + '-hls';
      vm.domIDMp4 = vm.domID + '-mp4';
    };

    vm.$onChanges = function(changes) {
      if (!vm.preview) {
        vm.playVideo(vm.autoPlay);
      }

      if (changes.video) {
        vm.videoTimes = clipsUtilsService.getClipHHMMSS(changes.video.currentValue);
      }
    };

    vm.playVideo = function(shouldAutoPlay) {
      vm.autoPlay = shouldAutoPlay;

      if (vm.twitch) {
        vm.mode = 'twitch';
        var autoplayParam = (vm.autoPlay) ? 'autoplay=true' : 'autoplay=false';
        vm.twitchlIframeUrl = vm.twitchIframeUrl || $sce.trustAsResourceUrl('https://player.twitch.tv/?channel=' + vm.twitch + '&' + autoplayParam);
      } else 
      if (vm.trovo) {
        vm.mode = 'trovo';
        vm.trovoIframeUrl = vm.trovoIframeUrl || $sce.trustAsResourceUrl('https://trovo.live/embed/player?streamername=' + vm.trovo + '&fullscreen=0&hidecontrol=0&autoplay=1');
      } else
      if (vm.url) {
        vm.mode = 'url';
        var streamURL = vm.url;

        $timeout(function() {
          var hlsVideoDom = document.getElementById(vm.domIDHls);
          var hls = new Hls();
          hls.attachMedia(hlsVideoDom);
          hls.loadSource(streamURL);
          hls.on(Hls.Events.ERROR, function(name, data) {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  hlsVideoDom.pause();
                  hls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  hls.recoverMediaError();
                  break;
                default:
                  hls.destroy();
                  break;
              }
            }
          });
          hls.on(Hls.Events.MANIFEST_PARSED, function() {
            if (vm.autoPlay) {
              hlsVideoDom.play();
            }
          });
        }, 100);
      } else
      if (vm.mp4) {
        vm.mode = 'mp4';
        var mp4URL = coreUtilsService.encodeS3VideoUrl(vm.mp4);
        $timeout(function() {
          var mp4VideoDom = document.getElementById(vm.domIDMp4);
          if (!mp4VideoDom) {
            return;
          }
          mp4VideoDom.setAttribute('src', mp4URL);

          mp4VideoDom.addEventListener('loadedmetadata', function() {
            if (vm.autoPlay) {
              mp4VideoDom.play();
            }
          }, false);

          mp4VideoDom.load();
        }, 300);
      }
    };

    vm.join = function(list) {
      if (!list) {
        return;
      }
      return list.join(', ');
    }
  }
})();
