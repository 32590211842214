(function() {
  'use strict';

  angular.module('app.facebookShare', [
    'app.core'
  ])
  .run(function(facebookShareService, CUSTOM_CONF){
    facebookShareService.init(CUSTOM_CONF.fbAppId);
  });
})();
