(function() {
  'use strict';

  function CutClipError(message) {
    this.name = 'CutClipError';
    this.message = message;
  }

  angular
    .module('app.core')
    .factory('clipsUtilsService', clipsUtilsService);

  clipsUtilsService.$inject = ['videogularConfigService'];

  function clipsUtilsService(videogularConfigService) {

    return {
      secondsToHHMMSS: secondsToHHMMSS,
      hhMMSSToSeconds: hhMMSSToSeconds,
      clipHHMMSStoSeconds: clipHHMMSStoSeconds,
      getTotalTime: getTotalTime,
      mergeClips: mergeClips,
      createNewClip: createNewClip,
      formatClipBeforeSave: formatClipBeforeSave,
      checkClipTimeChanges: checkClipTimeChanges,
      groupGamesList: groupGamesList,
      getClipHHMMSS: getClipHHMMSS,
      CutClipError: CutClipError
    };

    // convert seconds to HHMMSS format. Needed for clips merging
    function secondsToHHMMSS(sec_num) {
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds = parseInt(sec_num - (hours * 3600) - (minutes * 60), 10);

      if (hours < 10) {
        hours = '0' + hours;
      }
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      return hours + ':' + minutes + ':' + seconds;
    }

    function hhMMSSToSeconds(timeField) {
      var timeInSeconds = 0;
      var items=timeField.trim().split(':');//trim the undefined values while splitting
      items.forEach(function(item, index) {
        (index === 0) && (timeInSeconds += parseInt(item * 3600));
        (index === 1) && (timeInSeconds += parseInt(item * 60));
        (index === 2) && (timeInSeconds += parseInt(item));
      });
      return timeInSeconds;
    }

    function clipHHMMSStoSeconds(clip) {
      var endTime = clip.timeInHHMMSS.endTime;
      var startTime = clip.timeInHHMMSS.startTime;
      var splittedEndTime = endTime.split(':');
      var splittedStartTime = startTime.split(':');
      var endTimeSeconds = (+splittedEndTime[0]) * 60 * 60 + (+splittedEndTime[1]) * 60 + (+splittedEndTime[2]);
      var startTimeSeconds = (+splittedStartTime[0]) * 60 * 60 + (+splittedStartTime[1]) * 60 + (+splittedStartTime[2]);
      clip.endTime = endTimeSeconds;
      clip.startTime = startTimeSeconds;
      return clip;
    }

    function getTotalTime(clips) {
      var totalTime = 0;
      clips.forEach(function(clip) {
        totalTime += (clip.endTime - clip.startTime);
      });
      return this.secondsToHHMMSS(Math.round(totalTime));
    }

    /**
     * method checks existing clips in list2 and adds to list1 only new clips
     * @param list1 - current clips list
     * @param list2Object - new clips object created by analyzer
     *
     */
    function mergeClips(list1, list2Object) {
      if (!list2Object || !list2Object.data.length) {
        return list1;
      }
      var list2 = list2Object.data;
      list2.forEach(function(item2) {
        var exists = false;
        list1.forEach(function(item1) {
          if (item1.ID === item2.ID) {
            item1.awsURL = item2.awsURL;
            exists = true;
          }
        });

        if (!exists) {
          list1.push(item2);
        }
      });
      return list1;
      // return angular.merge(list1, list2);
    }

    function createNewClip(selectedGame, currentTime, totalTime) {
      var startTime,
        endTime,
        startTimeInSeconds,
        endTimeInSeconds,
        cutBeforeSBEventMargin = parseInt(selectedGame.cutBeforeSBEventMargin || 10),
        cutAfterSBEventMargin = parseInt(selectedGame.cutAfterSBEventMargin || 10),
        newClipTemp,
        vm = this,
        minDurationInSeconds = 3;

      if (!videogularConfigService.API) {
        return;
      }

      if ((cutBeforeSBEventMargin + cutAfterSBEventMargin < minDurationInSeconds)) {
        cutBeforeSBEventMargin = 1;
        cutAfterSBEventMargin = 2;
      }

      startTimeInSeconds = currentTime - cutBeforeSBEventMargin > 0 ?
        currentTime - cutBeforeSBEventMargin :
        0;
      endTimeInSeconds = currentTime + cutAfterSBEventMargin < totalTime ?
        currentTime + cutAfterSBEventMargin :
        totalTime;
      startTime = vm.secondsToHHMMSS(startTimeInSeconds);
      endTime = vm.secondsToHHMMSS(endTimeInSeconds);

      newClipTemp = {
        fullVideoID: selectedGame.ID,
        startTime: startTimeInSeconds,
        endTime: endTimeInSeconds,
        highlightTime: null,
        timeInHHMMSS: {
          startTime: startTime,
          endTime: endTime,
          highlightTime: vm.secondsToHHMMSS(currentTime),
        },
        score: null,
        tags: angular.copy(selectedGame.tags),
        preview: null,
        importance: 0,
        eventType: null
      };

      return newClipTemp;
    }

    function formatClipBeforeSave(clip) {
      if (!clip.tags) {
        delete clip.tags;
      }
      return clip;
    }

    function checkClipTimeChanges(clipsInitialCopy, clip, fullCheck) {
      return clipsInitialCopy.some(function(item) {
        if (item.ID === clip.ID) {
          if (!fullCheck) {
            return (item.startTime === clip.startTime && item.endTime === clip.endTime);
          }
          return angular.equals(item, clip);
        }
      });
    }

    function groupGamesList(listItem) {
      var date = new Date((listItem.gameDate || listItem.createdAt)),
        year = date.getFullYear(),
        month = date.getMonth() + 1;

      return [month, year].join('-');
    }

    function getClipHHMMSS(clip) {
      var highLightTime = clip.highlightTime || clip.startTime;
      return {
        startTime: secondsToHHMMSS(clip.startTime),
        endTime: secondsToHHMMSS(clip.endTime),
        highlightTime: secondsToHHMMSS(highLightTime),
      };
    }
  }
})();
