(function() {
  'use strict';

  angular
    .module('app.auth')
    .run(appRun);

  appRun.$inject = ['routerHelper'];

  /* @ngInject */
  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [
      {
        state: 'login',
        config: {
          url: '/login',
          templateUrl: 'app/auth/auth-login.html',
          controller: 'AuthController',
          controllerAs: 'AuthCtrl',
          title: 'Auth',
          params: {
            back: null,
            queryParams: null
          }
        }
      },
      {
        state: 'changePassword',
        config: {
          url: '/change-password',
          templateUrl: 'app/auth/auth-change-password.html',
          controller: 'AuthController',
          controllerAs: 'AuthCtrl',
          title: 'Auth',
          params: {
            oldPass: null
          }
        }
      },
      {
        state: 'restore-password',
        config: {
          url: '/restore-password',
          templateUrl: 'app/auth/restore-password.html',
          controller: 'AuthController',
          controllerAs: 'AuthCtrl',
          title: 'Auth'
        }
      },
      {
        state: 'confirm-restore-password',
        config: {
          url: '/confirm-restore-password',
          templateUrl: 'app/auth/confirm-restore-password.html',
          controller: 'AuthController',
          controllerAs: 'AuthCtrl',
          title: 'Auth'
        }
      },
      {
        state: 'confirm-registration',
        config: {
          url: '/confirm-registration',
          templateUrl: 'app/auth/confirm-registration.html',
          controller: 'AuthController',
          controllerAs: 'AuthCtrl',
          title: 'Auth'
        }
      },
      {
        state: 'register',
        config: {
          url: '/register',
          templateUrl: 'app/auth/register.html',
          controller: 'AuthController',
          controllerAs: 'AuthCtrl',
          title: 'Auth'
        }
      }
    ];
  }

})();
