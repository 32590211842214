(function() {
  'use strict';
  angular
    .module('app.player')
    .directive('clip', Clip);

  Clip.$inject = ['clipsUtilsService'];

  function Clip() {
    return {
      bindToController: true,
      controller: ClipController,
      controllerAs: 'clipCtrl',
      restrict: 'E',
      templateUrl: 'app/directives/clips-tab/clip.tmpl.html',
      replace: true,
      scope: {
        model: '=',
        controller: '=',
        index: '=',
        length: '=',
        user: '<'
      }
    };
  }

  ClipController.$inject = [
    '$window', 'CONF', '$scope', 'clipsUtilsService', 'clipsService', 'videogularConfigService',
    '$timeout', 'coreUtilsService', 'gameFieldsService', 'analyzerUtilsService', 'remoteAnalyzerService', 'fullVideosService', '$stateParams', 'logger'
  ];

  /* @ngInject */
  function ClipController($window, CONF, $scope, clipsUtilsService, clipsService, videogularConfigService,
                          $timeout, coreUtilsService, gameFieldsService, analyzerUtilsService, remoteAnalyzerService, fullVideosService, $stateParams, logger) {
    var vm = this;
    vm.errorMessages = {};
    vm.timePattern = '^[0-5][0-9]:[0-5][0-9]:[0-5][0-9]$';
    vm.scorePattern = '^[0-9]{1,3}-[0-9]{1,3}$';
    vm.importancePattern = '^[0-9]{1,2}$';
    vm.tagPattern = '^[a-zA-Z0-9_]$';
    vm.selectedTags = [];
    vm.gifLoading = false;
    vm.gifLoadingTooltip = '';
    vm.clipLoading = false;
    vm.clipLoadingTooltip = '';
    vm.importanceClass = getImportanceClass;
    vm.toggleOptions = toggleOptions;
    vm.canMoveUp = canMoveUp;
    vm.canMoveDown = canMoveDown;
    vm.cutClipToDisk = cutClipToDisk;
    vm.navigateTo = navigateTo;

    vm.disabled = function(clipID) {
      var runningList = analyzerUtilsService.getRunningStreamAnalyzersList();
      var fullVideoID = $stateParams['fullVideoID'] || $stateParams['video_id'];
      var matchedTask = runningList.find(function(task) {
        return task.videoID === fullVideoID;
      });
      return (remoteAnalyzerService.getDisable() && matchedTask && analyzerUtilsService.getProcessState('analyzeStream'))
        || vm.clipLoading
        || vm.isProcessing(clipID);
    };

    $scope.$watch(() => vm.model.processing, (newVal) => {
      vm.clipLoading = newVal;
    })

    $scope.$watch(() => vm.model.eventType, (newVal, oldVal) => {
      vm.model.edited = true;
      newVal && newVal !== oldVal && vm.controller.debounceSave(vm.model);
    }, true)

    $scope.$on('blockStart', function(event, clipID) {
      if (clipID === vm.model.ID) {
        vm.clipLoading = true;
      }
    });

    $scope.$on('blockFinish', function(event, clipID) {
      if (clipID === vm.model.ID) {
        vm.clipLoading = false;
        vm.playClip(vm.model);
      }
    });

    vm.playClip = function(clip) {
      var startTime = clip.startTime;
      var endTime = clip.endTime;

      play(startTime, endTime, this.model.awsURL);
    };

    function play(startTime, endTime, url) {
      vm.controller.showClipsPlayer = true;
      if (videogularConfigService.API) {
        videogularConfigService.API.stop();
        if (url && vm.controller.config) {
          vm.controller.config.sources = videogularConfigService.updateSource(url);
        }
      }
      $timeout(function() {
        if (vm.controller.useTwitchIframePlayer) {
          var video = document.getElementById('remote-analyzer-clip-player');
          var sourceDom = document.getElementById('remote-analyzer-clip-player-source');
          if (sourceDom) {
            sourceDom.setAttribute('src', url);
            video.load();
          } else {
            var source = document.createElement('source');
            source.setAttribute('id', 'remote-analyzer-clip-player-source');
            source.src = url;
            source.type = 'video/mp4';
            video.appendChild(source);
          }
          video.addEventListener('loadedmetadata', function() {
            video.play();
          }, false);

        } else if (videogularConfigService.API) {
          vm.controller.playerCurrentTime = vm.controller.currentTime;
          if (vm.controller.controllerType !== CONF.controllerTypes.stream) {
            videogularConfigService.API.seekTime(startTime, false);
          }
          vm.controller.clipStopTime = endTime;
          videogularConfigService.API.play();
        }
      }, 700);
    }

    vm.isProcessing = function(inClipID) {
      if (vm.controller.processingClipIDs) {
        return vm.controller.processingClipIDs.includes(inClipID);
      } else {
        return false;
      }
    };

    vm.addGreyClass = function() {
      return ((vm.clipsProcessing && vm.clipsProcessing.indexOf(vm.model.ID) !== -1) || vm.clipLoading);
    };

    vm.createClip = function(clip) {
      vm.clipLoading = true;
      vm.videoSpinner = true;
      return gameFieldsService.checkURL((clip.awsS3URL || clip.awsURL), false, 'video/mp4')
        .catch(function() {
          vm.clipLoadingTooltip = 'Video file is being created. It will open in new tab once ready';
          return clipsService.cutClip(clip.ID);
        })
        .then(function() {
          return navigateTo(clip.awsURL);
        })
        .finally(function() {
          vm.clipLoading = false;
          vm.videoSpinner = false;
          vm.clipLoadingTooltip = '';
        });
    };

    vm.createGif = function(clip) {
      vm.gifLoading = true;
      return gameFieldsService.checkURL((clip.gifS3URL || clip.gifURL), false, 'image/gif')
        .catch(function() {
          vm.gifLoadingTooltip = 'GIF file is being created. It will open in new tab once ready';
          return clipsService.createGif(clip.ID).then(function(res) {
            var gifURL = res.data.gifURL || res.data.clip.gifURL;
            clip.gifURL = gifURL;
            vm.model.gifURL = gifURL;
          });
        })
        .then(function() {
          return navigateTo(clip.gifURL);
        })
        .finally(function() {
          vm.gifLoading = false;
          vm.gifLoadingTooltip = '';
        });
    };

    function cutClipToDisk(clip) {
      clip.isBusy = true;

      return Promise.resolve()
        .then(function() {
          var fullCheck = false;
          if (clip.ID && clipsUtilsService.checkClipTimeChanges(vm.controller.clipsInitialCopy, clip, fullCheck)) {
            return Promise.resolve();
          } else {
            return clipsService.cutClip(clip.ID);
          }
        })
        .then(function() {
          return coreUtilsService.saveToDisk(clip.awsURL, clip.awsURL.split('/')[clip.awsURL.split('/').length - 1]);
        })
        .finally(function() {
          clip.isBusy = false;
        });
    }

    function canMoveUp() {
      return vm.index > 0;
    }

    function canMoveDown() {
      return vm.index < vm.length - 1;
    }

    function toggleOptions() {
      if (vm.clipLoading) {
        return;
      }
      vm.expanded = !vm.expanded;
    }

    function getImportanceClass() {
      return vm.model && CONF.importanceColors['level' + vm.model.importance];
    }

    function navigateTo(url) {
      if (url) {
        $window.open(url, '_blank');
      }
    }

    vm.updateLeftValue = function(clip) {
      if (!clip.timeInHHMMSS.startTime) {
        clip.timeInHHMMSS.startTime = clipsUtilsService.secondsToHHMMSS(vm.controller.startTime);
      }
      var startTime = clip.startTime;
      clip = clipsUtilsService.clipHHMMSStoSeconds(clip);
      clip.chunkStartTime += clip.startTime - startTime;
      if (!vm.model) {
        return;
      }
      vm.controller.startTime = clip.startTime;
      if (clip.startTime > Math.floor(vm.controller.totalTime) && vm.controller.controllerType === CONF.controllerTypes.manual) {
        clip.startTime = Math.floor(vm.controller.totalTime);
      } else if (clip.startTime > clip.endTime && vm.controller.controllerType === CONF.controllerTypes.manual) {
        clip.startTime = clip.endTime;
      }
      if (clip.endTime - clip.startTime > 10 * 60) {
        clip.startTime = startTime;
        vm.controller.startTime = clip.startTime;
        logger.error("Clip length must be < 10 minutes");
      }
      vm.model.timeInHHMMSS = {
        startTime: clipsUtilsService.secondsToHHMMSS(clip.startTime),
        endTime: clipsUtilsService.secondsToHHMMSS(clip.endTime),
        highlightTime: clipsUtilsService.secondsToHHMMSS(vm.model.highlightTime)
      };
      vm.model.edited = true;
      vm.controller.debounceSave(vm.model);
    };

    vm.updateRightValue = function(clip) {
      if (!clip.timeInHHMMSS.endTime) {
        clip.timeInHHMMSS.endTime = clipsUtilsService.secondsToHHMMSS(vm.controller.endTime);
      }
      var endTime = clip.endTime;
      clip = clipsUtilsService.clipHHMMSStoSeconds(clip);
      clip.chunkEndTime += clip.endTime - endTime;
      if (!vm.model) {
        return;
      }
      vm.controller.endTime = clip.endTime;
      if (clip.endTime > Math.floor(vm.controller.totalTime) && vm.controller.controllerType === CONF.controllerTypes.manual) {
        clip.endTime = Math.floor(vm.controller.totalTime);
      } else if (clip.endTime < clip.startTime && vm.controller.controllerType === CONF.controllerTypes.manual) {
        clip.endTime = clip.startTime;
      } 
      if (clip.endTime - clip.startTime > 10 * 60) {
        clip.endTime = endTime;
        vm.controller.endTime = clip.endTime;
        logger.error("Clip length must be < 10 minutes");
      }
      vm.model.timeInHHMMSS = {
        startTime: clipsUtilsService.secondsToHHMMSS(clip.startTime),
        endTime: clipsUtilsService.secondsToHHMMSS(clip.endTime),
        highlightTime: clipsUtilsService.secondsToHHMMSS(vm.model.highlightTime)
      };
      vm.model.edited = true;
      vm.controller.debounceSave(vm.model);
    };
  }
})();
