(function() {
  'use strict';

  angular
    .module('app.admin')
    .controller('AdminSystemController', AdminSystemController);

  AdminSystemController.$inject = ['$window', '$scope', '$interval', '$uibModal', '$timeout', 'adminService', 'logger'];

  function AdminSystemController($window, $scope, $interval, $uibModal, $timeout, adminService, logger) {
    var vm = this;

    vm.getSystemInfo = function getSystemInfo() {
      vm.isCheckingSystemInfo = true;
      adminService.getSystemInfo()
        .then(function(response) {
          vm.systemInfo = response.data;
        })
        .catch(function(error) {
          logger.error(error.data ? error.data.message : 'error get system info');
        })
        .finally(function() {
          vm.isCheckingSystemInfo = false;
        });
    };

    vm.toggleSystemInfo = function() {
      vm.showSystemInfo = !vm.showSystemInfo;
      if (vm.showSystemInfo) {
        vm.getSystemInfo();
      }
    };

    vm.toggleSystemInfo();

  }
})();
