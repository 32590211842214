(function() {
  'use strict';
  angular
    .module('app.admin')
    .component('addNewPushNotificationComponent', {
      templateUrl: 'app/profile/pushNotifications/addNewPushNotification.html',
      controller: addNewPushNotificationController,
      controllerAs: '$ctrl',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
    });

  addNewPushNotificationController.$inject = ['authenticatedUser', 'profileService', 'logger'];

  function addNewPushNotificationController(authenticatedUser, profileService, logger) {
    var vm = this;

    vm.data = {
      topics: [],
      method: 'email',
      email: authenticatedUser.email,
      apiParams: {
        url: 'https://',
        method: 'POST',
        headers: {}
      },
      headers: [{
        key: '',
        value: '',
      }]
    };

    vm.getTopics = function() {
      return profileService.getPushNotificationTopics()
        .then(function(result) {
          vm.topics = result.data;
        });
    };

    vm.$onInit = function() {
      vm.getTopics();
      vm.data = vm.resolve.data || vm.data;

      if (!vm.data.headers) {
        vm.data.headers = [];
        Object.keys(vm.data.apiParams.headers).forEach(function(key) {
          vm.data.headers.push({
            key: key,
            value: vm.data.apiParams.headers[key]
          });
        });
      }
    };

    vm.addMoreHeaders = function() {
      vm.data.headers.push({
        key: '',
        value: ''
      });
    };

    vm.save = function() {
      vm.isBusy = true;

      vm.data.apiParams.headers = {};
      vm.data.headers.forEach(function(header) {
        if (header.key && header.key !== '') {
          vm.data.apiParams.headers[header.key] = header.value;
        }
      });

      return profileService.registerPushNotification(vm.data)
        .then(function() {
          vm.close();
        })
        .catch(function(res) {
          logger.warn(res.message);
        })
        .finally(function() {
          vm.isBusy = false;
        });
    };
  }
})();
