/*global io:true */
(function() {
  'use strict';

  angular
    .module('app.core')
    .provider('Socket', SocketProvider);

  function SocketProvider() {
    // https://github.com/socketio/socket.io-client/blob/master/docs/API.md#with-extraheaders
    function getSocket(inChannelName) {
      var socket = io('/', {
        transports: ['websocket'],
        upgrade: false,
        query: {
          channel: inChannelName
        }
        // transportOptions: {
        //   polling: {
        //     extraHeaders: {
        //       channel: inChannelName
        //     }
        //   }
        // }
      });
      return socket;
    }

    this.$get = function() {
      return {
        getSocket: getSocket
      };
    };
  }
})();
