(function () {
  'use strict';

  angular
    .module('app.layout')
    .controller('StartController', StartController);

  StartController.$inject = ['$rootScope', '$timeout', 'serverStatusService', 'CUSTOM_CONF'];

  /* @ngInject */
  function StartController($rootScope, $timeout, serverStatusService, CUSTOM_CONF) {
    var vm = this;
    vm.showCreatePage = CUSTOM_CONF.showCreatePage;
    vm.showOverlay = true;
    vm.startPageOverlayText = 'Setting up servers. One moment, please.';

    $rootScope.$on('detectionServerStatusUpdated', function (event, detectionServerStatus) {
      if (detectionServerStatus === 'running') {
        vm.showOverlay = false;
      } else {
        vm.showOverlay = true;
      }
    });

    serverStatusService.checkDetectionServerStatus()
      .then(function () {
        vm.showOverlay = false;
      })
      .catch(function(){
        // wait for root scope
        vm.showOverlay = true;
      });
  }
})();
