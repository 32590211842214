(function() {
  'use strict';
  angular
    .module('app.admin')
    .component('removePushNotificationComponent', {
      templateUrl: 'app/profile/pushNotifications/removePushNotification.html',
      controller: removePushNotificationController,
      controllerAs: '$ctrl',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
    });

  removePushNotificationController.$inject = ['profileService', 'logger'];

  function removePushNotificationController(profileService, logger) {
    var vm = this;

    vm.remove = function() {
      vm.isBusy = true;
      console.log(vm.resolve.data.ID);

      return profileService.removePushNotification(vm.resolve.data.ID)
        .then(function() {
          vm.close();
        })
        .catch(function(res) {
          logger.warn(res.message);
        })
        .finally(function() {
          vm.isBusy = false;
        });
    };
  }
})();
