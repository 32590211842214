(function() {
  'use strict';
  angular
    .module('app.admin')
    .filter('secondsToDateTime', [function() {
      return function(seconds) {
        return new Date(1970, 0, 1).setSeconds(seconds);
      };
    }])
    .filter('bytes', function() {
      return function(bytes, precision) {
        if (!bytes || isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
          return '-';
        }
        precision = precision || 1;
        var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
          number = Math.floor(Math.log(bytes) / Math.log(1024));
        return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
      };
    })
    .component('showResourcesComponent', {
      templateUrl: 'app/admin/show-resources.html',
      controller: showResourcesController,
      controllerAs: '$ctrl',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
    });

    showResourcesController.$inject = ['$timeout', '$scope', 'fullVideosService', 'profileService', 's3UploadService', 'coreUtilsService', 'authService', 'logger'];

  function showResourcesController($timeout, $scope, fullVideosService, profileService, s3UploadService, coreUtilsService, authService, logger) {
    var vm = this;
    vm.controllerName = 'showResources';
    vm.error = '';
    vm.isBusy = false;
    vm.formMode = 'upload';
    vm.logoUploadList = [];
    vm.selectedLogos = [];
    vm.logoList = [];
    vm.userLogos = [];
    vm.userFullVideos = [];
    vm.resourceTypes = {
      logo: 'logos'
    }
    vm.logoTypes = ['CPU', 'GPU'];

    vm.$onInit = function() {
      vm.user = vm.resolve.inUser;
      vm.addLogoSlot();
      return vm.refreshResources()
        .then(() => {
          return fullVideosService.getFullVideos(`userID=${vm.user.email}`);
        })
        .then((res) => {
          vm.userFullVideos = res.data || [];
        })
        .catch((err) => {
          vm.error = err.message || err;
          logger.warn(vm.error);
        })
    };

    vm.addLogoSlot = function(name) {
      vm.logoUploadList.push({
        modelFiles: null,
        modelFileURLs: null,
        modelFilesAreValid: false,
        thumbnailFile: null,
        thumbnailFileURL: null,
        thumbnailFileIsValid: false,
        uploadInProgress: false,
        uploadProgress: 0,
        name: name,
        type: 'CPU',
        resetThumbnailFile: function(inputElement) {
          this.thumbnailFile = null;
          this.thumbnailFileURL = null;
          this.thumbnailFileIsValid = false;
          inputElement.value = "";
        },
        resetModelFiles: function(inputElement) {
          this.modelFiles = null;
          this.modelFileURLs = null;
          this.modelFilesAreValid = false;
          inputElement.value = "";
        }
      });
    }

    vm.removeLogoSlot = function(logo) {
      vm.logoUploadList = vm.logoUploadList.filter(el => el !== logo);
    }

    vm.formValidation = function() {
      if (vm.formMode === 'pick') {
        return vm.selectedLogos.length > 0;
      }

      return vm.logoUploadList.every(logo => {
        return logo.modelFilesAreValid && logo.thumbnailFileIsValid;
      })
    }

    vm.refreshResources = function() {
      vm.loading = true;
      vm.logoList = [];
      vm.userLogos = [];
      return vm.getResources()
        .finally(() => {
          vm.loading = false;
        })
    }

    vm.getResources = function() {
      // can distinguish between different resource types in the future if needed
      return profileService.getLogos()
        .then(function(result) {
          vm.logoList = result.data;
          vm.userLogos = vm.filterResourcesByUser('logo');
        });
    };

    vm.filterResourcesByUser = function(resourceType) {
      if (resourceType === 'logo') return vm.logoList.filter(logo => Object.keys(vm.user.logos).includes(logo.ID));
    }

    vm.delete = function(inData) {
      var message = '';
      var matchedGames = vm.userFullVideos.length ? 
        vm.userFullVideos.filter((video) => video.logos ? video.logos.find(logo => logo.ID === inData.ID) : false) : [];
      
      if (matchedGames.length) {
        message = `User has ${matchedGames.length} games that are associated with this resource:\n\n`
        message += matchedGames.map((curr) => {
          return `${curr.gameDate} - ${curr.visitingTeam} vs. ${curr.homeTeam}\n`
        }).toString();
        message += '\n\nAre you sure you want to delete this resource?';
      } else {
        message = 'Are you sure you want to delete this resource?';
      }
      
      if (confirm(message)) {
        vm.isBusy = true;
        // can distinguish between different resource types in the future if needed
        delete vm.user.logos[inData.ID];
        return authService.updateUser(vm.user)
        .catch((err) => {
          vm.error = err.message || err;
          logger.warn(vm.error);
        })
        .finally(() => {
          vm.refreshResources();
          vm.isBusy = false;
        });
      }
      return;         
    };

    vm.getAllTags = function() {
      return vm.user.logos ? Object.keys(vm.user.logos).map((logoID) => vm.user.logos[logoID].tag).filter((tag, idx, self) => {
        return tag && self.indexOf(tag) === idx;
      }) : [];
    }

    vm.updateLogo = function(logo) {
      return profileService.updateLogo(logo.ID, logo)
        .then(() => {
          vm.user.logos[logo.ID].tag = logo.newTag;
          return authService.updateUser(vm.user);
        }).finally(() => vm.refreshResources());
    }

    vm.downloadLogo = function(inLogo) {
      const files = [`${inLogo.name}_thumbnail.png`, ...inLogo.files];
      const bucket = inLogo.S3directory.split('/')[0];
      const key = inLogo.S3directory.split('/').slice(1).join('/');
      return files.map(filename => {
        return coreUtilsService.saveToDisk(s3UploadService.getS3UrlForKey(`${key}${filename}`, bucket), filename);
      })
    }

    $scope.chooseModelFiles = function(inputElement, logo) {
      var modelFiles = inputElement.files;
      logo.error = null;
      if (logo.type === 'CPU') {
        if (Array.from(modelFiles).filter(file => file.name.includes('.json')).length !== 1) {
          logo.error = 'There should be exactly one JSON file included in the CPU logo mode';
          logo.resetModelFiles(inputElement);
        }
      } else if (logo.type === 'GPU') {
        const cfgCount = Array.from(modelFiles).filter(file => file.name.includes('.cfg')).length;
        const weightsCount = Array.from(modelFiles).filter(file => file.name.includes('.weights')).length;
        if (cfgCount !== 1 || weightsCount !== 1) {
          logo.error = 'There should be exactly one CFG and one WEIGHTS file included in the GPU logo mode';
          logo.resetModelFiles(inputElement);
        }
      }

      if (modelFiles.length) {
        logo.modelFiles = null;
        logo.modelFileURLs = null;
        $timeout(function() {
          logo.modelFiles = modelFiles;
          if (Array.from(logo.modelFiles).some(file => file.size > 250*1024*1024)) {
            logo.resetModelFiles(inputElement);
            vm.error = "Some files exceeded max size of 250MB";
            return;
          }
          logo.modelFileURLs = Array.from(logo.modelFiles).map(file => URL.createObjectURL(file));
          logo.modelFilesAreValid = true;
          logo.name = logo.modelFiles[0].name.split('.')[0];
          vm.error = '';
        }, 100);
      } else {
        logo.resetModelFiles(inputElement);
        $scope.$digest();
      }
    };

    $scope.chooseThumbnailFile = function(inputElement, logo) {
      var thumbnailFiles = inputElement.files;
      if (thumbnailFiles.length) {
        logo.thumbnailFile = null;
        logo.thumbnailFileURL = null;
        $timeout(function() {
          logo.thumbnailFile = thumbnailFiles[0];
          if (logo.thumbnailFile.size > 10*1024*1024) {
            logo.resetThumbnailFile(inputElement);
            vm.error = "File exceeded max size of 10MB";
            return;
          }
          logo.thumbnailFileURL = URL.createObjectURL(logo.thumbnailFile);
          logo.thumbnailFileIsValid = true;
          vm.error = '';
        }, 100);
      } else {
        logo.resetThumbnailFile(inputElement);
        $scope.$digest();
      }
    };

    $scope.$on('fileChunkUploaded', function(event, data) {
      // TODO progress for each file?
      vm.uploadProgressPercent = data.progress;
      $scope.$apply();
    });

    vm.upload = function(resourceType, ID, file, resourcename, filename) {
      var fileToUpload = file;
      var presetID = ID || UUID.genV1().toString();
      var extension = file.name.slice(file.name.indexOf('.'));
      var fileName = filename || file.name.split('.')[0];
      var resourceName = resourcename || fileName;
      var S3directory = `${s3UploadService.getS3Prefix()}/${vm.resourceTypes[resourceType]}/${resourceName}_${presetID}/`;
      var fileKey = `${S3directory}${fileName}${extension}`;

      return s3UploadService.createMultipartUpload(fileToUpload, fileKey, 'rocketreel-user-resources', {}, fileName.includes('thumbnail') ? undefined : 'private')
        .then(() => {
          return {
            ID: presetID,
            S3directory: `rocketreel-user-resources/${S3directory}`
          };
        })
        .catch((err) => {
          //TODO cleanup after failure
          logger.error(err);
          return err;
        })
    };

    vm.assignLogos = function() {
      vm.isBusy = true;

      if (!vm.user.logos) vm.user.logos = {};
      vm.selectedLogos.forEach(logo => vm.user.logos[logo.ID] = {});

      return authService.updateUser(vm.user)
      .catch((err) => {
        vm.error = err.message || err;
        logger.warn(vm.error);
      })
      .finally(() => {
        vm.refreshResources();
        vm.isBusy = false;
      }); 
    }

    vm.uploadChain = function(modelFiles, data, next) {
      if (!next) return;
      const file = modelFiles.find(el => el === next);
      return vm.upload('logo', data.ID, file, data.name)
        .then((res) => {
          if (!res) {
            throw 'File upload failed';
          }
          data.S3directory = res.S3directory;
          data.ID = res.ID;
          return true;
        })
        .then(() => {
          let nextIdx = modelFiles.findIndex(el => el === next)+1;
          let nextFile = undefined;
          if (nextIdx !== modelFiles.length) nextFile = modelFiles[nextIdx];
          return vm.uploadChain(modelFiles, data, nextFile);
        })
    }

    vm.logoChain = function(logo, outputLogos = []) {
      if (!logo) return outputLogos;
      let currentLogoIdx = vm.logoUploadList.findIndex(el => el === logo);
      vm.logoUploadList[currentLogoIdx].uploadStatus = 'processing';
      var data = {
        ID: UUID.genV1().toString(),
        name: logo.name || logo.modelFiles[0].name.split('.')[0],
        files: Array.from(logo.modelFiles).map(file => file.name),
        S3directory: undefined,
        type: logo.type
      }

      return vm.uploadChain(Array.from(logo.modelFiles), data, Array.from(logo.modelFiles)[0])
      .then(() => vm.upload('logo', data.ID, logo.thumbnailFile, data.name, `${data.name}_thumbnail`))
      .then(() => profileService.createLogo(data))
      .catch(() => {
        vm.logoUploadList[currentLogoIdx].uploadStatus = 'failed';
      })
      .then((logo) => {
        if (logo) outputLogos.push(logo);
        vm.logoUploadList[currentLogoIdx].uploadStatus = 'done';
        let nextIdx = currentLogoIdx+1;
        let nextLogo = undefined;
        if (nextIdx !== vm.logoUploadList.length) nextLogo = vm.logoUploadList[nextIdx];
        return vm.logoChain(nextLogo, outputLogos);
      })
    }

    vm.saveLogos = function() {
      vm.isBusy = true;
      
      if (!vm.formValidation()) {
        vm.error = "Form invalid. Please check if all the fields are filled correctly and try again.";
        return;
      }

      return vm.logoChain(vm.logoUploadList[0])
      .then((createdLogos) => {
        createdLogos = createdLogos.map(response => response.data);
        if (!vm.user.logos) vm.user.logos = {};
        return Promise.all(createdLogos.map(logo => {
          vm.user.logos[logo.ID] = {};
          return authService.updateUser(vm.user);
        }))
      })
      .then(() => {
        vm.logoUploadList = vm.logoUploadList.filter(el => el.uploadStatus === 'failed');
        vm.selectedLogos = [];
      })
      .catch((err) => {
        vm.error = err.message || err;
        logger.warn(vm.error);
        // TODO cleanup only when failed to save to the DB
      })
      .finally(() => {
        vm.refreshResources();
        vm.isBusy = false;
      });
    };
  }
})();
