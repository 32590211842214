(function() {
  angular.module('app.core')
    .directive('hideMarkers', hideMarkers);

  hideMarkers.$inject = ['$timeout'];

  function hideMarkers($timeout) {
    var timeoutFunction;
    return {
      restrict: 'A',
      scope: {},
      link: function(scope, element) {
        element.bind('mousemove', function() {
          var bars = angular.element(element[0].querySelectorAll('vg-controls'));
          bars.removeClass('hide-controls');
          $timeout.cancel(timeoutFunction);
          timeoutFunction = $timeout(function() {
            bars.addClass('hide-controls');
          }, 5000);
        });
        element.bind('mouseout', function() {
          var bars = angular.element(element[0].querySelectorAll('vg-controls'));
          bars.addClass('hide-controls');
        });
      }
    };
  }

})();
