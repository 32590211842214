(function() {
  'use strict';

  angular
    .module('app.core')
    .component('addGameWizard', {
      templateUrl: 'app/directives/add-game-wizard/add-game-wizard.html',
      controller: controller,
      bindings: {
        mode: '@',
        fullVideo: '<',
        display: '@',
        displayText: '@'
      }
    });

  controller.$inject = [
    '$uibModal', '$state', '$stateParams'
  ];

  function controller($uibModal, $state, $stateParams) {
    var vm = this;

    vm.openModal = function() {
      vm.isOpen = true;
      return $uibModal
        .open({
          component: 'addGameWizardModal',
          backdrop: 'static',
          windowClass: 'add-game-wizard-model',
          size: 'lg',
          keyboard: false,
          resolve: {
            mode: function() {
              return vm.mode;
            },
            fullVideo: function() {
              return vm.fullVideo;
            }
          }
        }).result
        .then((function() {
          // reload page
          $state.go('.', $stateParams, {
            reload: true
          });
        }))
        .finally(function() {
          vm.isOpen = false;
        });
    };

    vm.$onInit = function() {
      // vm.openModal();
      vm.display = vm.display || 'float';
      vm.displayText = vm.displayText || 'Add';
    };

  }
})();
