(function() {
  'use strict';
  angular
    .module('app.admin')
    .component('addNewTwitchSubscriptionComponent', {
      templateUrl: 'app/profile/twitch/addNewTwitchSubscription.html',
      controller: addNewTwitchSubscriptionController,
      controllerAs: '$ctrl',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
    });

  addNewTwitchSubscriptionController.$inject = ['gameFieldsService', 'profileService', 'logger'];

  function addNewTwitchSubscriptionController(gameFieldsService, profileService, logger) {
    var vm = this;
    vm.groupSportTypeList = gameFieldsService.groupSportTypeList;
    vm.error = '';

    vm.data = {
      twitchUserID: null,
      twitchUserName: null,
      expiresAt: null,
      autoRenew: 1,
      logs: [],
      sportType: null,
    };

    gameFieldsService.getSportTypes().then(function(res) {
      vm.sportTypes = res.data.filter(function(item) {
        return item.category === 'Esports';
      });
    });

    vm.$onInit = function() {};

    vm.onSelectSportType = function(item) {
      vm.data.sportType = item.name;
    };

    vm.save = function() {
      vm.isBusy = true;
      return profileService.createTwitchSubscription(vm.data)
        .then(function() {
          vm.close();
        })
        .catch(function(res) {
          vm.error = "Could not process your request. Something wrong with the Twitch subscription service.";
          logger.warn(res.message);
        })
        .finally(function() {
          vm.isBusy = false;
        });
    };
  }
})();
