(function() {
  'use strict';

  angular
    .module('app.analyzerTask')
    .controller('AnalyzerTaskController', AnalyzerTaskController);

  AnalyzerTaskController.$inject = [
    '$state',
    'authenticatedUser'
  ];

  function AnalyzerTaskController(
    $state,
    authenticatedUser
  ) {
    var vm = this;
    vm.isAdmin = (authenticatedUser.isAdmin && $state.current.name === 'admin.tasks');
  }
})();
