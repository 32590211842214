(function() {
  'use strict';

  angular
    .module('app.admin')
    .controller('AdminUsersController', AdminUsersController);

  const ALL = 'All';
  const NOT_APPROVED = 'Not approved';
  const APPROVED = 'Approved';

  AdminUsersController.$inject = ['$window', '$scope', '$interval', '$uibModal', '$timeout', 'adminService', 'logger', 'authService'];

  function AdminUsersController($window, $scope, $interval, $uibModal, $timeout, adminService, logger, authService) {
    var vm = this;

    vm.updateListInterval = 60 * 1000;
    vm.initUserListDelayTime = 200;
    vm.loading = true;
    vm.proStatusChanging = false;
    vm.users = [];
    vm.statuses = [ALL, NOT_APPROVED, APPROVED];
    vm.defaultUserSettings = {};
    vm.filter = {
      user: '',
      company: '',
      status: ALL
    };

    if ($window.localStorage['currentUserAdmin'] !== 'true' && $window.localStorage['currentUserCompanyAdmin'] !== 'true') {
      return authService.logout();
    }

    vm.currentLoggenInList = [];

    $scope.$on('$destroy', function() {
      $interval.cancel(vm.checkUserList);
    });

    function init() {
      vm.getAvailableRoles();

      vm.isAdministrator = $window.localStorage['currentUserAdmin'] === 'true';

      $timeout(function() {
        vm.list();
      }, vm.initUserListDelayTime);
    }

    vm.checkUserList = $interval(function() {
      vm.list();
      if (vm.showSystemInfo) {
        vm.getSystemInfo();
      }
    }, vm.updateListInterval);

    vm.isLoadingList = false;
    vm.list = function list() {
      if (vm.isLoadingList) {
        return;
      }
      vm.isLoadingList = true;
      adminService.userList().then(function(response) {
        vm.users = response.data;
        vm.loading = false;
        vm.lastUpdatedAt = new Date();
      })
      .then(function () {
        return adminService.getDefaultUserSettings();
      })
      .then(function (response) {
        return vm.defaultUserSettings = response.data;
      })
      .then(function () {
        return adminService.getUsersOwningApiKeys();
      })
      .then((result) => {
        result.data.forEach(userIDKeyIDPair => {
          vm.users.find(user => user.email === userIDKeyIDPair.userID).apiAccessKeyID = userIDKeyIDPair.ID;
        });
      })
      .catch(function(error) {
        logger.error(error.data ? error.data.message : 'error list users');
      }).finally(function() {
        vm.isLoadingList = false;
      });
    };

    vm.checkDefaultExpiryTimes = function (user) {
      return angular.equals(user.userSettings.expiryTimes, vm.defaultUserSettings.expiryTimes);
    }

    vm.approve = function approve(user) {
      adminService.userApprove(user.email).then(function() {
        logger.info('Successfully approved ' + user.email);
        user.approved = true;
      }).catch(function(error) {
        logger.error(error.data ? error.data.message : 'error approve user');
      });
    };

    vm.kickOut = function kickOut(user) {
      if (!$window.confirm('Are you sure to kick out the logged in user: ' + user.email + ' ?')) {
        return;
      }
      adminService.userKickOut(user.email).then(function() {
        logger.info('Successfully kick out ' + user.email);
        vm.list();
      }).catch(function(res) {
        logger.warn(res.data ? (res.data.message || res.data.error) : 'error kick out user');
      });
    };

    vm.ban = function ban(user) {
      if (!$window.confirm('Are you sure to ban the user: ' + user.email + ' ?')) {
        return;
      }
      adminService.userBan(user.email).then(function() {
        logger.info('Successfully banned ' + user.email);
        user.approved = false;
      }).catch(function(error) {
        logger.error(error.data ? error.data.message : 'error ban user');
      });
    };

    vm.toggleUserProStatus = function(user) {
      if (vm.proStatusChanging) {
        return;
      }
      vm.proStatusChanging = true;
      var toggledStatus = !user.pro;
      adminService.toggleUserProStatus(user.email, toggledStatus).then(function() {
        logger.info('toggle pro status success: ' + user.email);
        user.pro = toggledStatus;
      }).finally(function() {
        vm.proStatusChanging = false;
      });
    };

    vm.remove = function(user) {
      if (!$window.confirm('Remove operation is irreversible. Are you sure you want to delete ' + user.email + ' account?')) {
        return;
      }

      adminService.userRemove(user.email).then(function() {
        logger.info('Successfully removed ' + user.email);
        var idx = vm.users.findIndex(function(u) {
          return u.email === user.email;
        });
        vm.users.splice(idx, 1);
      }).catch(function(error) {
        logger.error(error.data ? error.data.message : 'error remove user');
      });
    };

    vm.userFilter = function(user) {
      return approveFilter(user) && emailFilter(user) && companyFilter(user);
    };

    vm.showRunningTasks = function(user) {
      if (!user.tasks || user.tasks.length === 0) {
        return;
      }

      $uibModal.open({
        component: 'showTasksComponent',
        backdrop: 'static',
        windowClass: 'admin-show-video-files',
        keyboard: false,
        resolve: {
          inTitle: function() {
            return user.email + ' Running Tasks';
          },
          inTasks: function() {
            return user.tasks;
          }
        }
      });
    };

    vm.showVideoFiles = function(user) {
      $uibModal.open({
        component: 'showVideoFilesComponent',
        windowClass: 'admin-show-video-files',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          inUser: function() {
            return user;
          },
          inUsers: function() {
            return vm.users;
          }
        }
      });
    };

    vm.showResources = function(user) {
      $uibModal.open({
        component: 'showResourcesComponent',
        windowClass: 'admin-show-video-files',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          inUser: function() {
            return user;
          }
        }
      });
    };

    vm.getAvailableRoles = function() {
      return adminService.getAvailableRoles().then(function(response) {
        vm.roles = response.data;
      });
    };

    vm.changeRole = function(user) {
      return adminService.changeRole(user.email, user.role).then(function() {
        logger.info('Successfully changed role for user ' + user.email + '. Changes are visible after login.');
      });
    };

    vm.editUser = function(user) {
      if (!user.ID) {
        return;
      }
      var modalInstance = $uibModal.open({
        component: 'editUserComponent',
        windowClass: 'admin-edit-user',
        backdrop: 'static',
        resolve: {
          inUser: function() {
            return user;
          },
          inDefaults: function() {
            return vm.defaultUserSettings;
          }
        }
      });
      modalInstance.result.then(function() {
        vm.list();
      });
    };

    init();

    function approveFilter(user) {
      if (vm.filter.status !== ALL) {
        return user.approved === (vm.filter.status === APPROVED);
      }
      return true;
    }

    function companyFilter(user) {
      if (vm.filter.company && vm.filter.company.length > 0) {
        return (user.profile && user.profile.toLowerCase().indexOf(vm.filter.company.toLowerCase()) !== -1) ||
          (user.profile && user.profile.toLowerCase().indexOf(vm.filter.company.toLowerCase()) !== -1);
      }
      return true;
    }

    function emailFilter(user) {
      if (vm.filter.user && vm.filter.user.length > 0) {
        return (user.name && user.name.toLowerCase().indexOf(vm.filter.user.toLowerCase()) !== -1) ||
          (user.email && user.email.toLowerCase().indexOf(vm.filter.user.toLowerCase()) !== -1);
      }
      return true;
    }
  }
})();
