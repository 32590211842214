(function() {
  'use strict';

  angular.module('app.auth')
    .factory('authenticatedUser', authenticatedUser);

  authenticatedUser.$inject = [];

  function authenticatedUser() {
    this.timestamp = Date.now();
    this.setUser = function(inUser) {
      Object.assign(this, inUser);

      this.hasRightFor = function(inRightName) {
        if (!this.rights || !inRightName) {
          return false;
        }
        return this.rights.indexOf(inRightName) !== -1;
      };
    };
    return this;
  }
})();
