(function() {

  angular.module('app.facebookShare')
    .directive('facebook', ['$http', 'clipsService', '$location', function($http, clipsService, $location) {
      return {
        restrict: 'E',
        replace: true,
        template: '<button type="button" class="btn btn-info">' +
          '<i class="fa fa-facebook-official" aria-hidden="true" uib-tooltip="Facebook" tooltip-append-to-body="true"></i>' +
          '</button>',
        scope: {
          clipId: '=',
          callback: '='
        },
        transclude: true,
        link: function(scope, element) {
          element.unbind();
          element.bind('click', function(e) {
            var api = 'api/clip/' + scope.clipId + '/page';
            $http.get('/' + api)
              .then(function() {
                /**
                 *  Clear open graph cache
                 */
                return $http.get('https://graph.facebook.com/?id=' + $location.absUrl() + api + '&scrape=true');
              })
              .then(function() {
                FB.ui({
                  method: 'share',
                  href: $location.absUrl() + api
                }, function(response) {
                  if (scope.callback !== undefined && typeof scope.callback === 'function') {
                    scope.callback(response);
                  }
                });
              });
            e.preventDefault();
          });
        }
      };
    }]);
})();
