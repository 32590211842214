(function() {
  'use strict';

  angular
    .module('app.core')
    .service('SocketIOService', SocketIOService);

  SocketIOService.inject = ['Socket', '$http'];

  function SocketIOService(Socket, $http) {
    this.getChannel = getChannel;
    this.setDefaultChannel = setDefaultChannel;
    this.getLastMessage = getLastMessage;

    function setDefaultChannel(channelName){
      this.channelName = channelName;
      this.socket =  Socket.getSocket(channelName); //get socket instance
      return this.socket;
    }
    function getChannel(channelName) {
      var socket = Socket.getSocket(channelName); //get socket instance
      // socket.on('connect', console.log);
      // socket.on('connected', console.log);
      // socket.on('test', console.log);
      return socket;
    }

    function getLastMessage(gameID) {
      return $http({
        method: 'POST',
        url: '/api/io/lastMessage',
        data: {
          channel: this.channelName,
          gameID
        }
      });
    }
  }
})();
