angular
  .module('app.core').directive('fileUploader', function() {
  return {
    restrict: 'AE',
    scope: {
      file: '@',
      controller: '=',
      fileChange: '&'
    },
    link: function(scope, el) {
      el.bind('change', function(event) {
        scope.controller.fileToUpload = event.currentTarget.children['file'].files[0];
        if (event.currentTarget.children['fileName'])
          event.currentTarget.children['fileName'].innerText = scope.controller.fileToUpload.name
      });
    }
  };
});
