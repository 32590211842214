(function() {

  angular.module('app.player')
    .directive('draggableClipsList', function() {
      return {
        restrict: 'E',
        templateUrl: 'app/directives/draggable-clips-list/draggable-clips-list.html',
        scope: {
          controller: '='
        },
        controllerAs: '$ctrl'
      };
    });
})();
