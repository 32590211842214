(function() {

  angular.module('app.core')
    .directive('videoMarkersPlayer', ['$document', '$timeout', '$rootScope', 'clipsUtilsService', 'videogularConfigService', 'CONF',
      function($document, $timeout, $rootScope, clipsUtilsService, videogularConfigService, CONF) {
        return {
          restrict: 'E',
          templateUrl: 'app/directives/markers-player/markers-player.html',
          require: '^videogular',
          scope: {
            startTimeClip: '=startClipTime',
            endTimeClip: '=endClipTime',
            controller: '='
          },
          link: function(scope) {
            scope.videogularConfigService = videogularConfigService;
            scope.rangeStyle = {};
            scope.totalLabelStyle = {};
            scope.labelStyle = {};

            scope.slider = {
              minValue: 0,
              maxValue: 1000,
              showTicks: true,
              options: {
                floor: 0,
                ceil: 10000,
                step: 1,
                draggableRange: true,
                hideLimitLabels: true,
                hidePointerLabels: false,
                mergeRangeLabelsIfSame: false,
                onChange: onSliderChange,
                translate: function(value, id, ceil) {
                  if (ceil === 'high') {
                    return '<div class="scrub-time label-primary js-video-angular-marker-label-start video-marker-angular-label">' + getLabelValue(value) + '</div>';
                  }
                  if (ceil === 'model') {
                    return '<div class="scrub-time label-primary js-video-angular-marker-label-end video-marker-angular-label">' + getLabelValue(value) + '</div>';
                  }

                }
              }
            };

            function getLabelValue(time) {
              var leftOffset = document.querySelector('.rz-pointer-min').offsetLeft;
              var rightOffset = document.querySelector('.rz-pointer-max').offsetLeft;

              if (rightOffset - leftOffset < 100 && scope.slider.minValue !== scope.slider.maxValue) {
                for (var i = 0; i <= 4; i++) {
                  document.querySelectorAll('.rz-bubble')[i].style.display = 'none';
                }
                document.querySelector('.js-video-angular-marker-label-total').style.visibility = 'visible';
                scope.totalTime = getTotalTime(scope.slider.minValue, scope.slider.maxValue);
                document.querySelector('.js-video-angular-marker-label-total').style.left = leftOffset - 23 + (rightOffset - leftOffset) / 2 + 'px';
              }
              else if (scope.slider.minValue === scope.slider.maxValue) {
                for (i = 0; i <= 4; i++) {
                  document.querySelectorAll('.rz-bubble')[i].style.display = 'none';
                }
                document.querySelector('.js-video-angular-marker-label-total').style.visibility = 'visible';
                document.querySelector('.js-video-angular-marker-label-total').style.left = rightOffset - 13 + 'px';
                scope.totalTime = clipsUtilsService.secondsToHHMMSS(time);
              }
              else {
                for (i = 0; i <= 4; i++) {
                  document.querySelectorAll('.rz-bubble')[i].style.display = 'block';
                }
                document.querySelector('.js-video-angular-marker-label-total').style.visibility = 'hidden';
              }
              return clipsUtilsService.secondsToHHMMSS(time);
            }

            function onSliderChange() {
              var clip = scope.controller.clip;
              clip.edited = true;

              var startTime = clip.startTime;
              clip.startTime = scope.slider.minValue;
              clip.chunkStartTime += clip.startTime - startTime;

              var endTime = clip.endTime;
              clip.endTime = scope.slider.maxValue;
              clip.chunkEndTime += clip.endTime - endTime;

              clip.highlightTime = clip.highlightTime || scope.slider.minValue;
              clip.timeInHHMMSS = {
                startTime: clipsUtilsService.secondsToHHMMSS(scope.slider.minValue),
                endTime: clipsUtilsService.secondsToHHMMSS(scope.slider.maxValue),
                highlightTime: clipsUtilsService.secondsToHHMMSS(clip.highlightTime)
              };
              handleTimelineSeek(scope.slider.minValue, scope.slider.maxValue);

              // scope.controller.debounceSave(clip);
            }

            function handleTimelineSeek(start, end) {
              if (start) {
                videogularConfigService.API.seekTime(start, false);
              }
              if (end) {
                scope.controller.clipStopTime = end;
              }
            }

            function getTotalTime(startTime, endTime) {
              return clipsUtilsService.secondsToHHMMSS(startTime) + '-' + clipsUtilsService.secondsToHHMMSS(endTime);
            }

            scope.$watch('startTimeClip', function() {
              $timeout(updateSliderData);
            });

            scope.$watch('endTimeClip', function() {
              $timeout(updateSliderData);
            });

            function getTotalTimeFromPlayer() {
              return (videogularConfigService.API.totalTime / 1000);
            }

            function getTotalTimeFromCurrentVideo() {
              if (scope.controller.selectedGame
                && scope.controller.selectedGame.videoInfo
                && scope.controller.selectedGame.videoInfo.duration) {
                return scope.controller.selectedGame.videoInfo.duration;
              }
            }

            function getTotalTimeFromClips() {
              clipsUtilsService.hhMMSSToSeconds(scope.controller.totalTime);
            }

            function updateSliderData() {
              scope.slider.minValue = scope.startTimeClip;
              scope.slider.maxValue = scope.endTimeClip;
              scope.slider.options.floor = Math.max(scope.startTimeClip - 20, 0);
              if (scope.controller.controllerType === CONF.controllerTypes.stream) {
                scope.slider.options.ceil = scope.endTimeClip + 20;
              } else {
                var totalTime = getTotalTimeFromPlayer() || getTotalTimeFromCurrentVideo() || getTotalTimeFromClips();
                scope.slider.options.ceil = (totalTime - scope.endTimeClip > 20)
                  ? scope.endTimeClip + 20
                  : totalTime;
              }
            }
          }
        };
      }]);

})();
