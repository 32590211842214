(function() {
  'use strict';

  angular.module('app.core')
    .component('notificationIndicator', {
      templateUrl: 'app/directives/notification-indicator/notification-indicator.component.html',
      controller: NotificationIndicatorComponent,
      bindings: {}
    });

  NotificationIndicatorComponent.$inject = ['$scope', 'notificationPopupListService'];

  function NotificationIndicatorComponent($scope, notificationPopupListService) {
    var $ctrl = this;
    var unsubscribe;

    $ctrl.$onInit = function() {
      refresh();

      unsubscribe = notificationPopupListService.subscribe(refresh);
    };

    $ctrl.$onDestroy = function() {
      unsubscribe();
    };

    function refresh() {
      notificationPopupListService.getUnreadCount().then(function(count) {
        $ctrl.count = count;
      });
    }
  }

})();
