(function() {
  'use strict';

  angular
    .module('app.profile')
    .controller('TwitterAccountController', TwitterAccountController);

  TwitterAccountController.$inject = ['$state', '$window', 'profileService'];

  function TwitterAccountController($state, $window, profileService) {
    var vm = this;
    vm.userEmail = $window.localStorage['currentUserEmail'];
    vm.currentStateName = $state.current.name;

    vm.getCredentials = function() {
      return profileService.getTwitterTokens()
        .then(function(result) {
          vm.credentialsList = result.data;
        });
    };

    vm.deleteCredentials = function(credentials) {
      if (confirm('Remove this twitter credentials record?')) {
        profileService.deleteTwitterTokens(credentials.ID)
          .then(function() {
            vm.getCredentials();
          });
      }
    };

    vm.openSignInWindow = function() {
      return profileService.openTwitterSignInWindow()
        .then(() => {
          vm.getCredentials();
        });
    };

    vm.$onInit = function() {
      vm.getCredentials();
    };
  }
})();
