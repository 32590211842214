(function() {

  angular.module('app.core')
    .directive('gameData', gameDataDirective);

  gameDataDirective.$inject = ['CONF'];

  function gameDataDirective(CONF) {
    return {
      restrict: 'E',
      templateUrl: 'app/directives/game-data/game-data.html',
      scope: {
        controller: '='
      },
      link: function(scope) {
        scope.datePickerFormat = CONF.datePickerFormat;
        scope.datePickerAltFormats = CONF.datePickerAltFormats;
        scope.dateOptions = CONF.dateOptions;
      },
    };
  }

})();
