(function() {
  'use strict';

  angular
    .module('app.core')
    .factory('streamsSchedulerService', streamsSchedulerService);

  streamsSchedulerService.$inject = ['$http', 'logger'];

  function streamsSchedulerService($http, logger) {

    return {
      getScheduleList: getScheduleList,
      addSchedule: addSchedule,
      updateSchedule: updateSchedule,
      deleteSchedule: deleteSchedule,
      triggerSchedule: triggerSchedule,
      pingScheduler: pingScheduler
    };

    function pingScheduler() {
      return $http({
        method: 'GET',
        url: '/api/scheduler',
      }).catch(function(error) {
        logger.error(error);
      });
    }

    function getScheduleList() {
      return $http({
        method: 'GET',
        url: '/api/schedule/',
      }).catch(function(error) {
        logger.info('error on schedule item delete', error);
      });
    }

    function updateSchedule(streamObj) {
      return $http({
        method: 'PUT',
        url: '/api/schedule/' + streamObj.ID,
        data: streamObj
      });
    }

    function addSchedule(streamObj) {
      return $http({
        method: 'POST',
        url: '/api/schedule/',
        data: streamObj
      });
    }

    function deleteSchedule(scheduleID) {
      return $http({
        method: 'DELETE',
        url: '/api/schedule/' + scheduleID,
      }).catch(function(error) {
        logger.info('error on schedule item delete', error);
      });
    }

    function triggerSchedule(scheduleID){
      return $http({
        method: 'GET',
        url: '/api/schedule/' + scheduleID + '/trigger',
      }).catch(function(error) {
        logger.info('error on schedule item trigger', error);
      });
    }
  }
})();
