(function() {

  angular.module('app.player')
    .directive('imageSlider', imageSlider);
  imageSlider.$inject = ['$timeout'];

  function imageSlider($timeout) {
    return {
      restrict: 'E',
      templateUrl: 'app/image-slider/image-slider.html',
      link: function(scope, element, attrs) {
        scope.currentSlideIndex = 0;
        scope.numberLoaded = true;

        scope.slickConfig = {
          enabled: true,
          autoplay: false,
          draggable: false,
          pauseOnFocus: true,
          pauseOnHover: false,
          autoplaySpeed: 0,
          event: {
            afterChange: function(event, slick, currentSlide, nextSlide) {
              scope.currentSlideIndex = currentSlide;
            },
            init: function(event, slick) {
              slick.slickGoTo(scope.currentSlideIndex);
            }
          }
        };

        scope.carouselItems = [
          {id: 0, poster: '../images/poster.png'},
          {id: 1, poster: '../images/poster.png'},
          {id: 2, poster: '../images/poster.png'},
          {id: 3, poster: '../images/poster.png'},
          {id: 4, poster: '../images/poster.png'},
          {id: 5, poster: '../images/poster.png'},
          {id: 6, poster: '../images/poster.png'},
          {id: 7, poster: '../images/poster.png'},
          {id: 8, poster: '../images/poster.png'},
          {id: 9, poster: '../images/poster.png'}
        ];

        scope.removeItem = function(index) {
          scope.numberLoaded = false; // disable slick
          scope.carouselItems.splice(index, 1);
          $timeout(function() {
            scope.numberLoaded = true; // enable slick
          }, 1);
        };
      }
    };
  }

})();
