(function() {
  'use strict';

  angular
    .module('app.core')
    .factory('fullVideosUtilsService', fullVideosUtilsService);

  fullVideosUtilsService.$inject = [];

  function fullVideosUtilsService() {

    return {
      fullVideosFormatter: fullVideosFormatter
    };

    function fullVideosFormatter(videos, runningAnalyzers) {
      runningAnalyzers.sort(function(a, b){
        return (a.createdAt < b.createdAt) ? 1 : -1;
      });

      var videoData = videos;
      var countries,
        teams = {},
        arr = [];
      for (var key in videoData) {
        if (videoData.hasOwnProperty(key)) {
          var runningTask = runningAnalyzers.find(function(item){
            return item.videoID === videoData[key].ID;
          });

          if( runningTask
              && runningTask.detectionType === 'stream'
              && runningTask.status ==='processing'
          ){
            continue;
          }

          formatStatus(videoData[key], runningTask);

          videoData[key].league = videoData[key].league ? {name: videoData[key].league} : {name: ''};
          teams = {home: videoData[key].homeTeam, visiting: videoData[key].visitingTeam};
          videoData[key].gameTeams = teams;
          countries = videoData[key].countries ? videoData[key].countries.split(';') : [];
          countries = countries.map(function(item) {
            return {name: item};
          });
          videoData[key].countries = countries;
          videoData[key].createdAt = new Date(videoData[key].createdAt);
          arr.push(videoData[key]);
        }
      }
      return arr;
    }
  }

  function formatStatus(inVideo, inMatchedTask){
    inMatchedTask = (inMatchedTask && inMatchedTask.status) ? inMatchedTask : {
      status: 'complete'
    };
    var disabled = false;
    var isRunning = false;
    var message = null;
    switch(inMatchedTask.status){
      case 'failed':
        disabled = true;
        message = 'Failed detection, please contact administrator.';
        break;
      case 'processing':
        disabled = (inMatchedTask && inMatchedTask.detectionType === 'stream');
        isRunning = true;
        message = 'Detection is running.';
        break;
      default:
    }
    inVideo.disabled = disabled;
    inVideo.isRunning = isRunning;
    inVideo.statusMessage = message;
    return inVideo;
  }
})();
