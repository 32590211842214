(function() {
  'use strict';

  angular
    .module('app.streams-scheduler')
    .component('scheduleList', {
      templateUrl: 'app/streams-scheduler/schedule-list.html',
      controller: ScheduleListController,
      bindings: {}
    });

  ScheduleListController.$inject = [
    '$window',
    '$state',
    '$timeout',
    '$uibModal',
    'CONF',
    'remoteAnalyzerService',
    'gameFieldsService',
    'streamsSchedulerService',
  ];

  function ScheduleListController(
    $window,
    $state,
    $timeout,
    $uibModal,
    CONF,
    remoteAnalyzerService,
    gameFieldsService,
    streamsSchedulerService
  ) {
    var vm = this;

    this.$onInit = function() {
      vm.listLoading = true;

      streamsSchedulerService.getScheduleList().then(function(res) {
        vm.data = res.data.map(function(item) {
          var countDown = new Date(item.scheduledAt).getTime() - Date.now();
          if (countDown <= 3600 * 1000) {
            item.countDown = countDown / 1000;
          }
          // ROC-1268 - remove NaN
          if (isNaN(countDown) || countDown < 0)  {
            item.countDown = 0;
          }
          item.scheduledAtDate = new Date(item.scheduledAt).toLocaleDateString();
          item.scheduledAtTime = new Date(item.scheduledAt).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit'
          });
          return item;
        });
      }).finally(function() {
        vm.listLoading = false;
      });
    }
  }
})();
