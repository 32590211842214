(function() {
  'use strict';

  angular
      .module('app.remote-analyzer')
      .controller('RemoteAnalyzerController', RemoteAnalyzerController);

  RemoteAnalyzerController.$inject = [
    'remoteAnalyzerService',
    'analyzerUtilsService',
    'videogularConfigService',
    'fullVideosService',
    'gameFieldsService',
    'clipsService',
    'teamsService',
    'profileService',
    '$q',
    '$rootScope',
    '$scope',
    '$sce',
    '$interval',
    'clipsUtilsService',
    's3UploadService',
    'serverStatusService',
    'adminService',
    'authService',
    'scoreboardDetectionService',
    '$window',
    '$timeout',
    'CONF',
    'logger',
    'debounce',
    '$state',
    '$stateParams',
    'authenticatedUser',
    'userSettings',
    '$log',
    '$uibModal'
  ];

  function RemoteAnalyzerController(
    remoteAnalyzerService,
    analyzerUtilsService,
    videogularConfigService,
    fullVideosService,
    gameFieldsService,
    clipsService,
    teamsService,
    profileService,
    $q,
    $rootScope,
    $scope,
    $sce,
    $interval,
    clipsUtilsService,
    s3UploadService,
    serverStatusService,
    adminService,
    authService,
    scoreboardDetectionService,
    $window,
    $timeout,
    CONF,
    logger,
    debounce,
    $state,
    $stateParams,
    authenticatedUser,
    userSettings,
    $log,
    $uibModal) {
    var vm = this;

    vm.loading = false;
    vm.videoSpinner = false;

    $rootScope.showSplash = false;
    this.gameTeams = null;
    this.visitingTeam = null;
    this.homeTeam = null;
    this.sportTypes = null;
    this.sportType = null;
    this.leagues = null;
    this.league = null;
    this.gameDate = null;
    this.gameScore = null;
    this.streamURL = null;
    this.showGamefields = true;
    this.showStreamPlayer = false;

    const RESTART_ON = 1;
    const RESTART_RESET = 0;

    vm.showLoggerInfo = true;
    vm.gameCreated = null;
    vm.clips = [];
    vm.clipsInitialCopy = [];
    vm.currentTime = 0;
    vm.detectionServerRunning = serverStatusService.isConnected();

    vm.playing = false;
    vm.clipsLoading = false;
    vm.refreshButtonTooltip = 'You should start analyzer first';
    vm.detectGameStart = true;
    vm.calcSBEvent = true;
    vm.showMarkers = false;
    vm.videoSpinner = false;
    vm.clipItSpinner = false;
    vm.showAnalyzerMessages = false;
    vm.clipsService = clipsService;
    vm.analyzerUtilsService = analyzerUtilsService;

    vm.streamMode = 'url'; // url, twitch or trovo
    vm.streamTwitchChannelName = null;
    vm.streamTrovoChannelName = null;

    vm.sportRadarGameID = null;

    vm.currentAnalyzer = {};
    vm.controllerType = CONF.controllerTypes.stream;
    vm.gameFieldsErrors = [];
    vm.analyzerMessages = [];

    // TODO: move this and same messages from player controller to config
    vm.sbOffsetBeforeTooltip = 'time to cut before event happened';
    vm.sbOffsetAfterTooltip = 'time to cut after event happened';

    vm.config = videogularConfigService.setInitialConfig(null);

    vm.hideClipsControlButtons = true;
    vm.showClipsPlayer = false;

    vm.regex = '^$|^[A-Za-z0-9]+';
    vm.cutBeforeSBEventMargin = 0;
    vm.cutAfterSBEventMargin = 0;

    // array of clip IDs that are being processed on server
    vm.proUser = false;
    vm.importanceFilter = 0;
    vm.selectedFilters = [];
    vm.eventTypes = [];

    vm.addNewStreamCofirm = addNewStreamCofirm;
    vm.addNewStream = addNewStream;

    vm.authenticatedUser = authenticatedUser;
    vm.userSettings = userSettings;

    vm.groupSportTypeList = gameFieldsService.groupSportTypeList;

    vm.playerMode = null;

    vm.selectedLogos = [];
    vm.logos = [];

    vm.disableOnClick=function(){
      remoteAnalyzerService.setDisable(true);
    }
    vm.enableOnClick=function(){
      remoteAnalyzerService.setDisable(false);
    }
    initAnalyzerState();

    vm.proUser = adminService.isUserPro().then(function(res) {
      vm.proUser = res;
    });

    vm.getUser = function() {
      return authService.getUserFromDB(window.localStorage.currentUserEmail).then((res) => {
        vm.user = res.data;
      })
    }

    vm.$onInit = function() {
      return profileService.getUserLogos()
        .then((res) => {
          vm.logos = res.data;
        })
        .then(vm.getUser())
    }

    $scope.$on('blockStart', function() {
      vm.clipItSpinner = true;
      vm.videoSpinner = true;
    });

    $scope.$on('blockFinish', function() {
      vm.clipItSpinner = false;
      vm.videoSpinner = false;
    });

    vm.loadData = function(checkQueryParams) {
      return $q
        .all([
          gameFieldsService.getSportTypes().then(function(res) {
            vm.sportTypes = res.data;
          }),
        ])
        .then(function() {
          return Promise.all([
            fillFormFromQueryParameters($stateParams),
            analyzerUtilsService.refreshRunningList().then(function() {
              var runningList = analyzerUtilsService.getRunningStreamAnalyzersList();
              var fullVideoID = $stateParams['fullVideoID'] || $stateParams['video_id'];

              var matchedTask = runningList.find(function(task) {
                return task.videoID == fullVideoID;
              });
              if (matchedTask) {
                vm.currentAnalyzer = matchedTask;
                vm.changeAnalyzerProcess();
              } else if (runningList.length > 0 && !$stateParams['stream_url']) {
                vm.currentAnalyzer = runningList[0];
                vm.changeAnalyzerProcess();
              } else {
                $state.go('.', {
                  fullVideoID: null
                }, {
                  notify: false
                });
                vm.currentAnalyzer = vm.currentAnalyzer || {};
              }
            })
          ]);
        })
        .then(function() {
          if (checkQueryParams && ($stateParams['autoStart'] || $stateParams['auto_start'])) {
            vm.toggleGameDetection();
          }
          vm.detectionServerRunning = serverStatusService.isConnected();

          updateEventTypesByGame(vm.currentAnalyzer.video);

          vm.checkQueuedClips();
        });
    };

    vm.loadData(true);

    vm.checkQueuedClips = function() {
      if (vm.currentAnalyzer.fullVideoID) {
        clipsService.getQueueClipsByFullVideoID(vm.currentAnalyzer.fullVideoID).then(function(res) {
          vm.queuedClips = res.data;
        });
      } else {
        vm.queuedClips = [];
      }
    };

    vm.checkQueuedClipsInterval = $interval(vm.checkQueuedClips, 30 * 1000);

    vm.deleteQueueClip = function(inClip) {
      return clipsService.deleteQueueClip(inClip.queueKey)
        .then(function() {
          vm.checkQueuedClips();
        });
    };

    $rootScope.$on('detectionServerStatusUpdated', function(event, detectionServerStatus) {
      vm.detectionServerRunning = detectionServerStatus === 'running';
    });

    $rootScope.$on('analyzerStart', function() {
      vm.startStream();
    });

    $scope.$on('$destroy', function() {
      vm.cleanPlayers();
      $interval.cancel(vm.checkQueuedClipsInterval);
    });

    vm.cleanPlayers = function() {
      var twitchIframePlayer = document.getElementById('twitch-iframe-player');
      if (twitchIframePlayer) {
        while (twitchIframePlayer.firstChild) {
          twitchIframePlayer.removeChild(twitchIframePlayer.firstChild);
        }
      }
      var trovoIframePlayer = document.getElementById('trovo-iframe-player');
      if (trovoIframePlayer) {
        while (trovoIframePlayer.firstChild) {
          trovoIframePlayer.removeChild(trovoIframePlayer.firstChild);
        }
      }
      return new Promise(function(resolve) {
        $timeout(function() {
          vm.playerMode = null;
          vm.twitchlIframeUrl = null;
          vm.trovoIframeUrl = null;
          vm.showClipsPlayer = false;

          return resolve();
        }, 200);
      });
    };

    vm.swapStreamMode = function() {
      if (!vm.streamURL) {
        return;
      }

      if (vm.streamURL.indexOf('trovo.live') > -1) {
        vm.streamMode = 'trovo';
      } else if (vm.streamURL.indexOf('twitch.tv') > -1) {
        vm.streamMode = 'twitch';
      } else if (vm.streamURL.indexOf('.m3u8') > -1 || vm.streamURL.indexOf('rtmp://') > -1 || vm.streamURL.indexOf('youtube.com/watch?v=') > -1) {
        vm.streamMode = 'url';
      }
    };

    function initAnalyzerState() {
      vm.isProcessedByAnalyzer = analyzerUtilsService.getProcessState('analyzeStream');
      vm.showGamefields = remoteAnalyzerService.showGameFields = !vm.isProcessedByAnalyzer;
      isCurrentAnalyzerRunning();
    }

    vm.onChangeSrc = function() {
      if (!$rootScope.showSplash) {
        vm.videoSpinner = true;
      }
    };

    vm.setLeagues = function() {
      vm.detectGameStart = vm.sportType.defaultParameters.detectGameStart;
      vm.calcSBEvent = vm.sportType.defaultParameters.calcSBEvent;
      vm.cutBeforeSBEventMargin = vm.sportType.defaultParameters.cutBeforeSBEventMargin;
      vm.cutAfterSBEventMargin = vm.sportType.defaultParameters.cutAfterSBEventMargin;

      return gameFieldsService.getLeaguesBySportType(vm.sportType.name)
        .then(function(res) {
          vm.leagues = res.data;
          if (vm.leagues.length > 0) {
            vm.league = vm.leagues[0];
          } else {
            vm.league = {
              sportType: vm.sportType.name,
              name: 'Other'
            };
          }
          return vm.setTeams();
        });
    };

    vm.setTeams = function() {
      return gameFieldsService.getTeamsByLeague(vm.sportType.name, vm.league.name)
        .then(function(res) {
          vm.gameTeams = res.data.map(function(item) {
            return item.teamName;
          });
          vm.visitingTeam = '';
          vm.homeTeam = '';
          if (vm.sportType.name !== 'Soccer') {
            vm.noAudio = false;
          }
          vm.isManualClipping = (vm.sportType && vm.sportType.name === 'Manual Clipping');
          if (vm.isManualClipping) {
            vm.visitingTeam = 'Manual';
            vm.homeTeam = 'Clipping';
          }
          return vm.gameTeams;
        });
    };

    vm.canToggleDetection = function() {
      // playerForm.$invalid
      return vm.streamURL &&
        vm.detectionServerRunning &&
        !vm.disableToggleDetectionButton;
    };

    vm.toggleGameDetection = function() {
      vm.disableControlButtons = true;
      vm.disableToggleDetectionButton = true;
      if (!vm.isProcessedByAnalyzer) {
        remoteAnalyzerService.checkStreamAvailability(authenticatedUser.email, vm.streamURL)
          .then((res) => {
            if (res.data == 0) {
              vm.gameFieldsErrors = [];
              vm.gameFieldsErrors.push("Stream is not live yet");
              vm.gameFieldsErrors.push("Please make sure the stream has been started and try again in a moment");
              vm.disableControlButtons = false;
              vm.disableToggleDetectionButton = false;
            } else {
              vm.addGame();
            }
          })
      } else {
        if ($window.confirm('Please confirm STOP Detection?')) {
          vm.stopAnalyzer();
        } else {
          vm.disableControlButtons = false;
          vm.disableToggleDetectionButton = false;      
        }
      }  
    };

    vm.addGame = function() { 
      vm.gameFieldsErrors = gameFieldsService.validateGameFields(vm.cutBeforeSBEventMargin, vm.cutAfterSBEventMargin);
      if (!vm.visitingTeam || !vm.homeTeam || vm.gameFieldsErrors.length) {
        logger.info('Fill the teams fields');
        vm.disableControlButtons = false;
        vm.disableToggleDetectionButton = false;
        return;
      }
      if (vm.streamMode === 'twitch') {
        vm.streamTwitchChannelName = vm.streamURL;
        vm.streamTrovoChannelName = null;
      } else if (vm.streamMode === 'trovo') {
        vm.streamTrovoChannelName = vm.streamURL;
        vm.streamTwitchChannelName = null;
      } else {
        vm.streamTwitchChannelName = null;
        vm.streamTrovoChannelName = null;
      }
      if (vm.sportType.analyzerKey === 'user_logo' && (!vm.selectedLogos || vm.selectedLogos.length === 0)) {
        logger.info('Choose logos');
        return;
      }

      return Promise.resolve()
        .then(function() {
          if (vm.streamMode === 'url') {
            return gameFieldsService.checkHLSURL(vm.streamURL);
          } else
          if (vm.streamMode === 'twitch') {
            var toCheckURL = vm.streamTwitchChannelName || vm.streamURL;
            return gameFieldsService.checkTwitchChannel(toCheckURL);
          } else
          if (vm.streamMode === 'trovo') {
            var toCheckURL = vm.streamTrovoChannelName || vm.streamURL;
            return gameFieldsService.checkTrovoChannel(toCheckURL);
          }
        })
        .catch(function(e) {
          vm.gameFieldsErrors.push('Cannot locate stream');
          return Promise.reject(e);
        })
        .then(function(streamObj) {
          var visitingTeam = (vm.visitingTeam.teamName ? vm.visitingTeam.teamName : vm.visitingTeam);
          var homeTeam = ((vm.homeTeam.teamName) ? vm.homeTeam.teamName : vm.homeTeam);
          var model = {
            streamURL: streamObj.streamURL,
            streamTwitchChannelName: vm.streamTwitchChannelName,
            streamTrovoChannelName: vm.streamTrovoChannelName,
            visitingTeam: visitingTeam,
            homeTeam: homeTeam,
            gameDate: new Date(),
            gameScore: null,
            sportType: vm.sportType.name || null,
            cutBeforeSBEventMargin: vm.cutBeforeSBEventMargin,
            cutAfterSBEventMargin: vm.cutAfterSBEventMargin,
            removeBlackDigits: vm.removeBlackDigits,
            removeWhiteDigits: vm.removeWhiteDigits,
            detectGameStart: vm.detectGameStart,
            calcSBEvent: vm.calcSBEvent,
            isStored: false,
            noAudio: vm.noAudio,
            sportRadarGameID: vm.sportRadarGameID,
            logos: vm.selectedLogos
          };

          teamsService.createIfNotExist(
              vm.sportType.name,
              vm.league.name,
              [vm.homeTeam, vm.visitingTeam]);

          return fullVideosService.createGameVideo(model)
            .then(function(res) {
              vm.gameCreated = res.data;
              vm.selectedVideoID = res.data.ID;
              vm.refreshButtonTooltip = '';

              analyzerUtilsService.setProcessState('analyzeStream', true, vm.gameCreated.ID);
              $rootScope.$broadcast('detectionStarted');
              vm.currentAnalyzer = {
                videoID: vm.gameCreated.ID,
                video: vm.gameCreated
              };
              vm.isProcessedByAnalyzer = true;
              analyzerUtilsService.setCurrentGame(vm.currentAnalyzer);
              return remoteAnalyzerService.analyzeURL(vm.gameCreated.ID)
            })
            .then(function(res) {
              vm.currentAnalyzer = Object.assign(vm.currentAnalyzer, res.data);
              vm.disableToggleDetectionButton = false;
              $state.go('.', {
                fullVideoID: vm.currentAnalyzer.videoID
              }, {
                notify: false
              });
            })
            .catch(function(e) {
              vm.gameFieldsErrors.push(e.message || 'Cannot start stream analyzer');
              return Promise.reject(e);
            });
        })
        .then(function() {
          analyzerUtilsService.refreshRunningList();
          updateEventTypesByGame(vm.currentAnalyzer.video);
          console.log(vm.currentAnalyzer);
          return vm.changeAnalyzerProcess();
        })
        .catch(function(e) {
          logger.warning(`Start detection: ${e && e.message ? e.message : e}`);
          vm.disableToggleDetectionButton = false;
          vm.disableControlButtons = false;
          vm.isProcessedByAnalyzer = false;
          vm.showGamefields = remoteAnalyzerService.showGameFields = true;
        });
    };

    vm.stopAnalyzer = function() {
      // var id = (vm.currentAnalyzer && vm.currentAnalyzer.videoID) ? vm.currentAnalyzer.videoID : vm.gameCreated.ID;
      return fullVideosService.stopAnalyzer(vm.currentAnalyzer.ID)
        .finally(function() {
          vm.disableControlButtons = true;
          vm.disableToggleDetectionButton = true;
        });
    };

    vm.restartAnalyzer = function() {
      if ($window.confirm('Warning: Restart will remove the previous detected clips.')) {
        $rootScope.$broadcast('blockStartRestart');
        vm.clips.forEach(clip => {
          clipsService.deleteVideoClips(clip.ID);
        });
        vm.clips = [];
        vm.clipsInitialCopy = [];

        const userID = $window.localStorage['currentUserEmail'];
        const videoID = (vm.currentAnalyzer && vm.currentAnalyzer.videoID) ? vm.currentAnalyzer.videoID : vm.gameCreated.ID;
        return fullVideosService.restartAnalyzer(userID, videoID, RESTART_ON)
          .finally(function() {
            fullVideosService.restartAnalyzer(userID, videoID, RESTART_RESET);
            $rootScope.$broadcast('blockFinishRestart');
          });
      }
    };

    function stopAnalyzerHandler() {
      var task = analyzerUtilsService.getRunningStreamAnalyzersList().find(function(analyzer) {
        return (vm.currentAnalyzer.videoID === analyzer.videoID);
      });
      if (task) {
        analyzerUtilsService.removeFromAnalyzersList(task.videoID);
      }
      analyzerUtilsService.setProcessState('analyzePlayer', false);
      logger.success('Video removed from analyzer');
      logger.success('Your video is now uploading to S3 bucket. You can find the video in Clip tab once upload is done');
      $rootScope.$broadcast('stopAnalyzer');


      vm.playing = false;
      vm.isProcessedByAnalyzer = false;

      vm.addNewStream();
    }

    function isCurrentAnalyzerRunning() {
      vm.isProcessedByAnalyzer = analyzerUtilsService.getRunningStreamAnalyzersList().some(function(analyzer) {
        return vm.currentAnalyzer.videoID === analyzer.videoID;
      });
    }

    function setClips(clips) {
      vm.clips = clips.data.map(function(clip) {
        clip.highlightTime = clip.highlightTime || clip.startTime;
        if (!clip.timeInHHMMSS) {
          clip.timeInHHMMSS = setHHMMSS(clip);
        }
        return clip;
      });

      vm.totalTime = clipsUtilsService.getTotalTime(vm.clips);
      vm.clipsInitialCopy = angular.copy(vm.clips);
      vm.clipsLoading = false;
    }

    vm.timeFieldSelected = function(clip) {
      vm.clip = clip;
      if (clip.startTime < clip.endTime) {
        vm.startTime = clip.startTime;
        vm.endTime = clip.endTime;
        vm.showMarkers = true;
      } else {
        vm.showMarkers = false;
      }
    };

    $scope.$on('markersdirective:changetime', function(event, time) {
      vm.clip.startTime = time.start;
      vm.clip.endTime = time.end;
      vm.clip.timeInHHMMSS = setHHMMSS(time);
    });

    function saveClip(clip) {
      vm.clipItSpinner = true;
      var fullCheck = true;
      var reservedClipsCopy = angular.copy(vm.clipsInitialCopy);
      clip = clipsUtilsService.formatClipBeforeSave(clip);
      var clipCopy = angular.copy(clip);
      if (clip.ID && clipsUtilsService.checkClipTimeChanges(reservedClipsCopy, clipCopy, fullCheck)) {
        return $q.resolve({});
      }
      delete clipCopy.timeInHHMMSS;
      fullCheck = false;
      clip.ID && $rootScope.$broadcast('blockStart', clip.ID);
      vm.videoSpinner = true;
      if ($rootScope.startHlsTimestampInHertz) {
        clipCopy['startHlsTimestampInHertz'] = $rootScope.startHlsTimestampInHertz;
      }
      return clipsService.handleClipSave(clipCopy)
        .then(function() {
          if (vm.clip) vm.clip.edited = false;
          vm.checkQueuedClips();
        })
        .catch(function(err) {
          logger.error(err);
          vm.videoSpinner = false;
          vm.clipItSpinner = false;
          $rootScope.$broadcast('blockFinish', clip.ID);
          throw new clipsUtilsService.CutClipError('cut clip failed');
        })
    }

    // create a debounce function for clip saving
    vm.debounceSave = debounce(saveClip, 1000);
    vm.regularSave = saveClip;

    vm.removeClip = function(clip) {
      if ($window.confirm('Are you sure? Clip will be deleted from all tabs.')) {
        vm.clips.splice(vm.clips.indexOf(clip), 1);
      }

      clipsService.deleteVideoClips(clip.ID).then(function() {
        vm.totalTime = clipsUtilsService.getTotalTime(vm.clips);
        vm.showClipsPlayer = false;
      });
    };

    function setHHMMSS(time) {
      var highlightTime = time.highlightTime || time.startTime;
      return {
        startTime: clipsUtilsService.secondsToHHMMSS(time.startTime),
        highlightTime: clipsUtilsService.secondsToHHMMSS(highlightTime),
        endTime: clipsUtilsService.secondsToHHMMSS(time.endTime)
      };
    }

    $scope.$on('clipDetected', function(event, clipEventMessage) {
      var clipExists = vm.clips.some(function(clip) {
        return clip.ID === clipEventMessage.clipID;
      });
      clipEventMessage.clip.timeInHHMMSS = clipsUtilsService.getClipHHMMSS(clipEventMessage.clip);
      !clipExists && (clipEventMessage.videoID === vm.currentAnalyzer.videoID) &&
      vm.clips.push(clipEventMessage.clip) &&
      vm.clipsInitialCopy.push(angular.copy(clipEventMessage.clip));
      $rootScope.$broadcast('blockFinish', clipEventMessage.clipID);
    });

    $scope.$on('streamInterrupted', function(event, streamInterruptedEventMessage) {
      if (!streamInterruptedEventMessage.videoID === vm.currentAnalyzer.videoID) {
        return;
      }
      vm.clips.forEach(function(clip) {
        if (!clip.oldFileName) {
          clip.oldFileName = streamInterruptedEventMessage.oldFileName;
        }
      });
    });

    function getClips(id) {
      clipsService.getClipsForVideo(id).then(function(result) {
        result.data.map(function(clip) {
          clip.highlightTime = clip.highlightTime || clip.startTime;
          if (!clip.timeInHHMMSS) {
            clip.timeInHHMMSS = setHHMMSS(clip);
          }
          return clip;
        });
        vm.showLoggerInfo = true;
        var clips = {};
        clips.data = clipsUtilsService.mergeClips(vm.clips, result);
        setClips(clips);
      });
    }

    function delay(n) {
      n = n || 2000;
      return new Promise(done => {
        setTimeout(() => {
          done();
        }, n);
      }) 
    }

    function waitForDetectionUrl(attempt = 0) {
      var analyzerVideoData;
      return remoteAnalyzerService.getRunningAnalyzers()
        .then(function(res) {
          analyzerVideoData = res.data.processList.find(function(game) {
            if (game.videoID === vm.currentAnalyzer.videoID) {
              return game.video;
            }
          });
          return 0;
        })
        .then(() => {
          if (analyzerVideoData.analyzerStatus === "Started") {
            return analyzerVideoData;
          } else if (attempt > 19) {
            logger.info("Still preparing your stream, please wait");
            return Promise.reject();
          }
          $rootScope.$broadcast('analyzerWarmup', analyzerVideoData.analyzerStatus); 
          return 0;
        })
        .then((res) => {
          if (res) {
            return res;
          }
          return delay(2000);
        })
        .then(() => {
          if (analyzerVideoData.analyzerStatus === "Started") {
            vm.currentAnalyzer = analyzerVideoData;
            return analyzerVideoData;
          }
          return waitForDetectionUrl(attempt+1);
        })
    }

    vm.changeAnalyzerProcess = function() {
      resetFilters();
      $rootScope.$broadcast('gameChanged', 'Updating Status');
      $rootScope.$broadcast('killStreamVideoPreview', {});
      analyzerUtilsService.setProcessState('analyzeStream', true, vm.currentAnalyzer.videoID);
      vm.showAnalyzerMessages = true;
      vm.analyzerMessages.push('Please wait for a moment while stream is loading');
      waitForDetectionUrl(0)
        .then((analyzerVideoData) => {
          if (analyzerVideoData) {
            vm.streamURL = analyzerVideoData.video.streamURL;
            vm.detectionUrl = analyzerVideoData.detectionUrl;
            vm.streamTwitchChannelName = analyzerVideoData.video.streamTwitchChannelName;
            vm.streamTrovoChannelName = analyzerVideoData.video.streamTrovoChannelName;
            if (vm.streamTwitchChannelName) {
              vm.streamMode = 'twitch';
            } else if (vm.streamTrovoChannelName) {
              vm.streamMode = 'trovo';
            } else {
              vm.streamMode = 'url';
            }

            vm.sportType = vm.sportTypes.find(function(sportType) {
              if (sportType.name === analyzerVideoData.video.sportType) {
                return sportType;
              }
            });
            vm.isManualClipping = (vm.sportType && vm.sportType.name === 'Manual Clipping');

            teamsService.getTeams().then(function(res) {
              vm.gameTeams = res.data.map(function(item) {
                return item.teamName;
              });
              vm.homeTeam = analyzerVideoData.video.homeTeam;
              vm.visitingTeam = analyzerVideoData.video.visitingTeam;

              if (vm.homeTeam) {
                const homeTeamData = res.data.find(function(item) {
                  return item.teamName === vm.homeTeam;
                });
                vm.league = {
                  name: (homeTeamData) ? homeTeamData.league : 'Other',
                  sportType: vm.sportType.name,
                };
              }
            });
            vm.cutBeforeSBEventMargin = analyzerVideoData.video.cutBeforeSBEventMargin || vm.cutBeforeSBEventMargin;
            vm.cutAfterSBEventMargin = analyzerVideoData.video.cutAfterSBEventMargin || vm.cutAfterSBEventMargin;
            vm.detectGameStart = analyzerVideoData.video.detectGameStart;
            vm.calcSBEvent = analyzerVideoData.video.calcSBEvent;
          }
          vm.clips = [];
          vm.clipsInitialCopy = [];
          vm.isProcessedByAnalyzer = true;
          getClips(vm.currentAnalyzer.videoID);
          analyzerUtilsService.setCurrentGame(vm.currentAnalyzer);
          console.log(vm.currentAnalyzer.analyzerStatus);
          if (vm.currentAnalyzer.analyzerStatus === 'Stopping' || vm.currentAnalyzer.analyzerStatus === 'Analyzed') {
            $rootScope.$broadcast('stoppingAnalyzer', vm.currentAnalyzer.fullVideoID);
            vm.disableControlButtons = true;
            vm.disableToggleDetectionButton = true;
          } else if (vm.currentAnalyzer.analyzerStatus === 'Pending'){
            vm.disableControlButtons = true;
            vm.disableToggleDetectionButton = true;
          } else {
            vm.disableControlButtons = false;
            vm.disableToggleDetectionButton = false;
          }
          $rootScope.$broadcast('gameChanged', vm.currentAnalyzer.analyzerStatus);
          vm.selectedVideoID = vm.currentAnalyzer.fullVideoID || vm.currentAnalyzer.videoID;

          // var cachedSettings = remoteAnalyzerService.cacheSettings[vm.selectedVideoID];
          // if (cachedSettings) {
          vm.showGamefields = remoteAnalyzerService.showGameFields;
          vm.showStreamPlayer = remoteAnalyzerService.showStreamPlayer;
          // } else {
          //   vm.showGamefields = true;
          //   vm.showStreamPlayer = true;
          // }
        })
        .then(function() {
          if (vm.showStreamPlayer) {
            vm.startStream();
          }

          $state.go('.', {
            fullVideoID: vm.currentAnalyzer.videoID
          }, {
            notify: false
          });

          updateEventTypesByGame(vm.currentAnalyzer.video);
        })
        .catch((err) => {
          vm.stopAnalyzer();
        })
    };

    $scope.$on('analyzeSuccess', function() {
      stopAnalyzerHandler();
    });

    $scope.$on('blockStart', function() {
      vm.clipItSpinner = true;
    });

    $scope.$on('blockFinish', function() {
      vm.clipItSpinner = false;
    });

    $scope.$on('blockStartRestart', function() {
      vm.restartSpinner = true;
    });

    $scope.$on('blockFinishRestart', function() {
      vm.restartSpinner = false;
    });

    $scope.$on('preparingAnalyzer', function() {
      vm.disableControlButtons = false;
      vm.disableToggleDetectionButton = false;
    });

    $scope.$on('waitOnHlsReady', function() {
      console.log('Waiting for HLS feed');
      vm.showAnalyzerMessages = true;
      vm.analyzerMessages = [];
      vm.analyzerMessages.push('Please allow a moment for the feed to load');
      $timeout(() => {
        vm.changeAnalyzerProcess();
      }, 20000)
    });

    vm.toggleGameFields = function() {
      vm.showGamefields = !vm.showGamefields;
      // if (vm.selectedVideoID) {
      remoteAnalyzerService.showGameFields = vm.showGamefields;
      // }
    };

    vm.startStream = function() {
      vm.gameFieldsErrors = [];
      vm.analyzerMessages = [];
      vm.showStreamPlayer = true;
      return vm.cleanPlayers()
        .then(function() {
          if (vm.streamMode === 'url') {
            vm.playerMode = 'hls';
            var urlToCheck = vm.detectionUrl;
            return gameFieldsService.checkHLSURL(urlToCheck);
          } else
          if (vm.streamMode === 'twitch') {
            vm.playerMode = 'twitch';
            var toCheckURL = vm.streamTwitchChannelName || vm.streamURL;
            return gameFieldsService.checkTwitchChannel(toCheckURL);
          }
          if (vm.streamMode === 'trovo') {
            vm.playerMode = 'trovo';
            var toCheckURL = vm.streamTrovoChannelName || vm.streamURL;
            return gameFieldsService.checkTrovoChannel(toCheckURL);
          }
        })
        .then(function(streamObj) {
          vm.playing = true;
          vm.showStreamPlayer = remoteAnalyzerService.showStreamPlayer = true;
          if (!streamObj.twitchlIframeUrl && !streamObj.trovoIframeUrl) {
            vm.playerMode = 'hls';
            vm.twitchlIframeUrl = null;
            vm.trovolIframeUrl = null;
            vm.videoSpinner = true;
            vm.showAnalyzerMessages = false;
            // emit event for hls player
            $rootScope.$broadcast('startHls', streamObj.streamURL, true);
          } else 
          if (streamObj.trovoIframeUrl) {
            $rootScope.$broadcast('toggleStreamPlayer', false);
            vm.playerMode = 'trovo';
            $timeout(function() {
              vm.trovoIframeUrl = $sce.trustAsResourceUrl(streamObj.trovoIframeUrl + "&parent=" + window.location.hostname); 
            }, 300);
          } else
          if (streamObj.twitchlIframeUrl) {
            $rootScope.$broadcast('toggleStreamPlayer', false);
            vm.playerMode = 'twitch';
            $timeout(function() {
              vm.twitchlIframeUrl = $sce.trustAsResourceUrl(streamObj.twitchlIframeUrl + "&parent=" + window.location.hostname); 
            }, 300);
          } else {
            return Promise.reject();
          }
        })
        .catch(function(e) {
          logger.warning(e);
          vm.gameFieldsErrors.push('Cannot locate stream');
        });
    };

    vm.canClipIt = function() {
      return vm.playing &&
        vm.selectedVideoID &&
        vm.detectionServerRunning &&
        !vm.clipItSpinner &&
        !vm.disableToggleDetectionButton &&
        !(vm.playerMode === 'twitch') &&
        !(vm.playerMode === 'trovo');
    };

    vm.toggleStreamPlayer = function() {
      vm.showStreamPlayer = !vm.showStreamPlayer;
      if (vm.twitchlIframeUr || vm.trovoIframeUrl) {
        vm.playerMode = null;
      } else {
        $rootScope.$broadcast('toggleStreamPlayer', vm.showStreamPlayer);
      }
      // if (vm.selectedVideoID) {
      remoteAnalyzerService.showStreamPlayer = vm.showStreamPlayer;
      // }
    };

    vm.toggleFrames = function() {
      vm.showFrames = !vm.showFrames;
      $rootScope.$broadcast('toggleFrames', vm.showFrames);
    };

    vm.toggleIgnoreDigits = function(clickedIgnoreWhite) {
      if (clickedIgnoreWhite && vm.removeWhiteDigits) {
        vm.removeBlackDigits = false;
      } else if (!clickedIgnoreWhite && vm.removeBlackDigits) {
        vm.removeWhiteDigits = false;
      }
    }

    vm.runningAnalyzersListFilter = function(process) {
      return process.detectionType === vm.controllerType;
    };

    vm.addClip = function() {
      var video = {
        ID: vm.selectedVideoID,
        cutBeforeSBEventMargin: vm.cutBeforeSBEventMargin,
        cutAfterSBEventMargin: vm.cutAfterSBEventMargin
      };
      var currentTime = Math.floor(videojs.players.hlsVideo.currentTime());
      var totalTime = Math.floor(videojs.players.hlsVideo.duration());
      var clip = clipsUtilsService.createNewClip(video, currentTime, totalTime);

      if (!clip) {
        logger.warning('Please play the video');
        return;
      }

      if (!clip.endTime || clip.startTime > clip.endTime) {
        logger.warning('Invalid End Time for cutting the clip. Please make sure your stream is playing.');
        return;
      }

      $rootScope.$broadcast('blockStart');
      saveClip(clip).then(function() {
        vm.totalTime = clipsUtilsService.getTotalTime(vm.clips);
      });
    };

    function addNewStreamCofirm() {
      if (confirm('Do you want to add another stream?')) {
        return addNewStream();
      }
    }

    function addNewStream() {
      vm.cleanPlayers();

      $timeout(function() {
        vm.currentAnalyzer = {};
        vm.streamURL = null;
        vm.streamMode = 'url';
        vm.streamTwitchChannelName = null;
        vm.streamTrovoChannelName = null;
        vm.sportType = null;
        vm.league = null;
        vm.homeTeam = null;
        vm.visitingTeam = null;
        vm.cutBeforeSBEventMargin = 0;
        vm.cutAfterSBEventMargin = 0;
        vm.removeWhiteDigits = false;
        vm.removeBlackDigits = false;
        vm.detectGameStart = true;
        vm.calcSBEvent = true;
        vm.noAudio = false;
        vm.isManualClipping = false;
        vm.clips = [];
        vm.clipsInitialCopy = [];
        vm.totalTime = clipsUtilsService.getTotalTime(vm.clips);
        vm.showStreamPlayer = false;
        vm.playerMode = null;
        vm.twitchlIframeUrl = null;
        vm.playing = false;
        vm.isProcessedByAnalyzer = false;
        vm.disableToggleDetectionButton = false;
        vm.sportRadarGameID = null;

        vm.showGamefields = true;

        // $rootScope.$broadcast('stopAnalyzer');
        // analyzerUtilsService.setProcessState('analyzePlayer', false);
        analyzerUtilsService.setCurrentGame(null);

        var params = Object.keys($stateParams).reduce(function(obj, key) {
          obj[key] = null;
          return obj;
        }, {});
        $state.go('.', params, {
          notify: false
        });
      });
    }

    function resetFilters() {
      vm.selectedFilters = [];
      vm.importanceFilter = 0;
    }

    function fillFormFromQueryParameters(stateParams) {
      return Promise.resolve()
        .then(function() {
          if (stateParams['stream_url']) {
            vm.streamURL = stateParams['stream_url'];
          }

          if (stateParams['auto_detect_start']) {
            vm.detectGameStart = (stateParams['auto_detect_start'] === 'true') ? true : false;
          }

          if (stateParams['detect_sb']) {
            vm.calcSBEvent = (stateParams['detect_sb'] === 'true') ? true : false;
          }

          if (stateParams['obe']) {
            vm.cutBeforeSBEventMargin = Number(stateParams['obe']);
          }
          if (stateParams['oae']) {
            vm.cutAfterSBEventMargin = Number(stateParams['oae']);
          }

          var targetSport = stateParams['sport'] || stateParams['league'];
          if (targetSport) {
            vm.sportType = vm.sportTypes.find(function(sportType) {
              return sportType.name === targetSport;
            });

            if (vm.sportType) {
              vm.league = {
                sportType: vm.sportType.name,
                name: 'Other'
              };
              return vm.setTeams().then(function(res) {
                return setTeamsFromQueryParameters(res.data, stateParams);
              });
            }
          }
        });
    }

    function setTeamsFromQueryParameters(teams, stateParams) {
      if (stateParams['home_team']) {
        var homeTeam = teams.find(function(team) {
          return (team.ID === stateParams['home_team'] || team.teamName === stateParams['home_team']);
        });
        if (homeTeam) {
          vm.homeTeam = homeTeam.teamName;
        } else {
          vm.switchTeamSelect('home');
          vm.homeTeam = stateParams['home_team'];
        }
      }

      if (stateParams['away_team']) {
        var visitingTeam = teams.find(function(team) {
          return (team.ID === stateParams['away_team'] || team.teamName === stateParams['away_team']);
        });
        if (visitingTeam) {
          vm.visitingTeam = visitingTeam.teamName;
        } else {
          vm.switchTeamSelect('guest');
          vm.visitingTeam = stateParams['away_team'];
        }
      }
    }

    function updateEventTypesByGame(inGame) {
      if (!inGame || !inGame.sportType) {
        vm.eventTypes = [];
        return;
      }
      if (vm.isFetchEventTypes) {
        return;
      }
      vm.isFetchEventTypes = true;
      var sportTypeName = inGame.sportType.name || inGame.sportType;
      if (sportTypeName === 'User Logo Detection') {
        vm.eventTypes = vm.selectedGame.logos ? vm.selectedGame.logos.map((logo) => {
          return {
            event: logo.name.toLowerCase().replaceAll(' ', '_'),
            name: vm.user.logos[logo.ID].alias || logo.name
          }
        }) : [];
        vm.isFetchEventTypes = false;
        return;
      }
      return gameFieldsService.getEventTypesBySportType(sportTypeName).then(function(res) {
        vm.eventTypes = res.data;
        $timeout(function() {
          vm.isFetchEventTypes = false;
        }, 500);
      });
    }

    vm.getRTMPStream = function (){
      $uibModal.open({
        component: 'getStreamLinkComponent',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          currentUser: function() {
            return authenticatedUser.email;
          },
          parentController: function() {
            return vm;
          }
        }
      });
    }
  }
})();
