(function() {
  'use strict';

  angular
    .module('app.core')
    .factory('teamsService', teamsService);

  teamsService.$inject = ['$http', 'logger'];

  function teamsService($http, logger) {

    return {
      getLeagues: getLeagues,
      createLeague: createLeague,
      updateLeague: updateLeague,
      getTeams: getTeams,
      updateTeam: updateTeam,
      createTeam: createTeam,
      deleteTeam: deleteTeam,
      createIfNotExist: createIfNotExist,
    };

    function getLeagues() {
      return $http({
        method: 'GET',
        url: '/api/leagues'
      }).catch(function(error) {
        logger.info('error when try get leagues ', error);
      });
    }

    function getTeams() {
      return $http({
        method: 'GET',
        url: '/api/teams/'
      });
    }

    function updateLeague(leagueID, leagueData) {
      return $http({
        method: 'PUT',
        url: '/api/league/' + leagueID,
        data: leagueData
      }).catch(function(error) {
        logger.info('error when try update league ', error);
      });
    }

    function createLeague(leagueData) {
      return $http({
        method: 'POST',
        url: '/api/league',
        data: leagueData
      }).catch(function(error) {
        logger.info('error on league creation ', error);
      });
    }

    function updateTeam(team, teamID) {
      return $http({
        method: 'PUT',
        url: '/api/updateTeam/' + teamID,
        data: team
      }).then(function() {
        logger.log('Successfully updated team name');
      }).catch(function(error) {
        logger.info('error when try set name ', error);
      });
    }

    function createTeam(team) {
      return $http({
        method: 'POST',
        url: '/api/createTeam/',
        data: team
      }).catch(function(error) {
        // logger.info('error on team creation ', error);
      });
    }

    function deleteTeam(teamID) {
      return $http({
        method: 'DELETE',
        url: '/api/teams/' + teamID,
      }).then(function() {
        logger.log('Successfully deleted team');
      }).catch(function(error) {
        logger.info('error when try to delete team ', error);
      });
    }

    function createIfNotExist(inSportType, inLeague, inTeamNames) {
      return getTeams()
        .then(function(res) {
          var teams = res.data;
          var toSaveTeamName = {};
          inTeamNames.forEach(function(teamName) {
            if (!teamName || !teams) {
              return;
            }
            var matched = teams.find(function(item) {
              return item.sportType === inSportType &&
                item.league === inLeague &&
                item.teamName === teamName;
            });
            if (!matched && !toSaveTeamName[teamName]) {
              toSaveTeamName[teamName] = teamName;
              createTeam({
                sportType: inSportType,
                league: inLeague,
                teamName: teamName
              });
            }
          });
        })
        .catch(function() {});
    }
  }
})();
