(function() {
  'use strict';

  angular
    .module('app.facebookShare')
    .factory('facebookShareService', facebookShareService);

  facebookShareService.$inject = ['$window'];

  function facebookShareService($window) {
    return {
      init: function(fbId) {
        if (fbId) {
          this.fbId = fbId;
          $window.fbAsyncInit = function() {
            FB.init({
              appId: fbId,
              xfbml: true,
	      version: 'v3.3'
            });
          FB.AppEvents.logPageView();
          };
          (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
 		if (d.getElementById(id)) {return;}
 		js = d.createElement(s); js.id = id;
 		js.src = "https://connect.facebook.net/en_US/sdk.js";
 		fjs.parentNode.insertBefore(js, fjs);
 		}(document, 'script', 'facebook-jssdk'));
        } else {
          throw ('FB App Id Cannot be blank');
        }
      }
    };
  }

})();
