(function() {
  'use strict';

  angular
    .module('app.profile')
    .run(appRun);

  appRun.$inject = ['routerHelper', 'authService', '$q'];

  /* @ngInject */
  function appRun(routerHelper, authService, $q) {
    routerHelper.configureStates(getStates(authService, $q));
  }

  function getStates(authService, $q) {
    return [{
      state: 'profile',
      config: {
        url: '/profile',
        templateUrl: 'app/profile/profile.html',
        resolve: {
          authData: ['authService', function(authService) {
            return authService.resume().then(function() {
              $q.resolve();
            }, function() {
              // need pass to q reject exception http://stackoverflow.com/questions/23324942/angularjs-promises-rethrow-caught-exceptions
              return $q.reject(new Error('AUTH_REQUIRED'));
            });
          }]
        },
        title: 'Profile'
      }
    }, {
      state: 'profile.twitterAccount',
      config: {
        url: '/twitterAccount',
        templateUrl: 'app/profile/twitter/twitterAccount.html',
        controller: 'TwitterAccountController',
        controllerAs: '$ctrl',
        resolve: {
          authData: ['authService', function(authService) {
            return authService.resume().then(function() {
              $q.resolve();
            }, function() {
              // need pass to q reject exception http://stackoverflow.com/questions/23324942/angularjs-promises-rethrow-caught-exceptions
              return $q.reject(new Error('AUTH_REQUIRED'));
            });
          }]
        },
        title: 'Twitter Accounts'
      }
    }, {
      state: 'profile.twitchWatchList',
      config: {
        url: '/twitchWatchList',
        templateUrl: 'app/profile/twitch/twitchWatchList.html',
        controller: 'TwitchWatchListController',
        controllerAs: '$ctrl',
        resolve: {
          authData: ['authService', function(authService) {
            return authService.resume().then(function() {
              $q.resolve();
            }, function() {
              // need pass to q reject exception http://stackoverflow.com/questions/23324942/angularjs-promises-rethrow-caught-exceptions
              return $q.reject(new Error('AUTH_REQUIRED'));
            });
          }]
        },
        title: 'Twitch Accounts'
      }
    }, {
      state: 'profile.instagramSettings',
      config: {
        url: '/instagramSettings',
        templateUrl: 'app/profile/instagram/instagramSettings.html',
        controller: 'InstagramSettingsController',
        controllerAs: '$ctrl',
        resolve: {
          authData: ['authService', function(authService) {
            return authService.resume().then(function() {
              $q.resolve();
            }, function() {
              // need pass to q reject exception http://stackoverflow.com/questions/23324942/angularjs-promises-rethrow-caught-exceptions
              return $q.reject(new Error('AUTH_REQUIRED'));
            });
          }]
        },
        title: 'Instagram settings'
      }
    }, {
      state: 'profile.teams',
      config: {
        url: '/teams',
        templateUrl: 'app/profile/teams/teams.html',
        controller: 'TeamsController',
        controllerAs: '$ctrl',
        resolve: {
          authData: ['authService', function(authService) {
            return authService.resume().then(function() {
              $q.resolve();
            }, function() {
              // need pass to q reject exception http://stackoverflow.com/questions/23324942/angularjs-promises-rethrow-caught-exceptions
              return $q.reject(new Error('AUTH_REQUIRED'));
            });
          }]
        },
        title: 'Teams configuration'
      }
    }, {
      state: 'profile.pushNotifications',
      config: {
        url: '/pushNotifications',
        templateUrl: 'app/profile/pushNotifications/pushNotifications.html',
        controller: 'PushNotificationsController',
        controllerAs: '$ctrl',
        resolve: {
          authData: ['authService', function(authService) {
            return authService.resume().then(function() {
              $q.resolve();
            }, function() {
              // need pass to q reject exception http://stackoverflow.com/questions/23324942/angularjs-promises-rethrow-caught-exceptions
              return $q.reject(new Error('AUTH_REQUIRED'));
            });
          }]
        },
        title: 'Push Notifications'
      }
    }, {
      state: 'profile.prepostRolls',
      config: {
        url: '/prepostRolls',
        templateUrl: 'app/profile/prepostRolls/prepostRolls.html',
        controller: 'PrepostRollsController',
        controllerAs: '$ctrl',
        resolve: {
          authData: ['authService', function(authService) {
            return authService.resume().then(function() {
              $q.resolve();
            }, function() {
              // need pass to q reject exception http://stackoverflow.com/questions/23324942/angularjs-promises-rethrow-caught-exceptions
              return $q.reject(new Error('AUTH_REQUIRED'));
            });
          }]
        },
        title: 'Manage Pre/Post Rolls'
      }
    }, {
      state: 'profile.logos',
      config: {
        url: '/logos',
        templateUrl: 'app/profile/logos/logos.html',
        controller: 'LogosController',
        controllerAs: '$ctrl',
        resolve: {
          authData: ['authService', function(authService) {
            return authService.resume().then(function() {
              $q.resolve();
            }, function() {
              // need pass to q reject exception http://stackoverflow.com/questions/23324942/angularjs-promises-rethrow-caught-exceptions
              return $q.reject(new Error('AUTH_REQUIRED'));
            });
          }]
        },
        title: 'Manage logos'
      }
    }, {
      state: 'profile.apiAccess',
      config: {
        url: '/apiAccess',
        templateUrl: 'app/profile/apiAccess/apiAccess.html',
        controller: 'ApiAccessController',
        controllerAs: '$ctrl',
        resolve: {
          authData: ['authService', function(authService) {
            return authService.resume().then(function() {
              $q.resolve();
            }, function() {
              // need pass to q reject exception http://stackoverflow.com/questions/23324942/angularjs-promises-rethrow-caught-exceptions
              return $q.reject(new Error('AUTH_REQUIRED'));
            });
          }]
        },
        title: 'Manage API Access'
      }
    }];
  }

})();
