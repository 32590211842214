(function() {

  angular.module('app.player')
    .directive('clipsTab', function() {
      return {
        restrict: 'E',
        templateUrl: 'app/directives/clips-tab/clips-tab.html',
        controller: ClipsTabController,
        controllerAs: '$ctrl',
        scope: {
          model: '=',
          controller: '='
        }
      };
    });

  ClipsTabController.$inject = ['authService', 'authenticatedUser'];

  function ClipsTabController(authService, authenticatedUser) {
    var $ctrl = this;

    authService.getUserFromDB(authenticatedUser.email).then(function(result) {
      $ctrl.user = result.data;
    });
  $ctrl.idSelected = null;
  $ctrl.setSelected = function(idSelected){
  	$ctrl.idSelected = idSelected;
  	}
  }

})();
