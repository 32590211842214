(function() {
  'use strict';

  angular
    .module('blocks.logger')
    .factory('logger', logger);

  logger.$inject = ['$log', '$window', '$state', '$http', 'toaster', 'authenticatedUser', 'notificationPopupListService'];

  var ignoreErrors = [
    'jQuery(...).showTab is not a function',
    'jQuery().showTab is not a function'
  ];
  /* @ngInject */
  function logger($log, $window, $state, $http, toaster, authenticatedUser, notificationPopupListService) {
    var service = {
      showToasts: true,

      error: error,
      info: info,
      success: success,
      warn: warning,
      warning: warning,

      // straight to console; bypass toaster
      log: $log.log
    };

    return service;

    /////////////////////

    function error(message, data, title) {
      console.log(message, data, title);
      if (ignoreErrors.indexOf(message) > -1) {
        return;
      }
      toaster.error(message, title);      
      notificationPopupListService.addItem(message, 'exclamation-triangle');
      $log.error(message);
      $log.error(parseData(data));
      return $http({
        method: 'POST',
        url: '/api/errorReport',
        data: {
          subject: 'UI Error: ' + message,
          message: {
            title: title,
            data: data,
            message: message,
            timestamp: new Date(),
            request: {
              userID: authenticatedUser.email,
              state: $state.current.name,
              url: $window.location.href
            }
          }
        }
      });
    }

    function info(message, data, title) {
      toaster.info(message, title);
      notificationPopupListService.addItem(message);
      $log.info(message);
      $log.info(parseData(data));
    }

    function success(message, data, title) {
      toaster.success(message, title);
      notificationPopupListService.addItem(message);
      $log.info(message);
      $log.info(parseData(data));
    }

    function warning(message, data, title) {
      // HACK! Message generated by angular and capured somewhere by our logger, needs to be resolved later
      if (message.includes('i.info')) return;
      toaster.warning(message, title);
      notificationPopupListService.addItem(message, 'exclamation-triangle');
      $log.warn(message);
      $log.warn(parseData(data));
    }

    function parseData(inData) {
      if (!inData) {
        return '';
      }
      if (inData.message) {
        return inData.message;
      }
      if (inData.error) {
        return inData.error;
      }
      return '';
    }
  }
}());
