(function() {
  'use strict';

  angular
    .module('app.player')
    .controller('PlayerController', PlayerController);

  PlayerController.$inject = [
    '$rootScope',
    '$scope',
    '$uibModal',
    'logger',
    '$timeout',
    'dragulaService',
    '$q',
    '$window',
    'CONF',
    'fullVideosService',
    'fullVideosUtilsService',
    'clipsService',
    'gameFieldsService',
    'clipsUtilsService',
    'analyzerUtilsService',
    'debounce',
    'serverStatusService',
    'remoteAnalyzerService',
    'videogularConfigService',
    'adminService',
    'authService',
    'scoreboardDetectionService',
    '$state',
    '$stateParams',
    'SocketIOService',
    'streamsSchedulerService',
    'teamsService',
    'profileService'
  ];

  function PlayerController($rootScope,
                            $scope,
                            $uibModal,
                            logger,
                            $timeout,
                            dragulaService,
                            $q,
                            $window,
                            CONF,
                            fullVideosService,
                            fullVideosUtilsService,
                            clipsService,
                            gameFieldsService,
                            clipsUtilsService,
                            analyzerUtilsService,
                            debounce,
                            serverStatusService,
                            remoteAnalyzerService,
                            videogularConfigService,
                            adminService,
                            authService,
                            scoreboardDetectionService,
                            $state,
                            $stateParams,
                            SocketIOService,
                            streamsSchedulerService,
                            teamsService,
                            profileService) {

    var vm = this;

    // constants used also by game-data.html template
    vm.constants = {
      START_TIME_BY_ANALYZER: 'analyzer',
      START_TIME_BY_USER: 'user',
    };

    $rootScope.showSplash = false;
    $rootScope.changeByMove = false;

    vm.startTime = null;
    vm.endTime = null;
    vm.showMarkers = false;
    vm.playerCurrentTime = null;
    vm.clipStopTime = null;
    vm.videoSpinner = false;
    vm.clipItSpinner = false;
    vm.clips = [];
    vm.clipsInitialCopy = [];
    vm.config = null;
    vm.selectedGame = null;
    vm.selectedVideoID = null;
    vm.currentTime = 0;
    vm.totalTime = '';
    vm.eventTypes = [];
    vm.saveSelectedClips = true;
    vm.allClipsSelected = false;
    vm.showGame = 'player';
    vm.playerLoading = true;
    vm.importanceColors = CONF.importanceColors;
    vm.disableControlButtons = false; // analyzerUtilsService.getProcessState('analyzePlayer');
    vm.sbOffsetBeforeTooltip = 'time to cut before event happened';
    vm.sbOffsetAfterTooltip = 'time to cut after event happened';
    vm.controllerType = CONF.controllerTypes.manual;
    vm.gameFieldsErrors = [];
    vm.timePattern = '^[0-5][0-9]:[0-5][0-9]:[0-5][0-9]*$';
    vm.detectionServerRunning = serverStatusService.isConnected();
    vm.runningAnalyzers = [];
    vm.proUser = false;
    vm.importanceFilter = 0;
    vm.selectedFilters = [];
    vm.showClipsPlayer = false;
    vm.logos = [];

    $scope.$watch(() => vm.showClipsPlayer, (newVal) => {
      if (!newVal && videogularConfigService.API) videogularConfigService.API.stop();
    });

    vm.proUser = adminService.isUserPro().then(function(res) {
      vm.proUser = res;
    });

    vm.groupGamesList = clipsUtilsService.groupGamesList;
    vm.processingClipIDs = [];

    Promise.all([
      gameFieldsService.getCountries().then(function(res) {
        vm.countries = res.data;
      }),
      gameFieldsService.getSportTypes().then(function(res) {
        vm.sportTypes = res.data;
      }),
      gameFieldsService.getLeagues().then(function(res) {
        vm.leagues = res.data;
      }),
      profileService.getUserLogos().then((res) => {
        vm.logos = res.data;
      }),
      authService.getUserFromDB(window.localStorage.currentUserEmail).then((res) => {
        vm.user = res.data;
      }),
      getTeams()
    ]).then(function() {
      return getVideos();
    }).catch(logger.error);

    $rootScope.$on('detectionServerStatusUpdated', function(event, detectionServerStatus) {
      if (detectionServerStatus === 'running') {
        vm.detectionServerRunning = true;
        getRunningAnalyzerTasks();
      } else {
        vm.detectionServerRunning = false;
      }
    });

    $rootScope.$on('hlsPlayerReady', function(event, controller) {
      if (vm.selectedGame && vm.selectedGame.awsURL) {
        if (!vm.showClipsPlayer && !vm.isSrcAVideo() && controller === vm) {
          $rootScope.$broadcast('startHls', vm.selectedGame.awsURL);
        }
      }
    });

    $timeout(function() {
      vm.currentTab = parseInt($stateParams.tab || 0);
    });

    $scope.$on('analyzersListPopulated', function() {
      // update process button.
      if (!vm.isProcessedByAnalyzer && vm.selectedGame) {
        vm.isProcessedByAnalyzer = vm.isGameAnalyzing(vm.selectedGame.ID, true);
      }
    });

    $scope.$on('closeAddModalSuccess', function() {
      getVideos();
    });

    $scope.$on('clipDetected', function(event, clipEventMessage) {
      $rootScope.$broadcast('blockFinish', clipEventMessage.clip.ID);

      if (!vm.selectedGame) {
        return;
      }

      if (vm.selectedGame.clipRestoringInProgress) {
        vm.selectedGame.clipRestoringInProgress = false;
        vm.selectedGame.status = 'active';
      }

      // should update the awsURL... etc
      clipEventMessage.clip.timeInHHMMSS = clipsUtilsService.getClipHHMMSS(clipEventMessage.clip);

      var clipIndex = vm.clips.findIndex(function(clip) {
        return clip.ID === clipEventMessage.clipID;
      });
      if (clipIndex > -1) {
        vm.clips.splice(clipIndex, 1);
      }

      addNewClipToClipArray(clipEventMessage.clip, vm.clips);
      vm.clipsInitialCopy.push(angular.copy(clipEventMessage.clip));

      vm.clips = vm.clips.filter(function(clip) {
        return clip.ID;
      });
    });

    $scope.$on('analyzeSuccess', function() {
      vm.disableControlButtons = false;
      vm.isProcessedByAnalyzer = false;
    });

    $scope.$on('blockStart', function() {
      vm.clipItSpinner = true;
    });

    $scope.$on('blockFinish', function() {
      vm.clipItSpinner = false;
    });

    $scope.$on('preparingAnalyzer', function() {
      vm.disableControlButtons = false;
      vm.disableToggleDetectionButton = false;
    });

    function getRunningAnalyzerTasks() {
      return remoteAnalyzerService.getRunningAnalyzers()
        .then(function(result) {
          if (result.data && result.data.processList) {
            vm.runningAnalyzers = result.data.processList;
          }
        });
      // .catch(function(error) {
      //   logger.log('cannot get node server status: ', error);
      // });
    }

    function addNewClipToClipArray(clip, clipArray) {
      var existItem = clipArray.find(function(item) {
        return item.ID === clip.ID;
      });
      if ((!existItem) && (clip.fullVideoID === $stateParams.gameID)) {
        clipArray.push(clip);
      }
    }

    function getVideos() {
      vm.clipsLoading = true;
      return getRunningAnalyzerTasks()
        .then(function() {
          return fullVideosService.getFullVideos();
        })
        .then(fullVideosHandler);
    }

    function getTeams() {
      return teamsService.getTeams().then(function(res) {
        vm.teams = res.data;

        vm.selectedGame = formatGame(vm.selectedGame);
      });
    }

    function fullVideosHandler(fullVideos) {
      return Promise.resolve()
        .then(function() {
          return streamsSchedulerService.getScheduleList().then(function(result) {
            if (result && result.data) {
              vm.streamScheduleList = result.data.reduce(function(array, item) {
                if (item.scheduleStatus === 'Pending') {
                  array.push(item.fullVideoID);
                }
                return array;
              }, []);
            }
            var data = fullVideos.data.filter(function(item) {
              return vm.streamScheduleList.indexOf(item.ID) === -1;
            });
            // // ignore "Pending" schedule fullvideos
            // console.log(vm.streamScheduleList);
            vm.videoData = fullVideosUtilsService.fullVideosFormatter(data, vm.runningAnalyzers);
          });
        })
        .then(function() {
          if (!vm.videoData.length) {
            return Promise.reject();
          }
          if (!vm.selectedGame) {
            var currentRunningGame;
            var queryTargetGame;

            currentRunningGame = vm.videoData.find(function(video) {
              return vm.isGameAnalyzing(video.ID, true);
            });
            const gameID = $stateParams.gameID;
            if (gameID) {
              const matchedGame = vm.videoData.find(function(video) {
                return video.ID === gameID && !video.disabled;
              });
              currentRunningGame = matchedGame || currentRunningGame;
            }

            if (!currentRunningGame) {
              queryTargetGame = vm.videoData.find(function(video) {
                return !video.disabled;
              });
            }
            vm.selectedGame = formatGame(currentRunningGame || queryTargetGame);
            updateEventTypesByGame(vm.selectedGame);
            if (!vm.selectedGame) {
              return Promise.reject('Can not load selected Game');
            }
          }
        })
        .then(function() {
          var promises = [];

          analyzerUtilsService.setProcessState('analyzePlayer', true, vm.selectedGame.ID);
          vm.isProcessedByAnalyzer = analyzerUtilsService.getProcessState('analyzePlayer');
          vm.isProcessedByAnalyzer = vm.isGameAnalyzing(vm.selectedGame.ID, true);

          analyzerUtilsService.setCurrentGame(vm.selectedGame);
          vm.selectedGame.gameDate = vm.selectedGame.gameDate ? new Date(vm.selectedGame.gameDate) : new Date();
          vm.selectedVideoID = vm.selectedGame.ID;
          if (!vm.selectedGame.detectGameStart && vm.selectedGame.startTime != void (0)) {
            vm.startTime = clipsUtilsService.secondsToHHMMSS(vm.selectedGame.startTime);
            vm.selectedGame.detectGameStart = false;
          } else {
            vm.selectedGame.detectGameStart = true;
          }

          // Update the socket msg.
          promises.push(SocketIOService.getLastMessage(vm.selectedGame.ID));
          // promises.push(clipsService.getClipsForVideo(vm.selectedVideoID).then(setClips));

          if (!vm.setStartTimeBy) {
            vm.setStartTimeBy = getStartTimeByMethod();
          }
          setSelectedGame();
          promises.push(vm.setLeague());

          if (vm.selectedGame.awsURL) {
            var setConfig = gameFieldsService.checkURL(vm.selectedGame.awsURL, false, 'video/mp4').then(function() {
              vm.config = videogularConfigService.setInitialConfig(vm.selectedGame.awsURL);
            });
            promises.push(setConfig);
          } else {
            vm.config = videogularConfigService.setInitialConfig(null);
          }

          return Promise.all(promises);
        })
        .catch(function() {
          vm.config = videogularConfigService.setInitialConfig(null);
        })
        .finally(function() {
          $timeout(function() {
            vm.playerLoading = false;
            vm.clipsLoading = false;
          });
        });
    }

    function setClips(inClips) {
      vm.clips = inClips.map(function(clip) {
        clip.highlightTime = clip.highlightTime || clip.startTime;
        if (!clip.timeInHHMMSS) {
          clip.timeInHHMMSS = setHHMMSS(clip);
        }
        return clip;
      });
      vm.disableControlButtons = setControlButtons();

      vm.totalTime = clipsUtilsService.getTotalTime(vm.clips);

      vm.clipsInitialCopy = angular.copy(vm.clips);
      vm.clipsLoading = false;
    }

    vm.addGame = function() {
      $uibModal
        .open({
          component: 'addGameComponent',
          backdrop: 'static',
        }).result
        .then(function(gameID) {
          $state.go('.', {
            gameID: gameID
          });
          // refresh team after adding game
          getTeams();
        });
    };

    vm.showQueue = function() {
      var modal = $uibModal.open({
        component: 'queueComponent',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          activeGames: function() {
            return vm.videoData;
          },
          runningAnalyzers: function() {
            return vm.runningAnalyzers;
          }
        }
      });
      modal.result.then(function() {
        const removed = !vm.videoData.find(function(game) {
          return game.ID === vm.selectedGame.ID && game.isStored === vm.selectedGame.isStored;
        });
        if (removed) {
          vm.selectedGame = formatGame(vm.videoData[0]);
          updateEventTypesByGame(vm.selectedGame);
          vm.gameChangedHandler();
          vm.updateGame();
        }
      });
    };

    vm.toggleSBDetection = function() {
      if (vm.selectedGame.calcSBEvent) {
        vm.setStartTimeBy = vm.constants.START_TIME_BY_ANALYZER;
      } else {
        vm.setStartTimeBy = vm.constants.START_TIME_BY_USER;
      }
    }

    // change sport type should flush league and teams
    vm.setSportType = function(flush = false) {
      return Promise.resolve()
        .then(function() {
          if (flush) {
            vm.selectedGame.league = null;
            vm.selectedGame.homeTeam = '';
            vm.selectedGame.visitingTeam = '';
            vm.selectedGame.logos = null;
            vm.gameLeagues = null;
            vm.gameTeams = null;
          }

          vm.homeTeamSelect = true;
          vm.visitingTeamSelect = true;

          if (vm.selectedGame.sportType) {
            if (vm.selectedGame.sportType.name !== 'Soccer') {
              vm.selectedGame.noAudio = false;
            }

            if (vm.selectedGame.sportType.analyzerKey === 'user_logo' && !vm.selectedGame.logos) {
              vm.selectedGame.logos = [];
            }
            vm.gameLeagues = vm.leagues.filter(function(item) {
              return item.sportType === vm.selectedGame.sportType.name;
            });
          }
        });
    };

    vm.setLeague = function(flush = false) {
      return Promise.resolve()
        .then(function() {
          if (flush) {
            vm.selectedGame.visitingTeam = '';
            vm.selectedGame.homeTeam = '';
          }
          if (vm.selectedGame.league) {
            vm.gameTeams = vm.teams.filter(function(item) {
              return item.league === vm.selectedGame.league.name;
            }).map(function(item) {
              return item.teamName;
            });
          }
        });
    };

    vm.switchTeamSelect = function(side) {
      vm.homeTeamSelect = side === 'home' ? !vm.homeTeamSelect : vm.homeTeamSelect;
      vm.visitingTeamSelect = side === 'guest' ? !vm.visitingTeamSelect : vm.visitingTeamSelect;
    };

    vm.toggleIgnoreDigits = function(clickedIgnoreWhite) {
      if (clickedIgnoreWhite && vm.selectedGame.removeWhiteDigits) {
        vm.selectedGame.removeBlackDigits = false;
      } else if (!clickedIgnoreWhite && vm.selectedGame.removeBlackDigits) {
        vm.selectedGame.removeWhiteDigits = false;
      }
    }

    vm.gameFormValid = function() {
      return !vm.playerForm.$invalid && (vm.selectedGame.sportType.analyzerKey === 'user_logo' ? vm.selectedGame.logos && vm.selectedGame.logos.length : true);
    }

    vm.saveGame = function() {
      if (!vm.selectedGame || vm.playerForm.$invalid || vm.playerForm.$pristine || $rootScope.showSplash) {
        return;
      }

      $rootScope.showSplash = true;
      var model = {};

      vm.gameFieldsErrors = gameFieldsService.validateGameFields(vm.selectedGame.cutBeforeSBEventMargin, vm.selectedGame.cutAfterSBEventMargin);
      if (vm.gameFieldsErrors.length) {
        $rootScope.showSplash = false;
        return;
      }
      return Promise.resolve()
        .then(() => {
          var createTeamPromises = [];
          if (!vm.homeTeamSelect && !isSavedTeam(vm.selectedGame.homeTeam)) {
            createTeamPromises.push(createTeam(vm.selectedGame.homeTeam));
          }

          if (!vm.visitingTeamSelect && !isSavedTeam(vm.selectedGame.visitingTeam)) {
            createTeamPromises.push(createTeam(vm.selectedGame.visitingTeam));
          }
          return Promise.all(createTeamPromises);
        })
        .then(() => {
          model.ID = vm.selectedGame.ID;
          model.visitingTeam = vm.selectedGame.visitingTeam;
          model.homeTeam = vm.selectedGame.homeTeam;
          model.sportType = vm.selectedGame.sportType.name || vm.selectedGame.sportType;
          model.gameScore = vm.selectedGame.gameScore;
          model.gameDate = vm.selectedGame.gameDate;
          model.cutBeforeSBEventMargin = vm.selectedGame.cutBeforeSBEventMargin;
          model.cutAfterSBEventMargin = vm.selectedGame.cutAfterSBEventMargin;
          model.removeWhiteDigits = vm.selectedGame.removeWhiteDigits;
          model.removeBlackDigits = vm.selectedGame.removeBlackDigits;
          model.isStored = vm.selectedGame.isStored;
          model.startTime = getStartTimeValue();
          model.detectGameStart = vm.setStartTimeBy !== vm.constants.START_TIME_BY_USER;
          model.calcSBEvent = vm.selectedGame.calcSBEvent;
          model.noAudio = vm.selectedGame.noAudio;
          model.tags = vm.selectedGame.tags;
          model.logos = vm.selectedGame.logos ? vm.selectedGame.logos : null;
          return fullVideosService.updateGameData(model).then(function() {
            logger.success('Game saved');
            updateEventTypesByGame(vm.selectedGame);
            getVideos();
            $rootScope.showSplash = false;
          });
        })
        .catch(logger.error);
    };

    /**
     * former saveClips. method modified to save one clips only.
     * @returns {Promise.<any>}
     */
    function saveClip(clip) {
      var fullCheck = true;
      var reservedClipsCopy = angular.copy(vm.clipsInitialCopy);
      clip = clipsUtilsService.formatClipBeforeSave(clip);
      var clipCopy = angular.copy(clip);
      if (clip.ID && clipsUtilsService.checkClipTimeChanges(reservedClipsCopy, clipCopy, fullCheck)) {
        return $q.resolve({});
      }
      delete clipCopy.timeInHHMMSS;
      fullCheck = false;
      clip.ID && $rootScope.$broadcast('blockStart', clip.ID);
      vm.videoSpinner = true;
      return clipsService.handleClipSave(clipCopy)
        .then(function(res) {
          const clipID = (res.data.clip) ? res.data.clip.ID : res.data.ID;
          return clipsService.getClip(clipID);
        })
        .then(function(res) {
          if (vm.clip) vm.clip.edited = false;
          clip = Object.assign(clip, res.data);
          checkRunningHighlightRequest(clip.fullVideoID);
        })
        .catch(function(err) {
          logger.error(err);
          throw new clipsUtilsService.CutClipError('cut clip failed');
        })
        .finally(() => {
          vm.videoSpinner = false;
          $rootScope.$broadcast('blockFinish', clip.ID);
        })
    }

    // create a debounce function for clip saving
    vm.debounceSave = debounce(saveClip, 1000);
    vm.regularSave = saveClip;

    /**
     * cut all clips to one video reel
     */

    vm.createAutomatedHighlight = function(inCategory, inSelectedGameID) {
      if (vm.clips.length < 2) {
        logger.info('Not enough data to create condensed game file');
        return;
      }
      if (inCategory === 'AutomatedCondensedGame') {
        vm.disableControlButtonForCondensedGame = true;
      } else {
        vm.disableControlButtonForReel = true;
      }

      var data = {
        category: inCategory,
        fullVideoID: inSelectedGameID,
        importance: vm.importanceFilter,
        selectedFilters: vm.selectedFilters || [],
      };
      return clipsService.createAutomatedHighlight(data)
        .then(function() {
          checkRunningHighlightRequest(inSelectedGameID);
        })
        .catch(function(err) {
          console.log(err);
          if (inCategory === 'AutomatedCondensedGame') {
            vm.disableControlButtonForCondensedGame = true;
          } else {
            vm.disableControlButtonForReel = true;
          }
        });
    };

    vm.expiryTimeForCurrentGame = function(category) {
      let sportCategory = vm.selectedGame.sportType.category || vm.sportTypes.find(sport => sport.name === vm.selectedGame.sportType).category;
      if (!sportCategory) return undefined;
      let expiryTimes = vm.user.userSettings.expiryTimes[sportCategory.toLowerCase()];
      return expiryTimes ? expiryTimes[category] : undefined;
    }

    vm.gameChangedHandler = function() {
      this.selectedGame.gameDate = new Date(this.selectedGame.gameDate);
      this.selectedGame.calcSBEvent = this.selectedGame.calcSBEvent === undefined ? true : this.selectedGame.calcSBEvent;
      $stateParams.gameID = this.selectedGame.ID;
      $rootScope.$broadcast('gameChanged', 'Updating Status');
      updateEventTypesByGame(vm.selectedGame);
      checkRunningHighlightRequest(vm.selectedGame.ID);
      vm.selectedGame.daysUntilRemoval = vm.expiryTimeForCurrentGame('fullVideos') ? Math.floor((vm.expiryTimeForCurrentGame('fullVideos')-(Date.now()-(new Date(vm.selectedGame.updatedAt)).getTime()))/(24*60*60*1000)) : undefined;
      vm.tabChanged(0);
    };

    vm.tabChanged = function(tabID) {
      vm.currentTab = tabID;
      if (tabID == 0) {
        vm.showClipsPlayer = false;
      }
    }

    vm.isSrcAVideo = function() {
      if (vm.selectedGame && vm.selectedGame.awsURL.includes('mp4')) {
        return true; 
      }
      return false;
    }

    vm.restoreClips = function() {
      vm.selectedGame.clipRestoringInProgress = true;
      return clipsService.restoreClips(vm.selectedVideoID)
        .catch((err) => {
          logger.error(err.message || err);
          vm.selectedGame.clipRestoringInProgress = false;
        })
    }

    vm.updateGame = function() {
      vm.selectedGame.status = '';
      vm.selectedGame.clipRestoringInProgress = false;
      vm.selectedGame.daysUntilRemoval = vm.expiryTimeForCurrentGame('fullVideos') ? Math.floor((vm.expiryTimeForCurrentGame('fullVideos')-(Date.now()-(new Date(vm.selectedGame.updatedAt)).getTime()))/(24*60*60*1000)) : undefined;
      $rootScope.$broadcast('stopAnalyzer');
      if (videogularConfigService.API) {
        videogularConfigService.API.stop();
      }
      resetFilters();
      vm.showMarkers = false;
      vm.clipsLoading = true;
      vm.selectedVideoID = vm.selectedGame.ID;
      vm.currentAnalyzer = null;
      vm.isProcessedByAnalyzer = false;
      clipsService.getClipsForVideo(vm.selectedVideoID).then(function(res) {
        if (res.data.length) {
          vm.selectedGame.status = 'active';
          return setClips(res.data);
        } else {
          setClips(res.data);
          return clipsService.checkRetiredOrRemovedClips(vm.selectedVideoID)
            .then((res) => {
              vm.selectedGame.status = res.data.status;
            })
        }
      });

      // get teams when changing game
      vm.setLeague();

      vm.startTime = clipsUtilsService.secondsToHHMMSS(vm.selectedGame.startTime);
      vm.disableToggleDetectionButton = true;

      gameFieldsService.checkURL(vm.selectedGame.awsURL, false, 'video/mp4')
        .then(function() {
          vm.showClipsPlayer = false;
          $rootScope.$broadcast('startHls', vm.selectedGame.awsURL);
          return analyzerUtilsService.refreshRunningList();
        })
        .catch(function(err) {
          vm.selectedGame.awsURL = vm.selectedGame.awsURL.replace('/playlist/hls/playlist.m3u8', '/video.mp4');
          if (vm.config) {
            vm.config.sources = videogularConfigService.updateSource(vm.selectedGame.awsURL);
            vm.config.preload = true;
          }
          return analyzerUtilsService.refreshRunningList();
          vm.config = null;
          // logger.warning('Game not found or video file is corrupted. Please choose another.', err);
          $timeout(function() {
            vm.config = videogularConfigService.setInitialConfig(null);
          });
        })
        .finally(function() {
          vm.disableToggleDetectionButton = false;
          $timeout(function() {
            vm.selectedGame = formatGame(vm.selectedGame);
          });
          return fullVideosService.getRelatedAnalyzerTasks(vm.selectedGame.ID)
          .then(function(res) {
            vm.selectedGame.tasks = res.data.sort(function (a, b) {
              return a.createdAt > b.createdAt ? -1 : 1;
            });
            console.log('Status');
            if (vm.selectedGame.tasks[0].analyzerStatus === 'Pending'){
                vm.disableControlButtons = true;
                vm.disableToggleDetectionButton = true;
            } else if (vm.selectedGame.tasks[0].analyzerStatus === 'Completed'){
                vm.disableControlButtons = false;
                vm.disableToggleDetectionButton = false;
                vm.isProcessedByAnalyzer = false;
            } else {
              vm.disableControlButtons = false;
              vm.disableToggleDetectionButton = false;
            }
            $rootScope.$broadcast('gameChanged', vm.selectedGame.tasks[0].analyzerStatus);
          })
          .catch(function(err) {
            console.log(err);
          });

        });

      var currentTime = vm.currentTime;
      $timeout(function() {
        if (videogularConfigService.API) {
          videogularConfigService.API.seekTime(currentTime, false);
        }
      }, 300);

      vm.totalTime = clipsUtilsService.getTotalTime(vm.clips);
      vm.isProcessedByAnalyzer = vm.isGameAnalyzing(vm.selectedGame.ID, true);
      analyzerUtilsService.setProcessState('analyzePlayer', vm.isProcessedByAnalyzer, vm.selectedGame.ID);
      analyzerUtilsService.setCurrentGame(vm.selectedGame);
      vm.setStartTimeBy = getStartTimeByMethod();
    };

    vm.isGameAnalyzing = function(ID, setCurrentAnalyzer) {
      if (setCurrentAnalyzer) {
        var task = analyzerUtilsService.getRunningClipAnalyzersList().find(function(analyzer) {
          return (ID === analyzer.videoID);
        });
        vm.currentAnalyzer = task;
        return (task) ? true : false;
      } else {
        return analyzerUtilsService.getRunningClipAnalyzersList().some(function(analyzer) {
          return (ID === analyzer.videoID);
        });
      }
    };

    vm.toggleGameDetection = function() {
      vm.disableControlButtons = true;
      vm.disableToggleDetectionButton = true;
      if (!vm.isProcessedByAnalyzer) {
        if ($window.confirm('Warning: This video has already been processed. Re-running the detection will remove all previously detected clips.')) {
          vm.addToAnalyzer();
        } else {
          vm.disableControlButtons = false;
          vm.disableToggleDetectionButton = false;
        }
      } else {
        vm.stopAnalyzer();
      }
    };

    vm.addToAnalyzer = function() {
      fullVideosService.addVideoToAnalyzer(vm.selectedVideoID)
        .then(function(res) {
          vm.currentAnalyzer = res.data;
          $timeout(function() {
            vm.proUser && scoreboardDetectionService.registerInstance($window.localStorage['currentUserEmail'], vm.selectedVideoID);
          }, 10000);
          analyzerUtilsService.setCurrentGame(vm.selectedGame);
          analyzerUtilsService.setProcessState('analyzePlayer', true, vm.selectedVideoID);
          vm.isProcessedByAnalyzer = analyzerUtilsService.getProcessState('analyzePlayer');
          $rootScope.$broadcast('detectionStarted');
          logger.success('video added to analyzer');
          vm.clips = [];
          vm.clipsInitialCopy = [];

          return analyzerUtilsService.refreshRunningList();
        })
        .catch(function() {
          vm.isProcessedByAnalyzer = false;
        })
        .finally(function() {
          vm.disableControlButtons = true;
          vm.disableToggleDetectionButton = true;
        });
    };

    vm.stopAnalyzer = function() {
      vm.disableControlButtons = false;
      fullVideosService.stopAnalyzer(vm.currentAnalyzer.ID)
        .then(function() {
          analyzerUtilsService.setProcessState('analyzePlayer', false, vm.selectedVideoID);
          vm.isProcessedByAnalyzer = analyzerUtilsService.getProcessState('analyzePlayer');
          logger.success('video removed from analyzer');
          vm.currentAnalyzer = null;

          return analyzerUtilsService.refreshRunningList();
        })
        .finally(function() {
          vm.disableControlButtons = false;
          vm.disableToggleDetectionButton = false;
        });
    };

    vm.onChangeSrc = function() {
      if (!$rootScope.showSplash) {
        vm.videoSpinner = true;
      }
    };

    vm.addClip = function() {
      if (!vm.selectedGame.gameDate) {
        logger.error('Game not selected');
        return;
      }
      var clip;
      var currentTime = Math.floor(videojs.players.hlsVideo.currentTime()) || Math.floor(videogularConfigService.API.currentTime / 1000);
      var totalTime = Math.floor(videojs.players.hlsVideo.duration()) || Math.floor(videogularConfigService.API.totalTime / 1000);
      clip = clipsUtilsService.createNewClip(vm.selectedGame, currentTime, totalTime);
      $rootScope.$broadcast('blockStart');
      return saveClip(clip).then(function() {
        addNewClipToClipArray(clip, vm.clips);
        vm.totalTime = clipsUtilsService.getTotalTime(vm.clips);
        vm.disableControlButtons = setControlButtons();
      });
    };

    vm.removeClip = function(clip) {
      if ($window.confirm('Are you sure you want to delete this clip?')) {
        vm.clips.splice(vm.clips.indexOf(clip), 1);

        clipsService.deleteVideoClips(clip.ID).then(function() {
          vm.totalTime = clipsUtilsService.getTotalTime(vm.clips);
          vm.disableControlButtons = setControlButtons();
          vm.showClipsPlayer = false;
        });
      }
    };

    vm.validateClipFields = function(clipIndex, fields) {
      fields = fields || ['startTime', 'endTime', 'score'];
      var clip = vm.clips[clipIndex],
        hasError = false;
      for (var i in clip) {
        if (clip.hasOwnProperty(i) && fields.indexOf(i) !== -1 && !clip[i]) {
          switch (i) {
            case 'startTime':
              clip.errors.startTime = 'Start time field should be in hh-mm-ss format. Use 0 for empty digits';
              break;
            case 'endTime':
              clip.errors.endTime = 'End time field should be in hh-mm-ss format. Use 0 for empty digits';
              break;
            case 'score':
              clip.errors.score = 'Score field should be in 99-99 format. Use 0 for empty digits';
              break;
          }
          hasError = true;
        } else if (clip.errors && clip.hasOwnProperty(i) && fields.indexOf(i) !== -1 && clip[i]) {
          delete clip.errors[i];
        }
      }
      return !hasError;
    };

    vm.toggleExpand = function(clip, openOnly) {
      if (!clip.showOptions || openOnly) {
        vm.clips.forEach(function(item) {
          item.showOptions = false;
        });
        clip.showOptions = true;
      } else if (clip.showOptions) {
        clip.showOptions = false;
      }
    };

    function updateEventTypesByGame(inGame) {
      if (!inGame || !inGame.sportType) {
        vm.eventTypes = [];
        return;
      }
      if (vm.isFetchEventTypes) {
        return;
      }
      vm.isFetchEventTypes = true;
      var sportTypeName = inGame.sportType.name || inGame.sportType;
      if (sportTypeName === 'User Logo Detection') {
        vm.eventTypes = vm.selectedGame.logos ? vm.selectedGame.logos.map((logo) => {
          return {
            event: logo.name.toLowerCase().replaceAll(' ', '_'),
            name: vm.user.logos[logo.ID].alias || logo.name
          }
        }) : [];
        vm.isFetchEventTypes = false;
        return;
      }
      return gameFieldsService.getEventTypesBySportType(sportTypeName).then(function(res) {
        vm.eventTypes = res.data;
        vm.isFetchEventTypes = false;
      });
    }

    function setSelectedGame() {
      for (var i = 0; i < vm.videoData.length; i++) {
        if (vm.videoData[i].ID === vm.selectedVideoID) {
          vm.selectedGame = formatGame(vm.videoData[i]);
          updateEventTypesByGame(vm.selectedGame);
          $rootScope.showSplash = false;
          vm.gameChangedHandler();
          vm.updateGame();
          vm.setSportType();
          break;
        }
      }
    }

    function formatGame(inGame) {
      if (!inGame) {
        return;
      }
      if (inGame.sportType && !inGame.sportType.name) {
        inGame.sportType = vm.sportTypes.find(function(item) {
          return item.name === inGame.sportType;
        });
      }

      if (inGame.league && !inGame.league.name) {
        inGame.league = vm.leagues.find(function(item) {
          return item.name === inGame.league;
        });
      }

      if (inGame.homeTeam) {
        var teamData = vm.teams.find(function(item) {
          return item.teamName === inGame.homeTeam;
        });
        if (teamData) {
          inGame.league = vm.leagues.find(function(item) {
            return item.name === teamData.league;
          });
        }
      } else {
        inGame.league = vm.leagues.find(function(item) {
          return item.sportType === inGame.sportType.name;
        });
      }

      if (inGame.league) {
        vm.gameTeams = vm.teams.filter(function(item) {
          return item.league === inGame.league.name;
        }).map(function(item) {
          return item.teamName;
        });
      }

      return inGame;
    }

    vm.timeFieldSelected = function(clip) {
      vm.clip = clip;
      if (clip.startTime <= clip.endTime) {
        vm.startTime = clip.startTime;
        vm.endTime = clip.endTime;
        vm.showMarkers = true;
      } else {
        vm.showMarkers = false;
      }
    };

    vm.changeAnalyzerProcess = function() {
      vm.clips = [];
      vm.clipsInitialCopy = [];
    };

    function setHHMMSS(time) {
      var highlightTime = time.highlightTime || time.startTime;
      return {
        startTime: clipsUtilsService.secondsToHHMMSS(time.startTime),
        highlightTime: clipsUtilsService.secondsToHHMMSS(highlightTime),
        endTime: clipsUtilsService.secondsToHHMMSS(time.endTime)
      };
    }

    // function checkClipsLength() {
    function setControlButtons() {
      return !vm.clips || vm.clips.length <= 0;
    }

    function getStartTimeByMethod() {
      return vm.selectedGame.detectGameStart === true ?
        vm.constants.START_TIME_BY_ANALYZER :
        vm.constants.START_TIME_BY_USER;
    }

    function getStartTimeValue() {
      if (vm.setStartTimeBy !== vm.constants.START_TIME_BY_USER) {
        return null;
      }

      var time = vm.startTime || '00:00:00';
      return clipsUtilsService.hhMMSSToSeconds(time);
    }

    function resetFilters() {
      vm.selectedFilters = [];
      vm.importanceFilter = 0;
    }

    function isSavedTeam(team) {
      return vm.teams.some(function(gameTeam) {
        return gameTeam.teamName === team;
      });
    }

    function createTeam(name) {
      if (!name || !vm.selectedGame.league || !vm.selectedGame.sportType) {
        return Promise.resolve();
      }
      var params = {
        teamName: name,
        league: vm.selectedGame.league.name,
        sportType: vm.selectedGame.sportType.name
      };
      return teamsService.createTeam(params).then(function() {
        return getTeams();
      }).catch(function() {
      });
    }

    function checkRunningHighlightRequest(inFullVideoID) {
      if (vm.checkRunningHighlightRequest) {
        return Promise.resolve();
      }

      vm.checkRunningHighlightRequest = true;

      return fullVideosService.getRelatedTranscoderTasks(inFullVideoID).then(function(res) {
        vm.checkRunningHighlightRequest = false;
        vm.processingClipIDs = [];

        var shouldRecheck = (res.data.length > 0);
        var hasRunningReel = false;
        var hasRunningHighlight = false;

        res.data.forEach(function(data) {
          if (data.mode === 'reel') {
            hasRunningReel = true;
          } else if (data.mode === 'highlight') {
            hasRunningHighlight = true;
          } else if (data.mode === 'clip') {
            vm.processingClipIDs.push(data.clipID);
          }
        });
        vm.disableControlButtonForReel = hasRunningReel;
        vm.disableControlButtonForCondensedGame = hasRunningHighlight;

        if (shouldRecheck) {
          return new Promise(function(resolve, reject) {
            $timeout(function() {
              checkRunningHighlightRequest(inFullVideoID).then(resolve).catch(reject);
            }, 5 * 1000);
          });
        }
      }).catch(function(err) {
        logger.error(err);
        vm.checkRunningHighlightRequest = false;
        vm.processingClipIDs = [];
      });
    }
  }
  var $video = $('.video');

  $video.on('mouseover', function() {
    this.dataset.over = true;
    this.controls = true;
  });
  $video.on('mouseout', function() {
    delete this.dataset.over;
    if (this.paused) this.controls = false;
  });

})();
