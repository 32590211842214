angular.module("app.core")
.constant("CUSTOM_CONF", {
  "appVersion": "1.8.8",
  "showCreatePage": true,
  "whatIsNew": [
    "New and improved User Logo mode",
    "General bug fixes and UI stability improvements"
  ],
  "fbAppId": "1357681281055911"
});
