(function () {
  'use strict';

  angular.module('app.auth')
    .factory('authService', authService);

  authService.$inject = ['$http', '$window', '$interval', '$q', '$timeout', 'logger', '$rootScope', 'coreUtilsService', 'authenticatedUser', 'userSettings'];

  function authService($http, $window, $interval, $q, $timeout, logger, $rootScope, coreUtilsService, authenticatedUser, userSettings) {
    var vm = this;
    vm.logout = logout;
    vm.user = {};
    vm.hasLogin = false;
    vm.codeHash = {
      PasswordChangeRequired: PasswordChangeRequired,
      ChangePasswordSuccessful: ChangePasswordSuccessful,
      Authorized: Authorized
    };
    vm.isUserAuthenticated = false;

    var isInIframe = inIframe();
    vm.maxRetries = (isInIframe) ? 5 : 3;
    vm.retryTimeout = (isInIframe)? 1000 : 500;
    vm.currentTry = 0;

    return {
      authUser: authUser,
      setToken: setToken,
      isAuthenticated: isAuthenticated,
      resume: resume,
      isAppAdministrator: isAppAdministrator,
      isCompanyAdministrator: isCompanyAdministrator,
      isAdministrator: isAdministrator,
      clearToken: clearToken,
      logout: logout,
      passwordRestore: passwordRestore,
      registerUser: registerUser,
      refreshUserToken: refreshUserToken,
      confirmForgotPassword: confirmForgotPassword,
      getUserName: getUserName,
      addUserToDB: addUserToDB,
      changePassword: changePassword,
      confirmRegistration: confirmRegistration,
      resendConfirmation: resendConfirmation,
      getUserData: getUserData,
      updateUser: updateUser,
      getUserFromDB: getUserFromDB
    };

    function isAuthenticated(){
      return vm.isUserAuthenticated;
    }

    function changePassword(email, password, newPassword) {
      var data = {
        email: email,
        password: password,
        newPassword: newPassword
      };
      return $http.post('/api/changePassword', data);
    }

    function PasswordChangeRequired() {
      $rootScope.$broadcast('authService:changePassword');
    }

    function ChangePasswordSuccessful() {
      $rootScope.$broadcast('authService:changePasswordSuccess');
    }

    function Authorized(response) {
      Object.assign(vm.user, response.data);
      vm.user.isAuthenticated = true;
      authenticatedUser.setUser(vm.user);
      $rootScope.$broadcast('authService:signin', vm.hasLogin);
    }

    function authUser(email, password, newPassword, rememberUser) {
      var data = {
        email: email,
        password: password,
        newpassword: newPassword ? newPassword : null
      };

      return $http.post('/api/login', data).then(loginSuccess.bind(vm, email, rememberUser)).catch(handleError);
    }

    function getUserFromDB(email) {
      return $http.get('/api/users/' + email);
    }

    function addUserToDB(email) {
      var data = {
        email: email,
        lastLogin: new Date()
      };
      return $http.post('/api/users', data);
    }

    function updateUser(user) {
      return $http.put('/api/users/' + user.ID, user);
    }

    function setToken(token) {
      $window.localStorage['satellizer_token'] = token;
    }

    function clearToken() {
      $window.localStorage['satellizer_token'] = '';
    }

    function resume() {
      vm.currentTry++;
      var token = getUrlParameterByName('token');
      return $http({
        method: 'POST',
        url: '/api/test',
        headers: (token) ? {
          'x-access-token': token
        }: {}
      })
      .then(function(res) {
        return getUserFromDB(res.data.user.email)
          .then(function (response) {
            res.data.lastLogin = new Date();
            userSettings.setSettings(response.data.userSettings);
            return res;
          });
      })
      .then(function (res) {
        $rootScope.$broadcast('resumeSuccess');
        vm.isUserAuthenticated = true;
        $window.localStorage['currentUserEmail'] = res.data.user.email;
        $window.localStorage['currentUserAdmin'] = String(!!res.data.isAdmin);
        $window.localStorage['currentUserCompanyAdmin'] = String(!!res.data.isCompanyAdmin);
        vm.user = res.data.user;
        authenticatedUser.setUser(vm.user);
        return res;
      })
      .catch(function(err){
        if(vm.currentTry >= vm.maxRetries){
          vm.currentTry = 0;
          vm.isUserAuthenticated = false;
          $window.localStorage['currentUserEmail'] = '';
          $window.localStorage['currentUserAdmin'] = 'false';
          $window.localStorage['currentUserCompanyAdmin'] = 'false';
          $rootScope.$broadcast('resumeFailed');
          return Promise.reject(err);
        } else {
          return new Promise(function(resolve, reject){
            setTimeout(function(){
              return resume().then(resolve).catch(reject);
            }, vm.retryTimeout);
          });
        }
      });
    }

    function isAppAdministrator() {
      return isAdministrator(true, false);
    }

    function isCompanyAdministrator() {
      return isAdministrator(false, true);
    }

    function isAdministrator(app, company) {
      return resume().then(function() {
        if ((app && $window.localStorage['currentUserAdmin'] === 'true') ||
          (company && $window.localStorage['currentUserCompanyAdmin'] === 'true')) {
          return $q.resolve();
        } else {
          return $q.reject();
        }
      });
    }

    function logout() {
      vm.user.isAuthenticated = false;
      authenticatedUser.setUser(vm.user);
      coreUtilsService.currentIntervals.forEach(function (intervalPromise) {
        $interval.cancel(intervalPromise);
      });
      // TODO: hack. rewrite login without using email in local storage
      $window.localStorage.removeItem('currentUserEmail');
      // $rootScope.$broadcast('logout');

      return $http.post('/api/logout');
    }

    function passwordRestore(email) {
      return $http.post('/api/forgotPassword', { email: email }).catch(handleError);
    }
    function confirmForgotPassword(code, password, email) {
      var data = {
        confirmationCode: code,
        newPassword: password,
        email: email
      };
      return $http.post('/api/confirmForgotPassword', data).then(function (res) {
        logger.info(res.data.message);
      }).catch(handleError);
    }
    function registerUser(userData) {
      return $http.post('/api/register', userData).catch(handleError);
    }
    function confirmRegistration(data) {
      return $http.post('/api/confirmRegistration', data).catch(handleError);
    }
    function resendConfirmation(data) {
      return $http.post('/api/resendConfirmation', data).catch(handleError);
    }
    function refreshUserToken(data) {
      return $http.post('/api/refreshUserToken', data).catch(handleError);
    }

    function handleError(error) {
      $rootScope.$broadcast('authService:error', error);
      return $q.reject(error);
    }

    function loginSuccess(email, rememberUser, response) {
      getUserFromDB(email)
        .then(function(res) {
          res.data.lastLogin = new Date();
          userSettings.setSettings(res.data.userSettings);
          return updateUser(res.data);
        })
        .then(function() {
          vm.hasLogin = true;
          loginCallback(email, rememberUser, response);
        })
        .catch(function(res){
          if (res.status >= 400 && res.status < 500 && response.data && response.code) {
            vm.hasLogin = false;
            loginCallback(email, rememberUser, response);
          }
        });
    }

    function loginCallback(email, rememberUser, response) {
      var fn = vm.codeHash[response.code];
      authenticatedUser.setUser(vm.user);
      // TODO: hack. rewrite email getter using API call by token
      $window.localStorage['currentUserEmail'] = email;
      if (response.data.user) {
        $window.localStorage['currentUserCompany'] = response.data.user.profile;
        $window.localStorage['currentUserAdmin'] = response.data.admin;
        $window.localStorage['currentUserCompanyAdmin'] = response.data.companyAdmin;
      }
      if (rememberUser) {
        $window.localStorage['refreshUserToken'] = true;
      }
      if (typeof fn === 'function') {
        fn.call(vm, response);
      } else {
        logger.error('Code is not defined');
      }
    }

    function getUserName() {
      // TODO: hack. rewrite email getter using API call by token
      return vm.user.email || $window.localStorage['currentUserEmail'];
    }

    function getUserData() {
      return {
        email:  $window.localStorage['currentUserEmail'],
        company:  $window.localStorage['currentUserCompany']
      };
    }

    function getUrlParameterByName(name, url) {
      url = url || window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
      var results = regex.exec(url);
      if (!results) { return null; }
      if (!results[2]) { return ''; }
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    function inIframe () {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
  }
})();
