(function() {
  'use strict';

  angular
    .module('app.dashboard', [
      'app.core'
    ])
    .filter('startFrom', function() {
      return function(input, start) {
        start = parseInt(start); //parse to int
        return input.slice(start);
      };
    });

})();
