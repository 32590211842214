(function() {
  'use strict';

  angular
    .module('app.core')
    .component('clipSlider', {
      templateUrl: 'app/directives/clip-slider/clip-slider.html',
      controller: ClipSliderController,
      bindings: {
        refreshIntervalInSeconds: '<?',
        user: '<?'
      }
    });

  ClipSliderController.$inject = [
    '$window',
    '$timeout',
    '$uibModal',
    '$interval',
    'clipsService',
    'coreUtilsService'
  ];

  function ClipSliderController(
    $window,
    $timeout,
    $uibModal,
    $interval,
    clipsService,
    coreUtilsService
  ) {
    var vm = this;
    vm.clipPageSize = 6;
    vm.clipCurrentPage = 0;
    vm.clipTotalPages = 0;
    vm.clips = [];

    vm.$onInit = function() {
      vm.refreshInterval = (vm.refreshIntervalInSeconds || 30) * 1000;

      vm.refreshIntervalWorker = $interval(function() {
        vm.setClips();
      }, vm.refreshInterval);
    };

    vm.$onChanges = function() {
      // based on the mode
      vm.setClips();
    };

    vm.$onDestroy = function() {
      $interval.cancel(vm.refreshIntervalWorker);
    };

    vm.setClips = function() {
      if (vm.isBusy) {
        return;
      }
      vm.isBusy = true;
      return vm.getLatestClips()
        .then(function() {
          vm.clipTotalPages = Math.ceil(vm.clips.length / vm.clipPageSize);
        })
        .finally(function() {
          vm.isBusy = false;
        });
    };

    vm.getLatestClips = function() {
      return clipsService.getLatestClips().then(function(res) {
        vm.clips = res.data;
      }).catch(function() {
        vm.clips = [];
      });
    };

    vm.lastPage = function() {
      if (vm.clipCurrentPage !== 0) {
        vm.clipCurrentPage = vm.clipCurrentPage - 1;
      }
    };

    vm.nextPage = function() {
      if (vm.clipCurrentPage + 1 < vm.clipTotalPages) {
        vm.clipCurrentPage = vm.clipCurrentPage + 1;
      }
    };

    vm.download = function(inClip) {
      if (inClip.isDownloading) {
        return;
      }
      inClip.isDownloading = true;
      var fileName = 'clip.mp4';
      coreUtilsService.saveToDisk(inClip.awsURL, fileName).finally(function() {
        $timeout(function() {
          inClip.isDownloading = false;
        });
      });
    };

    vm.open = function(inClip) {
      $window.open(inClip.awsURL, '_blank');
    };
  }
})();
