(function() {
  'use strict';
  angular
    .module('app.core')
    .component('addGameComponent', {
      templateUrl: 'app/game/add-game.html',
      controller: addGameController,
      controllerAs: 'addGame',
      bindings: {
        close: '&',
        dismiss: '&',
      },
    });

  addGameController.$inject = [
    'authService',
    'gameFieldsService',
    's3UploadService',
    'fullVideosService',
    'teamsService',
    'coreUtilsService',
    'logger',
    '$scope',
    '$rootScope'
  ];

  function addGameController(authService,
    gameFieldsService,
    s3UploadService,
    fullVideosService,
    teamsService,
    coreUtilsService,
    logger,
    $scope,
    $rootScope) {
    var vm = this;
    var pathways = {};
    vm.controllerName = 'addGame';
    vm.saveGameStatusMessage = 'Video must be uploaded first';
    vm.submitDisabled = true;
    vm.videoUploaded = false;
    vm.uploadProgressPercent = 0;
    vm.fileName = null;
    vm.fileToUpload = null;
    vm.awsUrl = null;
    vm.allowAwsUrl = false;
    vm.sportTypes = [];
    vm.leagues = [];
    vm.regex = '^$|^[A-Za-z0-9]+';
    vm.debounce = {
      debounce: 500
    };
    vm.groupSportTypeList = gameFieldsService.groupSportTypeList;

    vm.uploadType = 'file';
    $scope.$on('fileChunkUploaded', function(event, data) {
      if (data.id === vm.fileKey) {
        vm.uploadProgressPercent = data.progress;
        $scope.$apply();
      }
    });

    $scope.$on('processingUpload', function(event, data) {
      if (data.id === vm.fileKey) {
        vm.videoUploadProgressMessage = vm.progressMessages.processing;
        $scope.$apply();
      }
    });

    vm.progressMessages = {
      uploading: 'Video file is uploading',
      processing: 'File is uploaded, now processing',
      success: 'File successfully uploaded',
      error: 'Error uploading file'
    };

    vm.videoUploadProgressMessage = null;
    vm.game = {
      // fileToUpload: null,
      ID: null, // create here for file upload
      userID: null, // will be added in server
      profile: null, // will be added in server
      // awsURL: null,
      homeTeam: null,
      visitingTeam: null,
      gameTeams: null,
      gameDate: null,
      gameScore: null,
      sportType: null,
      // tags: [],
      // countries: null,
      cutBeforeSBEventMargin: 0,
      cutAfterSBEventMargin: 0,
      detectGameStart: false,
      externalURL: null, // for external aws s3 url
      streamURL: null,
      isStored: false,
      noAudio: false,
      removeWhiteDigits: false,
      removeBlackDigits: false,
      sportRadarGameID: null
    };

    pathways.company = coreUtilsService.replaceSpecialCharacters((authService.getUserData().company || 'unknown-company')) + '/';
    pathways.user = coreUtilsService.replaceSpecialCharacters(authService.getUserData().email) + '/';

    gameFieldsService.getSportTypes().then(function(res) {
      vm.sportTypes = res.data;
    });

    vm.setSportType = function() {
      vm.game.detectGameStart = vm.game.sportType.defaultParameters.detectGameStart;
      vm.game.cutBeforeSBEventMargin = vm.game.sportType.defaultParameters.cutBeforeSBEventMargin;
      vm.game.cutAfterSBEventMargin = vm.game.sportType.defaultParameters.cutAfterSBEventMargin;

      return gameFieldsService.getLeaguesBySportType(vm.game.sportType.name).then(function(res) {
        vm.leagues = res.data;
        if (vm.leagues.length > 0) {
          vm.game.league = vm.leagues[0];
        } else {
          vm.game.league = {
            name: 'Other',
            sportType: vm.game.sportType.name
          };
        }
        return vm.setLeague();
      });
    };

    vm.setLeague = function() {
      vm.leaugueStatusMessage = '';
      return gameFieldsService.getTeamsByLeague(vm.game.sportType.name, vm.game.league.name).then(function(res) {
        vm.visitingTeam = vm.game.visitingTeam;
        vm.homeTeam = vm.game.homeTeam;
        vm.gameTeams = res.data.map(function(item) {
          return item.teamName;
        });
        if (vm.game.sportType.name !== 'Soccer') {
          vm.game.noAudio = false;
        }
      });
    };

    vm.saveGame = function(model) {
      vm.submitDisabled = true;
      return Promise.resolve()
        .then(function() {
          teamsService.createIfNotExist(
            model.sportType.name,
            model.league.name,
            [vm.homeTeam, vm.visitingTeam]);

          model = angular.copy(model);
          var visitingTeam = getNormalizedTeamName(vm.visitingTeam);
          var homeTeam = getNormalizedTeamName(vm.homeTeam);
          Object.assign(model, {
            sportType: (model.sportType && model.sportType.name) ? model.sportType.name : model.sportType,
            visitingTeam: visitingTeam,
            homeTeam: homeTeam,
            gameTeams: {
              home: homeTeam,
              visiting: visitingTeam
            }
          });
          return fullVideosService.createGameVideo(model);
        })
        .then(function(res) {
          logger.success('Game added to database');
          vm.uploadProgressPercent = 0;
          $rootScope.$broadcast('closeAddModalSuccess');

          var gameID = res.data.ID;
          vm.close({
            $value: gameID
          });
        })
        .catch(function(e) {
          vm.submitDisabled = false;
          var error = e && e.error ? e.error : e;
          logger.error(error);
        });
    };

    vm.upload = function() {
      var fileToUpload = vm.fileToUpload;
      if (!fileToUpload) {
        return;
      }
      var presetID = UUID.genV1().toString();
      vm.game.ID = presetID;
      vm.fileName = 'video.mp4';
      var fileKey = pathways.user + 'fullVideos/' + presetID + '/' + vm.fileName;
      vm.fileKey = fileKey;
      vm.uploadInProgress = true;
      vm.videoUploaded = true;
      vm.uploadProgressPercent = 0;
      vm.videoUploadProgressMessage = vm.progressMessages.uploading;
      return s3UploadService.createMultipartUpload(fileToUpload, fileKey)
        .then(function() {
          // s3UploadService.validateS3Object(fileKey).then(console.log);
          vm.videoUploadProgressMessage = vm.progressMessages.success;
          vm.submitDisabled = false;
        })
        .catch(function() {
          vm.videoUploadProgressMessage = vm.progressMessages.error;
          vm.videoUploaded = false;
        })
        .finally(function() {
          vm.saveGameStatusMessage = '';
          vm.uploadInProgress = false;
        });
    };

    vm.changeAws = function() {
      vm.submitDisabled = false;
      vm.saveGameStatusMessage = '';
    };

    vm.onDismiss = function() {
      if (!vm.uploadInProgress) {
        vm.dismiss();
      }
    };

    function getNormalizedTeamName(team) {
      return ((team.teamName) ? team.teamName : team) || 'N/A';
    }
  }
})();
