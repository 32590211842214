(function() {
  'use strict';

  var MODES = {
    preRoll: {title: 'Pre-roll'},
    postRoll: {title: 'Post-roll'}
  };

  angular
    .module('app.profile')
    .component('prepostRollsComponent', PrepostRollsComponent());

  function PrepostRollsComponent() {
    return {
      templateUrl: 'app/profile/prepostRolls/prepostRolls.component.html',
      controller: PrepostRollsController,
      bindings: {
        user: '<',
        mode: '@',
        focus: '<?',
        uploadInProgress: '='
      }
    }
  }

  PrepostRollsController.$inject = ['$scope', '$rootScope', '$timeout', 'coreUtilsService', 's3UploadService', 'authService', 'logger', 'profileService'];

  function PrepostRollsController($scope, $rootScope, $timeout, coreUtilsService, s3UploadService, authService, logger, profileService) {
    var $ctrl = this;
    $ctrl.$onInit = function() {
      $ctrl.options = MODES[$ctrl.mode];
      $ctrl.initDone = s3UploadService.getInitDone();
    };

    $scope.$watch(() => s3UploadService.getInitDone(), (newVal) => {
      if ($ctrl.user && $ctrl.user.userSettings && newVal) {
        if ($ctrl.mode in $ctrl.user.userSettings) {
          $ctrl.enabled = $ctrl.user.userSettings[$ctrl.mode].enabled;
          refreshUrl();
        } else {
          $ctrl.user.userSettings[$ctrl.mode] = {};
        }
      }
      $ctrl.initDone = true;
    })

    $ctrl.$onChanges = function(changes) {
      if (changes.user && changes.user.currentValue && s3UploadService.getInitDone()) {
        $ctrl.user = changes.user.currentValue;
        if ($ctrl.mode in $ctrl.user.userSettings) {
          $ctrl.enabled = $ctrl.user.userSettings[$ctrl.mode].enabled;
          refreshUrl();
        } else {
          $ctrl.user.userSettings[$ctrl.mode] = {};
        }
      }
    };

    $scope.chooseLocalFile = function(element) {
      var files = element.files;
      if (files.length) {
        $ctrl.file = files[0];
        $ctrl.upload().finally(() => {
          element.value = "";
          $scope.$digest();
        })
      }
    };

    $ctrl.toggle = function() {
      $ctrl.enabled = !$ctrl.enabled;
      const userSettings = $ctrl.user.userSettings[$ctrl.mode];
      if (!$ctrl.enabled || ($ctrl.enabled && userSettings.fileName)) $ctrl.setPrePost();
    };

    $ctrl.setPrePost = function() {
      if($ctrl.mode == "preRoll"){
      	profileService.setPreEnable($ctrl.enabled);
      }
      if($ctrl.mode == "postRoll"){    
	      profileService.setPostEnable($ctrl.enabled);
      }
      $ctrl.user.userSettings[$ctrl.mode].enabled = $ctrl.enabled;
      $ctrl.update();
    }

    $ctrl.update = function() {
      return authService.updateUser($ctrl.user).then(function(result) {
        $ctrl.user = result.data;
      }).catch(logger.error);
    };

    $ctrl.upload = function() {
      var dirKey = getUserOtherVideoDirKey();
      var fileToUpload = $ctrl.file;
      var fileName = $ctrl.mode + '.mp4';
      $ctrl.uploadInProgress = true;
      $ctrl.thisUploadInProgress = true;
      $ctrl.uploadProgressPercent = 0;
      const userSettings = $ctrl.user.userSettings[$ctrl.mode];
      return s3UploadService.getUploadVideoDuration(fileToUpload)
        .then(function(duration) {
          $ctrl.duration = duration;
          $ctrl.fileKey = dirKey + fileName + '?shouldEncode=true&duration=' + duration.toString();
          return s3UploadService.createMultipartUpload(fileToUpload, $ctrl.fileKey);
        })
        .then(function() {
          if($ctrl.mode == "preRoll"){
            profileService.setPreDuration($ctrl.duration);
          }
          if($ctrl.mode == "postRoll"){    
            profileService.setPostDuration($ctrl.duration);
          }
          userSettings.duration = $ctrl.duration;
          userSettings.fileName = $ctrl.fileKey;
          $ctrl.enabled = true;
          return $ctrl.setPrePost();
        })
        .then(function() {
          $ctrl.file = null;
          return refreshUrl();
        })
        .then(() => {
          return profileService.prepostCleanup($ctrl.mode, $ctrl.fileKey);
        })
        .catch(logger.error)
        .finally(function() {
          $ctrl.fileKey = null;
          $ctrl.uploadInProgress = false;
          $ctrl.thisUploadInProgress = false;
          $ctrl.uploadProgressPercent = 0;
        });
    };

    $scope.$on('fileChunkUploaded', function(event, state) {
      if (state.id === $ctrl.fileKey && state.progress) {
        $ctrl.uploadProgressPercent = state.progress;
        $scope.$apply();
      }
    });

    function getUserOtherVideoDirKey() {
      var userDir = coreUtilsService.replaceSpecialCharacters($ctrl.user.email) + '/';
      return userDir + 'videos/';
    }

    function refreshUrl() {
      const userSettings = $ctrl.user.userSettings[$ctrl.mode];
      if (userSettings.fileName) {
        $ctrl.awsURL = s3UploadService.getS3UrlForKey(userSettings.fileName);
        $ctrl.duration = userSettings.duration;
      }
    }
  }

})();
