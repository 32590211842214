(function() {
  angular
    .module('app.core')
    .directive('logoSelector', function () {
      return {
        restrict: 'E',
        templateUrl: 'app/directives/logo-selector/logo-selector.html',
        controller: logoSelectorController,
        controllerAs: 'logoCtrl',
        bindToController: true,
        scope: {
          parentController: '=',
          model: '=',
          logos: '=',
          onChange: '='
        }
      }
    }); 

    logoSelectorController.$inject = ['$scope'];

    function logoSelectorController($scope) {
      var vm = this;

      $scope.$watch(() => vm.model, () => {
        if (vm.onChange) vm.onChange();
      }, true)

      vm.getLogoName = function(logo) {
        let logoName = !!logo ? logo.name : 'N/A';
        return vm.parentController.user.logos ? vm.parentController.user.logos[logo.ID] ? vm.parentController.user.logos[logo.ID].alias || logoName : logoName : logoName;
      }

      vm.getLogoTag = function(logo) {
        return vm.parentController.user.logos ? vm.parentController.user.logos[logo.ID] ? (vm.parentController.user.logos[logo.ID].tag === '' ? '' : vm.parentController.user.logos[logo.ID].tag) : '' : '';
      }
    }

})();
