(function() {
  'use strict';

  angular
    .module('app.videoEditor')
    .controller('VideoEditorController', VideoEditorController);

  VideoEditorController.$inject = [
    '$timeout',
    '$scope',
    '$stateParams',
    'clipsService',
    'logger',
    'gameFieldsService',
    'fullVideosService',
    'coreUtilsService',
    'authService',
    's3UploadService',
  ];

  function VideoEditorController(
    $timeout,
    $scope,
    $stateParams,
    clipsService,
    logger,
    gameFieldsService,
    fullVideosService,
    coreUtilsService,
    authService,
    s3UploadService) {
    var vm = this;

    vm.fullVideoIDs = [];
    vm.fullVideos = [];
    vm.clips = [];
    vm.availableClips = [];
    vm.selectedClips = [];
    vm.eventTypes = [];
    vm.importanceFilter = 8;
    vm.selectedEventTypes = [];

    vm.currentTime = 0;
    vm.currentVideoIndex = 0;
    vm.currentPlaylist = [];
    vm.currentTimeOffset = 0;

    vm.accordionOpenHighlightPackageList = false;
    vm.accordionOpenVideoPreview = true;

    vm.data = {
      category: 'CustomHighlight',
      packageName: 'custom-video',
      packageDescription: null,
      duration: 0,
      clipS3Keys: [],
      fullVideoIDs: [],
    };

    $scope.chooseLocalFile = function(element) {
      var files = element.files;
      if (files.length) {
        vm.file = files[0];
        vm.upload();
      }
    };

    this.$onInit = function() {
      var selecetedFullVideoID = $stateParams.fullVideoID;
      if (selecetedFullVideoID) {
        vm.fullVideoIDs.push(selecetedFullVideoID);
      }

      vm.getFullVideos()
        .then(function() {
          return vm.getEventTypes();
        });
      vm.getClips()
        .then(function() {
          vm.update();
          vm.updateFilter();

          $timeout(function() {
            vm.mp4VideoDom = document.getElementById('video-editor-preview');
            vm.mp4VideoDom.ontimeupdate = function() {
              $timeout(function() {
                vm.currentTime = vm.currentTimeOffset + vm.mp4VideoDom.currentTime;
              });
            };

            vm.mp4VideoDom.addEventListener('ended', function() {
              vm.currentTimeOffset += vm.currentPlaylist[vm.currentVideoIndex].videoInfo.duration;
              vm.currentVideoIndex++;

              if (vm.currentVideoIndex + 1 > vm.currentPlaylist.length) {
                vm.stopVideo();
              } else
              if (vm.currentPlaylist[vm.currentVideoIndex]) {
                vm.mp4VideoDom.setAttribute(
                  'src',
                  coreUtilsService.encodeS3VideoUrl(vm.currentPlaylist[vm.currentVideoIndex].awsURL)
                );
                vm.mp4VideoDom.load();
                vm.mp4VideoDom.play();
              }
            }, false);

            vm.listOtherVideos()
              .then(function() {
                // // TODO: remove debug code
                // vm.availableClips = vm.clips;
                // vm.selectAndAddAllClips();
              });

          }, 500);
        });
    };

    vm.getClips = function() {
      var promises = [];
      var clips = [];
      vm.fullVideoIDs.forEach(function(fullVideoID) {
        var getClipPromise = clipsService.getClipsForVideo(fullVideoID).then(function(res) {
          clips = [...clips, ...res.data];
        });
        promises.push(getClipPromise);
      });

      return Promise.all(promises)
        .then(function() {
          clips.forEach(function(clip) {
            clip.isSelected = false;
            clip.select = function() {
              clip.isSelected = true;
              vm.addClipToPreview(clip);
              vm.update();
            };
            clip.unselect = function(index) {
              clip.isSelected = false;
              vm.removeClipFromPreview(index);
              vm.update();
              vm.updateFilter();
            };
          });
          vm.clips = clips;
        })
        .catch(logger.error);
    };

    vm.selectAndAddAllClips = function() {
      vm.availableClips.forEach(function(clip) {
        clip.select();
      });

      vm.update();
      vm.updateFilter();
    };

    vm.update = function() {
      $timeout(function() {
        vm.totalDuration = vm.currentPlaylist.reduce(function(totalDuration, item) {
          if (item.videoInfo) {
            totalDuration += item.videoInfo.duration;
          }
          return totalDuration;
        }, 0);
      }, 500);
    };

    vm.getFullVideos = function() {
      var promises = [];

      vm.fullVideoIDs.forEach(function(fullVideoID) {
        var getFullVideoPromise = fullVideosService.getFullVideoData(fullVideoID)
          .then(function(res) {
            vm.fullVideos.push(res.data);
          });
        promises.push(getFullVideoPromise);
      });

      return Promise.all(promises);
    };

    vm.getEventTypes = function() {
      return gameFieldsService.getClipEventTypes().then(function(res) {
        var cacheMap = {};
        var targetSportTypes = vm.fullVideos.map(function(fullVideo) {
          return fullVideo.sportType;
        });
        targetSportTypes.push('General');
        vm.eventTypes = res.data
          .filter(function(item) {
            return targetSportTypes.includes(item.sportType);
          })
          .filter(function(item) {
            if (cacheMap[item.event]) {
              return false;
            } else {
              cacheMap[item.event] = true;
              return true;
            }
          })
          .sort(function(a, b) {
            return (a.name > b.name) ? 1 : -1;
          });
      });
    };

    vm.updateFilter = function() {
      vm.inputEventType = '';
      var eventTypeList = vm.selectedEventTypes.map(function(item) {
        return item.event;
      });
      vm.availableClips = vm.clips
        .filter(function(item) {
          return !item.isSelected;
        })
        .filter(function(item) {
          return vm.importanceFilter <= item.importance;
        })
        .filter(function(item) {
          return (eventTypeList.length !== 0) ?
            eventTypeList.includes(item.eventType) : true;
        }).reverse();
    };

    
    vm.addClipToPreview = function(clip) {
      $timeout(function() {
        var source = document.createElement('source');
        source.src = clip.awsURL;
        source.type = 'video/mp4';
        vm.mp4VideoDom.appendChild(source);
       if(!vm.currentPlaylist.includes(clip)){
        vm.currentPlaylist.push(clip);
       }
      }, 100);
    };

    vm.removeClipFromPreview = function(index) {
      vm.stopVideo();
      // var clipInPlayListIndex = vm.currentPlaylist.findIndex(function(item) {
      //   return item.ID === clip.ID;
      // });
      $timeout(function() {
        vm.currentPlaylist.splice(index, 1);
      }, 300);
    };

    vm.playVideo = function() {
      // use the currentTime to play the correct video;
      vm.mp4VideoDom.play();
    };

    vm.pauseVideo = function() {
      vm.mp4VideoDom = document.getElementById('video-editor-preview');
      vm.mp4VideoDom.pause();
    };

    vm.stopVideo = function() {
      vm.pauseVideo();
      $timeout(function() {
        vm.currentTime = 0;
        vm.currentVideoIndex = 0;
        vm.currentTimeOffset = 0;

        if (vm.currentPlaylist[vm.currentVideoIndex]) {
          vm.mp4VideoDom.setAttribute(
            'src',
            coreUtilsService.encodeS3VideoUrl(vm.currentPlaylist[vm.currentVideoIndex].awsURL)
          );
          vm.mp4VideoDom.load();
        }
      }, 100);
    };

    vm.updateCurrentTime = function() {
      // vm.playVideo();
    };

    vm.upload = function() {
      var fileToUpload = vm.file;
      var dirKey = getUserOtherVideoDirKey();

      vm.uploadInProgress = true;
      vm.uploadProgressPercent = 0;
      return s3UploadService.getUploadVideoDuration(fileToUpload)
        .then(function(duration) {
          var fileKey = dirKey + fileToUpload.name + '?shouldEncode=true&duration=' + duration.toString();
          return s3UploadService.createMultipartUpload(fileToUpload, fileKey);
        })
        .then(function() {
          vm.file = null;
          vm.uploadInProgress = false;
          vm.uploadProgressPercent = 0;
        })
        .catch(logger.error)
        .finally(function() {
          vm.uploadInProgress = false;
          vm.uploadProgressPercent = 0;
          vm.listOtherVideos();
        });
    };

    vm.listOtherVideos = function() {
      var dirKey = getUserOtherVideoDirKey();
      return s3UploadService.listObjectKeys(dirKey)
        .then(function(keys) {
          vm.otherVideos = keys.map(function(key) {
            var item = {
              s3Key: key,
              name: key.split('/').pop() || 'unknown',
              awsURL: s3UploadService.getS3UrlForKey(key),
              previewURL: null,
              videoInfo: {
                duration: 0
              }
            };

            const duration = getQueryVariable(item.awsURL, 'duration') || 0;
            item.videoInfo.duration = (Number.isNaN(duration) || duration === 'Infinity') ? 0 : parseFloat(duration);

            item.select = function() {
              vm.addClipToPreview(item);
              vm.update();
            };
            item.unselect = function(index) {
              vm.removeClipFromPreview(index);
              vm.update();
              vm.updateFilter();
            };

            return item;
          });
        });
    };

    vm.onDrop = function(srcIndex, dstIndex) {
      dstIndex = dstIndex > srcIndex ? dstIndex - 1 : dstIndex;
      var dropAllowed = srcIndex !== dstIndex;
      if (dropAllowed) {
        vm.currentPlaylist.splice(dstIndex, 0, vm.currentPlaylist.splice(srcIndex, 1)[0]);
        return true;
      }
      return false;
    };

    vm.rebindPlaylist = function() {
      vm.stopVideo();
    };

    vm.createVideo = function() {
      vm.isBusy = true;
      Object.assign(vm.data, {
        fullVideoIDs: vm.fullVideoIDs,
        duration: vm.totalDuration,
        clipS3Keys: vm.currentPlaylist.map(function(item) {
          return item.s3Key;
        })
      });

      return Promise.resolve()
        .then(function() {
          return clipsService.createCustomHighlight(vm.data);
        })
        .then(function() {
          vm.accordionOpenHighlightPackageList = true;
          vm.accordionOpenVideoPreview = false;
          $timeout(function() {
            vm.refreshHighlightPackagesAt = Date.now();
          });
        })
        .catch(logger.error)
        .finally(function() {
          vm.isBusy = false;
        });
    };

    function getUserOtherVideoDirKey() {
      var userDir = coreUtilsService.replaceSpecialCharacters(authService.getUserData().email) + '/';
      var dirKey = userDir + 'videos/';
      return dirKey;
    }
  }
})();

function getQueryVariable(inURL, variable) {
  var query = inURL.split('?')[1];
  if (!query) {
    return null;
  }
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return null;
}
