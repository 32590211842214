(function() {
  'use strict';

  angular
    .module('app.core')
    .component('infoModal', {
      templateUrl: 'app/directives/info-modal/info-modal.html',
      controller: infoModalController,
      bindings: {
        dismiss: '&',
        resolve: '<'
      }
    });

  infoModalController.$inject = [
    '$window', '$timeout', 'coreUtilsService',
  ];

  function infoModalController($window, $timeout, coreUtilsService) {
    var vm = this;

    vm.isDownloading = false;

    vm.$onInit = function() {
      vm.srcLink = this.resolve.srcLink;
      vm.fileName = vm.srcLink.split('/').pop();
    };

    vm.download = function() {
      vm.isDownloading = true;
      coreUtilsService.saveToDisk(vm.srcLink, vm.fileName).finally(function() {
        $timeout(function() {
          vm.isDownloading = false;
        });
      });
    };

    vm.open = function() {
      $window.open(vm.srcLink, '_blank');
    };
  }
})();
