(function() {
  'use strict';

  angular
    .module('app.core')
    .factory('s3UploadService', s3UploadService);

  s3UploadService.$inject = ['$rootScope', '$q', 'logger', '$http', 'authService', 'CONF'];

  function s3UploadService($rootScope, $q, logger, $http, authService, CONF) {
    var vm = this;
    var initDeferred = $q.defer();
    vm.uploadProgressPercent = 0;
    vm.logoKey = null;
    vm.initDone = false;
    init();

    return {
      createMultipartUpload: createMultipartUpload,
      updateLogoService: updateLogoService,
      deleteImage: deleteImage,
      getDate: getDate,
      getS3UrlForKey: getS3UrlForKey,
      getS3Prefix: getS3Prefix,
      validateS3Object: validateS3Object,
      getS3ConsoleURL: getS3ConsoleURL,
      listObjectKeys: listObjectKeys,
      getS3VideoCDN : getS3VideoCDN,
      getS3OutputJSON: getS3OutputJSON,
      getUploadVideoDuration: getUploadVideoDuration,
      getInitDone: getInitDone
    };

    function init() {
      // Configure The S3 Object
      getAWSCredentials()
        .then(function(response) {
          AWS.config.update({
            region: response.data.region,
            accessKeyId: response.data.accessKeyId,
            secretAccessKey: response.data.secretAccessKey
          });
          //TODO Set bucket by user company?
          return getBucketInfo();
        })
        .then(function(response) {
          vm.bucketName = response.data.bucketName;
          vm.S3Prefix = response.data.S3Prefix;
          vm.s3RegionURL = response.data.endpoint;
          vm.isLocalS3 = response.data.isLocalS3;
          vm.bucketCDNDomain = response.data.bucketCDNDomain;

          vm.s3Config = {
            region: response.data.region,
            apiVersion: '2006-03-01',
            endpoint: response.data.endpoint,
            params: {
              Bucket: vm.bucketName,
              // ACL: 'public-read'
            },
            httpOptions: {
              timeout: 240000
            } // default is 120000
          };

          if (!vm.isLocalS3) {
            vm.s3Config.params.ACL = 'public-read';
          } else {
            vm.s3Config.s3ForcePathStyle = true;
          }
          vm.bucket = new AWS.S3(vm.s3Config);
          vm.initDone = true;
          initDeferred.resolve();
        })
        .catch(function(error) {
          logger.log('Error', error);
          initDeferred.reject();
        });
    }

    function getInitDone() {
      return vm.initDone;
    }

    function getS3UrlForKey(keyName, bucket) {
      return vm.s3RegionURL + '/' + (bucket || vm.bucketName) + '/' + keyName;
    }

    function getS3VideoCDN() {
      return vm.bucketCDNDomain;
    }

    function getS3Prefix() {
      return vm.S3Prefix;
    }

    function getS3OutputJSON(fullVideo) {
      return fetch(`${getS3VideoCDN()}/${fullVideo.userID.replace(/\W+/g, '')}/fullVideos/${fullVideo.ID}/logs/events.json`)
        .then((res) => {
          if (res.ok) return res.json();
          return Promise.reject({code: 404, message: 'No output file found'});
        })
        .catch((err) => Promise.reject(err));
    }

    function getAWSCredentials() {
      return $http.get('/api/videoUploadCredentials');
    }

    function getBucketInfo() {
      return $http.get('/api/videoBucketInfo');
    }

    function progressCallbackFactory(state) {
      return function(progress) {
        vm.uploadProgressPercent += (vm.percentPerPart * ((progress.loaded - state.currentLoaded) / progress.total));
        state.currentLoaded = progress.loaded;
        $rootScope.$broadcast('fileChunkUploaded', {
          progress: (vm.uploadProgressPercent),
          id: state.id
        });
      };
    }

    function createLocalUpload(fileToUpload, filePath, inMetadata) {
      vm.deferred = $q.defer();
      vm.fileKey = filePath;
      vm.fileSize = fileToUpload.size;
      var state = {
        currentLoaded: 0,
        id: vm.fileKey
      };
      var params = {
        Bucket: vm.bucketName,
        Key: filePath,
        Body: fileToUpload,
        ContentType: fileToUpload.type,
        Metadata: inMetadata || {},
      };
      vm.bucket
        .putObject(params)
        .on('progress', progressCallbackFactory(state))
        .on('httpUploadProgress', progressCallbackFactory(state))
        .send(function(err, data) {
          if (err) {
            vm.deferred.reject(err);
          } else {
            vm.deferred.resolve(data);
          }
        });
      return vm.deferred.promise;
    }

    function createMultipartUpload(fileToUpload, filePath, bucketName, inMetadata, inAcl) {
      // Localstack has issue with CORS/exposeHeader for eTag
      // Use the direct upload instead when developing locally
      if (vm.isLocalS3) {
        return createLocalUpload(fileToUpload, filePath, inMetadata);
      }
      // File
      vm.fileKey = filePath;
      vm.fileSize = fileToUpload.size;
      // Upload
      vm.startTime = new Date();
      var partNum = 0;
      var partSize = 1024 * 1024 * 5; // Minimum 5MB per chunk (except the last part) http://docs.aws.amazon.com/AmazonS3/latest/API/mpUploadComplete.html
      // if file is larger than 500 Mb set part size to 50 Mb
      if (Math.ceil(vm.fileSize / 1024 / 1024) > 500) {
        partSize = 1024 * 1024 * 50;
      }
      vm.numPartsLeft = Math.ceil(vm.fileSize / partSize);
      vm.percentPerPart = 100 / vm.numPartsLeft;
      vm.maxUploadTries = 3;
      var multiPartParams = {
        Bucket: bucketName || vm.bucketName,
        Key: vm.fileKey,
        ContentType: fileToUpload.type,
        Metadata: inMetadata || {},
      };
      if (inAcl) multiPartParams.ACL = inAcl;
      vm.multipartMap = {
        Parts: []
      };

      vm.deferred = $q.defer();
      // Multipart
      vm.uploadRequest = vm.bucket.createMultipartUpload(multiPartParams, function(mpErr, multipart) {
        if (mpErr) {
          logger.log('Error!', mpErr);
          return;
        }
        var uploadQueue = [];
        var uploadConcurrency = 3;
        var uploadCurrent = 0;

        // Grab each partSize chunk and upload it as a part
        for (var rangeStart = 0; rangeStart < vm.fileSize; rangeStart += partSize) {
          partNum++;
          var end = Math.min(rangeStart + partSize, vm.fileSize),
            partParams = {
              Body: fileToUpload.slice(rangeStart, end),
              Bucket: bucketName || vm.bucketName,
              Key: vm.fileKey,
              PartNumber: String(partNum),
              UploadId: multipart.UploadId
            };
          uploadQueue.push({
            bucket: vm.bucket,
            multipart: multipart,
            partParams: partParams
          });
        }

        for (var startIndex = 0; startIndex < uploadConcurrency; startIndex++) {
          uploadFromQueue(uploadQueue);
          if (startIndex + 1 != uploadConcurrency) {
            uploadCurrent++;
          }
        }

        function uploadFromQueue(uploadQueue) {
          var queueItem = uploadQueue[uploadCurrent];
          if (queueItem) {
            uploadPart(queueItem.bucket, queueItem.multipart, queueItem.partParams, null, function() {
              uploadCurrent++;
              uploadFromQueue(uploadQueue);
            });
          }
        }
      });
      // TODO: investigate why request abort does not trigger outside this function
      return vm.deferred.promise;
    }

    function completeMultipartUpload(s3, doneParams) {
      s3.completeMultipartUpload(doneParams, function(err) {
        if (err) {
          logger.log('An error occurred while completing the multipart upload');
          logger.log(err);
        } else {
          vm.uploadProgressPercent = 0;
          vm.deferred.resolve();
        }
      });
    }

    function uploadPart(s3, multipart, partParams, tryNum, cb) {
      tryNum = tryNum || 1;
      var state = {
        currentLoaded: 0,
        id: partParams.Key
      };
      s3.uploadPart(partParams)
        .on('httpUploadProgress', progressCallbackFactory(state))
        .send(function(multiErr, mData) {
          if (multiErr) {
            logger.log('upload part error ', multiErr);
            if (tryNum < vm.maxUploadTries) {
              logger.log('Retrying upload of part: #', partParams.PartNumber);
              uploadPart(s3, multipart, partParams, tryNum + 1, cb);
            } else {
              logger.log('Failed uploading part: #', partParams.PartNumber);
            }
            return;
          }
          vm.multipartMap.Parts[this.request.params.PartNumber - 1] = {
            ETag: mData.ETag,
            PartNumber: Number(this.request.params.PartNumber)
          };
          if (--vm.numPartsLeft > 0) {
            return cb(); // complete only when all parts uploaded
          }

          var doneParams = {
            Bucket: partParams.Bucket || vm.bucketName,
            Key: vm.fileKey,
            MultipartUpload: vm.multipartMap,
            UploadId: multipart.UploadId
          };
          $rootScope.$broadcast('processingUpload', {
            id: partParams.Key
          });
          completeMultipartUpload(s3, doneParams);
        });
    }

    function getDate() {
      var date = new Date();
      var year = date.getUTCFullYear();
      var month = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      return year + '-' + month + '/';
    }

    function updateLogoService(teamName, file) {
      vm.logoKey = teamName.replace(' ', '') + '-logo';
      vm.logoFile = file;
      vm.deferred = $q.defer();
      addPhoto(CONF.s3AlbumName);
      return vm.deferred.promise;
    }

    function deleteImage(imageName) {
      var deferred = $q.defer();
      vm.bucket.deleteObject({
        Bucket: vm.bucketName,
        Key: 'logos/' + imageName
      }, function(err) {
        if (err) {
          deferred.reject(err);
        }
        deferred.resolve();
      });
      return deferred.promise;
    }

    function listObjectKeys(inPrefix) {
      var params = {
        Prefix: inPrefix
      };
      return initDeferred.promise.then(function() {
        return vm.bucket.listObjects(params).promise().then(function(res) {
          return res.Contents
            .filter(function(item) {
              return item.Size > 0;
            })
            .map(function(item) {
            return item.Key;
          });
        })
      });
    }

    function getPhoto(albumName) {
      var albumPhotosKey = encodeURIComponent(albumName) + '/';
      var consist = false;
      vm.bucket.listObjects({
        Prefix: albumPhotosKey
      }, function(err, data) {
        if (err) {
          vm.deferred.reject(err);
          logger.info('Err');
        }
        // `this` references the AWS.Response instance that represents the response
        var protocol = this.request.httpRequest.endpoint.protocol;
        var host = this.request.httpRequest.endpoint.hostname;
        var bucketUrl = protocol + '//' + host + '/';
        data.Contents.forEach(function(photo) {
          if (photo.Key === albumPhotosKey + vm.logoKey) {
            consist = true;
            return vm.deferred.resolve({
              key: photo.Key,
              awsUrl: bucketUrl + photo.Key
            });
          }
        });
        if (!consist) {
          return vm.deferred.reject({
            message: 'Logo not consists'
          });
        }
      });
    }

    function addPhoto(albumName) {
      var file = vm.logoFile;
      var albumPhotosKey = encodeURIComponent(albumName) + '/';

      var photoKey = albumPhotosKey + vm.logoKey;
      vm.bucket.upload({
        Key: photoKey,
        Body: file,
        ContentType: file.type,
        ACL: 'public-read'
      }, function(err) {
        if (err) {
          logger.log('There was an error uploading your photo: ', err.message);
          vm.deferred.reject(err);
        }
        logger.info('Logo loaded to aws');
        getPhoto(albumName);
      });
    }

    function validateS3Object(inS3Key, inBucket) {
      var s3 = (inBucket) ? new AWS.S3() : vm.bucket;
      inBucket = inBucket || vm.bucketName;
      var headParams = {
        Bucket: inBucket,
        Key: inS3Key
      };
      return s3.headObject(headParams).promise();
    }

    function getS3ConsoleURL(userID, fullVideoID, clipID, taskID) {
      var region = vm.s3Config.region;
      var bucket = vm.bucketName;
      var baseURL = 'https://s3.console.aws.amazon.com/s3/buckets';

      var url = baseURL + '/' + bucket + '/' + userID.replace(/\W+/g, '') + '/fullVideos/' + fullVideoID + '/';

      if (clipID) {
        url = url + 'clips/' + clipID + '/';
      }

      if (taskID) {
        url = url + 'logs/' + taskID + '.log';
      }

      url = url + '?region=' + region + '&tab=overview';
      return url;
    }

    function getUploadVideoDuration(inFile) {
      return new Promise(function(resolve, reject) {
        var rd = new FileReader();
        rd.onload = function() {
          var blob = new Blob([inFile], {
            type: inFile.type
          });
          var url = URL.createObjectURL(blob);
          var video = document.createElement('video');
          video.preload = 'metadata';
          video.addEventListener('loadedmetadata', function() {
            URL.revokeObjectURL(url);
            var duration = (isNaN(video.duration)) ? 0 : video.duration;
            resolve(duration);
          });
          video.src = url;
        };
        rd.readAsArrayBuffer(inFile);
      });
    }
  }
})();
