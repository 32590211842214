(function() {
  'use strict';

  angular
    .module('app.core')
    .factory('coreUtilsService', coreUtilsService);

  coreUtilsService.$inject = [];

  function coreUtilsService() {
    var currentIntervals = [];

    var techAccount = inIFrame();

    return {
      currentIntervals: currentIntervals,
      techAccount: techAccount,
      saveToDisk: saveToDisk,
      replaceSpecialCharacters: replaceSpecialCharacters,
      encodeS3VideoUrl: encodeS3VideoUrl
    };

    function downloadVideo(inURL) {
      return new Promise(function(resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', inURL, true);
        xhr.responseType = 'blob';

        xhr.onload = function(e) {
          if (this.status == 200) {
            var blob = new Blob([this.response], {
              type: 'video/mp4'
            });
            return resolve(blob);
          } else {
            return reject(e);
          }

        };

        xhr.onerror = function() {
          reject('could not download file');
        };

        xhr.send();
      });
    }

    function saveToDisk(fileURL, fileName) {
      var url = fileURL.split('?')[0];
      fileName = fileName ? fileName.split('?')[0] : null;
      if (url.endsWith('.mp4')) {
        return downloadVideo(fileURL).then(function(data) {
          return saveAs(data, fileName, true);
        });
      } else {
        return saveAs(fileURL, fileName);
      }

    }

    // https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t
    function inIFrame() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }

    function replaceSpecialCharacters(text) {
      return (text) ? text.replace(/\W+/g, '') : text;
    }

    function encodeS3VideoUrl(inS3VideoURL) {
      if (inS3VideoURL.indexOf('?shouldEncode=true') > -1) {
        var queryString = encodeURIComponent(inS3VideoURL.split('?')[1]);
        return inS3VideoURL.split('?')[0] + '%3F' + queryString;
      } else {
        return inS3VideoURL;
      }
    }

    function saveAs(url, filename, isBlob) {
      // for non-IE
      if (!window.ActiveXObject) {
        if (isBlob) {
          url = window.URL.createObjectURL(url);
        }
        var element = createElement(url, filename);
        document.body.appendChild(element);
        clickElement(element);
        document.body.removeChild(element);
        if (isBlob) {
          window.URL.revokeObjectURL(url);
        }
      }
      // for IE
      else if (!!window.ActiveXObject && document.execCommand) {
        var _window = window.open(url, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, filename || url);
        _window.close();
      }
      return Promise.resolve();
    }

    function createElement(fileURL, fileName) {
      var id = Date.now() + '-save-element';
      var saveElement = document.createElement('a');
      saveElement.setAttribute('id', id);
      saveElement.href = fileURL;
      saveElement.target = '_blank';
      saveElement.download = fileName || 'unknown';
      saveElement.style = 'display:none;opacity:0;color:transparent;';
      return saveElement;
    }

    function clickElement(element) {
      if (typeof element.click === 'function') {
        document.getElementById(element.id).click();
      } else {
        var event = document.createEvent('Event');
        event.initEvent('click', true, true);
        element.dispatchEvent(event);
      }
    }
  }
})();
