(function() {

  angular.module('app.player')
    .directive('clipsControlButtons', function() {
      return {
        restrict: 'E',
        templateUrl: 'app/directives/clips-tab/clips-control-buttons.html',
        scope: {
          controller: '='
        },
        controller: clipsControlButtonsController,
        controllerAs: '$ctrl'
      };
    });

  clipsControlButtonsController.$inject = [
    '$state'
  ];

  function clipsControlButtonsController($state) {
    var vm = this;
    vm.goToVideoEditor = function(inFullVideoID){
      $state.go('videoEditor', {
        fullVideoID: inFullVideoID
      });
    };
  }

})();
