(function() {
  'use strict';

  angular
    .module('app.admin')
    .controller('AdminCreateUserController', AdminCreateUserController);

  AdminCreateUserController.$inject = ['$state', 'adminService', 'logger'];

  function AdminCreateUserController($state, adminService, logger) {
    var vm = this;

    vm.loading = false;
    vm.user = {};

    vm.create = function() {
      vm.loading = true;
      adminService.userCreate(vm.user).then(function() {
        $state.go('admin');
        logger.info('User ' + vm.user.email + ' created successfully');
      }, function(err) {
        logger.error(err.data.message);
      }).finally(function() {
        vm.loading = false;
      });
    };
  }
})();
