(function() {
  'use strict';

  angular
    .module('app.admin')
    .factory('adminService', adminService);

  adminService.$inject = ['$http', '$window', 'logger', '$state'];

  function adminService($http, $window, logger, $state) {
    return {
      userList: userList,
      getUserData: getUserData,
      isUserPro: isUserPro,
      userApprove: userApprove,
      userBan: userBan,
      userCreate: userCreate,
      userRemove: userRemove,
      toggleUserProStatus: toggleUserProStatus,
      getLoggedInUsers: getLoggedInUsers,
      getSystemInfo: getSystemInfo,
      getAvailableRoles: getAvailableRoles,
      changeRole: changeRole,
      userKickOut: userKickOut,
      getDefaultUserSettings: getDefaultUserSettings,
      getUsersOwningApiKeys: getUsersOwningApiKeys,
      createApiAccessKey: createApiAccessKey,
      deleteApiAccessKey: deleteApiAccessKey
    };

    function catchAdminError(inMessage){
      return function(res){
        if(inMessage){
          logger.info(inMessage);
        }
        if(res.status === 401){
          // Not an admin, redirect to login page.
          logger.info('Only Admin can view this.');
          $state.go('login');
        } else {
          logger.error(res.data);
          return Promise.reject(res.data);
        }
      };
    }

    function getUserData() {
      return $http({
        method: 'GET',
        url: '/api/me'
      }).catch(catchAdminError());
    }

    function getDefaultUserSettings() {
      return $http({
        method: 'GET',
        url: '/api/userDefaults'
      }).catch(err => logger.error(err));
    }

    function isUserPro() {
      return getUserData().then(function(result) {
        var proAttribute = result.data.UserAttributes.find(function(item) {
          return item.Name === 'custom:pro';
        });
        var proUser = proAttribute ? proAttribute.Value === 'true' : false;
        return proUser;
      });
    }

    function userList() {
      return $http({
        method: 'GET',
        url: '/api/admin/users'
      }).catch(catchAdminError());
    }

    function userApprove(user) {
      return $http({
        method: 'POST',
        url: '/api/admin/users/approve',
        data: {user: user}
      });
    }

    function userBan(user) {
      return $http({
        method: 'POST',
        url: '/api/admin/users/ban',
        data: {user: user}
      });
    }

    function userKickOut(inUserID) {
      return $http({
        method: 'POST',
        url: '/api/admin/users/kickOut',
        data: {userID: inUserID}
      });
    }

    function toggleUserProStatus(user, proStatus) {
      return $http({
        method: 'POST',
        url: '/api/admin/users/toggleProStatus',
        data: { user: user, status: proStatus }
      }).catch(function(error) {
        logger.error(error.data ? error.data.message : 'toggle user pro status error');
      });
    }

    function userCreate(user) {
      return $http({
        method: 'POST',
        url: '/api/admin/users',
        data: {user: user}
      });
    }

    function userRemove(user) {
      return $http({
        method: 'DELETE',
        url: '/api/admin/users/' + encodeURI(user)
      });
    }

    function getLoggedInUsers() {
      return $http({
        method: 'GET',
        url: '/api/admin/loggedInUsers'
      });
    }

    function getSystemInfo() {
      return $http({
        method: 'GET',
        url: '/api/admin/systemInfo'
      }).catch(catchAdminError());
    }

    function getAvailableRoles() {
      return $http({
        method: 'GET',
        url: '/api/admin/roles'
      });
    }

    function changeRole(email, role) {
      return $http({
        method: 'POST',
        url: '/api/admin/roles',
        data: {
          username: email,
          role: role
        }
      });
    }

    function getUsersOwningApiKeys() {
      return $http({
        method: 'GET',
        url: '/api/apiAccessKeys'
      });
    }

    function createApiAccessKey(userID) {
      return $http({
        method: 'POST',
        url: '/api/apiAccessKeys',
        data: {
          userID: userID
        }
      });
    }

    function deleteApiAccessKey(id) {
      return $http({
        method: 'DELETE',
        url: '/api/apiAccessKeys/' + id
      });
    }

  }

})();
