(function() {
  'use strict';

  angular
    .module('app.player')
    .filter('propsFilter', propsFilter)
    .filter('secondsToTime', secondsToTime)
    .filter('filterClips', filterClips);

  function propsFilter() {
    return function(items, props) {
      var out = [];

      if (angular.isArray(items)) {
        items.forEach(function(item) {
          var itemMatches = false;

          var keys = Object.keys(props);
          for (var i = 0; i < keys.length; i++) {
            var prop = keys[i];
            var text = props[prop].toLowerCase();

            if (item[prop] !== undefined && item[prop].toString().toLowerCase().indexOf(text) !== -1) {
              itemMatches = true;
              break;
            }
          }

          if (itemMatches) {
            out.push(item);
          }
        });
      } else {
        out = items;
      }

      return out;
    };
  }

  function secondsToTime() {
    return function(time) {
      var sec_num = parseInt(time, 10); // don't forget the second param
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours < 10) {
        hours = '0' + hours;
      }
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      return hours + ':' + minutes + ':' + seconds;
    };

  }

  function filterClips() {
    return function( items, importanceFilter, selectedEventTypes) {
      return items.filter(function(item) {
        if (selectedEventTypes.length) {
          for (var i = 0; i < selectedEventTypes.length; i++) {
            if (selectedEventTypes[i].event === item.eventType && item.importance >= importanceFilter) {
              return true;
            }
          }
          return false;
        }
        return item.importance >= importanceFilter;
      });
    };
  }
})();
