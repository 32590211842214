(function() {

  angular.module('app.core')
    .component('errorBlock', {
      templateUrl: 'app/error-block/error-block.component.html',
      controller: errorBlockController,
      bindings: {
        messages: '<'
      }
    });

  function errorBlockController() {
    var vm = this;

    this.$onInit = function() {
      vm.errorMessages = vm.messages;
    };

    this.$onUpdate = function() {
      console.log(vm.errorMessages);
    };
  }

})();
