(function() {
  'use strict';

  angular
    .module('app.core')
    .component('uploadTwitter', {
      templateUrl: 'app/directives/share/twitter/uploadTwitter.html',
      controller: uploadTwitterController,
      bindings: {
        dismiss: '&',
        resolve: '<'
      }
    });

  uploadTwitterController.$inject = ['clipsService', 'authService', 'profileService'];

  function uploadTwitterController(clipsService, authService, profileService) {
    var vm = this;
    vm.currentUserEmail = authService.getUserName();
    vm.inputUrl = null;
    vm.uploadInProgress = false;
    vm.disableInputUrl = true;
    vm.message = 'Powered by REELY';
    vm.noGifUrlTooltip = null;
    vm.tweetURL = null;
    vm.uploadError = null;
    vm.uploadMediaType = 'video';
    vm.maxClipLength = 140;
    vm.clipLengthTooltip = null;
    vm.credentialsList = [];
    vm.selectedCredentialsID = null;

    this.$onInit = function() {
      vm.videoInfo = vm.resolve.videoInfo;
      vm.directLink = vm.videoInfo && (vm.videoInfo.duration > 60 * 2 + 20 || vm.videoInfo.size > 512 * 1024 * 1024);
      vm.gifURL = vm.directLink ? vm.resolve.gifDirectUrl : vm.resolve.gifURL;
      vm.clipURL = vm.directLink ? vm.resolve.clipDirectURL : vm.resolve.clipURL;
      vm.clipLength = vm.resolve.clipLength;
      vm.credentialsList = vm.resolve.credentialsList;
      vm.selectedCredentialsID = vm.credentialsList.length ? vm.credentialsList[0].ID : null;
      vm.inputUrl = vm.clipURL || vm.gifURL;
      if (vm.clipLength > vm.maxClipLength) {
        vm.clipLengthTooltip = 'Clip length is over 140 seconds';
      }

      if (!vm.gifURL) {
        vm.noGifUrlTooltip = 'This gif has not been created yet';
      }
      if (!vm.clipURL) {
        vm.noClipUrlTooltip = 'This video has not been created yet';
      }

      if (vm.credentialsList.length === 0) {
        vm.openSignInWindow();
      }
    };

    this.mediaTypeChanged = function() {
      vm.inputUrl = vm.uploadMediaType === 'video' ? vm.clipURL : vm.gifURL;
    };

    this.upload = function() {
      vm.uploadError = null;
      vm.uploadInProgress = true;
      clipsService
        .twitterVideoUpload(vm.uploadMediaType, {
          message: vm.message || 'Powered by REELY',
          gifURL: vm.gifURL,
          clipURL: vm.clipURL,
          userEmail: vm.currentUserEmail,
          credentialsId: vm.selectedCredentialsID,
          tokenID: vm.selectedCredentialsID,
        })
        .then(function(result) {
          vm.tweetURL = result.data.tweetUrl;
        })
        .catch(function(result) {
          vm.uploadError = result.error;
        })
        .finally(function() {
          vm.uploadInProgress = false;
        });
    };

    this.openSignInWindow = function() {
      return profileService.openTwitterSignInWindow()
        .then(() => {
          return profileService.getTwitterTokens().then(res => {
            vm.credentialsList = res.data;
            vm.selectedCredentialsID = vm.credentialsList.length ? vm.credentialsList[0].ID : null;
          });
        });
    };
  }
})();
