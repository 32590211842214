(function() {
  'use strict';

  angular
    .module('app.player')
    .run(appRun);

  appRun.$inject = ['routerHelper', 'authService', '$q'];

  /* @ngInject */
  function appRun(routerHelper, authService, $q) {
    routerHelper.configureStates(getStates(authService, $q));
  }

  function getStates(authService, $q) {
    return [
      {
        state: 'player',
        config: {
          url: '/clip-player?gameID&tab',
          templateUrl: 'app/player/player.html',
          controller: 'PlayerController',
          controllerAs: 'vm',
          title: 'Player',
          resolve: {
            authData: ['authService', function(authService) {
              return authService.resume().then(function() {
                $q.resolve();
              }, function() {
                // need pass to q reject exception http://stackoverflow.com/questions/23324942/angularjs-promises-rethrow-caught-exceptions
                return $q.reject(new Error('AUTH_REQUIRED'));
              });
            }]
          }
        }
      },
      {
        state: 'start',
        config: {
          url: '/start',
          templateUrl: 'app/navigation/app-start-page.html',
          controller: 'StartController',
          controllerAs: '$ctrl',
          title: 'RocketReel start',
          resolve: {
            authData: ['authService', function(authService) {
              return authService.resume().then(function() {
                $q.resolve();
              }, function() {
                // need pass to q reject exception http://stackoverflow.com/questions/23324942/angularjs-promises-rethrow-caught-exceptions
                return $q.reject(new Error('AUTH_REQUIRED'));
              });
            }]
          }
        }
      }
    ];
  }

})();
