(function() {
  'use strict';

  angular
    .module('app.remote-analyzer')
    .run(appRun);

  appRun.$inject = ['routerHelper', 'authService', '$q'];

  /* @ngInject */
  function appRun(routerHelper, authService, $q) {
    routerHelper.configureStates(getStates(authService, $q));
  }

  function getStates(authService, $q) {
    return [{
      state: 'remote-analyzer',
      config: {
        url: '/remote-analyzer?fullVideoID&video_id&stream_url&sport&league&autoStart&auto_start&home_team&away_team&obe&oae&auto_detect_start',
        templateUrl: 'app/remote-analyzer/remote-analyzer.html',
        controller: 'RemoteAnalyzerController',
        controllerAs: '$ctrl',
        resolve: {
          authData: ['authService', function(authService) {
            return authService.resume().then(function() {
              $q.resolve();
            }, function() {
              // need pass to q reject exception http://stackoverflow.com/questions/23324942/angularjs-promises-rethrow-caught-exceptions
              return $q.reject(new Error('AUTH_REQUIRED'));
            });
          }]
        },
        title: 'Remote analyzer'
      }
    }];
  }

})();
