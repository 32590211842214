(function() {
  'use strict';

  angular
    .module('app.profile')
    .factory('profileService', profileService);

  profileService.$inject = ['$http', 'logger'];

  function profileService($http, logger) {
	  var preDuration = null;
	  var postDuration = null;
	  var preEnable = false;
	  var postEnable = false;
    return {
      getTwitterTokens: getTwitterTokens,
      openTwitterSignInWindow: openTwitterSignInWindow,
      deleteTwitterTokens: deleteTwitterTokens,

      getTwitchSubscriptions: getTwitchSubscriptions,
      createTwitchSubscription: createTwitchSubscription,
      updateTwitchSubscription: updateTwitchSubscription,
      deleteTwitchSubscription: deleteTwitchSubscription,
      getTwitchSubscriptionsStatus: getTwitchSubscriptionsStatus,

      sendMailAttachment: sendMailAttachment,

      getPushNotifications: getPushNotifications,
      getPushNotificationTopics: getPushNotificationTopics,
      registerPushNotification: registerPushNotification,
      updatePushNotification: updatePushNotification,
      removePushNotification: removePushNotification,

      setPreDuration : setPreDuration,
      getPreDuration : getPreDuration,
      setPostDuration : setPostDuration,
      getPostDuration : getPostDuration,
      setPreEnable : setPreEnable,
      getPreEnable : getPreEnable,
      setPostEnable : setPostEnable,
      getPostEnable : getPostEnable,
      prepostCleanup: prepostCleanup,

      createLogo : createLogo,
      updateLogo : updateLogo,
      deleteLogo : deleteLogo,
      getLogos : getLogos,
      getUserLogos : getUserLogos,
      getLogo : getLogo
    };

    function getTwitterTokens() {
      return $http({
        method: 'GET',
        url: '/api/social/twitter/tokens'
      });
    }

    function deleteTwitterTokens(ID) {
      return $http({
        method: 'DELETE',
        url: '/api/social/twitter/token/' + ID
      }).catch(function(error) {
        logger.info('error on delete twitter credentials: ', error);
      });
    }

    function openTwitterSignInWindow() {
      return new Promise(function(resolve) {
        popupCenter('/api/social/twitter/signIn', 'Authorize RocketReel to update your tweets.', '600', '300');
        window.onmessage = function(e) {
          if (e.data === 'reloadTwitterTokens') {
            resolve();
          }
        };
      });
    }

    function getTwitchSubscriptions() {
      return $http({
        method: 'GET',
        url: '/api/social/twitch/subscriptions'
      });
    }

    function getTwitchSubscriptionsStatus(ID) {
      return $http({
        method: 'GET',
        url: '/api/social/twitch/subscriptions/status/' + ID,
      });
    }

    function createTwitchSubscription(inData) {
      return $http({
        method: 'POST',
        url: '/api/social/twitch/subscription',
        data: inData
      });
    }

    function updateTwitchSubscription(inID, inData) {
      return $http({
        method: 'PUT',
        url: '/api/social/twitch/subscription/' + inID,
        data: inData
      });
    }

    function deleteTwitchSubscription(inID) {
      return $http({
        method: 'DELETE',
        url: '/api/social/twitch/subscription/' + inID,
      });
    }

    function sendMailAttachment(inLink) {
      return $http({
        method: 'POST',
        url: '/api/social/sendMailAttachment/',
        data: {
          link: inLink
        }
      })
    }

    function getPushNotifications() {
      return $http({
        method: 'GET',
        url: '/api/pushNotifications'
      });
    }

    function registerPushNotification(inData) {
      if (inData.ID) {
        return updatePushNotification(inData.ID, inData);
      }
      return $http({
        method: 'POST',
        url: '/api/pushNotifications',
        data: inData
      });
    }

    function getPushNotificationTopics() {
      return $http({
        method: 'GET',
        url: '/api/pushNotificationTopics'
      });
    }

    function updatePushNotification(inID, inData) {
      return $http({
        method: 'PUT',
        url: '/api/pushNotification/' + inID,
        data: inData
      });
    }

    function removePushNotification(inID) {
      return $http({
        method: 'DELETE',
        url: '/api/pushNotification/' + inID
      });
    }

    function createLogo(inData) {
      return $http({
        method: 'POST',
        url: '/api/logo/create',
        data: inData
      });
    }

    function updateLogo(inID, inData) {
      return $http({
        method: 'PUT',
        url: '/api/logo/update/' + inID,
        data: inData
      });
    }

    function deleteLogo(inID) {
      return $http({
        method: 'DELETE',
        url: '/api/logo/delete/' + inID
      });
    }

    function getLogos() {
      return $http({
        method: 'GET',
        url: '/api/logo/getLogos'
      });
    }

    function getUserLogos() {
      return $http({
        method: 'GET',
        url: '/api/logo/getUserLogos'
      });
    }

    function getLogo(inID) {
      return $http({
        method: 'GET',
        url: '/api/logo/getLogo/' + inID
      });
    }

    function setPreDuration(data) {
      preDuration = data;
    }

    function getPreDuration() {
      return preDuration;
    }

    function setPostDuration(data) {
      postDuration = data;
    }

    function getPostDuration() {
      return postDuration;
    }

    function setPreEnable(data) {
      preEnable = data;
    }

    function getPreEnable() {
      return preEnable;
    }

    function setPostEnable(data) {
      postEnable = data;
    }

    function getPostEnable() {
      return postEnable;
    }

    function prepostCleanup(mode, currentKey) {
      return $http({
        method: 'DELETE',
        url: '/api/userPrepost',
        headers: {
          mode: mode,
          currentKey: currentKey
        }
      });
    }

  }

})();


function popupCenter(url, title, w, h) {
  // Fixes dual-screen position                         Most browsers      Firefox
  var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
  var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

  var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  var left = ((width / 2) - (w / 2)) + dualScreenLeft;
  var top = ((height / 2) - (h / 2)) + dualScreenTop;
  var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

  // Puts focus on the newWindow
  if (window.focus && newWindow) {
    newWindow.focus();
  }
}
