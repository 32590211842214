(function() {
  'use strict';

  angular
    .module('app.dashboard')
    .run(appRun);

  appRun.$inject = ['routerHelper', 'authService', '$q'];

  /* @ngInject */
  function appRun(routerHelper, authService, $q) {
    routerHelper.configureStates(getStates(authService, $q));
  }

  function getStates(authService, $q) {
    return [{
      state: 'dashboard',
      config: {
        url: '/',
        templateUrl: 'app/dashboard/dashboard.html',
        controller: 'DashboardController',
        controllerAs: '$ctrl',
        title: 'Dashboard',
        resolve: {
          authData: ['authService', function(authService) {
            return authService.resume().then(function() {
              $q.resolve();
            }, function(err) {
              // need pass to q reject exception http://stackoverflow.com/questions/23324942/angularjs-promises-rethrow-caught-exceptions
              return $q.reject(new Error('AUTH_REQUIRED'));
            });
          }]
        },
      }
    }];
  }

})();
