(function() {
  'use strict';

  angular
    .module('app.core')
    .component('serverStatus', {
      templateUrl: 'app/directives/server-status/server-status.html',
      controller: serverStatusController,
      bindings: {}
    });

  serverStatusController.$inject = ['$timeout', '$rootScope', 'serverStatusService', 'CONF', 'SocketIOService'];

  function serverStatusController($timeout, $rootScope, serverStatusService, CONF, SocketIOService) {
    var vm = this;

    this.$onInit = function() {
      this.rotateSpinner = false;
      this.isConnecting = false;
      this.statusClass = '';
      this.refresh = refresh;
      refresh();
      
      $rootScope.$on('$stateChangeSuccess', refresh);

      var socketChannel = SocketIOService.getChannel('global');
      socketChannel.on('detectionServerReady', handleConnected);
    };
    

    function refresh() {
      console.log('refresh');
      if (vm.isConnecting) {
        return;
      }

      vm.rotateSpinner = true;
      vm.isConnecting = true;
      return serverStatusService.checkDetectionServerStatus()
        .then(handleConnected)
        .catch(function(){
          $rootScope.$broadcast('detectionServerStatusUpdated', 'disconnected');
          serverStatusService.setNodeStatus(null);
          vm.statusClass = CONF.instanceStateColors['pending'];
        })
        .finally(function(){
          vm.isConnecting = false;
        });
    }

    function handleConnected(){
      $timeout(function(){
        vm.isConnecting = false;
        vm.rotateSpinner = false;
        vm.statusClass = CONF.instanceStateColors['running'];
        serverStatusService.setNodeStatus('active');
        $rootScope.$broadcast('detectionServerStatusUpdated', 'running');
      }, 300);
    }
  }
})();
