(function() {

  angular.module('app.player')
    .directive('playerWind',
      function() {
        return {
          restrict: 'E',
          require: '^videogular',
          scope: {
            currentTime: '=',
            direction: '='
          },
          link: function(scope, elem, attrs, API) {
            var step = 10;
            var direction = scope.direction === 'forward' ? 'forward' : 'backward';
            var icon = '<i ng-click="test()" class="fa fa-{direction} fa-1x scrub-bar-marker" aria-hidden="true"></i>'
              .replace('{direction}', direction);
            elem.append(icon);
            elem.addClass('player-wind');
            scope.API = API;
            elem.bind('click', updateCurrentTime);
            function updateCurrentTime() {
              var time = direction === 'forward' ? scope.currentTime + step : scope.currentTime - step;
              scope.API.seekTime(time, false);
            }
          }
        };
      }
    );
})();

