(function() {
  'use strict';

  angular
    .module('app.core')
    .factory('videogularConfigService', videogularConfigService);

  videogularConfigService.$inject = ['$sce'];

  function videogularConfigService($sce) {
    return {
      setInitialConfig: setInitialConfig,
      updateSource: updateSource,
      API: null
    };

    function setInitialConfig(awsURL) {
      return {
        preload: 'auto',
        sources: [
          {
            src: awsURL ? $sce.trustAsResourceUrl(awsURL) : null,
            type: 'video/mp4'
          }
        ],
        playback: ['0.5', '1', '1.5', '2', '3'],
        plugins: {
          poster: 'images/poster.png',
          controls: {
            autoHide: true,
            autoHideTime: 2000
          }
        }
      };
    }

    function updateSource(awsURL) {
      awsURL = updateTimestamp(awsURL);
      return [
        {
          src: $sce.trustAsResourceUrl(awsURL),
          type: 'video/mp4'
        }
      ];
    }
    function updateTimestamp(url) {
      return url.replace(/\?timestamp=\d+/i, '') + '?timestamp=' + new Date().getTime()
    }
  }
})();
