(function() {
  'use strict';

  angular.module('app.auth')
    .factory('userSettings', UserSettings);

  UserSettings.$inject = [];

  function UserSettings() {
    this.timestamp = Date.now();
    this.setSettings = function(settings) {
      Object.assign(this, settings);
    };
    return this;
  }
})();
