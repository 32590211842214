(function () {
  'use strict';

  angular
    .module('app.core')
    .factory('serverStatusService', serverStatusService);

  serverStatusService.$inject = ['$http', '$timeout', 'logger'];

  function serverStatusService($http, $timeout, logger) {

    var detectionServerStatus = null;
    var isConnedted = false;
    return {
      checkDetectionServerStatus: checkDetectionServerStatus,
      setNodeStatus: setNodeStatus,
      getNodeStatus: getNodeStatus,
      isConnected: function(){
        return isConnedted;
      }
    };

    function checkDetectionServerStatus() {
      return $http({
        method: 'GET',
        url: '/api/machine_status'
      })
        .then(function(result) {
          if (result.data && result.data[0]) {
            if (result.data[0] !== 'running') {
              return Promise.reject();
            }
          }

          detectionServerStatus = 'running';
          isConnedted = true;
        })
        .catch(function(error) {
          detectionServerStatus = null;
          isConnedted = false;
          logger.log('cannot get detection server status: ', error);
          return Promise.reject(error);
        })
        .finally(function(){
          
        });
    }

    /**
     *
     * @param status - sets status to 'active' if node is running
     */
    function setNodeStatus(status) {
      detectionServerStatus = status;
    }

    /**
     *
     * @returns {*} null|'active'
     */
    function getNodeStatus() {
      return detectionServerStatus;
    }
  }
})();
