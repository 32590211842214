(function() {
  'use strict';

  angular
    .module('app.support')
    .run(appRun);

  appRun.$inject = ['routerHelper', 'authService', '$q'];

  /* @ngInject */
  function appRun(routerHelper, authService, $q) {
    routerHelper.configureStates(getStates(authService, $q));
  }

  function getStates(authService, $q) {
    return [{
      state: 'support',
      config: {
        url: '/support',
        template: '<support></support>',
        resolve: {
          authData: ['authService', function(authService) {
            return authService.resume().then(function() {
              $q.resolve();
            }, function() {
              // need pass to q reject exception http://stackoverflow.com/questions/23324942/angularjs-promises-rethrow-caught-exceptions
              return $q.reject(new Error('AUTH_REQUIRED'));
            });
          }]
        },
        title: 'Support'
      }
    }];
  }

})();
