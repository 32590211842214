(function() {
  'use strict';

  angular
    .module('app.streams-scheduler')
    .controller('StreamsSchedulerController', StreamsSchedulerController);

  StreamsSchedulerController.$inject = ['$timeout', '$uibModal', '$filter', 'streamsSchedulerService'];

  function StreamsSchedulerController($timeout, $uibModal, $filter, streamsSchedulerService) {

    var vm = this;
    vm.showGamefields = true;
    vm.streamScheduleList = [];
    vm.scheduleItem = null;
    vm.listLoading = false;


    vm.addScheduleItem = addScheduleItem;
    vm.refreshScheduleList = refreshScheduleList;
    vm.deleteScheduleItem = deleteScheduleItem;
    vm.triggerScheduleItem = triggerScheduleItem;

    init();

    function init() {
      vm.listLoading = true;
      streamsSchedulerService.pingScheduler()
        .then((res) => {
          if (res.data.status === 'offline') {
            vm.schedulerUnavailableMessage = "We're sorry but the scheduling service is currently unavailable, please try again in a while or contact our support if the problem persists.";
            if (window.location.hostname.includes('rocketreel-dev') || window.location.hostname.includes('rocketreel-staging')) {
              vm.schedulerUnavailableMessage = 'ADMIN-DEV-STAGING SERVER OFFLINE';
            }
            return 0;
          }
          return streamsSchedulerService.getScheduleList()
        })
        .then(function(result) {
          if (result && result.data) {
            vm.schedulerUnavailableMessage = undefined;
            vm.streamScheduleList = result.data;
          }
        })
        .finally(function() {
          $timeout(function() {
            vm.listLoading = false;
          }, 700);
        });
    }

    function refreshScheduleList() {
      init();
    }

    function addScheduleItem(scheduleItem) {
      var modalInstance = $uibModal.open({
        component: 'addScheduleComponent',
        windowClass: 'add-schedule-modal',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          scheduleItem: function() {
            return angular.copy(scheduleItem);
          },
        }
      });

      modalInstance.result.then(function() {
        init();
      });
    }

    function deleteScheduleItem(scheduleItemID) {
      if (confirm('Delete this schedule?')) {
        return streamsSchedulerService.deleteSchedule(scheduleItemID).then(function() {
          init();
        });
      }
    }

    function triggerScheduleItem(scheduleItemID){
      return streamsSchedulerService.triggerSchedule(scheduleItemID).then(function() {
        init();
      });
    }
  }
})();
