(function() {
  'use strict';

  angular
    .module('app.profile')
    .controller('PushNotificationsController', PushNotificationsController);

  PushNotificationsController.$inject = ['$state', '$window', '$uibModal', '$http', 'profileService', 'logger'];

  function PushNotificationsController($state, $window, $uibModal, $http, profileService, logger) {
    var vm = this;
    vm.userEmail = $window.localStorage['currentUserEmail'];
    vm.currentStateName = $state.current.name;

    vm.getPushNotifications = function() {
      return profileService.getPushNotifications()
        .then(function(result) {
          vm.list = result.data;
        });
    };

    vm.delete = function(inData) {
      if (confirm('Are you sure to unwatch this twitch channel?')) {
        return profileService.deleteTwitchSubscription(inData.ID)
          .then(function() {
            vm.getSubscriptions();
          });
      }
    };

    vm.openCreateModal = function(data) {
      const modalPromise = $uibModal.open({
        component: 'addNewPushNotificationComponent',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          data: (data) ? angular.copy(data) : null
        }
      }).result;

      return modalPromise
        .then(function() {
          return vm.getPushNotifications();
        });
    };

    vm.openRemoveModal = function(data) {
      const modalPromise = $uibModal.open({
        component: 'removePushNotificationComponent',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          data: (data) ? angular.copy(data) : null
        }
      }).result;

      return modalPromise
        .then(function() {
          return vm.getPushNotifications();
        });
    };

    vm.ping = function(data) {
      return Promise.resolve()
        .then(function() {
          return $http({
            method: data.apiParams.method,
            url: data.apiParams.url,
            headers: data.apiParams.headers
          });
        })
        .then(function() {
          logger.info('Succesfully ping api. Please check your server to confirm');
        })
        .catch(function(error) {
          logger.warn('Failed to ping the api', error);
        });
    };

    vm.edit = function(data) {
      return vm.openCreateModal(data);
    };

    vm.remove = function(data) {
      return vm.openRemoveModal(data);
    };

    vm.$onInit = function() {
      vm.getPushNotifications();

      // // TODO: remove debug code
      // vm.openCreateModal();
    };
  }
})();
