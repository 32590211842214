(function () {
  'use strict';

  angular
    .module('app.admin')
    .controller('AdminResourcesController', AdminResourcesController);

  AdminResourcesController.$inject = ['profileService', 'adminService', '$window', 'fullVideosService', 'logger'];

  function AdminResourcesController(profileService, adminService, $window, fullVideosService, logger) {
    var vm = this;
    vm.logoList = [];
    vm.userList = [];
    vm.refreshing = false;
    
    vm.$onInit = function() {
      return vm.refresh();
    }

    vm.refresh = function() {
      vm.refreshing = true;
      return profileService.getLogos()
        .then(function(result) {
          vm.logoList = result.data;
        })
        .catch(() => {logger.error('Could not refresh resources at this moment')})
        .finally(() => {
          vm.refreshing = false;
        });
    }

    vm.removePermanently = function(resource) {
      resource.removing = true;
      return adminService.userList()
        .then(function(response) {
          vm.userList = response.data;
          return vm.userList.filter((user) => user.logos && Object.keys(user.logos).includes(resource.ID));
        })
        .then((boundUsers) => {
          return Promise.all(boundUsers.map((user) => {
            return fullVideosService.getFullVideos(`userID=${user.email}`)
              .then(res => {
                return res.data.filter(fv => fv.logos && fv.logos.filter(logo => logo.ID === resource.ID).length)
              })
              .then(filteredFVs => {
                user.boundGames = filteredFVs;
                return user;
              })
          }))
        })
        .then((boundUsers) => {
          let message = '';
          if (boundUsers.length) {
            message = `There are ${boundUsers.length} users associated with this resource:\n`
            message += boundUsers.map((user) => `\n${user.email} (${user.boundGames.length} games)`)
            message += '\n\nIf you delete it, they will NOT be able to rerun these games.\nAre you sure you want to delete this resource?';
          } else {
            message = 'Are you sure you want to delete this resource?';
          }
    
          if ($window.confirm(message)) {
            return profileService.deleteLogo(resource.ID);
          }
        })
        .catch((err) => logger.error('Could not remove resource at this moment'))
        .finally(() => {
          resource.removing = false;
          vm.refresh();
        })
    }
  }
})();
