(function() {
  'use strict';

  angular
    .module('app.profile')
    .controller('TeamsController', TeamsController);

  TeamsController.$inject = [
    '$scope',
    'teamsService',
    'gameFieldsService',
    'remoteAnalyzerService',
    's3UploadService',
    '$state',
    'logger'
  ];

  function TeamsController($scope, teamsService, gameFieldsService, remoteAnalyzerService, s3UploadService, $state, logger) {

    var vm = this;
    vm.currentStateName = $state.current.name;
    vm.showAddLeagueBlock = false;
    vm.showAddTeamBlock = false;
    vm.sportTypes = null;
    vm.leagues = null;
    vm.selectedSportType = null;
    vm.selectedLeague = null;
    vm.teams = null;
    vm.selectedTeam = {};
    vm.newLogoFile = null;
    vm.errors = [];
    vm.errorsForLeagueForm = [];
    vm.regex = '^$|^[A-Za-z0-9]+';
    vm.debounce = {
      debounce: 500
    };

    gameFieldsService.getSportTypes().then(function(res) {
      vm.sportTypes = res.data;
      vm.getAllLeaguesAndTeams();
    });

    $scope.fileLoaded = function(e) {
      if (!vm.selectedTeam.ID) {
        return;
      }
      var tgt = e.target || window.event.srcElement,
        files = tgt.files,
        fileReader;

      if (FileReader && files && files.length) {
        fileReader = new FileReader();
        fileReader.onload = function() {

          vm.selectedTeam.Logo = fileReader.result;
          fileReader = null;
          $scope.$apply();
        };
        fileReader.readAsDataURL(files[0]);
        vm.newLogoFile = files[0];
        vm.updateTeamLogo();
      } else {
        // Not supported
      }
      tgt.value = '';
    };

    vm.getAllLeaguesAndTeams = function() {
      return teamsService.getLeagues()
        .then(function(res) {
          vm.allLeagues = res.data || res;
          return teamsService.getTeams();
        })
        .then(function(res) {
          vm.allTeams = res.data || res;
        });
    };

    vm.setSportType = function() {
      gameFieldsService.getLeaguesBySportType(vm.selectedSportType.name).then(function(res) {
        vm.leagues = res.data || res;
        vm.showAddLeagueBlock = false;
        vm.selectedLeague = null;
        vm.selectedTeam = null;
      });
    };

    vm.setLeague = function() {
      gameFieldsService.getTeamsByLeague(vm.selectedSportType.name, vm.selectedLeague.name).then(function(res) {
        vm.teams = res.data || res;
        vm.showAddTeamBlock = false;
      });
    };

    vm.toggleAddLeague = function() {
      vm.showAddLeagueBlock = !vm.showAddLeagueBlock;
      vm.selectedLeague = null;
    };

    vm.toggleAddTeam = function() {
      vm.showAddTeamBlock = !vm.showAddTeamBlock;
      vm.selectedTeam = null;
    };

    vm.selectLeague = function(inLeague) {
      vm.selectedLeague = inLeague;
      vm.selectedTeam = null;
      vm.setLeague();
    };

    vm.saveLeague = function() {
      vm.errorsForLeagueForm = [];
      if (!vm.selectedLeague || !vm.selectedLeague.name || !vm.selectedSportType) {
        return;
      }

      if (vm.selectedLeague.ID) {
        return teamsService.updateLeague(vm.selectedLeague.ID, vm.selectedLeague).then(function() {
          leagueChangeHandler();
        });
      }

      var params = {
        name: vm.selectedLeague.name,
        sportType: vm.selectedSportType.name
      };

      return teamsService.createLeague(params).then(function() {
        logger.success('Successfully created new team ');
        leagueChangeHandler();
      });
    };

    vm.saveTeam = function() {
      vm.errors = [];
      if (!vm.selectedTeam || !vm.selectedTeam.teamName || !vm.selectedLeague) {
        return;
      }

      if (vm.selectedTeam.ID) {
        delete vm.selectedTeam.Name;
        teamsService.updateTeam(vm.selectedTeam, vm.selectedTeam.ID).then(function() {
          teamChangeHandler();
        });
        return;
      }

      if (!validateTeamName()) {
        vm.errors.push('Team with same name already exists');
        return;
      }

      var params = {
        teamName: vm.selectedTeam.teamName,
        league: vm.selectedLeague.name,
        sportType: vm.selectedSportType.name
      };

      teamsService.createTeam(params).then(function() {
        logger.success('Successfully created new team ');
        teamChangeHandler();
      });
    };

    function leagueChangeHandler() {
      vm.showAddLeagueBlock = false;
      vm.selectedLeague.name = null;
      vm.teams = null;
      vm.setSportType();
      vm.getAllLeaguesAndTeams();
    }

    function teamChangeHandler() {
      vm.showAddTeamBlock = false;
      vm.selectedTeam.teamName = null;
      vm.setLeague();
      vm.getAllLeaguesAndTeams();
    }

    function validateTeamName() {
      return !vm.teams.some(function(team) {
        return team.teamName === vm.selectedTeam.teamName;
      });
    }

    vm.updateTeamLogo = function() {
      s3UploadService.updateLogoService(vm.selectedTeam.teamName, vm.newLogoFile).then(function(team) {
        //log aws url
        vm.selectedTeam.Logo = team.awsUrl;
        teamsService.updateTeam(vm.selectedTeam, vm.selectedTeam.ID);
        vm.showAddTeamBlock = false;
      }, function(reason) {
        logger.info(reason);
      });
    };

    vm.deleteImage = function() {
      if (confirm('Are you sure you want to delete team image?')) {
        var imageName = vm.selectedTeam.Logo.split('/').pop();
        s3UploadService.deleteImage(imageName).then(function() {
          vm.selectedTeam.Logo = null;
          teamsService.updateTeam(vm.selectedTeam, vm.selectedTeam.ID);
        });
      }
    };

    vm.deleteTeam = function(team) {
      if (confirm('Are you sure you want to delete this team?')) {
        if (vm.selectedTeam === team) {
          vm.selectedTeam = null;
          if (vm.showAddTeamBlock) {
            vm.showAddTeamBlock = false;
          }
        }
        team.teamDeleting = true;
        teamsService.deleteTeam(team.ID).then(function() {
          for (var i = 0; i < vm.teams.length; i++) {
            if (vm.teams[i].ID === team.ID) {
              vm.teams.splice(i, 1);
            }
          }
          logger.success('Team has been deleted');
        }).finally(function() {
          team.teamDeleting = false;
          teamChangeHandler();
        });
      }
    };
  }
})();
