(function() {
  'use strict';
  angular
    .module('app.admin')
    .filter('secondsToDateTime', [function() {
      return function(seconds) {
        return new Date(1970, 0, 1).setSeconds(seconds);
      };
    }])
    .filter('bytes', function() {
      return function(bytes, precision) {
        if (!bytes || isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
          return '-';
        }
        precision = precision || 1;
        var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
          number = Math.floor(Math.log(bytes) / Math.log(1024));
        return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
      };
    })
    .component('showVideoFilesComponent', {
      templateUrl: 'app/admin/show-video-files.html',
      controller: showVideoFilesController,
      controllerAs: '$ctrl',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
    });

  showVideoFilesController.$inject = ['$timeout', '$uibModal', 'fullVideosService', 'clipsService', 's3UploadService', 'authService'];

  function showVideoFilesController($timeout, $uibModal, fullVideosService, clipsService, s3UploadService, authService) {
    var vm = this;
    vm.controllerName = 'showVideoFiles';
    authService.isAppAdministrator().then(function() {
      vm.isAdministrator = true;
    });

    vm.$onInit = function() {
      vm.user = vm.resolve.inUser;
      vm.users = vm.resolve.inUsers;
      vm.targetFullVideoID = vm.resolve.inTargetFullVideoID;

      vm.title = vm.user.email;

      $timeout(function() {
        vm.loadFullVideos();
      }, 200);
    };

    vm.loadFullVideos = function() {
      vm.fullVideos = null;

      var queryString = 'userID=' + encodeURIComponent(vm.user.email);
      fullVideosService.getFullVideos(queryString).then(function(res) {
        var videos = res.data.map(function(item) {
          item.showClips = false;
          // item.clips = [];
          item.s3ConsoleURL = s3UploadService.getS3ConsoleURL(item.userID, item.ID);
          return item;
        });

        $timeout(function() {
          var video = videos.find(function(item) {
            return item.ID === vm.targetFullVideoID;
          });
          if (video) {
            vm.fullVideos = [video];
            vm.toggleClips(video);
          } else {
            vm.fullVideos = videos;
          }
        }, 100);
      });
    };

    vm.toggleClips = function(fullVideo) {
      fullVideo.showClips = !fullVideo.showClips;

      if (fullVideo.showClips && !fullVideo.clips) {
        var queryString = 'userID=' + vm.user.email;
        clipsService.getClipsForVideo(fullVideo.ID, queryString).then(function(res) {
          fullVideo.clips = res.data.map(function(item) {
            item.s3ConsoleURL = s3UploadService.getS3ConsoleURL(item.userID, item.fullVideoID, item.ID);
            return item;
          });
        });
      }
    };
    vm.toggleTaskss = function(fullVideo) {
      fullVideo.showTasks = !fullVideo.showTasks;

      if (fullVideo.showTasks && !fullVideo.tasks) {
        fullVideosService.getRelatedAnalyzerTasks(fullVideo.ID).then(function(res) {
          fullVideo.tasks = res.data.map(function(item) {
            item.s3ConsoleURL = s3UploadService.getS3ConsoleURL(item.userID, item.fullVideoID, null, item.ID);
            return item;
          });
        });
      }
    };

    vm.transferOwnership = function(fullVideo) {
      if (!vm.users) {
        return;
      }
      var modalInstance = $uibModal.open({
        component: 'transferOwnershipComponent',
        backdrop: 'static',
        windowClass: 'admin-transfer-ownership',
        keyboard: false,
        resolve: {
          inFullVideo: function() {
            return fullVideo;
          },
          inUsers: function() {
            return vm.users;
          },
          fullVideos: function() {
            return vm.fullVideos;
          }
        }
      });
      modalInstance.result.then(function() {
        vm.loadFullVideos();
      });
    };
  }
})();
