(function() {

  angular.module('app.core')
      .service('analyzerUtilsService', analyzerUtilsService);

  analyzerUtilsService.$inject = ['CONF', '$state', '$rootScope', 'remoteAnalyzerService'];

  function analyzerUtilsService(CONF, $state, $rootScope, remoteAnalyzerService) {

    var processState = {
      cut: false,
      analyzePlayer: false,
      analyzeStream: false,
    };

    // represents game currently selected in clip tab or in stream tab. Think of refactoring
    var currentGame = null;

    var runningStreamAnalyzers = [];
    var runningClipAnalyzers = [];

    refreshRunningList();

    return {
      refreshRunningList: refreshRunningList,
      setProcessState: setProcessState,
      getProcessState: getProcessState,
      setCurrentGame: setCurrentGame,
      getCurrentGame: getCurrentGame,
      getCurrentGameId: getCurrentGameId,
      populateAnalyzersList: populateAnalyzersList,
      removeFromAnalyzersList: removeFromAnalyzersList,
      getRunningClipAnalyzersList: getRunningClipAnalyzersList,
      getRunningStreamAnalyzersList: getRunningStreamAnalyzersList,
      getRunningClipAnalyzer: getRunningClipAnalyzer,
      getRunningStreamAnalyzer: getRunningStreamAnalyzer
    };

    /**
     *
     * @param processName - string - 'cut'|'analyzePlayer'|'analyzeStream'
     * @param isActive - boolean
     * @param videoID - string
     */
    function setProcessState(processName, isActive, videoID) {
      processState[processName] = isActive;
      if (videoID && !isActive) {
        removeFromAnalyzersList(videoID);
      }
    }

    function getProcessState(processName) {
      if (processName) {
        return processState[processName];
      }
      return processState.cut || processState.analyzePlayer || processState.analyzeStream;
    }

    function setCurrentGame(game) {
      if (game) {
        $state.go('.', {gameID: game.ID}, {notify: false});
      }
      currentGame = game || null;
    }

    function getCurrentGame(){
      return currentGame;
    }

    function getCurrentGameId() {
      if (currentGame && currentGame.video) {
        return currentGame.video.ID;
      }
      if (currentGame && !currentGame.video) {
        return currentGame.ID;
      }
      return currentGame;
    }


    function populateAnalyzersList(eventMessage, progress) {
      var inList = false;
      var srcList = (eventMessage.detectionType === CONF.controllerTypes.stream) ?
          runningStreamAnalyzers : runningClipAnalyzers;
      if (progress < 100) {
        srcList.forEach(function(item) {
          if (item && item.videoID === eventMessage.videoID) {
            inList = true;
          }
        });
        if (!inList) {
          srcList.push(eventMessage);
          $rootScope.$broadcast('analyzersListPopulated');
        }
      }
    }

    function removeFromAnalyzersList(videoID) {
      runningStreamAnalyzers = runningStreamAnalyzers.filter(function(process) {
        return process.videoID !== videoID;
      });
      runningClipAnalyzers = runningClipAnalyzers.filter(function(process) {
        return process.videoID !== videoID;
      });
    }

    function getRunningClipAnalyzersList() {
      return runningClipAnalyzers;
    }

    function getRunningStreamAnalyzersList() {
      return runningStreamAnalyzers;
    }

    function getRunningClipAnalyzer(videoID) {
      return runningClipAnalyzers.find(function(process) {
        return process.videoID === videoID;
      });
    }

    function getRunningStreamAnalyzer(videoID) {
      return runningStreamAnalyzers.find(function(process) {
        return process.videoID === videoID;
      })
    }

    function refreshRunningList(){
      return remoteAnalyzerService.getRunningAnalyzers().then(function(result) {
        runningStreamAnalyzers = [];
        runningClipAnalyzers = [];
        if (result.data && result.data.processList) {
          for(var i=0; i < result.data.processList.length; i++){
            var task = result.data.processList[i];
            if(!task.isFinished){
              if(task.detectionType === 'video'){
                runningClipAnalyzers.push(task);
              } else
              if(task.detectionType === 'stream'){
                runningStreamAnalyzers.push(task);
              }
            }
          }
        }
      })
          .catch(function() {    });
    }
  }

})();
