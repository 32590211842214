(function() {
  angular.module('app.core').directive('msDaysTwoWay', function() {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function(scope, element, attrs, modelCtrl) {
        function msToDays(msValue) {
          return msValue/(24*60*60*1000);
        }
        function daysToMs(days) {
          return days*24*60*60*1000;
        }
        modelCtrl.$parsers.push(daysToMs);
        modelCtrl.$formatters.push(msToDays);
      }
    };
  });
})();
