(function() {

  angular
    .module('app.player')
    .component('queueRowComponent', {
      templateUrl: 'app/player/queue/queue-row/queue-row.component.html',
      controller: QueueRowController,
      bindings: {
        game: '<',
        onDelete: '&'
      }
    });

  QueueRowController.$inject = ['$filter'];

  function QueueRowController($filter) {
    var $ctrl = this;

    $ctrl.$onInit = function() {
      $ctrl.date = $filter('date')(new Date($ctrl.game.gameDate), 'MM/dd hh:mm');
    };

    $ctrl.delete = function() {
      $ctrl.onDelete({game: $ctrl.game});
    };
  }

})();
