(function() {
  'use strict';

  angular
    .module('app.core')
    .config(appConfig)
    .run(appRun);

  appConfig.$inject = ['$httpProvider'];

  /* @ngInject */
  function appConfig($httpProvider) {
    var parsed = parse(location.search);
    var headers = $httpProvider.defaults.headers.common;

    var headerPrefix = 'x-external-iframe-';
    // pass external (iframe) query params to backend via headers
    for (var key in parsed) {
      if (parsed.hasOwnProperty(key)) {
        var headerKey = headerPrefix + key.toLowerCase();
        headers[headerKey] = parsed[key];
      }
    }

    var url = (window.location != window.parent.location)
      ? document.referrer
      : document.location.href;
    headers[headerPrefix + 'base_url'] = parseBaseUrl(url);

    // alternatively, register the interceptor via an anonymous factory
    $httpProvider.interceptors.push(function($q) {
      return {
        response: function(response) {
          if(response.data && response.data.success === true){
            response.data.status = response.status;
            return response.data;
          } else {
            return response;
          }
        },
        responseError: function(rejection){
          if(rejection.data && rejection.data.success === false){
            rejection.data.status = rejection.status;
            return $q.reject(rejection.data);
          } else {
            return $q.reject(rejection);
          }
        }
      };
    });
  }

  appRun.$inject = ['routerHelper'];

  /* @ngInject */
  function appRun(routerHelper) {
    var otherwise = '/404';
    routerHelper.configureStates(getStates(), otherwise);
  }

  function getStates() {
    return [
      {
        state: '404',
        config: {
          url: '/404',
          templateUrl: 'app/core/404.html',
          title: '404',
          controller: function($rootScope) { /* @ngInject */
            $rootScope.showSplash = false;
          }
        }
      }
    ];
  }

  function parse(location) {
    var pairs = location.substring(1).split('&');
    var obj = {};

    for (var i in pairs) {
      if (pairs[i] !== '') {
        var pair = pairs[i].split('=');
        obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
      }
    }

    return obj;
  }

  function parseBaseUrl(url){
    var protocol = url.split('://')[0];
    var basePath = url.split('://')[1].split('/')[0];
    return protocol + '://' + basePath + '/';
  }

})();
