(function() {

  angular.module('app.core')
    .service('scoreboardDetectionService', scoreboardDetectionService);

  scoreboardDetectionService.$inject = ['$http', 'logger'];

  function scoreboardDetectionService($http, logger) {
    return {
      registerInstance: registerInstance,
      getScoreboardRectangles: getScoreboardRectangles,
      setUserRectangles: setUserRectangles
    };

    // Notify UI_server that detection has started
    function registerInstance(userId, videoId) {
      return $http({
        method: 'POST',
        url: '/api/detection/uiServerRegisterInstance',
        data: {
          userID: userId,
          videoID: videoId,
          requestRectangles: 1,
          resetProcessing: 0
        }
      }).catch(function(error) {
        logger.info('scoreboardDetectionService::registerInstance - cannot register instance: ', error);
      });
    }

    // get scoreboard candidates for user to select
    function getScoreboardRectangles(userId, videoId) {
      return $http({
        method: 'POST',
        url: '/api/detection/uiServerGetScoreboardRectangles',
        data: {
          userID: userId,
          videoID: videoId,
          requestRectangles: 1,
          resetProcessing: 0
        },
      });
    }

    function setUserRectangles(data) {
      return $http({
        method: 'POST',
        url: '/api/detection/uiServerSetUserRectangles',
        data: data
      }).catch(function(error) {
        logger.info('scoreboardDetectionService::setUserRectangles - cannot set user rectangles: ', error);
      });
    }
  }

})();
