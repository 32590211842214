(function() {
  var MAX_ATTEMPTS_COUNT = 20;

  angular.module('app.core')
      .directive('hlsPlayerWrapper', hlsPlayerWrapper);

  hlsPlayerWrapper.$inject = ['logger', '$rootScope', '$timeout'];

  function hlsPlayerWrapper(logger, $rootScope, $timeout) {
    return {
      restrict: 'E',
      templateUrl: 'app/directives/hls-player-wrapper/hls-player-wrapper.html',
      scope: {
        controller: '='
      },
      link: function(scope) {
        if (videojs.players.hlsVideo) videojs.players.hlsVideo.dispose();
        let player = videojs('hlsVideo', {
          liveui: true,
          fluid: true,
          errorDisplay: false
        })

        player.ready(() => {
          $rootScope.$broadcast('hlsPlayerReady', scope.controller);
        })

        player.on('error', function(event) {
          $rootScope.$broadcast('waitOnHlsReady');
        })

        scope.$on('startHls', function(event, streamUrl, autoplay = false) {
          player.pause();
          player.src({
            src: streamUrl,
            type: "application/x-mpegURL"
          })
          if (autoplay) {
            player.load();
            player.play();
          }
          
        })

        scope.$on('toggleStreamPlayer', function(event, isShowingPlayer) {
          console.log('toggleStreamPlayer');
          if (isShowingPlayer) {
            player.play();
          } else {
            player.pause();
          }
        });
      }
    };
  }
})();
