
(function() {
  'use strict';
  var MAX_ATTEMPTS_COUNT = 120;

  angular
      .module('app.core')
      .factory('gameFieldsService', gameFieldsService);

  gameFieldsService.$inject = ['$http', '$sce', 'logger', 'authenticatedUser'];

  function gameFieldsService($http, $sce, logger, authenticatedUser) {
    return {
      getClipEventTypes: getClipEventTypes,
      getEventTypesBySportType: getEventTypesBySportType,
      getCountries: getCountries,
      getSportTypes: getSportTypes,
      getLeagues: getLeagues,
      getLeaguesBySportType: getLeaguesBySportType,
      getTeamsByLeague: getTeamsByLeague,
      validateGameFields: validateGameFields,
      checkURL: checkURL,
      checkHLSURL: checkHLSURL,
      checkTwitchChannel: checkTwitchChannel,
      checkTrovoChannel: checkTrovoChannel,
      groupSportTypeList: groupSportTypeList,
    };

    function getClipEventTypes() {
      return $http({
        method: 'GET',
        url: '/api/eventTypes'
      }).catch(function(error) {
        logger.info('error in get event types ', error);
      });
    }

    function getEventTypesBySportType(inSportTypeName) {
      return $http({
        method: 'GET',
        url: '/api/sportType/' + inSportTypeName + '/eventTypes'
      }).catch(function(error) {
        logger.info('error in get event types ', error);
      });
    }

    function getCountries() {
      return $http({
        method: 'GET',
        url: '/api/countries'
      }).catch(function(error) {
        logger.info('error in get countries ', error);
      });
    }

    function getSportTypes() {
      return $http({
        method: 'GET',
        url: '/api/sportTypes'
      }).catch(function(error) {
        logger.info('error in get leagues ', error);
      });
    }

    function getLeagues() {
      return $http({
        method: 'GET',
        url: '/api/leagues'
      }).catch(function(error) {
        logger.info('error in get leagues ', error);
      });
    }

    function getLeaguesBySportType(sportType) {
      return $http({
        method: 'GET',
        url: '/api/sportType/' + sportType + '/leagues'
      }).catch(function(error) {
        logger.info('error in get leagues ', error);
      });
    }

    // TODO: refactor API to use league ID instead of name
    function getTeamsByLeague(sportTypeName, leagueName) {
      return $http({
        method: 'GET',
        url: '/api/teams/' + sportTypeName + '/' + leagueName
      }).catch(function(error) {
        logger.info('cannot get list of teams: ', error);
      });
    }

    function validateGameFields(cutBeforeSBEventMargin, cutAfterSBEventMargin) {
      if (!authenticatedUser.hasRightFor('offsets')) {
        return [];
      }

      cutBeforeSBEventMargin = parseInt(cutBeforeSBEventMargin);
      cutAfterSBEventMargin = parseInt(cutAfterSBEventMargin);

      let gameFieldsErrors = [];
      if (!cutBeforeSBEventMargin || !cutAfterSBEventMargin) {
        gameFieldsErrors.push('Offsets should have integer values');
      }

      if ((cutBeforeSBEventMargin + cutAfterSBEventMargin) <= 4) {
        gameFieldsErrors.push('Sum of the offsets should be greater than 4');
      }
      return gameFieldsErrors;
    }

    function getCleanHeaders() {
      const defaultHeaders = $http.defaults.headers.common;
      const headers = {};
      Object.keys(defaultHeaders).forEach(function(key) {
        if (key.indexOf('x-external-iframe-') > -1) {
          headers[key] = undefined;
        }
      });
      return headers;
    }

    function checkURL(strURL, isGet, inContentType) {
      return $http({
        method: (isGet) ? 'GET' : 'HEAD',
        url: strURL,
        headers: Object.assign(getCleanHeaders(), {
          // 'Access-Control-Allow-Origin': undefined,
          'Content-Type': (inContentType) ? inContentType : undefined
        })
      });
    }

    function checkHLSURL(strURL) {
      if (strURL.indexOf('rtmp://') > -1 || 
        strURL.indexOf('.m3u8') > -1 || 
        strURL.indexOf('twitch.tv') > -1 || 
        strURL.indexOf('youtube.com/watch?v=') > -1 ||
        strURL.indexOf('trovo.live') > -1) {
        return Promise.resolve({
          streamURL: strURL
        });
      } else
        return new Promise(function(resolve, reject) {
          var hls = new Hls();
          var attempt = 0;
          hls.loadSource(strURL);
          hls.on(Hls.Events.ERROR, function() {
            if (attempt < MAX_ATTEMPTS_COUNT) {
              attempt++;
              setTimeout(function() {
                hls.loadSource(strURL);
              }, 5000);
            } else {
              hls.destroy();
              return reject('Cannot load stream');
            }
          });
          hls.on(Hls.Events.MANIFEST_PARSED, function() {
            hls.destroy();
            return resolve({
              streamURL: strURL
            });
          });
        });
    }

    function checkTwitchChannel(strURL) {
      if (strURL.indexOf('twitch.tv/videos/') > -1) {
        var twitchVODID = strURL.split('/').pop();
        return Promise.resolve({
          streamURL: null,
          twitchVODID: twitchVODID,
          twitchlIframeUrl: $sce.trustAsResourceUrl('https://player.twitch.tv/?video=v' + twitchVODID)
        });
      } else
      if (strURL.indexOf('twitch.tv/') > -1 || strURL.indexOf('://') === -1) {
        var twitchChannelName = strURL.split('/').pop();
        return Promise.resolve({
          streamURL: null,
          twitchChannelName: twitchChannelName,
          twitchlIframeUrl: $sce.trustAsResourceUrl('https://player.twitch.tv/?channel=' + twitchChannelName)
        });
      } else {
        return Promise.resolve({
          streamURL: strURL,
        });
      }
    }

    function checkTrovoChannel(strURL) {
      if (strURL.indexOf('trovo.live/') > -1 || strURL.indexOf('://') === -1) {
        var trovoChannelName = strURL.split('/').pop();
        return Promise.resolve({
          streamURL: null,
          trovoChannelName: trovoChannelName,
          trovoIframeUrl: $sce.trustAsResourceUrl('https://trovo.live/embed/player?streamername=' + trovoChannelName + '&fullscreen=0&hidecontrol=0&autoplay=1')
        });
      } else {
        return Promise.resolve({
          streamURL: strURL,
        });
      }
    }

    function groupSportTypeList(listItem) {
      return listItem.category || 'Other';
    }

  }
})();
