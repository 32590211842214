(function() {
  'use strict';
  angular
    .module('app.admin')
    .component('showTasksComponent', {
      templateUrl: 'app/admin/show-tasks.html',
      controller: showTasksController,
      controllerAs: '$ctrl',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
    });

  showTasksController.$inject = ['$window', 'fullVideosService'];

  function showTasksController($window, fullVideosService) {
    var vm = this;
    vm.controllerName = 'showTasks';

    vm.$onInit = function() {
      vm.title = vm.resolve.inTitle || 'Tasks';
      vm.tasks = vm.resolve.inTasks.map(function(task) {
        task.timeSince = timeSince(new Date(task.createdAt));
        return task;
      });
      vm.isStoppingAnalyzer = false;
      vm.isStoppingVideoID = null;

      vm.tasks.forEach(function(task) {
        fullVideosService.getFullVideoData(task.fullVideoID).then(function(res) {
          task.fullVideo = res.data;
        });
      });
    };

    vm.stop = function(task) {
      if (!$window.confirm('Do you want to stop this stream detection?')) {
        return;
      }
      vm.isStoppingVideoID = task.fullVideoID;
      vm.isStoppingAnalyzer = true;
      return fullVideosService.stopAnalyzer(task.ID).then(() => {
        vm.dismiss();
      }).finally(() => {
        vm.isStoppingAnalyzer = false;
        vm.isStoppingVideoID = null;
      });
    };

    function timeSince(date) {
      var seconds = Math.floor((new Date() - date) / 1000);

      var interval = Math.floor(seconds / 31536000);

      if (interval > 1) {
        return interval + ' years ago';
      }
      interval = Math.floor(seconds / 2592000);
      if (interval > 1) {
        return interval + ' months ago';
      }
      interval = Math.floor(seconds / 86400);
      if (interval > 1) {
        return interval + ' days ago';
      }
      interval = Math.floor(seconds / 3600);
      if (interval > 1) {
        return interval + ' hours ago';
      }
      interval = Math.floor(seconds / 60);
      if (interval > 1) {
        return interval + ' minutes ago';
      }
      return Math.floor(seconds) + ' seconds ago';
    }
  }
})();
