(function() {
  'use strict';

  angular
    .module('app.core')
    .component('socialShare', {
      templateUrl: 'app/directives/share/socialShare.html',
      controller: SocialShareController,
      bindings: {
        disabled: '<',
        facebookLink: '<',
        gifUrl: '<',
        gifDirectUrl: '<',
        videoUrl: '<',
        videoDirectUrl: '<',
        videoInfo: '<',
        duration: '<',
        name: '<',
        mode: '@',
        user: '<?',
        parent: '='
      }
    });

  SocialShareController.$inject = ['$scope', '$window', '$http', '$location', '$uibModal', '$httpParamSerializer', 'logger', 'profileService', 'clipsService', 's3UploadService'
  ];

  function SocialShareController($scope, $window, $http, $location, $uibModal, $httpParamSerializer, logger, profileService, clipsService, s3UploadService) {
    var vm = this;
    vm.name = 'Video created by RocketReel AI';
    vm.loading = false;

    vm.popupID = (Date.now() * Math.random()).toFixed(0);

    this.$onInit = function() {
      vm.mode = vm.mode || 'button'; // icon

      $scope.$on('packageCreation', packageCreationHandler)
    };

    vm.shareFacebook = function() {
      var params = $httpParamSerializer({
        title: vm.name,
        awsURL: vm.videoUrl,
        gifURL: vm.gifUrl
      });
      var api = 'api/facebookShare?' + params;
      var absUrl = $location.absUrl() + api;
      return $http.get('/' + api)
        .then(function() {
          /**
           *  Clear open graph cache
           */
          return $http.get('https://graph.facebook.com/?id=' + absUrl + '&scrape=true');
        })
        .then(function() {
          FB.ui({
            method: 'share',
            href: vm.videoUrl,
	    title: 'RocketReel'
          }, function() {

          });
        })
        .catch(logger.error);
    };

    vm.twitterVideoUpload = function() {
      if (vm.parent && vm.parent.dismiss) {
        vm.parent.dismiss();
      }
      return profileService.getTwitterTokens()
        .then(function(result) {
          return $uibModal.open({
            component: 'uploadTwitter',
            size: 'md',
            backdrop: 'static',
            resolve: {
              clipLength: function() {
                return vm.duration || 1; // clip.endTime - clip.startTime;
              },
              gifURL: function() {
                return vm.gifUrl;
              },
              gifDirectUrl: function() {
                return vm.gifDirectUrl;
              },
              clipURL: function() {
                return vm.videoUrl;
              },
              clipDirectURL: function() {
                return vm.videoDirectUrl;
              },
              videoInfo: function() {
                return vm.videoInfo;
              },
              credentialsList: function() {
                return result.data;
              }
            }
          });
        })
        .catch(logger.warn);
    };

    vm.instagramSendMail = function() {
      if ($window.confirm('We will send you an email with this video to load to instagram')) {
        profileService.sendMailAttachment(vm.videoUrl).then(function(data) {
          logger.info(data.message);
        }).catch(function(error) {
          logger.error('Error on Instagram share. ' + error.message, error);
        });
      }
    };

    vm.share = function() {
      if (vm.loading || !vm.prepostRollsActive()) {
        return;
      }
      vm.loading = true;
      return clipsService.prepareUserClip(vm.videoUrl)
        .then(function(response) {
          if (response.data && response.data.awsURL) {
            vm.loading = false;
            $uibModal.open({
              component: 'infoModal',
              backdrop: 'static',
              keyboard: false,
              resolve: {
                srcLink: function() {
                  return response.data.awsURL;
                },
              }
            });
          } else {
            if (response.message) logger.info(response.message);
          }
        })
        .catch((err) => {
          vm.loading = false;
          logger.error(err);
        });
    };

    vm.prepostRollsActive = function() {
      return vm.user && vm.user.userSettings && ((vm.user.userSettings.preRoll && vm.user.userSettings.preRoll.enabled && vm.user.userSettings.preRoll.fileName) || (vm.user.userSettings.postRoll && vm.user.userSettings.postRoll.enabled && vm.user.userSettings.postRoll.fileName));
    };

    function packageCreationHandler(event, data) {
      if (!vm.loading || vm.videoUrl !== s3UploadService.getS3UrlForKey(data.packageDescription)) {
        return;
      }
      vm.loading = false;
    }

  }
})();
