(function() {
  'use strict';

  angular
    .module('app.core')
    .factory('clipsService', clipsService);

  clipsService.$inject = ['$http', 'logger'];

  function clipsService($http, logger) {

    return {
      getClipsForVideo: getClipsForVideo,
      getClip: getClip,
      updateClip: updateClip,
      createClip: createClip,
      handleClipSave: handleClipSave,
      deleteVideoClips: deleteVideoClips,
      cutClip: cutClip,
      getPackagesList: getPackagesList,
      createAutomatedHighlight: createAutomatedHighlight,
      createCustomHighlight: createCustomHighlight,
      deleteHighlightPackage: deleteHighlightPackage,
      twitterVideoUpload: twitterVideoUpload,
      createGif: createGif,
      getLatestClips: getLatestClips,
      getQueueClipsByFullVideoID: getQueueClipsByFullVideoID,
      deleteQueueClip: deleteQueueClip,
      prepareUserClip: prepareUserClip,
      checkRetiredOrRemovedClips: checkRetiredOrRemovedClips,
      restoreClips: restoreClips
    };

    function getClipsForVideo(videoID, queryString) {
      return $http({
        method: 'GET',
        url: '/api/clipsForVideo/' + videoID + ((queryString) ? '?' + queryString : '')
      }).catch(function(error) {
        logger.log('error on get clips for video: ', error);
      });
    }

    function deleteVideoClips(clipToDelete) {
      return $http({
        method: 'DELETE',
        url: '/api/clips/' + clipToDelete
      }).catch(function(error) {
        logger.info('error on delete clips for video: ', error);
      });
    }

    function getClip(clipID) {
      return $http({
        method: 'GET',
        url: '/api/clips/' + clipID
      }).catch(function(error) {
        logger.info('error on get clip: ', error);
        return Promise.reject(error);
      });
    }

    function createGif(clipID) {
      return $http({
        method: 'POST',
        url: '/api/detection/createGif/' + clipID,
        data: {
          clipID: clipID
        }
      }).catch(function(res) {
        if (res.status === 409) {
          logger.warn('This clip is still processing.');
        } else {
          handleError(res, 'error on create gif: ');
        }
      });
    }

    function handleClipSave(clip) {
      if (clip.ID) {
        return this.updateClip(clip);
      }
      return this.createClip(clip);
    }

    function createClip(clip) {
      return $http({
        method: 'POST',
        url: '/api/clips/',
        data: clip
      }).catch(function(res) {
        if (res.status === 404) {
          logger.info('The full video file is not available yet. Please wait');
          return Promise.reject();
        } else
        if (res.status === 409) {
          logger.warn('This clip is still processing.');
        } else {
          var error = (res.data && res.data.error) ? res.data.error : res;
          // logger.info('error on clip create: ' + error);
          return Promise.reject(error);
        }
      });
    }

    /**
     * Method that handles clips saving - saves, creates and deletes corresponding clips.
     *
     * @param clip - object - contains both clips to create - without ID
     *  and clips to update - with ID
     */
    function updateClip(clip) {
      return $http({
        method: 'PUT',
        url: '/api/clips/' + clip.ID,
        data: clip
      }).catch(function(error) {
        logger.info('error on clip update: ', error);
        return Promise.reject(error);
      });
    }


    /**
     * Sends request to cut single clip to disk
     *
     * @param clipId
     * @returns {*}
     */
    function cutClip(clipId) {
      return $http({
        method: 'POST',
        url: '/api/detection/createClip/' + clipId
      }).catch(function(res) {
        if (res.status === 404) {
          logger.info('The full video file is not available yet. Please wait');
          return Promise.reject();
        } else
        if (res.status === 409) {
          logger.warn('This clip is still processing.');
          return Promise.reject();
        } else {
          var error = (res.data && res.data.error) ? res.data.error : res;
          // logger.info('error on clip create: ' + error);
          return Promise.reject(error);
        }
      });
    }

    function getPackagesList(inFullVideoIds, rangeInSeconds) {
      return $http({
        method: 'GET',
        url: '/api/highlightPackage',
        params: {
          rangeInSeconds: rangeInSeconds,
          'fullVideoIDs[]': inFullVideoIds || []
        },
      }).catch(function(error) {
        logger.info('error on create clips video: ', error);
      });
    }

    function createCustomHighlight(inData) {
      return $http({
        method: 'POST',
        url: '/api/highlightPackage',
        data: inData
      });
    }

    function createAutomatedHighlight(inData) {
      return $http({
        method: 'POST',
        url: '/api/highlightPackageAutomated',
        data: inData
      });
    }

    function deleteHighlightPackage(inID) {
      return $http({
        method: 'DELETE',
        url: '/api/highlightPackages/' + inID
      });
    }

    /**
     * @param mediaType - upload video or gif
     * @param params - twitter message and clip URL
     */
    function twitterVideoUpload(mediaType, params) {
      // var endpoint = mediaType === 'gif' ? 'publishGif' : 'publishVideo';
      return $http({
        method: 'POST',
        url: '/api/social/twitter/publish',
        data: {
          url: (mediaType === 'gif') ? params.gifURL : params.clipURL,
          mediaType: mediaType,
          message: params.message,
          credentialsId: params.credentialsId,
          tokenID: params.tokenID,
        }
      });
    }

    function handleError(error, customMessage) {
      customMessage = customMessage || '';
      if (error.status === 502) {
        logger.info('Server is warming up, please wait');
      } else {
        logger.error(customMessage + error.statusText, error);
      }
    }

    function getLatestClips() {
      return $http({
        method: 'GET',
        url: '/api/clipsLatest'
      });
    }

    function getQueueClipsByFullVideoID(inFullVideoID) {
      return $http({
        method: 'GET',
        url: '/api/detection/queuedClips?fullVideoID=' + inFullVideoID
      });
    }

    function deleteQueueClip(inQueueKey) {
      return $http({
        method: 'DELETE',
        url: '/api/detection/queuedClips/' + inQueueKey
      });
    }

    function prepareUserClip(inVideoUrl) {
      return $http({
        method: 'POST',
        url: '/api/social/prepareClip',
        data: {
          url: inVideoUrl
        }
      });
    }

    function checkRetiredOrRemovedClips(fullVideoID) {
      return $http({
        method: 'GET',
        url: '/api/clipsRetiredOrRemoved/' + fullVideoID
      }).catch(function(error) {
        logger.info('Error on retired/removed clips check: ', error);
        return Promise.reject(error);
      });
    }

    function restoreClips(fullVideoID) {
      return $http({
        method: 'GET',
        url: '/api/clipsRestore/' + fullVideoID
      }).catch(function(error) {
        logger.info('Error on restore clips: ', error);
        return Promise.reject(error);
      });
    }
  }
})();
