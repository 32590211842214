(function() {
  'use strict';

  angular
    .module('app.profile')
    .controller('TwitchWatchListController', TwitchWatchListController);

  TwitchWatchListController.$inject = ['$state', '$window', '$uibModal', 'profileService'];

  function TwitchWatchListController($state, $window, $uibModal, profileService) {
    var vm = this;
    vm.userEmail = $window.localStorage['currentUserEmail'];
    vm.currentStateName = $state.current.name;
    vm.liveChannels = {};

    vm.getSubscriptions = function() {
      return profileService.getTwitchSubscriptions()
        .then(function(result) {
          vm.twitchWatchList = result.data;
        });
    };

    vm.delete = function(inData) {
      if (confirm('Are you sure to unwatch this twitch channel?')) {
        return profileService.deleteTwitchSubscription(inData.ID)
          .then(function() {
            vm.getSubscriptions();
          });
      }
    };

    vm.openCreateModal = function() {
      const modalPromise = $uibModal.open({
        component: 'addNewTwitchSubscriptionComponent',
        backdrop: 'static',
        keyboard: false,
        resolve: {}
      }).result;

      return modalPromise
        .then(function() {
          return vm.getSubscriptions()
            .then(() => {
              vm.fetchSubscriptionsStatus();
            });
        });
    };

    vm.fetchSubscriptionsStatus = function(){
      profileService.getTwitchSubscriptionsStatus({ID : 0})
        .then(res => {
          vm.liveChannels = {};
          res.data.forEach(element => {
            vm.liveChannels[element.user_id] = element.game_name;
          });
        })
    };

    vm.toggleAllowSportChange = function(data) {
      data.allowSportChange = !data.allowSportChange;
      return profileService.updateTwitchSubscription(data.ID, data)
        .then(() => {
          return vm.getSubscriptions();
        })
        .then(() => {
          return vm.fetchSubscriptionsStatus();
        });
    };

    vm.$onInit = function() {
      vm.getSubscriptions()
        .then(() => {
          vm.fetchSubscriptionsStatus();
        })
    };
  }
})();
