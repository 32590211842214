(function() {
  'use strict';

  angular
    .module('app.admin')
    .controller('AdminController', AdminController);

  AdminController.$inject = ['$state', 'authService'];

  function AdminController($state, authService) {
    var $ctrl = this;
    $ctrl.$state = $state;

    if ($state.current.name.indexOf('admin.') === -1) {
      $state.go('admin.system');
    }

    $ctrl.navigationMenu = [{
      name: 'Users',
      state: 'admin.users'
    },];

    authService.isAppAdministrator().then(function() {
      $ctrl.navigationMenu = [{
        name: 'System',
        state: 'admin.system'
      }, {
        name: 'Users',
        state: 'admin.users'
      }, {
        name: 'Tasks',
        state: 'admin.tasks'
      }, {
        name: 'Games',
        state: 'admin.games'
      }, {
        name: 'Lists',
        state: 'admin.lists'
      }, {
        name: 'Resources',
        state: 'admin.resources'
      }];
    });

    $ctrl.navigate = function(menuItem) {
      return $state.go(menuItem.state);
    };
  }
})();
