(function() {
  'use strict';

  angular.module('app.core')
    .component('notificationPopupList', {
      templateUrl: 'app/directives/notification-popup-list/notification-popup-list.component.html',
      require: {
        uibDropdown: '?^uibDropdown'
      },
      controller: NotificationPopupListComponent,
      bindings: {}
    });

  NotificationPopupListComponent.$inject = ['notificationPopupListService'];

  function NotificationPopupListComponent(notificationPopupListService) {
    var $ctrl = this;

    $ctrl.$onInit = function() {
      refresh();

      if ($ctrl.uibDropdown) {
        var toggle = $ctrl.uibDropdown.toggle;
        $ctrl.uibDropdown['toggle'] = function() {
          refresh();
          toggle();
        }
      }
    };

    $ctrl.read = function (item, $event) {
      $event.stopPropagation();
      item.read = true;
      notificationPopupListService.save($ctrl.items);
    };

    $ctrl.allRead = function ($event) {
      $event.stopPropagation();
      $ctrl.items.forEach(function (item) {
        item.read = true;
      });
      notificationPopupListService.save($ctrl.items);
    };

    function refresh() {
      return notificationPopupListService.getItems().then(function(items) {
        $ctrl.items = items;
      });
    }
  }

})();
