(function() {
  'use strict';

  const modes = [{
    icon: 'fa-video-camera',
    label: 'Stream URL',
    tooltip: 'Live Stream feed',
    value: 'stream'
  }, {
    icon: 'fa-twitch',
    label: 'Twitch',
    tooltip: 'Twitch Channel/VOD',
    value: 'twitch'
  }, {
    icon: 'fa-video-camera',
    label: 'Trovo',
    tooltip: 'Trovo Channel',
    value: 'trovo'
  }, {
    icon: 'fa-upload',
    label: 'Upload',
    tooltip: 'Upload from Local',
    value: 'upload'
  }, {
    icon: 'fa-cloud',
    label: 'AWS S3',
    tooltip: 'Copy Video From S3 Bucket',
    value: 'externalS3'
  }, {
    icon: 'fa-file-excel-o',
    label: 'Excel',
    tooltip: 'Load IDs from an Excel file',
    value: 'xlsx'
  }];

  angular
    .module('app.core')
    .component('addGameWizardModal', {
      templateUrl: 'app/directives/add-game-wizard/add-game-wizard-modal.html',
      controller: controller,
      bindings: {
        dismiss: '&',
        close: '&',
        resolve: '<'
      }
    });

  controller.$inject = [
    '$window', '$location', '$timeout', '$anchorScroll', '$scope', 'gameFieldsService', 'CONF',
    'coreUtilsService', 'authService', 's3UploadService', 'teamsService', 'streamsSchedulerService',
    'fullVideosService', 'logger', 'remoteAnalyzerService', 'serverStatusService', 'profileService'
  ];

  function controller(
    $window, $location, $timeout, $anchorScroll, $scope, gameFieldsService, CONF,
    coreUtilsService, authService, s3UploadService, teamsService, streamsSchedulerService,
    fullVideosService, logger, remoteAnalyzerService, serverStatusService, profileService
  ) {
    var vm = this;

    vm.$scope = $scope;

    vm.lastRTMPAccordionState = false;

    // Restricting availability of some modes only for certain users
    // TODO should be based on DB entries in the future
    vm.isModeEnabled = function(mode) {
      if (mode.value === 'xlsx' && 
          authService.getUserData().company !== 'NAS' && 
          !authService.getUserData().email.includes('nasathletics') &&
          !authService.isAdministrator()) return false;
      
      return true;
    }

    vm.modes = modes.filter((el) => vm.isModeEnabled(el));
    vm.groupSportTypeList = gameFieldsService.groupSportTypeList;
    vm.datePicker = {
      format: CONF.datePickerFormat,
      altFormat: CONF.datePickerAltFormats,
      options: {
        dateDisabled: false,
        minDate: new Date(),
        startingDay: 1,
      },
      hStep: 1,
      mStep: 1,
      isMeridian: true
    };
    vm.logos = [];
    vm.selectedLogos = [];
    
    vm.detectionServerRunning = serverStatusService.isConnected();

    gameFieldsService.getSportTypes().then(function(res) {
      vm.sportTypes = res.data;
    });

    vm.reset = function() {
      $timeout(function() {
        vm.uploadInProgress = false;
        vm.uploadProgressPercent = 0;
        vm.file = null;
        vm.xlsxFile = null;
        vm.parsedExcelEntries = null;
        vm.globalXlsxSelectedSportType = null;
        vm.logoArchive = null;
        vm.fileURL = null;
        vm.showAdvancedParameters = false;
        vm.shouldCreateScheduleItem = true;
        vm.canCreateScheduleItem = true;
        vm.isSourceValid = false;

        vm.accordionSource = true;
        vm.accordionGameDate = false;
        vm.accordionGameDetails = false;
        vm.accordionGetRTMPKey = false;
        vm.accordionUploadLogoArchive = false;

        vm.selectedSportType = null;
        vm.selectedLeague = null;

        var now = new Date((Date.now() + 5 * 60 * 1000));

        vm.fullVideo = {
          ID: null,
          isStored: false,
          cutAfterSBEventMargin: 0,
          cutBeforeSBEventMargin: 0,
          removeWhiteDigits: false,
          removeBlackDigits: false,
          detectGameStart: false,
          calcSBEvent: false,
          startTime: 0,
          externalURL: null,
          streamURL: null,
          streamTwitchChannelName: null,
          streamTrovoChannelName: null,
          sportRadarGameID: null,
          gameDate: now,
          gameScore: '0-0',
          homeTeam: null,
          visitingTeam: null,
          sportType: null,
          noAudio: false,
          profile: null,
        };

        vm.scheduleItem = {
          scheduledAt: now,
          scheduledAtTime: now,
          gameDatePopupOpened: false,
        };
      });
    };

    $scope.chooseLocalFile = function(element) {
      var files = element.files;
      if (files.length) {
        vm.file = null;
        vm.fileURL = null;
        $timeout(function() {
          vm.file = null;
          vm.fileURL = null;
        });
        $timeout(function() {
          vm.file = files[0];
          vm.fileURL = URL.createObjectURL(vm.file);
          vm.openAccordions();
        }, 500);
      }
    };

    vm.parseDate = function(date) {
      if (!date) return undefined;
      let dateArray = date.split(' ');
      dateArray.splice(dateArray.findIndex((el) => el === '-'), 1);
      let amPmIdx = dateArray.find((el) => el === 'PM') ? dateArray.findIndex((el) => el === 'PM') : dateArray.findIndex((el) => el === 'AM');
      dateArray[amPmIdx-1] = dateArray[amPmIdx] === 'AM' ? dateArray[amPmIdx-1] : 
                              (parseInt(dateArray[amPmIdx-1].split(':')[0])+12).toString()+':'+dateArray[amPmIdx-1].split(':')[1];
      dateArray.splice(amPmIdx, 1);
      return new Date(Date.parse(dateArray.join(' ')));
    }

    // function specifically for NAS and their xlsx stream/VOD scheduling files
    $scope.loadXlsxFile = function(element) {
      vm.xlsxLoading = true;
      var files = element.files;
      if (files.length) {
        vm.xlsxFile = null;
        $timeout(function() {
          vm.xlsxFile = null;
        });
        $timeout(function() {
          vm.xlsxFile = files[0];
          readXlsxFile(vm.xlsxFile)
            .then((rows) => {
              let parsingErrors = [];
              let basicHeaders = ['livestreamid', 'vodid', 'starttime', 'participant1', 'participant2', 'url', 'activity'];
              let parsedHeaders = null;
              let parsedStreams = rows.map((row) => {
                if (!parsedHeaders && row.some((el) => {
                  if (!el) return false;
                  return basicHeaders.includes(el.trim().replaceAll(' ', '').toLocaleLowerCase())
                })) {
                  parsedHeaders = row.map((header) => header.trim().replaceAll(' ', '').toLocaleLowerCase());
                  return null;
                }
                if (parsedHeaders && !parsedHeaders.includes('livestreamid') && !parsedHeaders.includes('vodid') && !parsingErrors.includes('ids')){
                  parsingErrors.push('ids');
                  return null;
                }
                if (!row.find((el) => el && (el.includes('vod') || el.includes('bdc')))) {
                  if (!parsedHeaders) return null;
                  return {
                    startDate: parsedHeaders.indexOf('starttime') >= 0 ? vm.parseDate(row[parsedHeaders.indexOf('starttime')]) : undefined,
                    homeTeam: row[parsedHeaders.indexOf('participant1')],
                    visitingTeam: row[parsedHeaders.indexOf('participant2')],
                    sportType: vm.sportTypes.find((el) => !el.name.localeCompare(row[parsedHeaders.indexOf('activity')], { sensitivity: 'base' }))
                  }
                }
                let parsedEntry = {
                    streamID: row[parsedHeaders.indexOf('livestreamid')],
                    vodID: row[parsedHeaders.indexOf('vodid')],
                    scheduleModes: [],
                    selectedScheduleMode: null,
                    startDate: parsedHeaders.indexOf('starttime') >= 0 ? vm.parseDate(row[parsedHeaders.indexOf('starttime')]) : undefined,
                    homeTeam: row[parsedHeaders.indexOf('participant1')],
                    visitingTeam: row[parsedHeaders.indexOf('participant2')],
                    sportType: vm.sportTypes.find((el) => !el.name.localeCompare(row[parsedHeaders.indexOf('activity')], { sensitivity: 'base' }))
                }

                if (parsedEntry.streamID) parsedEntry.scheduleModes.push({name: 'stream', urlPlaceholder: parsedEntry.streamID});
                if (parsedEntry.vodID) parsedEntry.scheduleModes.push({name: 'vod', urlPlaceholder: parsedEntry.vodID});
                if (parsedEntry.scheduleModes) parsedEntry.selectedScheduleMode = parsedEntry.scheduleModes[0];
                return parsedEntry;
              })
              if (parsingErrors.includes('ids')) {
                logger.error("Stream and VOD ID columns missing");
                vm.xlsxLoading = false;
                return;
              }
              return Promise.all(parsedStreams);
            })
            .then((res) => {
              vm.parsedExcelEntries = res.filter((el) => el !== null);
              vm.xlsxLoading = false;
            })
        }, 500);
      } else {
        vm.xlsxLoading = false;
      }
    };

    vm.submitXlsxEvents = function() {
      vm.isBusy = true;
      let schedulerPromises = vm.parsedExcelEntries.filter((el) => el.selectedScheduleMode).map((event) => {
        if (vm.validateXlsxEntry(event)) {
          return;
        }
        var data = {
          mode: 'stream',
          scheduledAt: event.startDate,
          fullVideo: {
            ID: null,
            isStored: false,
            cutAfterSBEventMargin: event.sportType.defaultParameters.cutAfterSBEventMargin,
            cutBeforeSBEventMargin: event.sportType.defaultParameters.cutBeforeSBEventMargin,
            removeWhiteDigits: false,
            removeBlackDigits: false,
            detectGameStart: event.sportType.defaultParameters.detectGameStart,
            calcSBEvent: event.sportType.defaultParameters.calcSBEvent,
            startTime: 0,
            externalURL: null,
            streamURL: event.selectedScheduleMode.urlPlaceholder,
            streamTwitchChannelName: null,
            streamTrovoChannelName: null,
            sportRadarGameID: null,
            gameDate: event.startDate,
            gameScore: '0-0',
            homeTeam: event.homeTeam,
            visitingTeam: event.visitingTeam,
            sportType: event.sportType.name,
            noAudio: false,
            profile: null,
            league: {
              name: 'Other',
              sportType: event.sportType.name
            }
          }
        };

        teamsService.createIfNotExist(
          event.sportType.name,
          data.fullVideo.league.name,
          [event.homeTeam, event.visitingTeam]);

        return streamsSchedulerService.addSchedule(data).catch((err) => {
          vm.parsedExcelEntries[vm.parsedExcelEntries.findIndex((el) => el === event)].error = err.message;
          return undefined;
        })
      })

      return Promise.all(schedulerPromises)
        .then((res) => {
          vm.parsedExcelEntries.forEach((entry, entryIdx, entriesArr) => {
            let scheduleResponse = res.find((el) => el && entry.selectedScheduleMode && el.data.fullVideo.streamURL === entry.selectedScheduleMode.urlPlaceholder)
            if (scheduleResponse && scheduleResponse.success) {
              vm.parsedExcelEntries[entryIdx].success = true;
              return;
            }
            vm.parsedExcelEntries[entryIdx].success = false;
          })
          vm.isBusy = false;
          vm.xlsxSchedulingDone = true;
        })
    }

    vm.removeXlsxEntry = function(entry) {
      vm.parsedExcelEntries.splice(vm.parsedExcelEntries.findIndex((el) => el === entry), 1);
    }

    vm.validateXlsxEntry = function(entry) {
      if (!entry.selectedScheduleMode) {
        return "Could not get any valid URLs for this game"
      } else if (entry.startDate < Date.now()) {
        return "Game has a past start date and won't be scheduled"
      } else if (!entry.startDate) {
        return "Game date missing"
      } else if (!entry.sportType) {
        return "Sport type missing"
      } else if (!entry.homeTeam || !entry.visitingTeam) {
        return "Teams must be specified"
      }
      return null;
    }

    vm.globalXlsxSportTypeChanged = function() {
      vm.parsedExcelEntries.forEach((el, idx) => {
        vm.parsedExcelEntries[idx].sportType = vm.globalXlsxSelectedSportType;
      })
    }

    vm.setDateToToday = function() {
      vm.scheduleItem.scheduledAt = new Date();
    };

    vm.changeMinDate = function(todayOnly) {
      vm.todayOnly = todayOnly;
      var today = new Date();
      if (todayOnly) {
        vm.datePicker.options.minDate = today;
        vm.datePicker.options.maxDate = today;
        vm.scheduleItem.scheduledAt = today;
      } else if (vm.shouldCreateScheduleItem) {
        vm.datePicker.options.minDate = today;
        vm.datePicker.options.maxDate = null;
        if (vm.scheduleItem && new Date(vm.scheduleItem.scheduledAt).getTime() < Date.now()) {
          vm.scheduleItem.scheduledAt = today;
        }
      } else {
        vm.datePicker.options.minDate = null;
        vm.datePicker.options.maxDate = null;
      }
    };

    vm.checkExternalURL = function() {
      if (!vm.fullVideo.externalURL || vm.fullVideo.externalURL === '') return;
      return gameFieldsService.checkURL(vm.fullVideo.externalURL, false, 'video/mp4')
        .catch(function() {
          return vm.warnInvalidSource();
        })
        .then(function() {
          vm.isSourceValid = true;
          vm.openAccordions();
        })
        .catch(function() {
          vm.fullVideo.externalURL = null;
        });
    };

    vm.checkStreamURL = function() {
      if (!vm.fullVideo.streamURL || vm.fullVideo.streamURL === '') return;
      return gameFieldsService.checkHLSURL(vm.fullVideo.streamURL)
        .catch(function() {
          return vm.warnInvalidSource();
        })
        .then(function() {
          vm.openAccordions();
        })
        .catch(function() {
          vm.fullVideo.streamURL = null;
        });
    };

    vm.checkTwitchChannel = function() {
      if (!vm.fullVideo.streamTwitchChannelName || vm.fullVideo.streamTwitchChannelName === '') return;
      return gameFieldsService.checkTwitchChannel(vm.fullVideo.streamTwitchChannelName)
        .catch(function() {
          return vm.warnInvalidSource();
        })
        .then(function(result) {
          vm.openAccordions();
          vm.fullVideo.twitchIframeUrl = result.twitchlIframeUrl;
        })
        .catch(function() {
          vm.fullVideo.checkTwitchChannel = null;
        });
    };

    vm.checkTrovoChannel = function() {
      if (!vm.fullVideo.streamTrovoChannelName || vm.fullVideo.streamTrovoChannelName === '') return;
      return gameFieldsService.checkTrovoChannel(vm.fullVideo.streamTrovoChannelName)
        .catch(function() {
          return vm.warnInvalidSource();
        })
        .then(function(result) {
          vm.openAccordions();
          vm.fullVideo.trovoIframeUrl = result.trovoIframeUrl;
        })
        .catch(function() {
          vm.fullVideo.checkTrovoChannel = null;
        });
    };

    vm.warnInvalidSource = function() {
      return new Promise(function(resolve, reject) {
        if ($window.confirm('Please test and confirm your Stream URL then click OK to continue.')) {
          return resolve();
        } else {
          return reject();
        }
      });
    };

    vm.openAccordions = function() {
      vm.isSourceValid = true;
      vm.accordionGameDate = true;
      vm.accordionGameDetails = true;
    };

    vm.setSportType = function() {
      vm.fullVideo.sportType = vm.selectedSportType.name;
      vm.fullVideo.detectGameStart = vm.selectedSportType.defaultParameters.detectGameStart;
      vm.fullVideo.calcSBEvent = vm.selectedSportType.defaultParameters.calcSBEvent;
      vm.fullVideo.cutBeforeSBEventMargin = vm.selectedSportType.defaultParameters.cutBeforeSBEventMargin;
      vm.fullVideo.cutAfterSBEventMargin = vm.selectedSportType.defaultParameters.cutAfterSBEventMargin;

      vm.isManualClipping = (vm.selectedSportType.name === 'Manual Clipping');
      if (vm.isManualClipping) {
        vm.fullVideo.visitingTeam = 'Manual';
        vm.fullVideo.homeTeam = 'Clipping';
      }

      return gameFieldsService.getLeaguesBySportType(vm.selectedSportType.name)
        .then(function(res) {
          vm.leagues = res.data;
          if (vm.leagues.length > 0) {
            vm.selectedLeague = vm.leagues[0];
          } else {
            vm.selectedLeague = {
              name: 'Other',
              sportType: vm.selectedSportType.name
            };
          }
          return vm.setLeague();
        });
    };

    vm.setLeague = function() {
      return gameFieldsService.getTeamsByLeague(vm.selectedSportType.name, vm.selectedLeague.name)
        .then(function(res) {
          vm.fullVideo.league = vm.selectedLeague.name;
          vm.fullVideo.visitingTeam = null;
          vm.fullVideo.homeTeam = null;
          vm.gameTeams = res.data.map(function(item) {
            return item.teamName;
          });
          if (vm.isManualClipping) {
            vm.fullVideo.visitingTeam = 'Manual';
            vm.fullVideo.homeTeam = 'Clipping';
          }          
        });
    };

    $scope.$on('fileChunkUploaded', function(event, data) {
      vm.uploadProgressPercent = data.progress;
      $scope.$apply();
    });

    vm.uploadVideo = function() {
      var fileToUpload = vm.file;
      var presetID = UUID.genV1().toString();

      var userDir = coreUtilsService.replaceSpecialCharacters(authService.getUserData().email) + '/';
      var fileKey = userDir + 'fullVideos/' + presetID + '/video.mp4';
      vm.uploadInProgress = true;
      vm.uploadProgressPercent = 0;

      return s3UploadService.createMultipartUpload(fileToUpload, fileKey)
        .then(function() {
          vm.fullVideo.ID = presetID;
        })
        .catch(logger.error)
        .finally(function() {
          vm.uploadInProgress = false;
          vm.uploadProgressPercent = 0;
        });
    };

    vm.submit = function() {
      vm.isBusy = true;
      vm.cleanupSource();

      return Promise.resolve()
        .then(function() {
          if (vm.mode === 'upload') {
            return vm.uploadVideo();
          }
        })
        .then(function() {
          teamsService.createIfNotExist(
            vm.selectedSportType.name,
            vm.selectedLeague.name,
            [vm.fullVideo.homeTeam, vm.fullVideo.visitingTeam]);
        })
        .then(function () {
          if (vm.selectedSportType.name === 'User Logo Detection') {
            vm.fullVideo.logos = vm.selectedLogos;
          }
        })
        .then(function() {
          var scheduleItem = vm.scheduleItem;
          scheduleItem.scheduledAt.setHours(scheduleItem.scheduledAtTime.getHours());
          scheduleItem.scheduledAt.setMinutes(scheduleItem.scheduledAtTime.getMinutes());

          vm.fullVideo.gameDate = scheduleItem.scheduledAt;
          if (vm.mode === 'twitch') {
              vm.fullVideo.streamURL = 'https://www.twitch.tv/' + vm.fullVideo.streamTwitchChannelName;
              if (!vm.detectionServerRunning && vm.scheduleItem.scheduledAt.getTime() < Date.now() + (1000 * 60 * 5)) {
                vm.scheduleItem.scheduledAt.setTime(Date.now() + (1000 * 60 * 10));
                vm.shouldCreateScheduleItem = true;
                vm.canCreateScheduleItem = false;
              }
          }

          if (vm.mode === 'trovo') {
            vm.fullVideo.streamURL = 'https://trovo.live/' + vm.fullVideo.streamTrovoChannelName;
            if (!vm.detectionServerRunning && vm.scheduleItem.scheduledAt.getTime() < Date.now() + (1000 * 60 * 5)) {
              vm.scheduleItem.scheduledAt.setTime(Date.now() + (1000 * 60 * 10));
              vm.shouldCreateScheduleItem = true;
              vm.canCreateScheduleItem = false;
            }
          }

          if (vm.shouldCreateScheduleItem) {
            var data = {
              mode: 'stream',
              scheduledAt: scheduleItem.scheduledAt,
              fullVideo: angular.copy(vm.fullVideo)
            };

            return streamsSchedulerService.addSchedule(data);
          } else {
            return fullVideosService.createGameVideo(vm.fullVideo)
              .then(function(res) {
                // stream, twitch
                if (vm.canCreateScheduleItem) {
                  return remoteAnalyzerService.analyzeURL(res.data.ID).catch(logger.error);
                } else {
                  return fullVideosService.addVideoToAnalyzer(res.data.ID).catch(logger.error);
                }
              });
          }
        })
        .then(function() {
          vm.close();
        })
        .catch(function(e) {
          var error = e && e.error ? e.error : e;
          logger.error(error);
        })
        .finally(function() {
          vm.isBusy = false;
        });

    };

    vm.changeMode = function() {
      $timeout(function() {
        vm.cleanupSource();

        if (vm.mode === 'stream' || vm.mode === 'twitch' || vm.mode === 'trovo') {
          vm.shouldCreateScheduleItem = true;
          vm.canCreateScheduleItem = true;
        } else {
          vm.shouldCreateScheduleItem = false;
          vm.canCreateScheduleItem = false;
        }

        vm.changeMinDate();
      });
    };

    vm.cleanupSource = function() {
      $timeout(function() {
        switch (vm.mode) {
          case 'upload':
            vm.fullVideo.externalURL = null;
            vm.fullVideo.streamURL = null;
            vm.fullVideo.streamTwitchChannelName = null;
            vm.fullVideo.streamTrovoChannelName = null;
            vm.xlsxFile = null;
            vm.parsedExcelEntries = null;
            break;
          case 'xlsx':
            vm.fullVideo.externalURL = null;
            vm.fullVideo.streamURL = null;
            vm.fullVideo.streamTwitchChannelName = null;
            vm.fullVideo.streamTrovoChannelName = null;
            vm.file = null;
            vm.fileURL = null;
            break;
          case 'externalS3':
            vm.fullVideo.streamURL = null;
            vm.fullVideo.streamTwitchChannelName = null;
            vm.fullVideo.streamTrovoChannelName = null;
            vm.file = null;
            vm.fileURL = null;
            vm.xlsxFile = null;
            vm.parsedExcelEntries = null;
            break;
          case 'stream':
            vm.fullVideo.streamTwitchChannelName = null;
            vm.fullVideo.streamTrovoChannelName = null;
            vm.fullVideo.externalURL = null;
            vm.file = null;
            vm.fileURL = null;
            vm.xlsxFile = null;
            vm.parsedExcelEntries = null;
            break;
          case 'twitch':
            vm.fullVideo.streamTrovoChannelName = null;
            vm.fullVideo.streamURL = null;
            vm.fullVideo.externalURL = null;
            vm.file = null;
            vm.fileURL = null;
            vm.xlsxFile = null;
            vm.parsedExcelEntries = null;
            break;
          case 'trovo':
            vm.fullVideo.streamTwitchChannelName = null;
            vm.fullVideo.streamURL = null;
            vm.fullVideo.externalURL = null;
            vm.file = null;
            vm.fileURL = null;
            vm.xlsxFile = null;
            vm.parsedExcelEntries = null;
            break;
          default:
        }
      }, 100);
    };

    vm.getUser = function() {
      return authService.getUserFromDB(window.localStorage.currentUserEmail).then((res) => {
        vm.user = res.data;
      })
    }

    vm.$onInit = function() {
      return profileService.getUserLogos()
        .then((res) => {
          vm.logos = res.data;
        })
        .then(vm.getUser())
    };

    vm.$onChanges = function() {
      vm.reset();
      vm.mode = this.resolve.mode || 'stream';
      vm.fullVideo = this.resolve.fullVideo || vm.fullVideo;
      vm.changeMode();
    };

    vm.toggleIgnoreDigits = function(clickedIgnoreWhite) {
      if (clickedIgnoreWhite && vm.fullVideo.removeWhiteDigits) {
        vm.fullVideo.removeBlackDigits = false;
      } else if (!clickedIgnoreWhite && vm.fullVideo.removeBlackDigits) {
        vm.fullVideo.removeWhiteDigits = false;
      }
    }

    vm.copyToClipboard = function(copiedElementID) {
      document.getElementById(copiedElementID).select();
      document.execCommand('copy');
      vm.info = copiedElementID + " copied to clipboard";
    };

    vm.swapStreamMode = function(url) {
      if (!url) {
        return;
      }

      if (url.indexOf('twitch.tv') > -1) {
        vm.mode = 'twitch';
        vm.fullVideo.streamTwitchChannelName = url;
        vm.checkTwitchChannel();
      } else if (url.indexOf('trovo.live') > -1) {
        vm.mode = 'trovo';
        vm.fullVideo.streamTrovoChannelName = url;
        vm.checkTrovoChannel();
      } else if (url.indexOf('s3') > -1 && url.indexOf('.mp4') > -1) {
        vm.mode = 'externalS3';
        vm.fullVideo.externalURL = url;
        vm.checkExternalURL();
      } else if (url.indexOf('.m3u8') > -1) {
        vm.mode = 'stream';
        vm.fullVideo.streamURL = url;
        vm.checkStreamURL();
      } else {
        return;
      }

      vm.changeMode();
    };

    vm.getRTMPStream = function() {
      if (vm.accordionGetRTMPKey && !vm.lastRTMPAccordionState) {
        vm.key = "Waiting...";
        vm.server = "Waiting...";
        vm.link = "Waiting...";
        vm.info = "";
        vm.keyOK = false;
        
        remoteAnalyzerService.getRTMPCredentials(authService.getUserData().email)
        .then((response) => {
          if (response.status == 200) {
            vm.info = "Key becomes invalid after an hour of inactivity";
            vm.key = response.data.key;
            vm.server = response.data.server;
            vm.link = response.data.link;
            vm.keyOK = true;
          } else {
            vm.keyOK = false;
            Promise.reject("Invalid response (" + response.status + ")");
          }
        })
        .then(() => {
          if (vm.keyOK) {
            vm.fullVideo.streamURL = vm.link;
            vm.checkStreamURL();
          }
        })
        .catch((error) => {
          vm.info = "Something went wrong, please try again later";
          vm.key = "Not available";
          vm.server = "Not available";
          vm.link = "Not available";
          vm.keyOK = false;
        });

      }
      vm.lastRTMPAccordionState = vm.accordionGetRTMPKey;
    }
  }
})();
