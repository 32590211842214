(function() {

  angular.module('app.core')
    .directive('videogularPlayerWrapper', videogularPlayerWrapper);

  videogularPlayerWrapper.$inject = ['videogularConfigService', '$rootScope']

  function videogularPlayerWrapper(videogularConfigService, $rootScope) {
    return {
      restrict: 'E',
      templateUrl: 'app/directives/videogular-player-wrapper/videogular-player-wrapper.html',
      scope: {
        controller: '='
      },
      link: function(scope) {
        scope.onUpdateTime = function(currentTime) {
          scope.controller.currentTime = currentTime;
          if (scope.controller.clipStopTime && currentTime >= scope.controller.clipStopTime) {
            videogularConfigService.API.pause();
            scope.controller.clipStopTime = null;
            videogularConfigService.API.seekTime(scope.controller.playerCurrentTime, false);
          }
        };

        scope.onCanPlay = function() {
          scope.controller.videoSpinner = false;

          if ($rootScope.showSplash) {
            $rootScope.showSplash = false;
          }
        };

        scope.onPlayerReady = function(API) {
          videogularConfigService.API = API;
          scope.API = API;
          scope.controller.playerLoading = false;
        };
      },
    };
  }

})();

