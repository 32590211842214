(function() {
  'use strict';

  angular
    .module('app.layout')
    .controller('ShellController', ShellController);

  ShellController.$inject = ['$rootScope', '$state', '$scope', '$window', '$timeout', 'authService', 'CONF'];

  /* @ngInject */
  function ShellController($rootScope, $state, $scope, $window, $timeout, authService, CONF) {
    $rootScope.showSplash = true;
    var vm = this;
    vm.allowGrayBg = true;
    vm.hasResumed = false;
    activate();

    vm.shouldShouldHeader = function(){
      return ($window.location.pathname !== '/start');
    };

    function activate() {
      authService.resume().then(function() {
        $timeout(function(){
          vm.authorized = $window.localStorage['currentUserEmail'];
          vm.hasResumed = true;
        }, 500);
      }, function() {
        $window.localStorage['currentUserEmail'] = '';
        vm.authorized = false;
        vm.hasResumed = true;
      });

      if($state && $state.current && $state.current.name){
        changeBgBasedOnStateName($state.current.name);
      }

      $scope.$on('$stateChangeStart', function(e, toState) {
        vm.onStartPage = toState.name === 'start';
        return changeBgBasedOnStateName(toState.name);
      });

      // TODO: ugly code. rewrite auth using satellizer
      $scope.$on('logout', function() {
        vm.authorized = false;
      });

      $scope.$on('authService:signin', function() {
        vm.authorized = true;
      });
    }

    function changeBgBasedOnStateName(stateName){
      if (CONF.allowGrayBg.indexOf(stateName) !== -1) {
        vm.allowGrayBg = true;
      } else {
        vm.allowGrayBg = false;
      }
    }

    // function hideSplash() {
    //   //Force a 1 second delay so we can see the splash.
    //   $timeout(function() {
    //     $rootScope.showSplash = false;
    //   }, 1000);
    // }
  }
})();
