(function() {

  angular.module('app.core')
    .factory('debounce', ['$timeout', '$q', function($timeout, $q) {
      return function debounce(func, wait, immediate) {
        var timeout;
        var deferred = $q.defer();
        return function() {
          var context = this, args = arguments;
          var later = function() {
            timeout = null;
            if (!immediate) {
              func && deferred.resolve(func.apply(context, args));
              deferred = $q.defer();
            }
          };
          var callNow = immediate && !timeout;
          if (timeout) {
            $timeout.cancel(timeout);
          }
          timeout = $timeout(later, wait);
          if (callNow) {
            func && deferred.resolve(func.apply(context, args));
            deferred = $q.defer();
          }
          return deferred.promise;
        };
      };
    }]);

})();
