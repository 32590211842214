(function() {
  'use strict';

  angular
    .module('app.admin')
    .controller('AdminGamesController', AdminGamesController);

  AdminGamesController.$inject = ['$window', '$scope', '$uibModal', '$timeout', 'fullVideosService', 's3UploadService', 'logger', 'authService', 'clipsService', 'adminService', 'CONF'];

  function AdminGamesController($window, $scope, $uibModal, $timeout, fullVideosService, s3UploadService, logger, authService, clipsService, adminService, CONF) {
    var vm = this;

    vm.loading = false;
    vm.videos = [];
    vm.users = [];
    vm.datePicker = {
      format: CONF.datePickerFormat,
      altFormat: CONF.datePickerAltFormats,
      options: {
        dateDisabled: false,
        startingDay: 1,
      },
      hStep: 1,
      mStep: 1,
      isMeridian: true
    };
    vm.from = {};
    vm.from.date = new Date();
    vm.from.date.setDate(vm.from.date.getDate() - 1);
    vm.to = {};
    vm.to.date = new Date();
    vm.to.date.setDate(vm.to.date.getDate());

    if ($window.localStorage['currentUserAdmin'] !== 'true' && $window.localStorage['currentUserCompanyAdmin'] !== 'true') {
      return authService.logout();
    }

    init();

    function init() {
      vm.isAdministrator = $window.localStorage['currentUserAdmin'] === 'true';

      adminService.userList().then(function(response) {
        vm.users = response.data;
      }).catch(function(error) {
        logger.error(error.data ? error.data.message : 'error list users');
      }).finally(function() {
        vm.list();
      });
    }

    vm.list = function list() {
      if (vm.loading) {
        return;
      }
      vm.loading = true;
      var query = {}
      query.startDate = vm.from.date;
      query.endDate = vm.to.date;
      fullVideosService.getFullVideosByDates(query).then(function(response) {
        vm.videos = response.data.map(function(item) {
          var user = vm.users.find(function (u) {
            return u.email === item.userID;
          });
          if (user) {
            item.company = user.profile;
          }
          item.showClips = false;
          item.s3ConsoleURL = s3UploadService.getS3ConsoleURL(item.userID, item.ID);
          return item;
        });
      }).catch(function(error) {
        console.log(error);
        logger.error(error.data ? error.data.message : 'error list videos');
      }).finally(function() {
        vm.loading = false;
      });
    };

    vm.showVideoFiles = function(user) {
      $uibModal.open({
        component: 'showVideoFilesComponent',
        windowClass: 'admin-show-video-files',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          inUser: function() {
            return user;
          },
          inUsers: function() {
            return vm.users;
          }
        }
      });
    };

    vm.toggleClips = function(fullVideo) {
      fullVideo.showClips = !fullVideo.showClips;

      if (fullVideo.showClips && !fullVideo.clips) {
        vm.loadingClips = true;
        clipsService.getClipsForVideo(fullVideo.ID).then(function(res) {
          fullVideo.clips = res.data.map(function(item) {
            item.s3ConsoleURL = s3UploadService.getS3ConsoleURL(item.userID, item.fullVideoID, item.ID);
            return item;
          });
        }).catch(function () {})
        .finally(function () {
          vm.loadingClips = false;
        });
      }
    };

    vm.toggleTasks = function(fullVideo) {
      fullVideo.showTasks = !fullVideo.showTasks;

      if (fullVideo.showTasks && !fullVideo.tasks) {
        fullVideosService.getRelatedAnalyzerTasks(fullVideo.ID).then(function(res) {
          fullVideo.tasks = res.data.map(function(item) {
            item.s3ConsoleURL = s3UploadService.getS3ConsoleURL(item.userID, item.fullVideoID, null, item.ID);
            return item;
          });
        });
      }
    };

    vm.transferOwnership = function(fullVideo) {
      var modalInstance = $uibModal.open({
        component: 'transferOwnershipComponent',
        backdrop: 'static',
        windowClass: 'admin-transfer-ownership',
        keyboard: false,
        resolve: {
          inFullVideo: function() {
            return fullVideo;
          },
          inUsers: function() {
            return vm.users;
          }
        }
      });
      modalInstance.result.then(function() {
        vm.list();
      });
    };

    vm.dateFilter = function (video) {
      if (!vm.from.date && !vm.to.date) {
        return true;
      }

      if (vm.from.date && new Date(video.gameDate) < new Date(vm.from.date)) {
        return false;
      }

      if (vm.to.date && new Date(video.gameDate) > new Date(vm.to.date)) {
        return false;
      }

      return true;
    };
  }
})();
