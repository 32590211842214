(function() {
  'use strict';

  angular
    .module('app.support')
    .component('support', {
      templateUrl: 'app/support/support.component.html',
      controller: SupportController
    });

  SupportController.$inject = ['s3UploadService'];

  function SupportController(s3UploadService) {
    var $ctrl = this;

    $ctrl.$onInit = function() {
      s3UploadService.listObjectKeys('support-videos').then(function(data) {
        $ctrl.videos = data.map(function(element) {
          return {
            url: s3UploadService.getS3UrlForKey(element),
            name: extractName(element)
          }
        })
      })
    };

    $ctrl.onContactUs = function() {
      window.location.href = 'mailto:';
    };

    function extractName(str) {
      return str.substring(str.lastIndexOf('/') + 1, str.lastIndexOf('.')).replace('_', ' ');
    }
  }
})();
