(function() {
  'use strict';

  angular
    .module('app.core')
    .component('reelyImage', {
      templateUrl: 'app/directives/reely-image/reely-image.html',
      controller: imageController,
      bindings: {
        url: '<'
      }
    });

  imageController.$inject = ['$http'];

  function imageController($http) {
    var vm = this;
    
    vm.$onChanges = function() {
      vm.displayURL = vm.url ? vm.url : '../../images/image-not-available.png';
    };
  }
})();
