(function() {
  'use strict';

  angular
    .module('app.core')
    .factory('validateService', validateService);

  validateService.$inject = [];

  function validateService() {
    return {
      validate: validate
    };

    function validate() {

    }
  }

})();
