/**
 * Created by Sergey on 1/5/2017.
 */
(function() {

  angular.module('app.auth')
    .directive('compareTo',
      function() {
        return {
          require: 'ngModel',
          scope: {
            otherModelValue: '=compareTo'
          },
          link: function(scope, element, attributes, ngModel) {
            ngModel.$validators.compareTo = function(modelValue) {
              if (scope.otherModelValue) {
                return modelValue == scope.otherModelValue;
              }
            };
            scope.$watch('otherModelValue', function() {
              ngModel.$validate();
            });
          }
        };
      }
    );
})();
