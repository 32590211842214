(function() {
  'use strict';

  var SUCCESS_MESSAGE = ' - Success!';

  angular
    .module('app.layout')
    .component('appFooter', {
      templateUrl: 'app/navigation/app-footer.html',
      controller: FooterController,
    });

  FooterController.$inject = ['$rootScope', '$scope', '$timeout', '$state', 'SocketIOService', 'authService', '$uibModal', 'analyzerUtilsService', 'CUSTOM_CONF', 'coreUtilsService', 'logger', 'notificationPopupListService'];

  /* @ngInject */
  function FooterController($rootScope, $scope, $timeout, $state, SocketIOService, authService, $uibModal, analyzerUtilsService, CUSTOM_CONF, coreUtilsService, logger, notificationPopupListService) {
    var vm = this;
    var userName = authService.getUserName() || null;
    vm.techAccount = coreUtilsService.techAccount;
    vm.appVersion = CUSTOM_CONF.appVersion;
    vm.clipsCutProgress = 0;
    vm.clipsAnalyzeProgress = 0;
    vm.analyzerProgressValue = 0;
    vm.successCutMessage = '';
    vm.successAnalyzeMessage = '';
    vm.clipsRefreshIntervalToken = null;
    vm.analyzerStreamInterval = 2000;
    vm.currentStateAlias = null;
    vm.progressBarType = 'info';
    vm.detectionProgressLabel = 'Detection progress';

    vm.analyzerTooltip = '';
    vm.analyzerTooltipOpened = false;

    initializeSocketConnection(userName);

    function initializeSocketConnection(userName) {
      var channelName = userName || 'global';
      var socketChannel = SocketIOService.setDefaultChannel(channelName);

      setDataAnalysisEventHandler(socketChannel);
      setClipsCuttingEventHandler(socketChannel);
      setTrackingEventHandler(socketChannel);
      setClipEventHandler(socketChannel);
      setStreamInterruptedEventHandler(socketChannel);
      setLoadFileEventHandler(socketChannel);
      setVideoConversionEventHandler(socketChannel);
      setPackageCreationEventHandler(socketChannel);
      setUploadFullVideoEventHandler(socketChannel);
      setPreparingEventHandler(socketChannel);
      setStoppingEventHandler(socketChannel);
      setCopyExternalFileEventHandler(socketChannel);
      setMailSentEventHandler(socketChannel);
      setDownloadClipsEventHandler(socketChannel);
      setNotificationPopupListHandler(socketChannel);
      setTranscoderFailedNotificationHandler(socketChannel);
      setAnalyzerStart(socketChannel);
      setClipSavedEventHandler(socketChannel);
    }

    function setDataAnalysisEventHandler(socketChannel) {
      socketChannel.on('dataAnalysis', function(eventMessage) {
        $timeout(function() {
          var clipsAnalyzeProgress = parseProgressValue(eventMessage.progress);
          analyzerUtilsService.populateAnalyzersList(eventMessage, clipsAnalyzeProgress);
          var currentGameId = analyzerUtilsService.getCurrentGameId();
          if (!currentGameId || currentGameId !== eventMessage.videoID) {
            return;
          }
          vm.detectionProgressLabel = 'Detection progress';
          vm.clipsAnalyzeProgress = clipsAnalyzeProgress;
          vm.successAnalyzeMessage = '';
          // use it for stream, as analyzer sends progress as -1, so progress bar loops at 10%
          vm.analyzerProgressValue = vm.clipsAnalyzeProgress !== -1 ? vm.clipsAnalyzeProgress : 100;

          // TODO: always check if analyzer sends zero progress
          if (vm.clipsAnalyzeProgress > 0 && vm.progressBarType !== 'info') {
            vm.progressBarType = 'info';
          }
          if (vm.clipsAnalyzeProgress === 0) {
            // clearAnalyzerProgressBar();
            setAnalyzerProgressBar();
          } else if (vm.clipsAnalyzeProgress === -1) {
            setAnalyzerProgressBar();
          } else if (vm.clipsAnalyzeProgress >= 100) {
            analyzerUtilsService.removeFromAnalyzersList(eventMessage.videoID);
            if (eventMessage.detectionType !== 'stream') {
              setAnalyzerSuccessMessage();
            }
            if (eventMessage.isTaskTotallyFinished) {
              $rootScope.$broadcast('analyzeSuccess', {
                gameID: eventMessage.videoID
              });
              analyzerUtilsService.setProcessState('analyzePlayer', false, eventMessage.videoID);
              analyzerUtilsService.setProcessState('analyzeStream', false, eventMessage.videoID);
              clearAnalyzerIntervals();
              vm.detectionProgressLabel = 'Loading file';
            }
          }
        });
      });
    }

    function setClipsCuttingEventHandler(socketChannel) {
      socketChannel.on('clipsCutting', function(eventMessage) {
        $timeout(function() {
          var currentGameId = analyzerUtilsService.getCurrentGameId();
          if (currentGameId !== eventMessage.videoID) {
            vm.successCutMessage = '';
            vm.clipsCutProgress = 0;
            return;
          }
          analyzerUtilsService.setProcessState('cut', true);
          vm.successCutMessage = '';
          vm.clipsCutProgress = parseProgressValue(eventMessage.progress);
          if (vm.clipsCutProgress >= 100) {
            $rootScope.$broadcast('blockFinish', eventMessage.clipID);
            handleCLipCutSuccess(eventMessage.videoID, eventMessage.cutType);
          }
        });
      });
    }

    function setTrackingEventHandler(socketChannel) {
      socketChannel.on('tracking', function(eventMessage) {
        $timeout(function() {
          var currentGameId = analyzerUtilsService.getCurrentGameId();
          if (currentGameId === eventMessage.videoID) {
            $rootScope.$broadcast('setAnalyzerFrames', eventMessage);
          }
        });
      });
    }

    function setClipEventHandler(socketChannel) {
      socketChannel.on('clip', function(eventMessage) {
        if (eventMessage.error) {
          logger.error(eventMessage.error);
          $rootScope.$broadcast('clipDetected', eventMessage);
        } else {
          $timeout(function() {
            $rootScope.$broadcast('clipDetected', eventMessage);
          });
        }
      });
    }

    function setClipSavedEventHandler(socketChannel) {
      socketChannel.on('clipSaved', function(eventMessage) {
        $timeout(function() {
          $rootScope.$broadcast('blockFinish', eventMessage.ID);
        });
      });
    }

    function setStreamInterruptedEventHandler(socketChannel) {
      socketChannel.on('streamInterrupted', function(eventMessage) {
        $timeout(function() {
          $rootScope.$broadcast('streamInterrupted', eventMessage);
        });
      });
    }

    function setPreparingEventHandler(socketChannel) {
      socketChannel.on('preparingAnalyzer', function(eventMessage) {
        $timeout(function() {
          var currentGameId = analyzerUtilsService.getCurrentGameId();
          if (!currentGameId || currentGameId !== eventMessage.videoID) {
            return;
          }

          vm.detectionProgressLabel = (eventMessage.detectionType === 'stream') ? 'Fetching Stream Feed' : 'Preparing';
          vm.analyzerProgressValue = 100;
          vm.progressBarType = 'warning';
          $rootScope.$broadcast('preparingAnalyzer');
        });
      });
    }

    function setStoppingEventHandler(socketChannel) {
      socketChannel.on('stoppingAnalyzer', function(eventMessage) {
        $timeout(function() {
          var currentGameId = analyzerUtilsService.getCurrentGameId();
          if (!currentGameId || currentGameId !== eventMessage.videoID) {
            return;
          }
          vm.detectionProgressLabel = 'Stopping';
          vm.analyzerProgressValue = 100;
          vm.progressBarType = 'danger';
        });
      });

      $scope.$on('stoppingAnalyzer', function($event, fullVideoID) {
        var currentGameId = analyzerUtilsService.getCurrentGameId();
        if (!currentGameId || currentGameId !== fullVideoID) {
          return;
        }
        vm.detectionProgressLabel = 'Stopping';
        vm.analyzerProgressValue = 100;
        vm.progressBarType = 'danger';
      });
    }

    function setCopyExternalFileEventHandler(socketChannel) {
      socketChannel.on('copyExternalFile', function(eventMessage) {
        $timeout(function() {
          var currentGameId = analyzerUtilsService.getCurrentGameId();
          if (!currentGameId || currentGameId !== eventMessage.videoID) {
            return;
          }
          vm.detectionProgressLabel = 'Copying From External AWS';
          vm.analyzerProgressValue = parseProgressValue(eventMessage.progress);
          vm.progressBarType = 'info';

          var currentGame = analyzerUtilsService.getCurrentGame();
          if (eventMessage.progress >= 100) {
            currentGame.isCopyingFromExternal = false;
            vm.analyzerProgressValue = -1;
            vm.detectionProgressLabel = 'Copied From External AWS';
          } else {
            currentGame.isCopyingFromExternal = true;
          }
          analyzerUtilsService.setCurrentGame(currentGame);
        });
      });
    }

    function setLoadFileEventHandler(socketChannel) {
      socketChannel.on('loadFileProgress', function(eventMessage) {
        $timeout(function() {
          var currentGameId = analyzerUtilsService.getCurrentGameId();
          if (!currentGameId || currentGameId !== eventMessage.videoID) {
            return;
          }
          var analyzerProgressValue = parseProgressValue(eventMessage.progress);
          if (analyzerProgressValue >= 100) {
            setAnalyzerSuccessMessage();
          }
        });
      });
    }

    function setUploadFullVideoEventHandler(socketChannel) {
      socketChannel.on('uploadFullVideoProgress', function(eventMessage) {
        $timeout(function() {
          var currentGameId = analyzerUtilsService.getCurrentGameId();
          if (!currentGameId || currentGameId !== eventMessage.videoID) {
            return;
          }
          vm.progressBarType = 'success';
          vm.detectionProgressLabel = 'Uploading Video File';
          vm.analyzerProgressValue = parseProgressValue(eventMessage.progress);
          if (vm.analyzerProgressValue >= 100) {
            setAnalyzerSuccessMessage();
          }
        });
      });
    }

    function setVideoConversionEventHandler(socketChannel) {
      socketChannel.on('videoConversion', function(eventMessage) {
        $timeout(function() {
          var currentGameId = analyzerUtilsService.getCurrentGameId();
          if (!currentGameId || currentGameId !== eventMessage.videoID) {
            return;
          }
          vm.progressBarType = 'info';
          vm.detectionProgressLabel = 'Converting video';
          vm.analyzerProgressValue = parseProgressValue(eventMessage.progress);
          if (vm.analyzerProgressValue === -1) {
            vm.analyzerProgressValue = 100;
            return;
          }
          if (vm.analyzerProgressValue >= 100) {
            setAnalyzerSuccessMessage();
          }
        });
      });
    }

    function setPackageCreationEventHandler(socketChannel) {
      socketChannel.on('packageCreation', function(eventMessage) {
        console.log(eventMessage);
        $uibModal.open({
          component: 'infoModal',
          backdrop: 'static',
          keyboard: false,
          resolve: {
            srcLink: function() {
              return eventMessage.packageURL;
            },
          }
        });
        $rootScope.$broadcast('packageCreation', eventMessage);
      });
    }

    function setMailSentEventHandler(socketChannel) {
      socketChannel.on('userMailSent', function() {
        $timeout(function() {
          logger.success('Email was successfully sent');
        });
      });
    }

    function setDownloadClipsEventHandler(socketChannel) {
      socketChannel.on('downloadClips', function(eventMessage) {
        var currentGameId = analyzerUtilsService.getCurrentGameId();
        if (!currentGameId || currentGameId !== eventMessage.videoID) {
          return;
        }
        if (vm.progressBarType !== 'info') {
          vm.progressBarType = 'info';
        }

        $timeout(function() {
          vm.analyzerProgressValue = parseProgressValue(eventMessage.progress);

          if (vm.analyzerProgressValue >= 100) {
            setAnalyzerSuccessMessage();
            $rootScope.$broadcast('downloadClipsProcessingDone', eventMessage);
          }
        });
      });
    }

    function setNotificationPopupListHandler(socketChannel) {
      socketChannel.on('analyzerServerError', function(eventMessage) {
        $timeout(function() {
            notificationPopupListService.addItem(eventMessage.message);
        });
      });
    }

    function setTranscoderFailedNotificationHandler(socketChannel) {
      socketChannel.on('transcoderFailed', function(eventMessage) {
        $timeout(function() {
            notificationPopupListService.addItem(eventMessage.message);
        });
      });
    }

    function setAnalyzerStart(socketChannel) {
      socketChannel.on('In progress', function(eventMessage) {
        $rootScope.$broadcast('analyzerStart', eventMessage);
      });
    }
    
    $scope.$on('$stateChangeStart', function() {
      analyzerUtilsService.setCurrentGame(null);
      clearAnalyzerIntervals();
    });

    $scope.$on('$stateChangeSuccess', function(event, toState) {
      vm.analyzerProgressValue = 0;
      vm.clipsCutProgress = 0;
      vm.currentStateAlias = toState.name === 'remote-analyzer' ? 'stream' : 'clip';
      vm.successCutMessage = '';
    }); 

    $scope.$on('stopAnalyzer', function() {
      clearAnalyzerIntervals();
    });

    $scope.$on('gameChanged', function(event, gameChangedMessage) {
      vm.detectionProgressLabel=gameChangedMessage;
      vm.progressBarType = 'warning';
    });

    $scope.$on('analyzerWarmup', function(event, analyzerWarmupMessage) {
      vm.detectionProgressLabel=analyzerWarmupMessage;
      vm.analyzerProgressValue = 100;
      vm.progressBarType = 'warning';
    });

    $scope.$on('detectionStarted', function() {
      vm.detectionProgressLabel="Pending";
      vm.progressBarType = 'warning';
    });

    function clearAnalyzerIntervals() {
      clearAnalyzerProgressBar();
      vm.clipsAnalyzeProgress = 0;
    }

    function setAnalyzerProgressBar() {
      vm.analyzerProgressValue = 100;
      vm.progressBarType = 'warning';
    }

    function clearAnalyzerProgressBar() {
      vm.analyzerProgressValue = 0;
      vm.progressBarType = 'info';
      vm.detectionProgressLabel = 'Detection progress';
      setAnalyzerSuccessMessage(true);
    }

    function setAnalyzerSuccessMessage(clear) {
      vm.successAnalyzeMessage = clear ? '' : SUCCESS_MESSAGE;
    }

    function handleCLipCutSuccess(gameID, cutType) {
      analyzerUtilsService.setProcessState('cut', false);
      vm.successCutMessage = SUCCESS_MESSAGE;
      $rootScope.$broadcast('cutSuccess', gameID, cutType);
      $timeout(function() {
        vm.clipsCutProgress = 0;
      }, 500);
    }

    vm.whatIsNew = function() {
      $uibModal.open({
        templateUrl: 'app/navigation/what-is-new.html',
        backdrop: 'static',
        size: 'sm',
        controller: function($scope, $uibModalInstance) {
          $scope.updates = CUSTOM_CONF.whatIsNew;
          $scope.version = CUSTOM_CONF.appVersion;
          $scope.close = function() {
            $uibModalInstance.dismiss('close');
          };
        }
      });
    };
  }
})();

function parseProgressValue(inProgressValue) {
  inProgressValue = (!isNaN(parseFloat(inProgressValue)) && isFinite(inProgressValue)) ? inProgressValue : 0;
  return parseFloat(inProgressValue.toFixed(1));
}
