(function() {
  'use strict';

  angular
    .module('app.admin')
    .run(appRun);

  appRun.$inject = ['routerHelper', 'authService', '$q'];

  /* @ngInject */
  function appRun(routerHelper, authService, $q) {
    routerHelper.configureStates(getStates(authService, $q));
  }

  function appAdminStateResolve(authService, $q) {
    return {
      authData: ['authService', function(authService) {
        var d = $q.defer();
        authService.isAppAdministrator().then(function() {
          d.resolve(authService.user);
        }, function() {
          authService.logout();
          // need pass to q reject exception http://stackoverflow.com/questions/23324942/angularjs-promises-rethrow-caught-exceptions
          return d.reject(new Error('AUTH_REQUIRED'));
        });
        return d.promise;
      }]
    };
  }
  function anyAdminStateResolve(authService, $q) {
    return {
      authData: ['authService', function(authService) {
        var d = $q.defer();
        authService.isAdministrator(true, true).then(function() {
          d.resolve(authService.user);
        }, function() {
          authService.logout();
          // need pass to q reject exception http://stackoverflow.com/questions/23324942/angularjs-promises-rethrow-caught-exceptions
          return d.reject(new Error('AUTH_REQUIRED'));
        });
        return d.promise;
      }]
    };
  }

  function getStates(authService, $q) {
    return [{
      state: 'admin',
      config: {
        url: '/admin',
        templateUrl: 'app/admin/admin.html',
        controller: 'AdminController',
        controllerAs: '$ctrl',
        title: 'Admin',
        resolve: anyAdminStateResolve(authService, $q)
      }
    }, {
      state: 'admin.system',
      config: {
        url: '/system',
        templateUrl: 'app/admin/admin-system.html',
        controller: 'AdminSystemController',
        controllerAs: '$ctrl',
        title: 'Admin | System',
        resolve: appAdminStateResolve(authService, $q)
      }
    }, {
      state: 'admin.users',
      config: {
        url: '/users',
        templateUrl: 'app/admin/admin-users.html',
        controller: 'AdminUsersController',
        controllerAs: '$ctrl',
        title: 'Admin | Users',
        resolve: anyAdminStateResolve(authService, $q)
      }
    }, {
      state: 'admin.tasks',
      config: {
        url: '/tasks',
        templateUrl: 'app/analyzerTask/analyzerTask.html',
        controller: 'AnalyzerTaskController',
        controllerAs: '$ctrl',
        title: 'Admin | Tasks',
        resolve: appAdminStateResolve(authService, $q)
      }
    }, {
      state: 'admin.lists',
      config: {
        url: '/lists',
        templateUrl: 'app/admin/admin-lists.html',
        controller: 'AdminListsController',
        controllerAs: '$ctrl',
        title: 'Admin | Lists',
        resolve: appAdminStateResolve(authService, $q)
      }
    }, {
      state: 'admin.resources',
      config: {
        url: '/resources',
        templateUrl: 'app/admin/admin-resources.html',
        controller: 'AdminResourcesController',
        controllerAs: '$ctrl',
        title: 'Admin | Resources',
        resolve: appAdminStateResolve(authService, $q)
      }
    }, {
      state: 'admin-create-user',
      config: {
        url: '/admin/create-user',
        templateUrl: 'app/admin/create-user.html',
        controller: 'AdminCreateUserController',
        controllerAs: '$ctrl',
        title: 'Admin - Create user',
        resolve: appAdminStateResolve(authService, $q)
      }
    }, {
      state: 'admin.games',
      config: {
        url: '/games',
        templateUrl: 'app/admin/admin-games.html',
        controller: 'AdminGamesController',
        controllerAs: '$ctrl',
        title: 'Admin | Games',
        resolve: anyAdminStateResolve(authService, $q)
      }
    }];
  }

})();
