(function() {

  angular.module('app.core')
    .component('messageBlock', {
      templateUrl: 'app/messages-block/messages-block.component.html',
      controller: messageBlockController,
      bindings: {
        messages: '<'
      }
    });

  function messageBlockController() {
    var vm = this;

    this.$onInit = function() {
      vm.currentMessages = vm.messages;
    };

    this.$onUpdate = function() {
      console.log(vm.currentMessages);
    };
  }

})();
