(function() {
  'use strict';

  var STORAGE_KEY = 'rr-ui-notifications';

  angular.module('app.core')
    .service('notificationPopupListService', NotificationPopupListService);

  NotificationPopupListService.$inject = ['$q'];

  function NotificationPopupListService($q) {
    var self = this;
    var listeners = [];

    var storage = window.sessionStorage;

    self.getUnreadCount = function() {
      var notifications = load();
      var unreadNotifications = notifications.filter(function (item) {
        return !item.read;
      });
      return $q.resolve(unreadNotifications.length);
    };

    self.getItems = function() {
      var notifications = load();
      return $q.resolve(notifications);
    };

    self.addItem = function(message, icon) {
      var notifications = load();
      notifications.unshift({
        icon: icon || 'info-circle',
        message: message,
        date: new Date()
      });
      if (notifications.length > 15) {
        notifications.length = 15;
      }
      self.save(notifications);
    };

    self.subscribe = function(listener) {
      listeners.push(listener);
      return function() {
        listeners.splice(listeners.indexOf(listener), 1);
      }
    };

    self.save = function(notifications) {
      storage.setItem(STORAGE_KEY, JSON.stringify(notifications));

      angular.forEach(listeners, function(listener) {
        listener(notifications);
      });
    };

    function load() {
      try {
        return JSON.parse(storage.getItem(STORAGE_KEY)) || [];
      } catch (e) {
        return [];
      }
    }
  }

})();
