(function() {

  angular.module('app.core')
    .directive('dndUpload', dndUpload);

  dndUpload.$inject = ['logger'];

  function dndUpload(logger) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        loading: '=',
        ngModel: '=',
        callback: '&'
      },
      require: '?ngModel',
      template: '<div>Drop preroll file here<span ng-if="loading" class="m-l-sm fa fa-spinner fa-spin"></span></div>',
      link: function(scope, element) {
        element.on('dragover', function(e) {
          e.preventDefault();
          e.stopPropagation();
        });
        element.on('dragenter', function(e) {
          e.preventDefault();
          e.stopPropagation();
        });
        element.on('drop', function(e) {
          e.preventDefault();
          e.stopPropagation();
          if (e.originalEvent.dataTransfer){
            if (e.originalEvent.dataTransfer.files.length > 0) {
              upload(e.originalEvent.dataTransfer.files);
            }
          }
          return false;
        });

        var upload = function(files) {
          scope.callback({
            file: files[0]
          });
        };
      }
    };
  }

})();
