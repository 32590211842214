(function() {
  'use strict';

  angular
    .module('app.streams-scheduler')
    .component('addScheduleComponent', {
      templateUrl: 'app/streams-scheduler/add-schedule/add-schedule.html',
      controller: addScheduleController,
      controllerAs: '$ctrl',
      bindings: {
        dismiss: '&',
        close: '&',
        resolve: '<'
      },
    });

  addScheduleController.$inject = [
    '$window',
    '$timeout',
    '$scope',
    'CONF',
    'gameFieldsService',
    'logger',
    'streamsSchedulerService',
    'teamsService',
    'profileService',
    'authService'
  ];

  function addScheduleController(
    $window,
    $timeout,
    $scope,
    CONF,
    gameFieldsService,
    logger,
    streamsSchedulerService,
    teamsService,
    profileService,
    authService
  ) {
    var vm = this;
    vm.addScheduleItems = addScheduleItems;
    vm.groupSportTypeList = gameFieldsService.groupSportTypeList;
    vm.addOneMoreItem = addOneMoreItem;
    vm.removeScheduleItem = removeScheduleItem;
    vm.logos = [];

    vm.datePicker = {
      format: CONF.datePickerFormat,
      altFormat: CONF.datePickerAltFormats,
      options: {
        dateDisabled: false,
        minDate: new Date(),
        startingDay: 1,
      },
      hStep: 1,
      mStep: 1,
      isMeridian: true
    };

    $scope.showCSVResult = function(element) {
      var files = element.files;
      if (files.length) {
        var r = new FileReader();
        r.onload = function(e) {
          var data = e.target.result;
          var content = {
            csv: data.replace(/\r\n|\r/g, '\n'),
            header: true,
            separator: ','
          }

          var result = csvToJSON(content);

          if ($window.confirm('Do you want to clear the unsaved schedules before importing the new data?')) {
            vm.scheduleItems = [];
          }

          result.forEach(function(item) {
            var gameDate = item.gameDate || item.timestamp || item.TimeStamp || item.Timestamp;
            var homeTeam = item.homeTeam || item.HomeTeam || item.hometeam;
            var visitingTeam = item.visitingTeam || item.visitingteam || item.VisitingTeam;
            var streamURL = item.streamURL || item.url || item.URL;
            var sportType = item.sportType || item.SportType;
            var sportRadarGameID = item.sportRadarGameID || item.sportradarGameID || item.sportradarID;
            var cutAfterSBEventMargin = item.offsetAfter || item.cutAfterSBEventMargin;
            var cutBeforeSBEventMargin = item.offsetBefore || item.cutBeforeSBEventMargin;
            if (!gameDate || !homeTeam || !visitingTeam || !streamURL || !sportType) {
              return;
            }
            var scheduleItem = getDefaultScheduleItem(gameDate);

            Object.assign(scheduleItem.fullVideo, {
              streamURL: streamURL,
              sportType: vm.sportTypes.find(function(sportTypeItem) {
                return sportTypeItem.name === sportType;
              }),
            });

            return scheduleItem.setSportType()
              .then(function() {
                scheduleItem.setLeagueByHomeTeam();
                vm.scheduleItems.push(scheduleItem);

                $timeout(function() {
                  scheduleItem.fullVideo.homeTeam = homeTeam;
                  scheduleItem.fullVideo.visitingTeam = visitingTeam;
                  scheduleItem.fullVideo.sportRadarGameID = sportRadarGameID;
                  scheduleItem.fullVideo.cutAfterSBEventMargin = parseFloat(cutAfterSBEventMargin || scheduleItem.cutAfterSBEventMargin);
                  scheduleItem.fullVideo.cutBeforeSBEventMargin = parseFloat(cutBeforeSBEventMargin || scheduleItem.cutBeforeSBEventMargin);
                });
              })
              .catch(logger.warn)
          });
        };
        r.readAsText(files[0]);
      }
    }

    vm.$onInit = function() {
      var inEditingScheduleItem = vm.resolve.scheduleItem;
      vm.isEditing = (inEditingScheduleItem) ? true : false;
      vm.loading = false;
      vm.initLoading = true;
      vm.scheduleItems = [];

      return Promise
        .all([
          gameFieldsService.getSportTypes().then(function(res) {
            vm.sportTypes = res.data;
          }),
          teamsService.getTeams().then(function(res) {
            vm.allTeams = res.data;
          }),
          vm.getResources(),
          vm.getUser()
        ])
        .then(function() {
          if (inEditingScheduleItem) {
            var defaultScheduleItem = getDefaultScheduleItem(inEditingScheduleItem.scheduledAt);
            var scheduleItem = Object.assign(defaultScheduleItem, inEditingScheduleItem);

            var fullVideo = scheduleItem.fullVideo;
            scheduleItem.fullVideo.sportType = vm.sportTypes.find(function(item) {
              return item.name === scheduleItem.fullVideo.sportType;
            });

            if (fullVideo.streamTwitchChannelName) {
              scheduleItem.streamMode = 'twitch';
            }

            if (fullVideo.streamTrovoChannelName) {
              scheduleItem.streamMode = 'trovo';
            }

            scheduleItem.loading = true;
            scheduleItem.editing = true;

            if (scheduleItem.fullVideo.streamURL === 'No URL') scheduleItem.fullVideo.streamURL = '';

            scheduleItem.scheduledAt = new Date(scheduleItem.scheduledAt);
            scheduleItem.setLeagueByHomeTeam();

            return scheduleItem.setSportType()
              .then(function() {
                vm.scheduleItems.push(scheduleItem);
              })
              .catch(logger.warn)
              .finally(function() {
                scheduleItem.loading = false;
              });
          } else {
            return vm.addOneMoreItem();
          }
        })
        .catch(logger.warn)
        .finally(function() {
          $timeout(function() {
            vm.initLoading = false;
          });
        });
    };

    vm.getResources = function () {
      return profileService.getUserLogos()
        .then((res) => {
          return vm.logos = res.data;
        })
    }

    vm.getUser = function() {
      return authService.getUserFromDB(window.localStorage.currentUserEmail).then((res) => {
        vm.user = res.data;
      })
    }

    vm.isNASIdPresent = function(url) {
      return url ? !!url.match('^(bdc|vod)([a-z0-9]){10}$') : false;
    }

    vm.swapStreamMode = function(url, currentMode) {
      if (!url) {
        return currentMode;
      }

      if (url.indexOf('twitch.tv') > -1) {
        return 'twitch';
      } else if (url.indexOf('trovo.live') > -1) {
        return 'trovo';
      } else {
        return currentMode;
      }
    };

    function getDefaultScheduleItem(inGameDate) {
      var date = new Date(inGameDate);
      const scheduleItem = {
        _id: (Date.now() * Math.random()).toFixed(0),
        editing: false,
        loading: false,
        streamMode: 'url',
        gameDatePopupOpened: false,
        leagues: null,
        gameTeams: null,
        gameFieldsErrors: null,

        ID: null,
        fullVideoID: null,
        scheduledAt: date,
        scheduledAtTime: date,
        selectedLogo: null,
        fullVideo: {
          ID: null,
          streamURL: null,
          streamTwitchChannelName: null,
          streamTrovoChannelName: null,
          visitingTeam: null,
          homeTeam: null,
          gameDate: date,
          gameScore: null,
          sportType: null,
          league: null,
          cutBeforeSBEventMargin: 0,
          cutAfterSBEventMargin: 0,
          removeBlackDigits: false,
          removeWhiteDigits: false,
          detectGameStart: false,
          calcSBEvent: false,
          isStored: false,
          noAudio: false,
          sportRadarGameID: null,
          logoID: null
        },
      };

      scheduleItem.toggleIgnoreDigits = function(clickedIgnoreWhite) {
        if (clickedIgnoreWhite && this.fullVideo.removeWhiteDigits) {
          this.fullVideo.removeBlackDigits = false;
        } else if (!clickedIgnoreWhite && this.fullVideo.removeBlackDigits) {
          this.fullVideo.removeWhiteDigits = false;
        }
      }

      scheduleItem.setSportType = function() {
        return gameFieldsService.getLeaguesBySportType(scheduleItem.fullVideo.sportType.name)
          .then(function(res) {
            scheduleItem.leagues = (res.data.length > 0) ? res.data : [{
              sportType: scheduleItem.fullVideo.sportType.name,
              name: 'Other'
            }];

            if (!scheduleItem.editing) {
              scheduleItem.fullVideo.league = scheduleItem.leagues[0];
            }

            return scheduleItem.setLeague();
          })
          .catch(logger.warn);
      };

      scheduleItem.setLeague = function() {
        if (!scheduleItem.editing) {
          var defaultParameters = scheduleItem.fullVideo.sportType.defaultParameters;
          scheduleItem.fullVideo.detectGameStart = defaultParameters.detectGameStart;
          scheduleItem.fullVideo.calcSBEvent = defaultParameters.calcSBEvent;
          scheduleItem.fullVideo.cutBeforeSBEventMargin = defaultParameters.cutBeforeSBEventMargin;
          scheduleItem.fullVideo.cutAfterSBEventMargin = defaultParameters.cutAfterSBEventMargin;
        }

        return gameFieldsService.getTeamsByLeague(scheduleItem.fullVideo.sportType.name, scheduleItem.fullVideo.league.name)
          .then(function(res) {
            scheduleItem.gameTeams = res.data.map(function(item) {
              return item.teamName;
            });
            if (!scheduleItem.editing) {
              scheduleItem.fullVideo.homeTeam = null;
              scheduleItem.fullVideo.visitingTeam = null;
            }
            return Promise.resolve();
          })
          .catch(logger.warn);
      };

      scheduleItem.validate = function() {
        var errors = gameFieldsService.validateGameFields(scheduleItem.fullVideo.cutBeforeSBEventMargin, scheduleItem.fullVideo.cutAfterSBEventMargin);

        if (!scheduleItem.scheduledAt) {
          errors.push('Game date is not set');
        }
        var now = new Date();
        if (scheduleItem.scheduledAt.getFullYear() < now.getFullYear() ||
          (scheduleItem.scheduledAt.getFullYear() === now.getFullYear() && scheduleItem.scheduledAt.getMonth() < now.getMonth()) ||
          (scheduleItem.scheduledAt.getFullYear() === now.getFullYear() && scheduleItem.scheduledAt.getMonth() === now.getMonth() && scheduleItem.scheduledAt.getDate() < now.getDate())) {
          errors.push('Game date cannot be less than current date');
        }
        if (scheduleItem.scheduledAt.getFullYear() === now.getFullYear() &&
          scheduleItem.scheduledAt.getMonth() === now.getMonth() &&
          scheduleItem.scheduledAt.getDate() === now.getDate() &&
          scheduleItem.scheduledAtTime.getTime() - now.getTime() < 0) {

          errors.push('Game time cannot be less than current time');
        }
        if (!scheduleItem.fullVideo.visitingTeam || !scheduleItem.fullVideo.homeTeam) {
          errors.push('Fill the teams fields');
        }
        if (!scheduleItem.fullVideo.streamURL) {
          errors.push('First load the video');
        }
        if (!scheduleItem.fullVideo.sportType) {
          errors.push('Select the sportType');
        }
        if (scheduleItem.fullVideo.sportType.analyzerKey === 'user_logo' && !scheduleItem.selectedLogo) {
          errors.push('Select logo package');
        }
        scheduleItem.gameFieldsErrors = errors;

        // digest
        $timeout(function() {
          scheduleItem.gameFieldsErrors = errors;
        });

        if (scheduleItem.gameFieldsErrors.length > 0) {
          return false;
        } else {
          return true;
        }
      }

      scheduleItem.setLeagueByHomeTeam = function() {
        var homeTeamData = vm.allTeams.find(function(item) {
          return item.teamName === scheduleItem.fullVideo.homeTeam;
        });
        if (homeTeamData) {
          scheduleItem.fullVideo.league = {
            name: homeTeamData.league,
            sportType: homeTeamData.sportType
          };
        }
      }

      scheduleItem.save = function() {
        if (!scheduleItem.validate()) {
          return Promise.reject('validation failed.');
        }

        var visitingTeamName = scheduleItem.fullVideo.visitingTeam;
        var homeTeamName = scheduleItem.fullVideo.homeTeam;

        scheduleItem.scheduledAt.setHours(scheduleItem.scheduledAtTime.getHours());
        scheduleItem.scheduledAt.setMinutes(scheduleItem.scheduledAtTime.getMinutes());

        scheduleItem.fullVideo.logoID = scheduleItem.selectedLogo ? scheduleItem.selectedLogo.ID : null;

        var data = {
          mode: 'stream',
          ID: scheduleItem.ID,
          fullVideoID: scheduleItem.ID,
          scheduledAt: scheduleItem.scheduledAt,
          fullVideo: angular.copy(scheduleItem.fullVideo)
        };
        if (scheduleItem.streamMode === 'twitch') {
          data.fullVideo.streamTwitchChannelName = scheduleItem.fullVideo.streamURL;
          data.fullVideo.streamTrovoChannelName = null;
          data.fullVideo.streamURL = scheduleItem.fullVideo.streamURL.includes('twitch.tv') ? 
                                      scheduleItem.fullVideo.streamURL : 'https://www.twitch.tv/' + scheduleItem.fullVideo.streamURL
        } else if (scheduleItem.streamMode === 'trovo') {
          data.fullVideo.streamTrovoChannelName = scheduleItem.fullVideo.streamURL;
          data.fullVideo.streamTwitchChannelName = null;
          data.fullVideo.streamURL = scheduleItem.fullVideo.streamURL.includes('trovo.live') ? 
                                      scheduleItem.fullVideo.streamURL : 'https://trovo.live/' + scheduleItem.fullVideo.streamURL 
        } else {
          data.fullVideo.streamTwitchChannelName = null;
          data.fullVideo.streamTrovoChannelName = null;
        }
        Object.assign(data.fullVideo, {
          gameDate: scheduleItem.scheduledAt,
          visitingTeam: visitingTeamName,
          homeTeam: homeTeamName,
          sportType: scheduleItem.fullVideo.sportType.name
        });

        teamsService.createIfNotExist(
          scheduleItem.fullVideo.sportType.name,
          scheduleItem.fullVideo.league.name,
          [homeTeamName, visitingTeamName]);

        scheduleItem.loading = true;

        var save = (!scheduleItem.editing) ?
          streamsSchedulerService.addSchedule :
          streamsSchedulerService.updateSchedule;

        return save(data)
          .catch(function(err) {
            scheduleItem.gameFieldsErrors.push(err.message);
            return Promise.reject(err);
          })
          .finally(function() {
            scheduleItem.loading = false;
          });
      }

      return scheduleItem;
    }

    function addScheduleItems() {
      vm.loading = true;
      var toRemoveItems = [];
      return vm.scheduleItems
        .reduce(function(promiseChain, scheduleItem) {
          return promiseChain.then(function() {
            return scheduleItem.save().then(function() {
              toRemoveItems.push(scheduleItem);
            }).catch(function() {});
          })
        }, Promise.resolve())
        .then(function() {
          $timeout(function() {
            toRemoveItems.forEach(function(scheduleItem) {
              vm.removeScheduleItem(scheduleItem);
            });

            if (vm.scheduleItems.length === 0) {
              vm.close();
            }
          }, 500);
        })
        .catch(logger.warn)
        .finally(function() {
          vm.loading = false;
        });
    }

    function removeScheduleItem(scheduleItem) {
      if (!vm.scheduleItems || vm.scheduleItems.length === 0 || !scheduleItem) {
        return;
      }
      var index = vm.scheduleItems.findIndex(function(item) {
        return item._id === scheduleItem._id;
      });
      if (index !== -1) {
        vm.scheduleItems.splice(index, 1);
      }
    }

    function addOneMoreItem() {
      return new Promise(function(resolve) {
        $timeout(function() {
          vm.scheduleItems.push(getDefaultScheduleItem(Date.now() + 5 * 60 * 1000));
          return resolve();
        }, 100);
      });
    }
  }
})();

function csvToJSON(content) {
  var lines = content.csv.split(new RegExp('\n(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)'));
  var result = [];
  var start = 0;
  var columnCount = lines[0].split(content.separator).length;

  var headers = [];
  if (content.header) {
    headers = lines[0].split(content.separator);
    start = 1;
  }

  for (var i = start; i < lines.length; i++) {
    var obj = {};
    var currentline = lines[i].split(new RegExp(content.separator + '(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)'));
    if (currentline.length === columnCount) {
      if (content.header) {
        for (var j = 0; j < headers.length; j++) {
          obj[headers[j]] = cleanCsvValue(currentline[j]);
        }
      } else {
        for (var k = 0; k < currentline.length; k++) {
          obj[k] = cleanCsvValue(currentline[k]);
        }
      }
      result.push(obj);
    }
  }
  return result;
};

function cleanCsvValue(value) {
  return value
    .replace(/^\s*|\s*$/g, "") // remove leading & trailing space
    .replace(/^"|"$/g, "") // remove " on the beginning and end
    .replace(/""/g, '"'); // replace "" with "
};
