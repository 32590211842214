(function() {
  'use strict';
  angular
    .module('app.remote-analyzer')
    .component('getStreamLinkComponent', {
      templateUrl: 'app/remote-analyzer/getStreamLink.html',
      controller: getStreamLinkController,
      controllerAs: '$ctrl',
      bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<'
      }
    });

  getStreamLinkController.$inject = ['remoteAnalyzerService'];

  function getStreamLinkController(remoteAnalyzerService) {
    var vm = this;
    vm.key = "Waiting...";
    vm.server = "Waiting...";
    vm.link = "Waiting...";
    vm.info = "";
    vm.keyOK = false;
    vm.parentController;
    
    vm.$onInit = function() {
      vm.parentController = vm.resolve.parentController;
      remoteAnalyzerService.getRTMPCredentials(vm.resolve.currentUser)
      .then((response) => {
        if (response.status == 200) {
          vm.info = "Key becomes invalid after an hour of inactivity";
          vm.key = response.data.key;
          vm.server = response.data.server;
          vm.link = response.data.link;
          vm.keyOK = true;
        } else {
          vm.keyOK = false;
          Promise.reject("Invalid response (" + response.status + ")");
        }
      })
      .catch((error) => {
        vm.info = "Something went wrong, please try again later";
        vm.key = "Not available";
        vm.server = "Not available";
        vm.link = "Not available";
        vm.keyOK = false;
      });
    }

    vm.copyToClipboard = function(copiedElementID) {
      document.getElementById(copiedElementID).select();
      document.execCommand('copy');
      vm.info = copiedElementID + " copied to clipboard";
    };

    vm.onClickedDone = function() {
      if (vm.keyOK) {
        vm.parentController.streamURL = vm.link;
      }
      vm.close();
    }
  }
})();
