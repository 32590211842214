(function() {

  // based on ngIf directive

  angular.module('app.core')
    .directive('hasRight', hasRight);

  hasRight.$inject = ['$animate', '$compile', 'authenticatedUser'];

  function hasRight($animate, $compile, authenticatedUser) {
    return {
      multiElement: true,
      restrict: 'A',
      priority: 800,
      terminal: true,
      transclude: 'element',
      $$tlb: true,
      link: function($scope, $element, $attr, ctrl, $transclude) {
        var block, childScope, previousElements;
        $scope.$watch($attr.hasRight, function hasRightWatchAction(value) {

          if (authenticatedUser.hasRightFor(value)) {
            if (!childScope) {
              $transclude(function(clone, newScope) {
                childScope = newScope;
                clone[clone.length++] = $compile.$$createComment('end hasRight', $attr.hasRight);
                block = {clone: clone};
                $animate.enter(clone, $element.parent(), $element);
              });
            }
          } else {
            if (previousElements) {
              previousElements.remove();
              previousElements = null;
            }
            if (childScope) {
              childScope.$destroy();
              childScope = null;
            }
            if (block) {
              previousElements = getBlockNodes(block.clone);
              $animate.leave(previousElements).done(function(response) {
                if (response !== false) {
                  previousElements = null;
                }
              });
              block = null;
            }
          }
        });
      }
    };
  }

  function getBlockNodes(nodes) {
    var node = nodes[0];
    var endNode = nodes[nodes.length - 1];
    var blockNodes = [node];

    do {
      node = node.nextSibling;
      if (!node) break;
      blockNodes.push(node);
    } while (node !== endNode);

    return angular.element(blockNodes);
  }

})();
