(function() {

  angular.module('app.core')
    .directive('hideIf', hideIf);

  hideIf.$inject = ['CONF', '$parse'];

  function hideIf(CONF, $parse) {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        if (!attrs.hideIf) {
          return;
        }
        var hideFeature = $parse(attrs.hideIf)(scope);
        if (CONF.hideFeatures.indexOf(hideFeature) !== -1) {
          element.remove();
        }
      }
    };
  }

})();
