(function() {
  angular
    .module('app.core')
    .service('ngCopy', ['$window', function($window) {
      var body = angular.element($window.document.body);
      var textarea = angular.element('<textarea/>');
      textarea.css({
        position: 'fixed',
        opacity: '0'
      });

      return function(toCopy) {
        textarea.val(toCopy);
        body.append(textarea);
        textarea[0].select();

        try {
          var successful = document.execCommand('copy');
          if (!successful) {
            throw successful;
          }
        } catch (err) {
          window.prompt('Copy to clipboard: Ctrl+C, Enter', toCopy);
        }

        textarea.remove();
      };
    }])
    .directive('ngClickCopy', ['ngCopy', 'logger', function(ngCopy, logger) {
      return {
        restrict: 'A',
        link: function(scope, element, attrs) {
          element.bind('click', function() {
            ngCopy(attrs.ngClickCopy);
            logger.info('Copied to clipboard!');
          });
        }
      };
    }])
    .filter('cut', function() {
      return function(value, wordwise, max, tail) {
        if (!value) {
          return '';
        }

        max = parseInt(max, 10);
        if (!max) {
          return value;
        }
        if (value.length <= max) {
          return value;
        }

        value = value.substr(0, max);
        if (wordwise) {
          var lastspace = value.lastIndexOf(' ');
          if (lastspace !== -1) {
            //Also remove . and , so its gives a cleaner result.
            if (value.charAt(lastspace - 1) === '.' || value.charAt(lastspace - 1) === ',') {
              lastspace = lastspace - 1;
            }
            value = value.substr(0, lastspace);
          }
        }

        return value + (tail || ' …');
      };
    });
})();
