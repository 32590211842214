(function() {
  'use strict';

  angular
    .module('app.profile')
    .controller('ApiAccessController', ApiAccessController);

    ApiAccessController.$inject = ['$state', '$window', '$timeout', 'authService'];

  function ApiAccessController($state, $window, $timeout, authService) {
    var vm = this;

    vm.userEmail = $window.localStorage['currentUserEmail'];
    vm.currentStateName = $state.current.name;
    vm.user = null;
    vm.copiedTooltipOpen = false;
    vm.keyBlurEnabled = true;

    vm.getUser = function() {
      return authService.getUserFromDB(vm.userEmail).then((res) => {
        return vm.user = res.data;
      })
    }

    vm.$onInit = function() {
      return vm.getUser().then(() => console.log(vm.user));
    };

    vm.copyKeyToClipboard = function() {
      navigator.clipboard.writeText(vm.user.apiAccessKey);
      vm.copiedTooltipOpen = true;
      $timeout(function () {
        vm.copiedTooltipOpen = false;
      }, 3000);
    };
  }
})();
