(function() {
  'use strict';
  angular
    .module('app.admin')
    .component('transferOwnershipComponent', {
      templateUrl: 'app/admin/transfer-ownership.html',
      controller: transferOwnerchipController,
      controllerAs: '$ctrl',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
    });

  transferOwnerchipController.$inject = ['$window', '$q', 'fullVideosService', 'logger'];

  function transferOwnerchipController($window, $q, fullVideosService, logger) {
    var vm = this;
    vm.controllerName = 'showTasks';

    vm.$onInit = function() {
      vm.fullVideo = vm.resolve.inFullVideo;
      vm.fullVideos = vm.resolve.fullVideos;
      vm.users = vm.resolve.inUsers;

      vm.transferRecord = {
        transferType: 'move', // copy
        exOwnerUserID: vm.fullVideo ? vm.fullVideo.userID : vm.fullVideos[0].userID,
        newOwnerUserID: null,
        fullVideoID: vm.fullVideo && vm.fullVideo.ID,
        description: null
      };
    };

    vm.selectUser = function(inUser) {
      vm.transferRecord.newOwnerUserID = inUser.email;
    };

    vm.groupUser = function(inUser) {
      return inUser.profile || 'Other';
    };

    vm.save = function() {
      if (vm.isSaving) {
        return;
      }
      if (!$window.confirm('Are you sure to ' + vm.transferRecord.transferType + ' the video file and clips to the new owner?')) {
        return;
      }
      vm.isSaving = true;
      var promises = [];

      if (vm.transferRecord.fullVideoID) {
        promises = [fullVideosService.transferOwnership(vm.transferRecord)];
      } else {
        promises = vm.fullVideos.map(function (fv) {
          return fullVideosService.transferOwnership({
            transferType: vm.transferRecord.transferType,
            exOwnerUserID: vm.transferRecord.exOwnerUserID,
            newOwnerUserID: vm.transferRecord.newOwnerUserID,
            fullVideoID: fv.ID,
            description: vm.transferRecord.description
          });
        });
      }
      return $q.all(promises)
        .then(function() {
          logger.info('Transfer Complete');        
          vm.close();
        })
        .catch(function(res) {
          logger.warn(res.error);
        })
        .finally(function() {
          vm.isSaving = false;
        });
    };
  }
})();
