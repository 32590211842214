(function() {
  'use strict';

  angular
    .module('app.core')
    .controller('tosController', function ($uibModalInstance) {
      var vm = this;

      vm.accept = function () {
        $uibModalInstance.close();
      };

      vm.disagree = function () {
        $uibModalInstance.dismiss('cancel');
      };
    });
})();
