(function() {

  angular.module('app.core')
    .directive('autoComplete', autocomplete)
    .directive('ngFocus', ['$timeout', function($timeout) {
      return {
        link: function(scope, element, attrs) {
          scope.$watch(attrs.ngFocus, function(val) {
            if (angular.isDefined(val) && val) {
              $timeout(function() {
                element[0].focus();
              });
            }
          }, true);

          element.bind('blur', function() {
            if (angular.isDefined(attrs.ngFocusLost)) {
              scope.$apply(attrs.ngFocusLost);

            }
          });
        }
      };
    }]);

  autocomplete.$inject = ['$timeout'];

  function autocomplete() {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        autoCompleteItems: '=',
      },
      link: function(scope, element, attrs, ngModelCtrl) {
        scope.$watch(
          function() {
            return scope.autoCompleteItems;
          },
          function(notEmpty) {
            if (notEmpty) {
              element.autocomplete({
                source: scope.autoCompleteItems,
                minLength: 0,
                select: function(event, ui) {
                  ngModelCtrl.$setViewValue(ui.item.value);
                  scope.$apply();
                }
              }).focus(function() {
                element.autocomplete('search', ngModelCtrl.$modelValue);
              });
            }
          }
        );
      }
    };
  }
})();
