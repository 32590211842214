(function () {
  'use strict';
  angular
    .module('app.admin')
    .component('editUserComponent', {
      templateUrl: 'app/admin/edit-user.html',
      controller: editUserController,
      controllerAs: '$ctrl',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
    });

  editUserController.$inject = ['$window', 'authService', 'logger', 'adminService'];

  function editUserController($window, authService, logger, adminService) {
    var vm = this;
    vm.controllerName = 'editUser';
    vm.saving = false;
    vm.changingApiAccessKeyStatus = false;

    vm.$onInit = function () {
      vm.user = JSON.parse(JSON.stringify(vm.resolve.inUser));
      vm.defaults = vm.resolve.inDefaults;
      vm.user.userSettings = vm.user.userSettings || vm.defaults || {};
    };

    vm.save = function () {
      vm.saving = true;
      return authService.updateUser(vm.user).then(function () {
        Object.assign(vm.resolve.inUser, vm.user);
        vm.close();
      }).catch(logger.error)
      .finally(() => {
        vm.saving = false;
      })
    };

    vm.activateApiKey = function () {
      vm.changingApiAccessKeyStatus = true;
      return adminService.createApiAccessKey(vm.user.email)
        .then((result) => vm.user.apiAccessKeyID = result.data.ID)
        .catch((err) => logger.error(err.message || err))
        .finally(() => {
          vm.changingApiAccessKeyStatus = false;
        })
    }

    vm.deactivateApiKey = function () {
      if ($window.confirm(`Are you sure you want to revoke this user\'s (${vm.user.email}) API Access Key?`)) {
        vm.changingApiAccessKeyStatus = true;
        return adminService.deleteApiAccessKey(vm.user.apiAccessKeyID)
          .then(() => vm.user.apiAccessKeyID = null)
          .catch((err) => logger.error(err.message || err))
          .finally(() => {
            vm.changingApiAccessKeyStatus = false;
          })
      }
      return;
    }
  }
})();
